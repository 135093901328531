import React, { useState } from "react";
import Header from "../Header";
import Sidebar from "../Sidebar";
import { Link, useNavigate } from "react-router-dom";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";

import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { getAccounts } from "../../connections/getAccounts";
import { useQuery } from "@tanstack/react-query";
import Select from "react-select";
import { postAddPharmacy } from "../../connections/postAddPharmacy";

const AddPharmacies = () => {
  const {
    user: { user },
  } = useSelector((store) => store);
  const navigate = useNavigate();
  const [formData, setFormData] = useState({});
  const { data: accountsData, isLoading: isLoading } = useQuery({
    queryKey: ["getAccounts"],
    queryFn: () => getAccounts(user?.token, null, 1, 100, "pharmacist"), // todo right 100 data in future we chnage to search
    refetchOnWindowFocus: false, // Don't refetch when window regains focus
    refetchOnReconnect: false, // Don't refetch when reconnecting
  });

  const handleChange = (name, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(formData, formData?.account_ids?.length);
    if (!formData?.name) {
      toast.error("Name is required");
      return;
    }
    if (!formData?.gstin_no) {
      toast.error("GST In No. is required");
      return;
    }
    if (!formData?.dl_no) {
      toast.error("DL No is required");
      return;
    }
    if (formData?.account_ids?.length <= 0) {
      toast.error("Minimum One Account is required");
      return;
    }

    const payload = {
      pharmacy: formData,
    };

    try {
      const result = await postAddPharmacy(user?.token, payload);
      if (result?.status?.status === "SUCCESS") {
        toast.success("Pharmacy Added Successfully");
        navigate("/admin/pharmacies");
        setFormData({
          title: "",
        });
      }
    } catch (error) {
      // toast.error(error.message);
      console.log(error.message);
    }
  };
  const accountOptions = accountsData?.payload?.accounts?.map((el) => ({
    label: el?.uid,
    value: el.id,
  }));

  //   console.log(formData, accountOptions);
  return (
    <>
      <Header />
      <Sidebar activeClassName="pharmacies-list" />
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="row">
              <div className="col-sm-12">
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to={-1}>Pharmacy</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <i className="feather-chevron-right">
                      <FeatherIcon icon="chevron-right" />
                    </i>
                  </li>
                  <li className="breadcrumb-item active">Add Pharmacy</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <div className="card">
                <div className="card-body">
                  <form onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="col-12">
                        <div className="form-heading">
                          <h4>Pharmacy</h4>
                        </div>
                      </div>
                      <div className="col-12 col-md-6 col-xl-6">
                        <div className="form-group local-forms">
                          <label>
                            Name <span className="login-danger">*</span>
                          </label>
                          <input
                            name="name"
                            value={formData.name}
                            onChange={(e) =>
                              handleChange("name", e.target.value)
                            }
                            className="form-control"
                            type="text"
                            required={true}
                          />
                        </div>
                      </div>
                      <div className="col-12 col-md-6 col-xl-6">
                        <div className="form-group local-forms">
                          <label>
                            GST IN No <span className="login-danger">*</span>
                          </label>
                          <input
                            name="gstin_no"
                            value={formData.gstin_no}
                            onChange={(e) =>
                              handleChange("gstin_no", e.target.value)
                            }
                            className="form-control"
                            type="text"
                            required={true}
                          />
                        </div>
                      </div>
                      <div className="col-12 col-md-6 col-xl-6">
                        <div className="form-group local-forms">
                          <label>
                            DL No <span className="login-danger">*</span>
                          </label>
                          <input
                            name="dl_no"
                            value={formData.dl_no}
                            onChange={(e) =>
                              handleChange("dl_no", e.target.value)
                            }
                            className="form-control"
                            type="text"
                            required={true}
                          />
                        </div>
                      </div>
                      <div className="col-12 col-md-6 col-xl-6">
                        <div className="form-group local-forms">
                          <label>
                            Account <span className="login-danger">*</span>
                          </label>
                          <Select
                            isLoading={isLoading}
                            value={
                              accountOptions?.filter((option) =>
                                formData?.account_ids?.includes(option.value)
                              ) || []
                            }
                            isClearable
                            isMulti
                            onChange={(selectedOptions) => {
                              // Handle the selected options as an array
                              //   console.log(selectedOptions, "selectedOptions");
                              const selectedIds = selectedOptions
                                ? selectedOptions.map((opt) => opt.value)
                                : [];
                              handleChange("account_ids", selectedIds);
                            }}
                            options={accountOptions}
                            menuPortalTarget={document.body}
                            styles={{
                              menu: (provided) => ({
                                ...provided,
                                zIndex: 9999,
                              }),
                              menuPortal: (base) => ({
                                ...base,
                                zIndex: 999999,
                              }),
                              control: (baseStyles, state) => ({
                                ...baseStyles,
                                borderColor: state.isFocused
                                  ? "#2e37a4"
                                  : "rgba(46, 55, 164, 0.1)",
                                boxShadow: state.isFocused
                                  ? "0 0 0 1px #2e37a4"
                                  : "none",
                                "&:hover": {
                                  borderColor: "rgba(46, 55, 164, 0.1)",
                                },
                                borderRadius: "10px",
                                fontSize: "14px",
                                minHeight: "45px",
                              }),
                              dropdownIndicator: (base, state) => ({
                                ...base,
                                transform: state.selectProps.menuIsOpen
                                  ? "rotate(-180deg)"
                                  : "rotate(0)",
                                transition: "250ms",
                                width: "35px",
                                height: "35px",
                              }),
                              option: (provided) => ({
                                ...provided,
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                maxWidth: "100%",
                                fontSize: "14px",
                              }),
                            }}
                            id="search-commodity"
                            components={{
                              IndicatorSeparator: () => null,
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="doctor-submit text-end">
                          <button
                            type="submit"
                            className="btn btn-primary submit-form me-2"
                          >
                            Submit
                          </button>
                          <button
                            type="button"
                            onClick={() => navigate("/admin/pharmacies")}
                            className="btn btn-primary cancel-form"
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddPharmacies;

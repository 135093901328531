import { useState } from "react";
import Header from "../Header";
import Sidebar from "../Sidebar";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { DatePicker } from "antd";
import dayjs from "dayjs";
import { postHoliday } from "../../connections/postHoliday";
const AddHoliday = () => {
  const {
    user: { user },
  } = useSelector((store) => store);
  const [formState, setFormState] = useState({});
  const navigate = useNavigate();
  const handleChange = (name, value) => {
    setFormState((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const isEndDateValid = (startDate, endDate) => {
    if (!startDate || !endDate) return false; // Ensure both dates are provided
    return dayjs(endDate).isAfter(dayjs(startDate)); // Check if end date is after start date
  };

  const handleAddHoliday = async (e) => {
    e.preventDefault();

    if (!isEndDateValid(formState.date, formState.end_date)) {
      toast.error("End date should be greater than start date");
      return;
    }
    const payload = {
      holiday: formState,
    };

    try {
      const data = await postHoliday(user?.token, payload);
      if (data.status.status === "SUCCESS") {
        toast.success("Holiday Added Successfully");
        setFormState({});
        navigate("/admin/holidays");
      }
    } catch (error) {
      // toast.error(error.message);
      console.log(error.message);
    }
  };
  return (
    <>
      <Header />
      <Sidebar activeClassName="holidays-list" />
      <>
        <div className="page-wrapper">
          <div className="content">
            {/* Page Header */}
            <div className="page-header">
              <div className="row">
                <div className="col-sm-12">
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to={-1}>Holidays </Link>
                    </li>
                    <li className="breadcrumb-item">
                      <i className="feather-chevron-right">
                        <FeatherIcon icon="chevron-right" />
                      </i>
                    </li>
                    <li className="breadcrumb-item active">Add Holidays</li>
                  </ul>
                </div>
              </div>
            </div>
            {/* /Page Header */}
            <div className="row">
              <div className="col-sm-12">
                <div className="card">
                  <div className="card-body">
                    <form>
                      <div className="row">
                        <div className="col-12">
                          <div className="form-heading">
                            <h4>Add Holidays</h4>
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-4">
                          <div className="form-group local-forms">
                            <label>
                              Date
                              <span className="login-danger">*</span>
                            </label>
                            <DatePicker
                              value={
                                formState.date ? dayjs(formState.date) : null
                              }
                              onChange={(_, dateString) =>
                                handleChange("date", dateString)
                              }
                              className="form-control datetimepicker"
                              suffixIcon={null}
                              style={{
                                borderColor: "rgba(46, 55, 164, 0.1)",
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-4">
                          <div className="form-group local-forms">
                            <label>
                              End Date
                              <span className="login-danger">*</span>
                            </label>
                            <DatePicker
                              value={
                                formState.end_date
                                  ? dayjs(formState.end_date)
                                  : null
                              }
                              onChange={(_, dateString) =>
                                handleChange("end_date", dateString)
                              }
                              className="form-control datetimepicker"
                              suffixIcon={null}
                              style={{
                                borderColor: "rgba(46, 55, 164, 0.1)",
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-4">
                          <div className="form-group local-forms">
                            <label>
                              Details
                              {/* <span className="login-danger">*</span> */}
                            </label>
                            <input
                              value={formState.details}
                              onChange={(e) =>
                                handleChange("details", e.target.value)
                              }
                              className="form-control"
                              type="text"
                            />
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="doctor-submit text-end">
                            <button
                              onClick={handleAddHoliday}
                              type="submit"
                              className="btn btn-primary submit-form me-2"
                            >
                              Create Holiday
                            </button>
                            <button
                              onClick={(e) => {
                                e.preventDefault();
                                navigate("/admin/holidays");
                              }}
                              type="submit"
                              className="btn btn-primary cancel-form"
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </>
  );
};

export default AddHoliday;

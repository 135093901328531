import React from "react";
import Header from "../Header";
import Sidebar from "../Sidebar";
import { Link, useParams } from "react-router-dom";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { useQuery } from "@tanstack/react-query";

import { useSelector } from "react-redux";

import { Skeleton } from "@mui/material";

import dayjs from "dayjs";
import { getConsumableCategoryDetail } from "../../connections/getConsumableCategoryDetail";
// import { Table } from "antd";

const ShowConsumableCategory = () => {
  const {
    user: { user },
  } = useSelector((store) => store);
  const { id } = useParams();
  const { data: getConsumableDetails, isLoading } = useQuery({
    queryKey: ["getConsumableCategoryDetail", id],
    queryFn: () => getConsumableCategoryDetail(user?.token, id),
    enabled: !!id,
    cacheTime: 1000 * 60 * 60,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchOnMount: false,
  });
  // const columns = [
  //   {
  //     title: "ID",
  //     dataIndex: "id",
  //     key: "id",
  //   },
  //   {
  //     title: "Name",
  //     dataIndex: "name",
  //     key: "name",
  //   },
  //   {
  //     title: "Code",
  //     dataIndex: "code",
  //     key: "code",
  //   },
  //   {
  //     title: "Created At",
  //     dataIndex: "created_at",
  //     key: "created_at",
  //     render: (created_at) =>
  //       created_at &&
  //       dayjs(created_at, "YYYY-MM-DD HH:mm:ss A").format(
  //         "MMMM DD, YYYY HH:mm"
  //       ),
  //   },
  //   {
  //     title: "Updated At",
  //     dataIndex: "updated_at",
  //     key: "updated_at",
  //     render: (updated_at) =>
  //       updated_at &&
  //       dayjs(updated_at, "YYYY-MM-DD HH:mm:ss A").format(
  //         "MMMM DD, YYYY HH:mm"
  //       ),
  //   },
  // ];
  return (
    <>
      <Header />
      <Sidebar activeClassName="consumable-category-list" />
      <>
        <div className="page-wrapper">
          <div className="content">
            {/* Page Header */}
            <div className="page-header">
              <div className="row">
                <div className="col-sm-12">
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item active">
                      <Link to={-1}>Consumable Category Details</Link>
                    </li>
                    <li className="breadcrumb-item">
                      <i className="feather-chevron-right">
                        <FeatherIcon icon="chevron-right" />
                      </i>
                    </li>
                    <li className="breadcrumb-item active">{id}</li>
                  </ul>
                </div>
              </div>
            </div>
            {/* /Page Header */}
            <div className="row">
              <div className="col-sm-12">
                <div className="card card-table show-entire">
                  <div className="card-body">
                    {/* content Header */}
                    <div className="page-table-header mb-2">
                      <div className="row align-items-center">
                        <div className="col">
                          <div className="doctor-table-blk">
                            <h3>Consumable Category Details</h3>
                            <div className="doctor-search-blk"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {isLoading ? (
                      <div>
                        <Skeleton
                          variant="rectangular"
                          width="100%"
                          height={430}
                        />
                      </div>
                    ) : (
                      <div className="content-details">
                        <div className="col-lg-12">
                          <div className="card-box">
                            <div className="card-block">
                              <ul className="personal-info">
                                <li className="pb-1">
                                  <span className="title">ID:</span>{" "}
                                  <span className="text">
                                    {getConsumableDetails?.id || "Empty"}
                                  </span>
                                </li>
                                <li className="pb-1">
                                  <span className="title">Name:</span>{" "}
                                  <span className="text">
                                    {getConsumableDetails?.name || "Empty"}
                                  </span>
                                </li>
                                <li className="pb-1">
                                  <span className="title">Code:</span>{" "}
                                  <span className="text">
                                    {getConsumableDetails?.code || "Empty"}
                                  </span>
                                </li>
                                <li className="pb-1">
                                  <span className="title">Created At:</span>{" "}
                                  <span className="text">
                                    {getConsumableDetails?.created_at
                                      ? dayjs(
                                          getConsumableDetails.created_at,
                                          "YYYY-MM-DD HH:mm:ss A"
                                        ).format("MMMM DD, YYYY HH:mm")
                                      : "Empty"}
                                  </span>
                                </li>
                                <li className="pb-1">
                                  <span className="title">Updated At:</span>{" "}
                                  <span className="text">
                                    {getConsumableDetails?.updated_at
                                      ? dayjs(
                                          getConsumableDetails.updated_at,
                                          "YYYY-MM-DD HH:mm:ss A"
                                        ).format("MMMM DD, YYYY HH:mm")
                                      : "Empty"}
                                  </span>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      // <div className="table-responsive">
                      //   <Table
                      //     columns={columns}
                      //     dataSource={[getConsumableDetails]}
                      //     pagination={false}
                      //     scroll={{ x: "max-content" }}
                      //   />
                      // </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </>
  );
};
export default ShowConsumableCategory;

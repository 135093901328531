import React, { useEffect } from "react";
import ApexCharts from "apexcharts";
import PropTypes from "prop-types";

const MedicineChart = ({ getMedicineSalesData }) => {
  useEffect(() => {
    const chartElement = document?.querySelector("#medicine-chart");

    const dates = getMedicineSalesData?.medicine_sales?.map(
      (item) => item.sale_date
    );
    const sales = getMedicineSalesData?.medicine_sales?.map((item) =>
      Math.abs(item.total_sales)
    );

    // Render the chart only if data is available
    if (chartElement) {
      const sColStackedOptions = {
        chart: {
          height: 230,
          type: "area",
          stacked: true,
          toolbar: {
            show: false,
          },
        },
        stroke: {
          curve: "smooth",
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              legend: {
                position: "bottom",
                offsetX: -10,
                offsetY: 0,
              },
            },
          },
        ],
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "15%",
          },
        },
        dataLabels: {
          enabled: false,
        },
        series: [
          {
            name: "Sales",
            color: "#2E37A4",
            data: sales?.length > 0 ? sales : [0], // Ensure data array is not empty
          },
        ],
        xaxis: {
          categories: dates?.length > 0 ? dates : [""], // Ensure categories array is not empty
        },
        yaxis: {
          labels: {
            formatter: function (value) {
              return value.toFixed(0);
            },
          },
        },
      };

      try {
        const chart = new ApexCharts(chartElement, sColStackedOptions);
        chart.render();
      } catch (error) {
        console.error("Error rendering chart:", error);
      }
    } else {
      console.error("Chart element not found");
    }
  }, [getMedicineSalesData]);

  return <div id="medicine-chart"></div>;
};

MedicineChart.propTypes = {
  getMedicineSalesData: PropTypes.object, // Expecting an object
};

export default MedicineChart;

import React from "react";
import Header from "../Header";
import Sidebar from "../Sidebar";
import { Link, useParams } from "react-router-dom";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { useQuery } from "@tanstack/react-query";

import { useSelector } from "react-redux";
import dayjs from "dayjs";
import { Skeleton } from "@mui/material";
import { Table } from "antd";
import { onShowSizeChange, itemRender } from "../Pagination";
import { getMedicineDetails } from "../../connections/getMedicineDetails";
const MedicineDetail = () => {
  const {
    user: { user },
  } = useSelector((store) => store);
  const { id } = useParams();

  const { data: getMedicineDetail, isLoading } = useQuery({
    queryKey: ["getMedicineDetails", id],
    queryFn: () => getMedicineDetails(user?.token, id),
    enabled: !!id,
    refetchOnWindowFocus: false, // Don't refetch when window regains focus
    refetchOnReconnect: false, // Don't refetch when reconnecting
  });
  const columns = [
    {
      title: "Sno",
      dataIndex: "id",
      key: "id",
      render: (text, record, index) => index + 1, // Display row index
    },
    {
      title: "Batch",
      dataIndex: "batch_no",
      key: "item",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "Flat Price",
      dataIndex: "flat_price",
      key: "flat_price",
    },
    {
      title: "Form Type",
      dataIndex: "form_type",
      key: "form_type",
    },
    {
      title: "Expiry",
      dataIndex: "expiry_date",
      key: "expiry_date",
      render: (date) => {
        if (!date) return null;

        const parsedDate = dayjs(date, "YYYY-MM-DD");
        const formattedDate = parsedDate.format("MMMM DD, YYYY");
        const isExpiringSoon = parsedDate.isBefore(dayjs().add(2, "month"));
        const style = isExpiringSoon
          ? {
              backgroundColor: "red",
              color: "white",
              padding: "5px 10px",
              borderRadius: "4px",
            }
          : {};

        return <span style={style}>{formattedDate}</span>;
      },
    },
    {
      title: "Packing",
      dataIndex: "packing",
      key: "packing",
    },
    {
      title: "Stock Left",
      dataIndex: "stock_left",
      key: "stock_left",
    },
  ];

  // const columnsMedicine = [
  //   {
  //     title: "ID",
  //     dataIndex: "id",
  //     key: "id",
  //     render: (text) => <strong>{text}</strong>,
  //   },
  //   {
  //     title: "Name",
  //     dataIndex: "name",
  //     key: "name",
  //     render: (text) => <strong>{text}</strong>,
  //   },
  //   {
  //     title: "Flat Price",
  //     dataIndex: "flat_price",
  //     key: "flat_price",
  //   },
  //   {
  //     title: "Discount",
  //     dataIndex: "discount",
  //     key: "discount",
  //   },
  //   {
  //     title: "Company",
  //     dataIndex: "company",
  //     key: "company",
  //   },
  //   {
  //     title: "SRP",
  //     dataIndex: "srp",
  //     key: "srp",
  //   },
  //   {
  //     title: "Total Stock Left",
  //     dataIndex: "total_stock_left",
  //     key: "total_stock_left",
  //   },
  //   {
  //     title: "Auto Purchase Threshold",
  //     dataIndex: "auto_purchase_threshold",
  //     key: "auto_purchase_threshold",
  //   },
  //   {
  //     title: "Medicine Salt",
  //     dataIndex: "medicine_salt",
  //     key: "medicine_salt",
  //     render: (_, record) => (
  //       <Link
  //         to={`/admin/medicine-salts/${record?.medicine_salt?.id}`}
  //         style={{
  //           whiteSpace: "normal",
  //           wordBreak: "break-word",
  //           maxWidth: "150px",
  //           display: "block",
  //           overflow: "hidden",
  //           textOverflow: "ellipsis",
  //         }}
  //       >
  //         {record?.medicine_salt?.name || ""}
  //       </Link>
  //     ),
  //   },
  //   {
  //     title: "GST",
  //     dataIndex: "gst",
  //     key: "gst",
  //   },
  //   {
  //     title: "HSN Name",
  //     dataIndex: "hsn_name",
  //     key: "hsn_name",
  //   },
  //   {
  //     title: "CGST",
  //     dataIndex: "cgst",
  //     key: "cgst",
  //   },
  //   {
  //     title: "SGST",
  //     dataIndex: "sgst",
  //     key: "sgst",
  //   },
  //   {
  //     title: "Created At",
  //     dataIndex: "created_at",
  //     key: "created_at",
  //     render: (text) => text || "Empty", // In case it's empty or null
  //   },
  //   {
  //     title: "Updated At",
  //     dataIndex: "updated_at",
  //     key: "updated_at",
  //   },
  // ];
  return (
    <>
      <Header />
      <Sidebar
        id="menu-item4"
        id1="menu-items4"
        activeClassName="medicine-list"
      />
      <>
        <div className="page-wrapper">
          <div className="content">
            {/* Page Header */}
            <div className="page-header">
              <div className="row">
                <div className="col-sm-12">
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to={-1}>Medicine</Link>
                    </li>
                    <li className="breadcrumb-item">
                      <i className="feather-chevron-right">
                        <FeatherIcon icon="chevron-right" />
                      </i>
                    </li>
                    <li className="breadcrumb-item active">
                      <Link to={-1}> Medicine Detail</Link>
                    </li>
                    <li className="breadcrumb-item">
                      <i className="feather-chevron-right">
                        <FeatherIcon icon="chevron-right" />
                      </i>
                    </li>
                    <li className="breadcrumb-item active">{id}</li>
                  </ul>
                </div>
              </div>
            </div>
            {/* /Page Header */}
            <div className="row">
              <div className="col-sm-12">
                <div className="card card-table show-entire">
                  <div className="card-body">
                    {/* content Header */}
                    <div className="page-table-header mb-2">
                      <div className="row align-items-center">
                        <div className="col">
                          <div className="doctor-table-blk">
                            <h3>Medicine Details</h3>
                            <div className="doctor-search-blk"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {isLoading ? (
                      <div>
                        <Skeleton
                          variant="rectangular"
                          width="100%"
                          height={430} // Same height as your chart
                        />
                      </div>
                    ) : (
                      <div className="content-details">
                        <div className="col-lg-12">
                          <div className="card-box">
                            <div className="card-block">
                              <ul className="personal-info">
                                <li className="pb-1">
                                  <span className="title">ID:</span>
                                  <span className="text">
                                    <strong>
                                      {getMedicineDetail?.id || "N/A"}
                                    </strong>
                                  </span>
                                </li>
                                <li className="pb-1">
                                  <span className="title">Name:</span>
                                  <span className="text">
                                    <strong>
                                      {getMedicineDetail?.name || "N/A"}
                                    </strong>
                                  </span>
                                </li>
                                <li className="pb-1">
                                  <span className="title">Flat Price:</span>
                                  <span className="text">
                                    {getMedicineDetail?.flat_price || "N/A"}
                                  </span>
                                </li>
                                <li className="pb-1">
                                  <span className="title">Discount:</span>
                                  <span className="text">
                                    {getMedicineDetail?.discount || "N/A"}
                                  </span>
                                </li>
                                <li className="pb-1">
                                  <span className="title">Company:</span>
                                  <span className="text">
                                    {getMedicineDetail?.company || "N/A"}
                                  </span>
                                </li>
                                <li className="pb-1">
                                  <span className="title">SRP:</span>
                                  <span className="text">
                                    {getMedicineDetail?.srp || "N/A"}
                                  </span>
                                </li>
                                <li className="pb-1">
                                  <span className="title">
                                    Total Stock Left:
                                  </span>
                                  <span className="text">
                                    {getMedicineDetail?.total_stock_left ||
                                      "N/A"}
                                  </span>
                                </li>
                                <li className="pb-1">
                                  <span className="title">
                                    Auto Purchase Threshold:
                                  </span>
                                  <span className="text">
                                    {getMedicineDetail?.auto_purchase_threshold ||
                                      "N/A"}
                                  </span>
                                </li>
                                <li className="pb-1">
                                  <span className="title">Medicine Salt:</span>
                                  <span className="text">
                                    {getMedicineDetail?.medicine_salt ? (
                                      <Link
                                        to={`/admin/medicine-salts/${getMedicineDetail.medicine_salt?.id}`}
                                        style={{
                                          whiteSpace: "normal",
                                          wordBreak: "break-word",
                                          maxWidth: "150px",
                                          display: "block",
                                          overflow: "hidden",
                                          textOverflow: "ellipsis",
                                        }}
                                      >
                                        {getMedicineDetail?.medicine_salt
                                          ?.name || "N/A"}
                                      </Link>
                                    ) : (
                                      "N/A"
                                    )}
                                  </span>
                                </li>
                                <li className="pb-1">
                                  <span className="title">GST:</span>
                                  <span className="text">
                                    {getMedicineDetail?.gst || "N/A"}
                                  </span>
                                </li>
                                <li className="pb-1">
                                  <span className="title">HSN Name:</span>
                                  <span className="text">
                                    {getMedicineDetail?.hsn_name || "N/A"}
                                  </span>
                                </li>
                                <li className="pb-1">
                                  <span className="title">CGST:</span>
                                  <span className="text">
                                    {getMedicineDetail?.cgst || "N/A"}
                                  </span>
                                </li>
                                <li className="pb-1">
                                  <span className="title">SGST:</span>
                                  <span className="text">
                                    {getMedicineDetail?.sgst || "N/A"}
                                  </span>
                                </li>
                                <li className="pb-1">
                                  <span className="title">Created At:</span>
                                  <span className="text">
                                    {getMedicineDetail?.created_at || "Empty"}
                                  </span>
                                </li>
                                <li className="pb-1">
                                  <span className="title">Updated At:</span>
                                  <span className="text">
                                    {getMedicineDetail?.updated_at || "N/A"}
                                  </span>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        {/* <div className="table-responsive">
                          <Table
                            columns={columnsMedicine}
                            dataSource={[getMedicineDetail]}
                            pagination={false}
                            scroll={{ x: "max-content" }}
                          />
                        </div> */}
                        {/* { items  list}  */}
                        {getMedicineDetail?.medicine_batches?.length > 0 && (
                          <div className="col-md-12 col-12 mb-2 p-2">
                            <h3 style={{ textDecoration: "underline" }}>
                              Batches
                            </h3>
                          </div>
                        )}
                        {getMedicineDetail?.medicine_batches?.length > 0 && (
                          <div className="table-responsive doctor-list">
                            <Table
                              id="table-to-print"
                              pagination={{
                                total:
                                  getMedicineDetail?.medicine_batches?.length,
                                showTotal: (total, range) =>
                                  `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                                //showSizeChanger: true,
                                onShowSizeChange: onShowSizeChange,
                                itemRender: itemRender,
                              }}
                              columns={columns}
                              dataSource={getMedicineDetail?.medicine_batches}
                              scroll={{ x: "max-content" }}
                              rowKey={(record) => record.id}
                            />
                          </div>
                        )}
                      </div>
                      // </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </>
  );
};

export default MedicineDetail;

import React, { useState } from "react";
import Header from "../Header";
import Sidebar from "../Sidebar";
import { DatePicker } from "antd";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import Select from "react-select";
import { Link, useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { postPatientDetails } from "../../connections/postPatientDetails";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useQuery } from "@tanstack/react-query";
import { getAllCity } from "../../connections/getAllCity";
import { getEducationList } from "../../connections/getEducationList";
import { getOccupations } from "../../connections/getOccupations";

const AddPatients = () => {
  const [formState, setFormState] = useState({});
  const {
    user: { user },
  } = useSelector((store) => store);
  const { data: getCity } = useQuery({
    queryKey: ["getAllCity"],
    queryFn: () => getAllCity(user?.token),

    // staleTime: Infinity, // Keep the data fresh indefinitely

    refetchOnWindowFocus: false, // Don't refetch when window regains focus
    refetchOnReconnect: false, // Don't refetch when reconnecting
  });
  const navigate = useNavigate();
  const dietOptions = [
    { value: "veg", label: "Veg" },
    { value: "non_veg", label: "Non Veg" },
    { value: "eggitarian", label: "Eggitarian" },
    { value: "vegan", label: "Vegan" },
  ];

  const [marital_status] = useState([
    { value: "unmarried", label: "Unmarried" },
    { value: "married", label: "Married" },
    { value: "divorced", label: "Divorced" },
  ]);
  // education options
  const { data: getAllEducation } = useQuery({
    queryKey: ["getEducationList", "all"],
    queryFn: () => getEducationList(user?.token, "all", null, null),
    enabled: !!user?.token,

    refetchOnWindowFocus: false, // Don't refetch when window regains focus
    refetchOnReconnect: false, // Don't refetch when reconnecting
  });
  const allEducationOptions = getAllEducation?.payload?.educations || [];
  const educationOptions = allEducationOptions?.map((el) => ({
    id: el?.id,
    value: el?.title,
  }));

  // Format options for react-select
  const formattedOptions = educationOptions.map((option) => ({
    label: option.value,
    value: option.id,
  }));

  ///occupations data
  const { data: getAllOccupations } = useQuery({
    queryKey: ["getOccupations", "all"],
    queryFn: () => getOccupations(user?.token, "all", null, null),
    enabled: !!user?.token,

    refetchOnWindowFocus: false, // Don't refetch when window regains focus
    refetchOnReconnect: false, // Don't refetch when reconnecting
  });

  const allOccupations = getAllOccupations?.payload?.occupations || [];
  const occupationOptions = allOccupations?.map((el) => ({
    id: el?.id,
    value: el?.title,
  }));
  // Formatting occupation options to match React Select's expected format
  const formattedOccupationOptions = occupationOptions.map((occupation) => ({
    value: occupation.id,
    label: occupation.value,
  }));

  // city options data
  const getAllCityData = getCity?.cities?.map((el) => ({
    value: el.name,
    label: el.name,
    id: el?.id,
  }));
  const cityOptions = getAllCityData || [];

  // Formatting city options to match React Select's expected format
  const formattedCityOptions = cityOptions.map((city) => ({
    value: city.id,
    label: city.value,
  }));
  const handleFormChange = (field, val) => {
    setFormState((prev) => ({
      ...prev,
      [field]: val,
    }));
  };
  const handleSubmitPatientDetails = async (e) => {
    e.preventDefault();
    if (
      !formState.first_name ||
      !formState?.last_name ||
      !formState?.age ||
      !formState?.mobile
    ) {
      toast.error("Full Name, DOB and mobile is required");
      console.log(formState);
      return;
    }
    const payload = {
      patient: {
        ...formState,
        full_name: formState?.first_name + " " + formState?.last_name,
      },
    };
    try {
      const data = await postPatientDetails(user?.token, payload);
      if (data.status.status === "SUCCESS") {
        toast.success("Patient Added Successfully");
        setFormState({});
        navigate("/patientslist");
      }
    } catch (error) {
      // toast.error(error.message);
      console.log(error.message);
    }
  };
  return (
    <div>
      <Header />
      <Sidebar
        id="menu-item2"
        id1="menu-items2"
        activeClassName="patient-list"
      />
      <>
        <div className="page-wrapper">
          <div className="content">
            {/* Page Header */}
            <div className="page-header">
              <div className="row">
                <div className="col-sm-12">
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to={-1}>Patients </Link>
                    </li>
                    <li className="breadcrumb-item">
                      <i className="feather-chevron-right">
                        <FeatherIcon icon="chevron-right" />
                      </i>
                    </li>
                    <li className="breadcrumb-item active">Add Patient</li>
                  </ul>
                </div>
              </div>
            </div>
            {/* /Page Header */}
            <div className="row">
              <div className="col-sm-12">
                <div className="card">
                  <div className="card-body">
                    <form>
                      <div className="row">
                        <div className="col-12">
                          <div className="form-heading">
                            <h4>Patients Details</h4>
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-4">
                          <div className="form-group local-forms">
                            <label>
                              First Name
                              <span className="login-danger">*</span>
                            </label>
                            <input
                              name="first_name"
                              value={formState?.first_name}
                              onChange={(e) =>
                                handleFormChange("first_name", e.target.value)
                              }
                              className="form-control"
                              type="text"
                              required={true}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-4">
                          <div className="form-group local-forms">
                            <label>
                              Last Name
                              <span className="login-danger">*</span>
                            </label>
                            <input
                              name="last_name"
                              value={formState.last_name}
                              onChange={(e) =>
                                handleFormChange("last_name", e.target.value)
                              }
                              className="form-control"
                              type="text"
                              required={true}
                            />
                          </div>
                        </div>
                        {/* <div className="col-12 col-md-6 col-xl-4">
                          <div className="form-group local-forms">
                            <label>
                              Uid <span className="login-danger">*</span>
                            </label>
                            <input
                              className="form-control"
                              type="number"
                              name=""
                              value={formState?.uid}
                              onChange={handleFormChange}
                            />
                          </div>
                        </div> */}
                        <div className="col-12 col-md-6 col-xl-6">
                          <div className="form-group local-forms">
                            <label>
                              Mobile <span className="login-danger">*</span>
                            </label>
                            <input
                              className="form-control"
                              type="text"
                              name="mobile"
                              value={formState?.mobile}
                              onChange={(e) =>
                                handleFormChange("mobile", e.target.value)
                              }
                              required={true}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-6">
                          <div className="form-group local-forms">
                            <label>
                              Email
                              {/* <span className="login-danger">*</span> */}
                            </label>
                            <input
                              className="form-control"
                              type="email"
                              placeholder="email"
                              name="email"
                              value={formState?.email}
                              onChange={(e) =>
                                handleFormChange("email", e.target.value)
                              }
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-6">
                          <div className="form-group local-forms">
                            <label>
                              Diet
                              {/* <span className="login-danger">*</span> */}
                            </label>
                            <Select
                              value={
                                dietOptions.find(
                                  (option) => option.value === formState.diet
                                ) || null
                              }
                              onChange={(e) =>
                                handleFormChange("diet", e?.value)
                              }
                              name="diet"
                              options={dietOptions}
                              placeholder="Select Diet"
                              components={{
                                IndicatorSeparator: () => null, // Hide separator line between the dropdown indicator and the control
                              }}
                              styles={{
                                control: (baseStyles, state) => ({
                                  ...baseStyles,
                                  // zIndex: 99999,
                                  borderColor: state.isFocused
                                    ? "none"
                                    : "2px solid rgba(46, 55, 164, 0.1)",
                                  boxShadow: state.isFocused
                                    ? "0 0 0 1px #2e37a4"
                                    : "none",
                                  "&:hover": {
                                    borderColor: state.isFocused
                                      ? "none"
                                      : "2px solid rgba(46, 55, 164, 0.1)",
                                  },
                                  borderRadius: "10px",
                                  fontSize: "14px",
                                  minHeight: "45px",
                                }),
                                dropdownIndicator: (base, state) => ({
                                  ...base,
                                  transform: state.selectProps.menuIsOpen
                                    ? "rotate(-180deg)"
                                    : "rotate(0)",
                                  transition: "250ms",
                                  width: "35px",
                                  height: "35px",
                                  zIndex: 9999,
                                }),
                                menu: (base) => ({
                                  ...base,
                                  zIndex: 9999, // Set a high z-index value here
                                }),
                              }}
                              isClearable
                            />
                          </div>
                        </div>

                        <div className="col-12 col-md-6 col-xl-6">
                          <div className="form-group local-forms cal-icon">
                            <label>
                              Date of birth
                              {/* <span className="login-danger">*</span> */}
                            </label>
                            {/* <input
                              className="form-control"
                              type="number"
                              placeholder=""
                              name="age"
                              value={formState?.age}
                              onChange={(e) => {
                                const value = e.target.value;
                                if (
                                  value === "" ||
                                  /^[0-9]{0,3}$/.test(value)
                                ) {
                                  handleFormChange("age", value);
                                }
                              }}
                            /> */}
                            <DatePicker
                              value={
                                formState.dob ? dayjs(formState.dob) : null
                              }
                              className="form-control datetimepicker"
                              onChange={(_, dateString) =>
                                handleFormChange("dob", dateString)
                              }
                              suffixIcon={null}
                              style={{
                                control: (baseStyles, state) => ({
                                  ...baseStyles,
                                  borderColor:
                                    "2px solid rgba(46, 55, 164, 0.1)",
                                  "&:hover": {
                                    borderColor: state.isFocused
                                      ? "none"
                                      : "none",
                                  },
                                }),
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-6">
                          <div className="form-group select-gender">
                            <label className="gen-label">
                              Gender
                              {/* <span className="login-danger">*</span> */}
                            </label>
                            <div className="form-check-inline">
                              <label className="form-check-label">
                                <input
                                  type="radio"
                                  name="gender"
                                  value="male"
                                  checked={formState.gender === "male"}
                                  onChange={(e) =>
                                    handleFormChange("gender", e.target.value)
                                  }
                                  className="form-check-input"
                                />
                                Male
                              </label>
                            </div>
                            <div className="form-check-inline">
                              <label className="form-check-label">
                                <input
                                  type="radio"
                                  name="gender"
                                  value="female"
                                  checked={formState.gender === "female"}
                                  onChange={(e) =>
                                    handleFormChange("gender", e.target.value)
                                  }
                                  className="form-check-input"
                                />
                                Female
                              </label>
                            </div>
                            <div className="form-check-inline">
                              <label className="form-check-label">
                                <input
                                  type="radio"
                                  name="gender"
                                  value="other"
                                  checked={formState.gender === "other"}
                                  onChange={(e) =>
                                    handleFormChange("gender", e.target.value)
                                  }
                                  className="form-check-input"
                                />
                                Other
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-4">
                          <div className="form-group local-forms">
                            <label>
                              Education
                              {/* <span className="login-danger">*</span> */}
                            </label>
                            <Select
                              value={formattedOptions.find(
                                (option) =>
                                  option.value === formState?.education_id
                              )}
                              onChange={(e) =>
                                handleFormChange("education_id", e.value)
                              }
                              options={formattedOptions}
                              id="education-select"
                              components={{
                                IndicatorSeparator: () => null,
                              }}
                              styles={{
                                control: (baseStyles, state) => ({
                                  ...baseStyles,
                                  borderColor: state.isFocused
                                    ? "#2e37a4"
                                    : "rgba(46, 55, 164, 0.1)",
                                  boxShadow: state.isFocused
                                    ? "0 0 0 1px #2e37a4"
                                    : "none",
                                  borderRadius: "10px",
                                  fontSize: "14px",
                                  minHeight: "45px",
                                }),
                                dropdownIndicator: (base, state) => ({
                                  ...base,
                                  transform: state.selectProps.menuIsOpen
                                    ? "rotate(-180deg)"
                                    : "rotate(0)",
                                  transition: "250ms",
                                  width: "35px",
                                  height: "35px",
                                }),
                                menu: (base) => ({ ...base, zIndex: 9999 }),
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-4">
                          <div className="form-group local-forms">
                            <label>
                              Occupation
                              {/* <span className="login-danger">*</span> */}
                            </label>
                            <Select
                              value={formattedOccupationOptions.find(
                                (option) =>
                                  option.value === formState?.occupation_id
                              )}
                              onChange={(e) =>
                                handleFormChange("occupation_id", e.value)
                              }
                              options={formattedOccupationOptions}
                              id="occupation-select"
                              components={{
                                IndicatorSeparator: () => null,
                              }}
                              styles={{
                                control: (baseStyles, state) => ({
                                  ...baseStyles,
                                  borderColor: state.isFocused
                                    ? "#2e37a4"
                                    : "rgba(46, 55, 164, 0.1)",
                                  boxShadow: state.isFocused
                                    ? "0 0 0 1px #2e37a4"
                                    : "none",
                                  borderRadius: "10px",
                                  fontSize: "14px",
                                  minHeight: "45px",
                                }),
                                dropdownIndicator: (base, state) => ({
                                  ...base,
                                  transform: state.selectProps.menuIsOpen
                                    ? "rotate(-180deg)"
                                    : "rotate(0)",
                                  transition: "250ms",
                                  width: "35px",
                                  height: "35px",
                                }),
                                menu: (base) => ({ ...base, zIndex: 9999 }),
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-4">
                          <div className="form-group local-forms">
                            <label>
                              Marital Status{" "}
                              {/* <span className="login-danger">*</span> */}
                            </label>
                            <Select
                              value={marital_status.find(
                                (option) =>
                                  option.value === formState?.marital_status
                              )}
                              onChange={(e) =>
                                handleFormChange("marital_status", e.value)
                              }
                              options={marital_status}
                              id="search-commodity"
                              components={{
                                IndicatorSeparator: () => null,
                              }}
                              styles={{
                                control: (baseStyles, state) => ({
                                  ...baseStyles,
                                  borderColor: state.isFocused
                                    ? "none"
                                    : "2px solid rgba(46, 55, 164, 0.1);",
                                  boxShadow: state.isFocused
                                    ? "0 0 0 1px #2e37a4"
                                    : "none",
                                  "&:hover": {
                                    borderColor: state.isFocused
                                      ? "none"
                                      : "2px solid rgba(46, 55, 164, 0.1)",
                                  },
                                  borderRadius: "10px",
                                  fontSize: "14px",
                                  minHeight: "45px",
                                }),
                                dropdownIndicator: (base, state) => ({
                                  ...base,
                                  transform: state.selectProps.menuIsOpen
                                    ? "rotate(-180deg)"
                                    : "rotate(0)",
                                  transition: "250ms",
                                  width: "35px",
                                  height: "35px",
                                }),
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-sm-12">
                          <div className="form-group local-forms">
                            <label>
                              Address
                              {/* <span className="login-danger">*</span> */}
                            </label>
                            <textarea
                              className="form-control"
                              value={formState.address}
                              onChange={(e) =>
                                handleFormChange("address", e.target.value)
                              }
                              rows={3}
                              cols={30}
                              defaultValue={""}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-3">
                          <div className="form-group local-forms">
                            <label>
                              City
                              {/* <span className="login-danger">*</span> */}
                            </label>
                            <Select
                              value={formattedCityOptions.find(
                                (option) => option.value === formState?.city
                              )}
                              onChange={(e) =>
                                handleFormChange("city", e.value)
                              }
                              options={formattedCityOptions}
                              id="city-select"
                              components={{
                                IndicatorSeparator: () => null,
                              }}
                              styles={{
                                control: (baseStyles, state) => ({
                                  ...baseStyles,
                                  borderColor: state.isFocused
                                    ? "#2e37a4"
                                    : "rgba(46, 55, 164, 0.1)",
                                  boxShadow: state.isFocused
                                    ? "0 0 0 1px #2e37a4"
                                    : "none",
                                  borderRadius: "10px",
                                  fontSize: "14px",
                                  minHeight: "45px",
                                }),
                                dropdownIndicator: (base, state) => ({
                                  ...base,
                                  transform: state.selectProps.menuIsOpen
                                    ? "rotate(-180deg)"
                                    : "rotate(0)",
                                  transition: "250ms",
                                  width: "35px",
                                  height: "35px",
                                }),
                                menu: (base) => ({ ...base, zIndex: 9999 }),
                              }}
                            />
                          </div>
                        </div>

                        <div className="col-12 col-md-6 col-xl-3">
                          <div className="form-group local-forms">
                            <label>
                              Pin Code
                              {/* <span className="login-danger">*</span> */}
                            </label>
                            <input
                              className="form-control"
                              type="number"
                              value={formState.pincode}
                              onChange={(e) =>
                                handleFormChange("pincode", e.target.value)
                              }
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-3">
                          <div className="form-group local-forms">
                            <label>
                              Age
                              <span className="login-danger">*</span>
                            </label>
                            <input
                              className="form-control"
                              type="number"
                              value={formState.age}
                              onChange={(e) =>
                                handleFormChange("age", e.target.value)
                              }
                            />
                          </div>
                        </div>

                        <div className="col-12 col-md-6 col-xl-6">
                          <div className="form-group select-gender">
                            <label className="gen-label">
                              Status
                              {/* <span className="login-danger">*</span> */}
                            </label>
                            <div className="form-check-inline">
                              <label className="form-check-label">
                                <input
                                  type="radio"
                                  name="active"
                                  value={true}
                                  onChange={(e) =>
                                    handleFormChange(
                                      "active",
                                      Boolean(e.target.value)
                                    )
                                  }
                                  className="form-check-input"
                                />
                                Active
                              </label>
                            </div>
                            <div className="form-check-inline">
                              <label className="form-check-label">
                                <input
                                  type="radio"
                                  name="active"
                                  className="form-check-input"
                                  value={false}
                                  onChange={() =>
                                    handleFormChange("active", false)
                                  }
                                />
                                In Active
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="doctor-submit text-end">
                            <button
                              type="submit"
                              className="btn btn-primary submit-form me-2"
                              onClick={handleSubmitPatientDetails}
                            >
                              Submit
                            </button>
                            <button
                              onClick={() => navigate("/patientslist")}
                              className="btn btn-primary cancel-form"
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="notification-box">
            <div className="msg-sidebar notifications msg-noti">
              <div className="topnav-dropdown-header">
                <span>Messages</span>
              </div>
              <div className="drop-scroll msg-list-scroll" id="msg_list">
                <ul className="list-box">
                  <li>
                    <Link to="#">
                      <div className="list-item">
                        <div className="list-left">
                          <span className="avatar">R</span>
                        </div>
                        <div className="list-body">
                          <span className="message-author">Richard Miles </span>
                          <span className="message-time">12:28 AM</span>
                          <div className="clearfix" />
                          <span className="message-content">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                          </span>
                        </div>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <div className="list-item new-message">
                        <div className="list-left">
                          <span className="avatar">J</span>
                        </div>
                        <div className="list-body">
                          <span className="message-author">John Doe</span>
                          <span className="message-time">1 Aug</span>
                          <div className="clearfix" />
                          <span className="message-content">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                          </span>
                        </div>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <div className="list-item">
                        <div className="list-left">
                          <span className="avatar">T</span>
                        </div>
                        <div className="list-body">
                          <span className="message-author">
                            {" "}
                            Tarah Shropshire{" "}
                          </span>
                          <span className="message-time">12:28 AM</span>
                          <div className="clearfix" />
                          <span className="message-content">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                          </span>
                        </div>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <div className="list-item">
                        <div className="list-left">
                          <span className="avatar">M</span>
                        </div>
                        <div className="list-body">
                          <span className="message-author">Mike Litorus</span>
                          <span className="message-time">12:28 AM</span>
                          <div className="clearfix" />
                          <span className="message-content">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                          </span>
                        </div>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <div className="list-item">
                        <div className="list-left">
                          <span className="avatar">C</span>
                        </div>
                        <div className="list-body">
                          <span className="message-author">
                            {" "}
                            Catherine Manseau{" "}
                          </span>
                          <span className="message-time">12:28 AM</span>
                          <div className="clearfix" />
                          <span className="message-content">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                          </span>
                        </div>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <div className="list-item">
                        <div className="list-left">
                          <span className="avatar">D</span>
                        </div>
                        <div className="list-body">
                          <span className="message-author">
                            {" "}
                            Domenic Houston{" "}
                          </span>
                          <span className="message-time">12:28 AM</span>
                          <div className="clearfix" />
                          <span className="message-content">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                          </span>
                        </div>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <div className="list-item">
                        <div className="list-left">
                          <span className="avatar">B</span>
                        </div>
                        <div className="list-body">
                          <span className="message-author">
                            {" "}
                            Buster Wigton{" "}
                          </span>
                          <span className="message-time">12:28 AM</span>
                          <div className="clearfix" />
                          <span className="message-content">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                          </span>
                        </div>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <div className="list-item">
                        <div className="list-left">
                          <span className="avatar">R</span>
                        </div>
                        <div className="list-body">
                          <span className="message-author">
                            {" "}
                            Rolland Webber{" "}
                          </span>
                          <span className="message-time">12:28 AM</span>
                          <div className="clearfix" />
                          <span className="message-content">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                          </span>
                        </div>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <div className="list-item">
                        <div className="list-left">
                          <span className="avatar">C</span>
                        </div>
                        <div className="list-body">
                          <span className="message-author"> Claire Mapes </span>
                          <span className="message-time">12:28 AM</span>
                          <div className="clearfix" />
                          <span className="message-content">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                          </span>
                        </div>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <div className="list-item">
                        <div className="list-left">
                          <span className="avatar">M</span>
                        </div>
                        <div className="list-body">
                          <span className="message-author">Melita Faucher</span>
                          <span className="message-time">12:28 AM</span>
                          <div className="clearfix" />
                          <span className="message-content">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                          </span>
                        </div>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <div className="list-item">
                        <div className="list-left">
                          <span className="avatar">J</span>
                        </div>
                        <div className="list-body">
                          <span className="message-author">Jeffery Lalor</span>
                          <span className="message-time">12:28 AM</span>
                          <div className="clearfix" />
                          <span className="message-content">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                          </span>
                        </div>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <div className="list-item">
                        <div className="list-left">
                          <span className="avatar">L</span>
                        </div>
                        <div className="list-body">
                          <span className="message-author">Loren Gatlin</span>
                          <span className="message-time">12:28 AM</span>
                          <div className="clearfix" />
                          <span className="message-content">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                          </span>
                        </div>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <div className="list-item">
                        <div className="list-left">
                          <span className="avatar">T</span>
                        </div>
                        <div className="list-body">
                          <span className="message-author">
                            Tarah Shropshire
                          </span>
                          <span className="message-time">12:28 AM</span>
                          <div className="clearfix" />
                          <span className="message-content">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                          </span>
                        </div>
                      </div>
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="topnav-dropdown-footer">
                <Link to="#">See all messages</Link>
              </div>
            </div>
          </div>
        </div>
      </>
    </div>
  );
};

export default AddPatients;

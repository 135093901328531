import React, { useEffect, useState } from "react";
import Header from "../Header";
import Sidebar from "../Sidebar";
import { Link, useLocation, useNavigate } from "react-router-dom";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import Select from "react-select";

import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { putConsumable } from "../../connections/putConsumable";
import { useQuery } from "@tanstack/react-query";
import { getConsumableCatList } from "../../connections/getConsumableCatList";
const EditConsumable = () => {
  const [formState, setFormState] = useState({});

  const navigate = useNavigate();
  const location = useLocation();
  const consumableData = location.state || null;
  const {
    user: { user },
  } = useSelector((store) => store);
  const handleChange = (event) => {
    if (event.target) {
      const { name, value } = event.target;
      setFormState((prevState) => ({ ...prevState, [name]: value }));
    } else {
      setFormState((prevState) => ({
        ...prevState,
        consumable_category_id: event.value,
      }));
    }
  };
  const { data: getConsumableCateData, refetch } = useQuery({
    queryKey: ["getConsumableCatList", "all"],
    queryFn: () => getConsumableCatList(user?.token, "all", null, null),
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });
  const data = getConsumableCateData?.payload?.consumable_categories || [];
  // console.log(data, "data");
  const options =
    data?.length > 0 &&
    data?.map((item) => ({
      value: item.id,
      label: item.name,
    }));

  const handleSubmit = async (e) => {
    if (!formState.code) {
      toast.error("Code is required");
      return;
    }
    e.preventDefault();
    const payload = {
      consumable: formState,
    };
    try {
      const result = await putConsumable(
        user?.token,
        payload,
        consumableData?.id
      );
      if (result?.status?.status === "SUCCESS") {
        toast.success("Consumable Added Successfully");
        setFormState({});
        navigate("/admin/consumable");
      }
    } catch (error) {
      // toast.error(error?.message);
      console.log(error.message);
    }
  };
  useEffect(() => {
    if (consumableData) {
      setFormState({
        name: consumableData?.name,
        code: consumableData?.code,
        consumable_category_id: consumableData?.consumable_category?.id,
        amount: consumableData?.amount,
        unit: consumableData?.unit,
        discount: consumableData?.discount,
      });
    }
  }, [consumableData]);
  console.log(formState, "formstate");
  return (
    <div>
      <Header />
      <Sidebar activeClassName="consumable-list" />
      <>
        <div className="page-wrapper">
          <div className="content">
            {/* Page Header */}
            <div className="page-header">
              <div className="row">
                <div className="col-sm-12">
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to={-1}>Consumable </Link>
                    </li>
                    <li className="breadcrumb-item">
                      <i className="feather-chevron-right">
                        <FeatherIcon icon="chevron-right" />
                      </i>
                    </li>
                    <li className="breadcrumb-item active">
                      Update Consumable
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            {/* /Page form */}
            <div className="row">
              <div className="col-sm-12">
                <div className="card">
                  <div className="card-body">
                    <form>
                      <div className="row">
                        <div className="col-12">
                          <div className="form-heading">
                            <h4>Update Consumable</h4>
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-4">
                          <div className="form-group local-forms">
                            <label>
                              Name
                              {/* <span className="login-danger">*</span> */}
                            </label>
                            <input
                              value={formState?.name}
                              name="name"
                              onChange={handleChange}
                              className="form-control"
                              type="text"
                              // required={true}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-4">
                          <div className="form-group local-forms">
                            <label>
                              Code
                              <span className="login-danger">*</span>
                            </label>
                            <input
                              value={formState?.code}
                              name="code"
                              onChange={handleChange}
                              className="form-control"
                              type="text"
                              required={true}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-4">
                          <div className="form-group local-forms">
                            <label>
                              Consumable category
                              {/* <span className="login-danger">*</span> */}
                            </label>
                            <span
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "10px",
                              }}
                            >
                              <Select
                                value={
                                  (options?.length > 0 &&
                                    options?.find(
                                      (option) =>
                                        option.value ===
                                        formState?.consumable_category_id
                                    )) ||
                                  null
                                }
                                onChange={handleChange}
                                placeholder="consumable category"
                                options={options}
                                menuPortalTarget={document.body}
                                styles={{
                                  container: (provided) => ({
                                    ...provided,
                                    width: "calc(100% - 40px)",
                                  }),
                                  menuPortal: (base) => ({
                                    ...base,
                                    zIndex: 9999,
                                  }),
                                  control: (baseStyles, state) => ({
                                    ...baseStyles,
                                    borderColor: state.isFocused
                                      ? "#2e37a4"
                                      : "rgba(46, 55, 164, 0.1)",
                                    boxShadow: state.isFocused
                                      ? "0 0 0 1px #2e37a4"
                                      : "none",
                                    "&:hover": {
                                      borderColor: "rgba(46, 55, 164, 0.1)",
                                    },
                                    borderRadius: "10px",
                                    fontSize: "14px",
                                    minHeight: "45px",
                                  }),
                                  dropdownIndicator: (base, state) => ({
                                    ...base,
                                    transform: state.selectProps.menuIsOpen
                                      ? "rotate(-180deg)"
                                      : "rotate(0)",
                                    transition: "250ms",
                                    width: "35px",
                                    height: "35px",
                                  }),
                                }}
                                id="search-commodity"
                                components={{
                                  IndicatorSeparator: () => null,
                                }}
                              />
                              <button
                                style={{
                                  width: "30px",
                                  height: "30px",
                                  borderRadius: "50%",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  border: "none",
                                  backgroundColor: "#3d5ee1",
                                  color: "white",
                                  cursor: "pointer",
                                }}
                                onClick={(e) => {
                                  e.preventDefault();
                                  const icon =
                                    e.currentTarget.querySelector("i");
                                  icon.classList.add("rotating");
                                  refetch().finally(() => {
                                    icon.classList.remove("rotating");
                                  });
                                }}
                              >
                                <i className="fa-solid fa-rotate-right"></i>
                              </button>
                            </span>
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-4">
                          <div className="form-group local-forms">
                            <label>
                              Unit
                              {/* <span className="login-danger">*</span> */}
                            </label>
                            <input
                              value={formState?.unit}
                              name="unit"
                              onChange={handleChange}
                              className="form-control"
                              type="text"
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-4">
                          <div className="form-group local-forms">
                            <label>
                              Amount
                              {/* <span className="login-danger">*</span> */}
                            </label>
                            <input
                              value={formState?.amount}
                              name="amount"
                              onChange={handleChange}
                              className="form-control"
                              type="text"
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-4">
                          <div className="form-group local-forms">
                            <label>
                              Discount
                              {/* <span className="login-danger">*</span> */}
                            </label>
                            <input
                              value={formState?.discount}
                              name="discount"
                              onChange={handleChange}
                              className="form-control"
                              type="text"
                            />
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="doctor-submit text-end">
                            <button
                              onClick={handleSubmit}
                              type="submit"
                              className="btn btn-primary submit-form me-2"
                            >
                              Update Consumable
                            </button>
                            <button
                              onClick={(e) => {
                                e.preventDefault();
                                navigate("/admin/consumable");
                              }}
                              type="submit"
                              className="btn btn-primary cancel-form"
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </div>
  );
};

export default EditConsumable;

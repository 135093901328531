import React from "react";
import Header from "../Header";
import Sidebar from "../Sidebar";
import { Link, useParams } from "react-router-dom";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { useQuery } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { Skeleton } from "@mui/material";
import dayjs from "dayjs";
// import { Table } from "antd";
import { getAppointmentSlotDetails } from "../../connections/getAppointmentSlotDetails";

const ShowAppointmentSlot = () => {
  const {
    user: { user },
  } = useSelector((store) => store);
  const { id } = useParams();
  const { data: appointmentSlotData, isLoading } = useQuery({
    queryKey: ["getAppointmentSlotDetails", id],
    queryFn: () => getAppointmentSlotDetails(user?.token, id),
    enabled: !!id,
    cacheTime: 1000 * 60 * 60,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchOnMount: false,
  });
  // const columns = [
  //   {
  //     title: "Code",
  //     dataIndex: "code",
  //   },
  //   {
  //     title: "Slot Type",
  //     dataIndex: "slot_type",
  //   },
  //   {
  //     title: "Hour",
  //     dataIndex: "hour",
  //   },
  //   {
  //     title: "Minutes",
  //     dataIndex: "minutes",
  //   },
  //   {
  //     title: "Meridiem",
  //     dataIndex: "meridiem",
  //   },

  //   {
  //     title: "Created At",
  //     dataIndex: "created_at",
  //     render: (date) => (
  //       <>
  //         {dayjs(date, "YYYY-MM-DD HH:mm:ss A").format("MMMM DD, YYYY HH:mm")}
  //       </>
  //     ),
  //   },
  //   {
  //     title: "Updated At",
  //     dataIndex: "updated_at",
  //     render: (date) => (
  //       <>
  //         {dayjs(date, "YYYY-MM-DD HH:mm:ss A").format("MMMM DD, YYYY HH:mm")}
  //       </>
  //     ),
  //   },
  // ];
  const formatDate = (date) =>
    dayjs(date, "YYYY-MM-DD HH:mm:ss A").format("MMMM DD, YYYY HH:mm");
  return (
    <>
      <Header />
      <Sidebar activeClassName="appointment-slot-list" />
      <>
        <div className="page-wrapper">
          <div className="content">
            {/* Page Header */}
            <div className="page-header">
              <div className="row">
                <div className="col-sm-12">
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item active">
                      <Link to={-1}>Appointment Slot Details</Link>
                    </li>
                    <li className="breadcrumb-item">
                      <i className="feather-chevron-right">
                        <FeatherIcon icon="chevron-right" />
                      </i>
                    </li>
                    <li className="breadcrumb-item active">{id}</li>
                  </ul>
                </div>
              </div>
            </div>
            {/* /Page Header */}
            <div className="row">
              <div className="col-sm-12">
                <div className="card card-table show-entire">
                  <div className="card-body">
                    {/* content Header */}
                    <div className="page-table-header mb-2">
                      <div className="row align-items-center">
                        <div className="col">
                          <div className="doctor-table-blk">
                            <h3>Appointment Slot Details</h3>
                            <div className="doctor-search-blk"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {isLoading ? (
                      <div>
                        <Skeleton
                          variant="rectangular"
                          width="100%"
                          height={430}
                        />
                      </div>
                    ) : (
                      <div className="content-details">
                        <div className="col-lg-12">
                          <div className="card-box">
                            <div className="card-block">
                              <ul className="personal-info">
                                <li className="pb-1">
                                  <span className="title">Code:</span>
                                  <span className="text">
                                    {appointmentSlotData?.code || "N/A"}
                                  </span>
                                </li>
                                <li className="pb-1">
                                  <span className="title">Slot Type:</span>
                                  <span className="text">
                                    {appointmentSlotData?.slot_type || "N/A"}
                                  </span>
                                </li>
                                <li className="pb-1">
                                  <span className="title">Hour:</span>
                                  <span className="text">
                                    {appointmentSlotData?.hour || "N/A"}
                                  </span>
                                </li>
                                <li className="pb-1">
                                  <span className="title">Minutes:</span>
                                  <span className="text">
                                    {appointmentSlotData?.minutes || "N/A"}
                                  </span>
                                </li>
                                <li className="pb-1">
                                  <span className="title">Meridiem:</span>
                                  <span className="text">
                                    {appointmentSlotData?.meridiem || "N/A"}
                                  </span>
                                </li>
                                <li className="pb-1">
                                  <span className="title">Created At:</span>
                                  <span className="text">
                                    {formatDate(
                                      appointmentSlotData?.created_at
                                    ) || "N/A"}
                                  </span>
                                </li>
                                <li className="pb-1">
                                  <span className="title">Updated At:</span>
                                  <span className="text">
                                    {formatDate(
                                      appointmentSlotData?.updated_at
                                    ) || "N/A"}
                                  </span>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      // <div className="table-responsive">
                      //   <Table
                      //     columns={columns}
                      //     dataSource={[appointmentSlotData]}
                      //     pagination={false}
                      //     scroll={{ x: "max-content" }}
                      //   />
                      // </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </>
  );
};
export default ShowAppointmentSlot;

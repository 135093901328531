import React, { useEffect, useState } from "react";
import Header from "../Header";
import Sidebar from "../Sidebar";
import { Link, useLocation, useNavigate } from "react-router-dom";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

import { putConsumableCate } from "../../connections/putConsumableCate";
const UpdateConsumableCat = () => {
  const [formState, setFormState] = useState({});
  const location = useLocation();
  const consumableCatData = location.state || null;
  const navigate = useNavigate();
  const {
    user: { user },
  } = useSelector((store) => store);
  const handleChange = (event) => {
    if (event.target) {
      const { name, value } = event.target;
      setFormState((prevState) => ({ ...prevState, [name]: value }));
    } else {
      setFormState((prevState) => ({
        ...prevState,
        consumable_category_id: event.value,
      }));
    }
  };

  const handleSubmit = async (e) => {
    if (!formState.name) {
      toast.error("Name is required");
      return;
    }
    e.preventDefault();
    const payload = {
      consumable_category: formState,
    };
    try {
      const result = await putConsumableCate(
        user?.token,
        payload,
        consumableCatData?.id
      );
      if (result?.status?.status === "SUCCESS") {
        toast.success("Consumable Category Added Successfully");
        setFormState({});
        navigate("/admin/consumable_categories");
      }
    } catch (error) {
      // toast.error(error?.message);
      console.log(error.message);
    }
  };
  useEffect(() => {
    if (consumableCatData) {
      setFormState({
        name: consumableCatData.name,
        code: consumableCatData.code,
      });
    }
  }, [consumableCatData]);
  return (
    <div>
      <Header />
      <Sidebar activeClassName="consumable-category-list" />
      <>
        <div className="page-wrapper">
          <div className="content">
            {/* Page Header */}
            <div className="page-header">
              <div className="row">
                <div className="col-sm-12">
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to={-1}>Consumable Category</Link>
                    </li>
                    <li className="breadcrumb-item">
                      <i className="feather-chevron-right">
                        <FeatherIcon icon="chevron-right" />
                      </i>
                    </li>
                    <li className="breadcrumb-item active">
                      Update Consumable Category
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            {/* /Page form */}
            <div className="row">
              <div className="col-sm-12">
                <div className="card">
                  <div className="card-body">
                    <form>
                      <div className="row">
                        <div className="col-12">
                          <div className="form-heading">
                            <h4>Update Consumable Category</h4>
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-6">
                          <div className="form-group local-forms">
                            <label>
                              Name <span className="login-danger">*</span>
                            </label>
                            <input
                              value={formState?.name}
                              name="name"
                              onChange={handleChange}
                              className="form-control"
                              type="text"
                              required={true}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-6">
                          <div className="form-group local-forms">
                            <label>
                              Code
                              {/* <span className="login-danger">*</span> */}
                            </label>
                            <input
                              value={formState?.code}
                              name="code"
                              onChange={handleChange}
                              className="form-control"
                              type="text"
                            />
                          </div>
                        </div>

                        <div className="col-12">
                          <div className="doctor-submit text-end">
                            <button
                              onClick={handleSubmit}
                              type="submit"
                              className="btn btn-primary submit-form me-2"
                            >
                              Submit
                            </button>
                            <button
                              onClick={(e) => {
                                e.preventDefault();
                                navigate("/admin/consumable_categories");
                              }}
                              type="submit"
                              className="btn btn-primary cancel-form"
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </div>
  );
};

export default UpdateConsumableCat;

import React, { useEffect, useState } from "react";
import Header from "../Header";
import Sidebar from "../Sidebar";
import { Link, useLocation, useNavigate } from "react-router-dom";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { DatePicker } from "antd";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { updateLabInventory } from "../../connections/updateLabInventory";

import Select, { components } from "react-select";
import { useQuery } from "@tanstack/react-query";
import { getMedicalTest } from "../../connections/getMedicalTest";
import useDebounce from "../../utils/hooks";
import { Search } from "@mui/icons-material";

const UpdateInventory = () => {
  const {
    user: { user },
  } = useSelector((store) => store);
  const navigate = useNavigate();
  const location = useLocation();
  const labInventoryData = location.state || null;
  const [formData, setFormData] = useState({
    name: "",
    stock_left: "",
    critical_stock_limit: "",
    price: "",
    units_purchased: "",
    purchased_at: "",
    brand: "",
    auto_purchase_threshold: "",
    medical_test_ids: [],
  });
  // const [selectedTestOptions, setSelectedTestOption] = useState([]);
  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleChangeTest = (name, value) => {
    // setSelectedTestOption(value);
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleDateChange = (date, dateString) => {
    setFormData((prevData) => ({
      ...prevData,
      purchased_at: date ? dateString : "",
    }));
  };
  //medical test option for medical filed
  const [medicalInput, setMedicalInput] = useState("");
  const handleMedicalChange = (inputValue) => {
    // e.preventDefault();
    setMedicalInput(inputValue);
  };
  const debouncedInputMedicine = useDebounce(medicalInput, 1000);

  const { data: medicalTestData, isLoading } = useQuery({
    queryKey: ["getMedicalTest", debouncedInputMedicine],
    queryFn: () =>
      getMedicalTest(user?.token, "null", 1, 10, debouncedInputMedicine),
    enabled: !!debouncedInputMedicine,

    refetchOnWindowFocus: false, // Don't refetch when window regains focus
    refetchOnReconnect: false, // Don't refetch when reconnecting
  });
  const AllMedicalTestOptions = medicalTestData?.payload?.medical_tests || [];
  const formattedMedicalTestOptions = AllMedicalTestOptions?.map((el) => ({
    value: el?.id,
    label: el?.name,
  }));

  const handleSubmit = async (e) => {
    if (!formData.name) {
      toast.error("Name is required");
      return;
    }
    e.preventDefault();
    const payload = {
      lab_inventory: {
        name: formData.name || "",
        stock_left: formData.stock_left || "",
        critical_stock_limit: formData.critical_stock_limit || "",
        price: formData.price || "",
        units_purchased: formData.units_purchased || "",
        purchased_at: formData.purchased_at || "",
        brand: formData.brand || "",
        auto_purchase_threshold: formData.auto_purchase_threshold || "",
        // medical_test_ids: formData.medical_tests || [],
        medical_test_ids:
          formData?.medical_test_ids?.length > 0 &&
          formData?.medical_test_ids?.map((el) => el.value),
      },
    };

    try {
      const result = await updateLabInventory(
        user?.token,
        payload,
        labInventoryData?.id
      );
      if (result?.status?.status === "SUCCESS") {
        toast.success("Lab Inventory Added Successfully");
        navigate("/admin/lab_inventory");
        setFormData({});
      }
    } catch (error) {
      // toast.error(error.message);
      console.log(error.message);
    }
  };
  useEffect(() => {
    if (labInventoryData) {
      setFormData({
        name: labInventoryData.name || "",
        stock_left: labInventoryData.stock_left || "",
        critical_stock_limit: labInventoryData.critical_stock_limit || "",
        price: labInventoryData.price || "",
        units_purchased: labInventoryData.units_purchased || "",
        purchased_at: labInventoryData.purchased_at || "",
        brand: labInventoryData.brand || "",
        auto_purchase_threshold: labInventoryData.auto_purchase_threshold || "",
        // medical_test_ids: labInventoryData.medical_tests || [],
        medical_test_ids:
          labInventoryData?.medical_tests?.length > 0
            ? labInventoryData?.medical_tests?.map((el) => ({
                value: el?.id,
                label: el?.name,
              }))
            : [],
      });
      // setSelectedTestOption(labInventoryData.medical_tests || []);
    }
  }, [labInventoryData]);
  console.log(formData);
  return (
    <>
      <Header />
      <Sidebar activeClassName="lab-inventory-list" />
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="row">
              <div className="col-sm-12">
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to={-1}>Lab Inventory</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <i className="feather-chevron-right">
                      <FeatherIcon icon="chevron-right" />
                    </i>
                  </li>
                  <li className="breadcrumb-item active">
                    Update Lab Inventory
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <div className="card">
                <div className="card-body">
                  <form onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="col-12">
                        <div className="form-heading">
                          <h4>Lab Inventory</h4>
                        </div>
                      </div>
                      <div className="col-12 col-md-6 col-xl-3">
                        <div className="form-group local-forms">
                          <label>
                            Name <span className="login-danger">*</span>
                          </label>
                          <input
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                            className="form-control"
                            type="text"
                            required={true}
                          />
                        </div>
                      </div>
                      <div className="col-12 col-md-6 col-xl-3">
                        <div className="form-group local-forms">
                          <label>Stock Left</label>
                          <input
                            name="stock_left"
                            value={formData.stock_left}
                            onChange={handleChange}
                            className="form-control"
                            type="number"
                          />
                        </div>
                      </div>
                      <div className="col-12 col-md-6 col-xl-3">
                        <div className="form-group local-forms">
                          <label>Price</label>
                          <input
                            name="price"
                            value={formData.price}
                            onChange={handleChange}
                            className="form-control"
                            type="number"
                          />
                        </div>
                      </div>
                      <div className="col-12 col-md-6 col-xl-3">
                        <div className="form-group local-forms">
                          <label>Units Purchased</label>
                          <input
                            name="units_purchased"
                            value={formData.units_purchased}
                            onChange={handleChange}
                            className="form-control"
                            type="number"
                          />
                        </div>
                      </div>
                      <div className="col-12 col-md-6 col-xl-3">
                        <div className="form-group local-forms">
                          <label>Brand</label>
                          <input
                            name="brand"
                            value={formData.brand}
                            onChange={handleChange}
                            className="form-control"
                            type="text"
                          />
                        </div>
                      </div>
                      <div className="col-12 col-md-6 col-xl-3">
                        <div className="form-group local-forms cal-icon">
                          <label>Purchased at</label>
                          <DatePicker
                            value={
                              formData.purchased_at
                                ? dayjs(formData.purchased_at)
                                : null
                            }
                            onChange={handleDateChange}
                            className="form-control datetimepicker"
                            suffixIcon={null}
                            style={{
                              borderColor: "rgba(46, 55, 164, 0.1)",
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-12 col-md-6 col-xl-3">
                        <div className="form-group local-forms">
                          <label>Auto purchase threshold</label>
                          <input
                            name="auto_purchase_threshold"
                            value={formData.auto_purchase_threshold}
                            onChange={handleChange}
                            className="form-control"
                            type="number"
                          />
                        </div>
                      </div>
                      <div className="col-12 col-md-6 col-xl-3">
                        <div className="form-group local-forms">
                          <label>Critical stock limit</label>
                          <input
                            name="critical_stock_limit"
                            value={formData.critical_stock_limit}
                            onChange={handleChange}
                            className="form-control"
                            type="number"
                          />
                        </div>
                      </div>
                      <div className="col-12 col-md-4">
                        <div className="form-group">
                          <label>Medical Tests</label>
                          <Select
                            components={{
                              DropdownIndicator: (props) => (
                                <components.DropdownIndicator {...props}>
                                  <Search style={{ color: "#999" }} />
                                </components.DropdownIndicator>
                              ),
                              IndicatorSeparator: () => null,
                            }}
                            onInputChange={handleMedicalChange}
                            isLoading={isLoading}
                            options={formattedMedicalTestOptions}
                            value={formData?.medical_test_ids?.map((test) => ({
                              value: test.value,
                              label: test.label,
                            }))}
                            onChange={(selectedOptions) =>
                              handleChangeTest(
                                "medical_test_ids",
                                selectedOptions
                              )
                            }
                            styles={{
                              menu: (provided) => ({
                                ...provided,
                                zIndex: 9999,
                              }),
                              menuPortal: (base) => ({
                                ...base,
                                zIndex: 999999,
                              }),
                              control: (baseStyles, state) => ({
                                ...baseStyles,
                                borderColor: state.isFocused
                                  ? "#2e37a4"
                                  : "rgba(46, 55, 164, 0.1)",
                                boxShadow: state.isFocused
                                  ? "0 0 0 1px #2e37a4"
                                  : "none",
                                "&:hover": {
                                  borderColor: "rgba(46, 55, 164, 0.1)",
                                },
                                borderRadius: "10px",
                                fontSize: "14px",
                                minHeight: "45px",
                              }),
                              dropdownIndicator: (base, state) => ({
                                ...base,
                                transform: state.selectProps.menuIsOpen
                                  ? "rotate(-180deg)"
                                  : "rotate(0)",
                                transition: "250ms",
                                width: "35px",
                                height: "35px",
                              }),
                            }}
                            isMulti
                            isSearchable
                            placeholder="Choose options"
                            className="react-select"
                            classNamePrefix="select"
                          />
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="doctor-submit text-end">
                          <button
                            type="submit"
                            className="btn btn-primary submit-form me-2"
                          >
                            Update Lab Inventory
                          </button>
                          <button
                            type="button"
                            onClick={() => navigate("/admin/lab_inventory")}
                            className="btn btn-primary cancel-form"
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UpdateInventory;

import jsPDF from "jspdf";
import "jspdf-autotable";
import dayjs from "dayjs";
export function getGreeting() {
  const now = new Date(); // Get the current date and time
  const hours = now.getHours(); // Get the current hour (0-23)

  if (hours >= 4 && hours < 12) {
    return "Good Morning";
  } else if (hours >= 12 && hours < 16) {
    return "Good Afternoon";
  } else if (hours >= 16 && hours < 21) {
    return "Good Evening";
  } else {
    return "Good Day";
  }
}
export function getCurrentMonthStartEndIST() {
  const now = new Date();

  // Start of the month in IST
  const start = new Date(now.getFullYear(), now.getMonth(), 1, 5, 30); // Midnight in IST
  const startDateOfMonth = start.toISOString().split("T")[0];
  const currentDate = dayjs().format("YYYY-MM-DD");
  // End of the month in IST
  const end = new Date(now.getFullYear(), now.getMonth() + 1, 0, 5, 30); // Last day of month in IST
  const endDateOfMonth = end.toISOString().split("T")[0];

  return { startDateOfMonth, endDateOfMonth, currentDate };
}
export const buildQueryString = (params, parentKey = "") => {
  return Object.entries(params)
    .map(([key, value]) => {
      const fullKey = parentKey ? `${parentKey}[${key}]` : key;
      if (value !== null && typeof value === "object") {
        return buildQueryString(value, fullKey);
      }
      return `${encodeURIComponent(fullKey)}=${encodeURIComponent(value)}`;
    })
    .filter(Boolean)
    .join("&");
};

export const handleDownloadCsv = (suppliers) => {
  const csvHeaders = [
    Object.keys(suppliers[0]).map(
      (key) => key?.charAt(0)?.toUpperCase() + key?.slice(1)?.replace(/_/g, " ")
    ),
  ];
  const csvRows = suppliers.map((supplier) => Object.values(supplier));

  const csvContent = [csvHeaders, ...csvRows]
    .map((e) => e.join(","))
    .join("\n");
  const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
  const link = document.createElement("a");
  link.href = URL.createObjectURL(blob);
  link.download = "exportCSV.csv";
  link.click();
};
export const handleShowJson = (suppliers) => {
  const jsonWindow = window.open("", "_blank");
  jsonWindow.document.write(
    "<pre>" + JSON.stringify(suppliers, null, 2) + "</pre>"
  );
  jsonWindow.document.title = "Suppliers JSON Data";
};

export const handleDownloadPdf = (suppliers) => {
  const doc = new jsPDF();
  if (suppliers?.length === 0) return;
  const headers = [
    Object.keys(suppliers[0]).map(
      (key) => key?.charAt(0)?.toUpperCase() + key?.slice(1)?.replace(/_/g, " ")
    ),
  ];

  // Map supplier data to a format suitable for the table
  const data = suppliers.map((supplier) => Object.values(supplier));

  doc.text("Suppliers List", 14, 10); // Title
  doc.autoTable({
    head: headers,
    body: data,
    startY: 20,
    theme: "grid",
    headStyles: {
      fillColor: [255, 255, 255],
      textColor: [0, 0, 0],
      fontStyle: "bold",
      border: "1px solid black",
      lineWidth: 0.5,
    },
    styles: { fontSize: 10 },
  });

  doc.save("exported.pdf");
};
// {purchase order}
export const generateCSV = (data) => {
  const header = [
    "Order ID",
    "Supplier Name",
    "Supplier Phone",
    "Supplier GST No",
    "Supplier Address",
    "Supplier Email",
    "Bill Type",
    "Bill Number",
    "Purchase Date",
    "Grand Total",
    "Item ID",
    "HSN Code",
    "Batch",
    "Expiry Date",
    "Quantity",
    "CGST",
    "SGST",
    "Rate",
    "MRP",
    "Amount",
    "Discount",
  ];

  const rows = data.flatMap((order) => {
    return order.purchase_items.map((item) => {
      return [
        order.id,
        order.supplier.name,
        order.supplier.phone,
        order.supplier.gst_no,
        order.supplier.address,
        order.supplier.email,
        order.bill_type,
        order.bill_number,
        order.purchase_date,
        order.grand_total,
        item.id,
        item.hsn_code,
        item.batch,
        item.expiry_date,
        item.quantity,
        item.cgst,
        item.sgst,
        item.rate,
        item.mrp,
        item.amount,
        item.discount,
      ];
    });
  });

  const csvContent = [header, ...rows].map((e) => e.join(",")).join("\n");

  // Create a Blob from the CSV content
  const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
  const url = URL.createObjectURL(blob);

  // Create a link and trigger a download
  const link = document.createElement("a");
  link.setAttribute("href", url);
  link.setAttribute("download", "purchase_orders.csv");
  link.style.visibility = "hidden";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
export const handlePrintPurchaseOrder = (purchaseOrders) => {
  // Return if no data
  if (!purchaseOrders || purchaseOrders.length === 0) return;

  // Initialize PDF document
  const doc = new jsPDF({
    orientation: "landscape",
    unit: "mm",
    format: "a4",
  });

  // Format date function
  const formatDate = (dateString) => {
    try {
      return dayjs(dateString).format("YYYY-MM-DD HH:mm");
    } catch (e) {
      return dateString;
    }
  };

  // Format currency
  const formatCurrency = (amount) => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(amount);
  };

  // Define table headers with custom widths
  const headers = [
    { header: "ID", width: 10 },
    { header: "Supplier", width: 40 },
    { header: "Created At", width: 30 },
    { header: "Updated At", width: 30 },
    { header: "Purchase Date", width: 25 },
    { header: "Grand Total", width: 25 },
    { header: "Bill Type", width: 20 },
    { header: "Department", width: 25 },
    { header: "Bill Number", width: 25 },
    { header: "Paid", width: 15 },
  ];

  // Map data with formatting
  const data = purchaseOrders.map((order) => [
    order.id,
    order.supplier?.name || "",
    formatDate(order.created_at),
    formatDate(order.updated_at),
    formatDate(order.purchase_date),
    formatCurrency(order.grand_total),
    order.bill_type,
    order.department?.name || "",
    order.bill_number,
    order.paid ? "Yes" : "No",
  ]);

  // Add title
  doc.setFontSize(16);
  doc.setFont(undefined, "bold");
  doc.text("Purchase Order List", doc.internal.pageSize.getWidth() / 2, 15, {
    align: "center",
  });

  // Add timestamp
  doc.setFontSize(10);
  doc.setFont(undefined, "normal");
  doc.text(`Generated on: ${dayjs().format("YYYY-MM-DD HH:mm:ss")}`, 14, 22);

  // Configure table
  doc.autoTable({
    startY: 30,
    head: [headers.map((h) => h.header)],
    body: data,
    theme: "grid",
    headStyles: {
      fillColor: [240, 240, 240],
      textColor: [0, 0, 0],
      fontStyle: "bold",
      halign: "center",
      lineWidth: 0.5,
      lineColor: [200, 200, 200],
      fontSize: 10,
    },
    bodyStyles: {
      fontSize: 9,
      lineColor: [200, 200, 200],
      lineWidth: 0.5,
    },
    columnStyles: {
      0: { halign: "center" }, // ID
      4: { halign: "center" }, // Purchase Date
      5: { halign: "right" }, // Grand Total
      9: { halign: "center" }, // Paid
    },
    alternateRowStyles: {
      fillColor: [248, 248, 248],
    },
    margin: { top: 30, right: 14, bottom: 20, left: 14 },
    didDrawPage: (data) => {
      // Add page number at the bottom
      doc.setFontSize(10);
      doc.text(
        `Page ${data.pageNumber} of ${data.pageCount}`,
        doc.internal.pageSize.getWidth() / 2,
        doc.internal.pageSize.getHeight() - 10,
        { align: "center" }
      );
    },
  });

  // Save with formatted filename including timestamp
  const filename = `purchase_orders_${dayjs().format("YYYYMMDD_HHmmss")}.pdf`;
  doc.save(filename);
};
// {medicine}
export const handlePrintMedicine = (purchaseOrders) => {
  // Return if no data
  if (!purchaseOrders || purchaseOrders.length === 0) return;

  // Initialize PDF document
  const doc = new jsPDF({
    orientation: "landscape",
    unit: "mm",
    format: "a4",
  });

  // Define table headers with custom widths
  const headers = [
    { header: "Name", width: 40 },
    { header: "Company", width: 10 },
    { header: "Amount", width: 30 },
    { header: "Discount", width: 30 },
    { header: "Packing", width: 25 },
    { header: "Total Stock Left", width: 25 },
    { header: "Expiry Date", width: 20 },
    { header: "Medicine Salt", width: 35 },
  ];

  // Map data with formatting
  const data = purchaseOrders.map((order) => [
    order?.name,
    order?.company || "",
    order?.amount,
    order?.discount,
    order?.Packing,
    order?.total_stock_left,
    order?.expiry_date,
    order?.medicine_salt?.name || "",
  ]);

  // Add title
  doc.setFontSize(16);
  doc.setFont(undefined, "bold");
  doc.text("Medicine Order List", doc.internal.pageSize.getWidth() / 2, 15, {
    align: "center",
  });

  // Add timestamp
  doc.setFontSize(10);
  doc.setFont(undefined, "normal");
  doc.text(`Generated on: ${dayjs().format("YYYY-MM-DD HH:mm:ss")}`, 14, 22);

  // Configure table
  doc.autoTable({
    startY: 30,
    head: [headers.map((h) => h.header)],
    body: data,
    theme: "grid",
    headStyles: {
      fillColor: [240, 240, 240],
      textColor: [0, 0, 0],
      fontStyle: "bold",
      halign: "center",
      lineWidth: 0.5,
      lineColor: [200, 200, 200],
      fontSize: 10,
    },
    bodyStyles: {
      fontSize: 9,
      lineColor: [200, 200, 200],
      lineWidth: 0.5,
    },
    columnStyles: {
      0: { halign: "center" }, // ID
      4: { halign: "center" }, // Purchase Date
      5: { halign: "right" }, // Grand Total
      9: { halign: "center" }, // Paid
    },
    alternateRowStyles: {
      fillColor: [248, 248, 248],
    },
    margin: { top: 30, right: 14, bottom: 20, left: 14 },
    didDrawPage: (data) => {
      // Add page number at the bottom
      doc.setFontSize(10);
      doc.text(
        `Page ${data.pageNumber} of ${data.pageCount}`,
        doc.internal.pageSize.getWidth() / 2,
        doc.internal.pageSize.getHeight() - 10,
        { align: "center" }
      );
    },
  });

  // Save with formatted filename including timestamp
  const filename = `medicine_${dayjs().format("YYYYMMDD_HHmmss")}.pdf`;
  doc.save(filename);
};
// {consumable}
export const handlePrintConsumable = (purchaseOrders) => {
  // Return if no data
  if (!purchaseOrders || purchaseOrders.length === 0) return;
  const formatDate = (dateString) => {
    try {
      return dayjs(dateString, "YYYY-MM-DD HH:mm:ss A").format(
        "YYYY-MM-DD HH:mm"
      );
    } catch (e) {
      return dateString;
    }
  };
  // Initialize PDF document
  const doc = new jsPDF({
    orientation: "landscape",
    unit: "mm",
    format: "a4",
  });

  // Define table headers with custom widths
  const headers = [
    { header: "id", width: 10 },
    { header: "Name", width: 40 },
    { header: "Code", width: 40 },
    { header: "Amount", width: 10 },
    { header: "Consumable Category", width: 20 },
    { header: "Discount", width: 10 },
    { header: "Unit", width: 10 },
    { header: "Active", width: 10 },
    { header: "Created At", width: 20 },
    { header: "Updated At", width: 20 },
  ];

  // Map data with formatting
  const data = purchaseOrders.map((order) => [
    order?.id || "",
    order?.name,
    order?.code || "",
    order?.amount,
    order?.consumable_category?.name,
    order?.discount,
    order?.unit,
    order?.active ? "Yes" : "No",
    formatDate(order.created_at),
    formatDate(order.updated_at),
  ]);

  // Add title
  doc.setFontSize(16);
  doc.setFont(undefined, "bold");
  doc.text("Consumable List", doc.internal.pageSize.getWidth() / 2, 15, {
    align: "center",
  });

  // Add timestamp
  doc.setFontSize(10);
  doc.setFont(undefined, "normal");
  doc.text(`Generated on: ${dayjs().format("YYYY-MM-DD HH:mm:ss")}`, 14, 22);

  // Configure table
  doc.autoTable({
    startY: 30,
    head: [headers.map((h) => h.header)],
    body: data,
    theme: "grid",
    headStyles: {
      fillColor: [240, 240, 240],
      textColor: [0, 0, 0],
      fontStyle: "bold",
      halign: "center",
      lineWidth: 0.5,
      lineColor: [200, 200, 200],
      fontSize: 10,
    },
    bodyStyles: {
      fontSize: 9,
      lineColor: [200, 200, 200],
      lineWidth: 0.5,
    },
    columnStyles: {
      0: { halign: "center" }, // ID
      3: { halign: "center" },
      5: { halign: "center" }, // Grand Total
      9: { halign: "center" }, // Paid
    },
    alternateRowStyles: {
      fillColor: [248, 248, 248],
    },
    margin: { top: 30, right: 14, bottom: 20, left: 14 },
    didDrawPage: (data) => {
      // Add page number at the bottom
      doc.setFontSize(10);
      doc.text(
        `Page ${data.pageNumber} of ${data.pageCount}`,
        doc.internal.pageSize.getWidth() / 2,
        doc.internal.pageSize.getHeight() - 10,
        { align: "center" }
      );
    },
  });

  // Save with formatted filename including timestamp
  const filename = `orders_${dayjs().format("YYYYMMDD_HHmmss")}.pdf`;
  doc.save(filename);
};
// invoice
export const handlePrintInvoice = (purchaseOrders) => {
  // Return if no data
  if (!purchaseOrders || purchaseOrders.length === 0) return;
  const formatDate = (dateString) => {
    try {
      return dayjs(dateString, "YYYY-MM-DD HH:mm:ss A").format(
        "YYYY-MM-DD HH:mm"
      );
    } catch (e) {
      return dateString;
    }
  };
  // Initialize PDF document
  const doc = new jsPDF({
    orientation: "landscape",
    unit: "mm",
    format: "a4",
  });

  // Define table headers with custom widths
  const headers = [
    { header: "Name", width: 30 },
    { header: "Patient", width: 20 },
    { header: "Invoice Id", width: 10 },
    { header: "Mobile", width: 20 },
    { header: "Status", width: 10 },
    { header: "Invoice Type", width: 10 },
    { header: "Total", width: 10 },
    { header: "Grand Total", width: 20 },
    { header: "Generated At", width: 20 },
    { header: "Generated By", width: 10 },
    { header: "Department", width: 10 },
  ];

  // Map data with formatting
  const data = purchaseOrders.map((order) => [
    order?.name || "",
    order?.patient?.uid,
    order?.invoiceable_id || "-",
    order?.mobile,
    order?.status,
    order?.invoice_type,
    order?.total,
    order?.grand_total,
    formatDate(order.generated_at),
    order?.generated_by,
    order?.department?.name,
  ]);

  // Add title
  doc.setFontSize(16);
  doc.setFont(undefined, "bold");
  doc.text("Invoice List", doc.internal.pageSize.getWidth() / 2, 15, {
    align: "center",
  });

  // Add timestamp
  doc.setFontSize(10);
  doc.setFont(undefined, "normal");
  doc.text(`Generated on: ${dayjs().format("YYYY-MM-DD HH:mm:ss")}`, 14, 22);

  // Configure table
  doc.autoTable({
    startY: 30,
    head: [headers.map((h) => h.header)],
    body: data,
    theme: "grid",
    headStyles: {
      fillColor: [240, 240, 240],
      textColor: [0, 0, 0],
      fontStyle: "bold",
      halign: "center",
      lineWidth: 0.5,
      lineColor: [200, 200, 200],
      fontSize: 10,
    },
    bodyStyles: {
      fontSize: 9,
      lineColor: [200, 200, 200],
      lineWidth: 0.5,
    },
    columnStyles: {
      0: { halign: "center" }, // ID
      1: { halign: "center" },
      2: { halign: "center" },
      3: { halign: "center" }, // Grand Total
      6: { halign: "center" }, // Paid
      7: { halign: "center" }, // Paid
    },
    alternateRowStyles: {
      fillColor: [248, 248, 248],
    },
    margin: { top: 30, right: 14, bottom: 20, left: 14 },
    didDrawPage: (data) => {
      // Add page number at the bottom
      doc.setFontSize(10);
      doc.text(
        `Page ${data.pageNumber} of ${data.pageCount}`,
        doc.internal.pageSize.getWidth() / 2,
        doc.internal.pageSize.getHeight() - 10,
        { align: "center" }
      );
    },
  });

  // Save with formatted filename including timestamp
  const filename = `invoice_${dayjs().format("YYYYMMDD_HHmmss")}.pdf`;
  doc.save(filename);
};
export const generateCsv = (suppliers) => {
  const flattenObject = (obj, prefix = "") => {
    const flat = {};
    for (let key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        const newKey = prefix ? `${prefix}_${key}` : key;

        if (Array.isArray(obj[key])) {
          obj[key].forEach((item, index) => {
            if (typeof item === "object" && item !== null) {
              Object.assign(flat, flattenObject(item, `${newKey}_${index}`));
            } else {
              flat[`${newKey}_${index}`] = item;
            }
          });
        } else if (typeof obj[key] === "object" && obj[key] !== null) {
          Object.assign(flat, flattenObject(obj[key], newKey));
        } else {
          flat[newKey] = obj[key];
        }
      }
    }
    return flat;
  };

  const flattenedSuppliers = suppliers.map(flattenObject);

  const csvHeaders = Object.keys(flattenedSuppliers[0]).map(
    (key) => key.charAt(0).toUpperCase() + key.slice(1).replace(/_/g, " ")
  );
  const csvRows = flattenedSuppliers.map((supplier) => Object.values(supplier));

  const csvContent = [csvHeaders, ...csvRows]
    .map((e) => e.join(","))
    .join("\n");
  const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
  const link = document.createElement("a");
  link.href = URL.createObjectURL(blob);
  link.download = "exportCSV.csv";
  link.click();
};

// appointment
export const handlePrintAppointment = (purchaseOrders) => {
  // Return if no data
  if (!purchaseOrders || purchaseOrders.length === 0) return;
  const formatDate = (dateString) => {
    try {
      return dayjs(dateString, "YYYY-MM-DD HH:mm:ss A").format(
        "YYYY-MM-DD HH:mm"
      );
    } catch (e) {
      return dateString;
    }
  };
  // Initialize PDF document
  const doc = new jsPDF({
    orientation: "landscape",
    unit: "mm",
    format: "a4",
  });

  // Define table headers with custom widths
  const headers = [
    { header: "Patient UID", width: 30 },
    { header: "Patient", width: 20 },
    { header: "Visit Type", width: 10 },
    { header: "Status", width: 10 },
    { header: "Appointment Slot", width: 20 },
    { header: "Consultant", width: 20 },
    { header: "Doctor", width: 20 },
    { header: "Created  At", width: 20 },
  ];

  // Map data with formatting
  const data = purchaseOrders.map((order) => [
    order?.uid || "-",
    order?.patient?.full_name || "-",
    order?.visit_type || "-",
    order?.status || "-",
    order?.appointment_slot?.code || "waiting",
    order?.consultant?.first_name
      ? `${order?.consultant?.first_name} ${order?.consultant?.last_name || ""}`
      : "",

    order?.doctor?.first_name
      ? `${order?.doctor?.first_name} ${order?.doctor?.last_name || ""}`
      : "",

    formatDate(order.created_at),
  ]);

  // Add title
  doc.setFontSize(16);
  doc.setFont(undefined, "bold");
  doc.text("Appointment List", doc.internal.pageSize.getWidth() / 2, 15, {
    align: "center",
  });

  // Add timestamp
  doc.setFontSize(10);
  doc.setFont(undefined, "normal");
  doc.text(`Generated on: ${dayjs().format("YYYY-MM-DD HH:mm:ss")}`, 14, 22);

  // Configure table
  doc.autoTable({
    startY: 30,
    head: [headers.map((h) => h.header)],
    body: data,
    theme: "grid",
    headStyles: {
      fillColor: [240, 240, 240],
      textColor: [0, 0, 0],
      fontStyle: "bold",
      halign: "center",
      lineWidth: 0.5,
      lineColor: [200, 200, 200],
      fontSize: 10,
    },
    bodyStyles: {
      fontSize: 9,
      lineColor: [200, 200, 200],
      lineWidth: 0.5,
    },
    // columnStyles: {
    //   0: { halign: "center" }, // ID
    //   1: { halign: "center" },
    //   2: { halign: "center" },
    //   3: { halign: "center" }, // Grand Total
    //   6: { halign: "center" }, // Paid
    //   7: { halign: "center" }, // Paid
    // },
    alternateRowStyles: {
      fillColor: [248, 248, 248],
    },
    margin: { top: 30, right: 14, bottom: 20, left: 14 },
    didDrawPage: (data) => {
      // Add page number at the bottom
      doc.setFontSize(10);
      doc.text(
        `Page ${data.pageNumber} of ${data.pageCount}`,
        doc.internal.pageSize.getWidth() / 2,
        doc.internal.pageSize.getHeight() - 10,
        { align: "center" }
      );
    },
  });

  // Save with formatted filename including timestamp
  const filename = `appointment_${dayjs().format("YYYYMMDD_HHmmss")}.pdf`;
  doc.save(filename);
};
// department
export const handleDownloadPdfDept = (suppliers) => {
  const doc = new jsPDF();
  if (suppliers?.length === 0) return;
  const headers = [
    Object.keys(suppliers[0]).map(
      (key) => key?.charAt(0)?.toUpperCase() + key?.slice(1)?.replace(/_/g, " ")
    ),
  ];

  // Map supplier data to a format suitable for the table
  const data = suppliers.map((supplier) => Object.values(supplier));

  doc.text("Department List", 14, 10); // Title
  doc.autoTable({
    head: headers,
    body: data,
    startY: 20,
    theme: "grid",
    headStyles: {
      fillColor: [255, 255, 255],
      textColor: [0, 0, 0],
      fontStyle: "bold",
      border: "1px solid black",
      lineWidth: 0.5,
    },
    styles: { fontSize: 10 },
  });

  doc.save("exported.pdf");
};

// medicinesalt
export const handleDownloadPdfSalt = (purchaseOrders) => {
  // Return if no data
  if (!purchaseOrders || purchaseOrders.length === 0) return;
  const formatDate = (dateString) => {
    try {
      return dayjs(dateString, "YYYY-MM-DD HH:mm:ss A").format(
        "YYYY-MM-DD HH:mm"
      );
    } catch (e) {
      return dateString;
    }
  };
  // Initialize PDF document
  const doc = new jsPDF({
    orientation: "landscape",
    unit: "mm",
    format: "a4",
  });

  // Define table headers with custom widths
  const headers = [
    { header: "Id", width: 10 },
    { header: "Medicine Salt Name", width: 50 },
    { header: "Created At", width: 20 },
    { header: "Updated At", width: 20 },
  ];

  // Map data with formatting
  const data = purchaseOrders.map((order) => [
    order?.id || "",
    order?.name,
    formatDate(order.created_at),
    formatDate(order.updated_at),
  ]);

  // Add title
  doc.setFontSize(16);
  doc.setFont(undefined, "bold");
  doc.text("Medicine Salt List", doc.internal.pageSize.getWidth() / 2, 15, {
    align: "center",
  });

  // Add timestamp
  doc.setFontSize(10);
  doc.setFont(undefined, "normal");
  doc.text(`Generated on: ${dayjs().format("YYYY-MM-DD HH:mm:ss")}`, 14, 22);

  // Configure table
  doc.autoTable({
    startY: 30,
    head: [headers.map((h) => h.header)],
    body: data,
    theme: "grid",
    headStyles: {
      fillColor: [240, 240, 240],
      textColor: [0, 0, 0],
      fontStyle: "bold",
      halign: "center",
      lineWidth: 0.5,
      lineColor: [200, 200, 200],
      fontSize: 10,
    },
    bodyStyles: {
      fontSize: 9,
      lineColor: [200, 200, 200],
      lineWidth: 0.5,
    },

    alternateRowStyles: {
      fillColor: [248, 248, 248],
    },
    margin: { top: 30, right: 14, bottom: 20, left: 14 },
    didDrawPage: (data) => {
      // Add page number at the bottom
      doc.setFontSize(10);
      doc.text(
        `Page ${data.pageNumber} of ${data.pageCount}`,
        doc.internal.pageSize.getWidth() / 2,
        doc.internal.pageSize.getHeight() - 10,
        { align: "center" }
      );
    },
  });

  // Save with formatted filename including timestamp
  const filename = `medicine_salt_${dayjs().format("YYYYMMDD_HHmmss")}.pdf`;
  doc.save(filename);
};
// consumable categories
export const handleDownloadPdfConsumableCat = (suppliers) => {
  const doc = new jsPDF();
  if (suppliers?.length === 0) return;
  const headers = [
    Object.keys(suppliers[0]).map(
      (key) => key?.charAt(0)?.toUpperCase() + key?.slice(1)?.replace(/_/g, " ")
    ),
  ];

  // Map supplier data to a format suitable for the table
  const data = suppliers.map((supplier) => Object.values(supplier));

  doc.text("Consumable Category List", 14, 10); // Title
  doc.autoTable({
    head: headers,
    body: data,
    startY: 20,
    theme: "grid",
    headStyles: {
      fillColor: [255, 255, 255],
      textColor: [0, 0, 0],
      fontStyle: "bold",
      border: "1px solid black",
      lineWidth: 0.5,
    },
    styles: { fontSize: 10 },
  });

  doc.save("exported.pdf");
};
//patient
export const handleDownloadPdfPatient = (patients) => {
  // Return if no data
  if (!patients || patients.length === 0) return;

  const formatDate = (dateString) => {
    try {
      return dayjs(dateString, "YYYY-MM-DD HH:mm:ss A").format(
        "YYYY-MM-DD HH:mm"
      );
    } catch (e) {
      return dateString;
    }
  };

  // Initialize PDF document
  const doc = new jsPDF({
    orientation: "landscape",
    unit: "mm",
    format: "a4",
  });

  // Define table headers with custom widths
  const headers = [
    { header: "UID", width: 15 },
    { header: "Full Name", width: 40 },
    { header: "Mobile", width: 20 },
    { header: "Email", width: 30 },
    { header: "Gender", width: 15 },
    { header: "Date of Birth", width: 25 },
    { header: "Age", width: 10 },
    { header: "Marital Status", width: 20 },
    { header: "City", width: 20 },
    { header: "Doctor", width: 30 },
    { header: "Created At", width: 25 },
    { header: "Updated At", width: 25 },
  ];

  // Map data with formatting
  const data = patients.map((patient) => [
    patient.uid || "",
    patient.full_name || "",
    patient.mobile || "",
    patient.email || "",
    patient.gender || "",
    patient.dob || "",
    patient.age || "",
    patient.marital_status || "",
    patient.city || "",
    patient.doctor || "",
    formatDate(patient.created_at),
    formatDate(patient.updated_at),
  ]);

  // Add title
  doc.setFontSize(16);
  doc.setFont(undefined, "bold");
  doc.text("Patient List", doc.internal.pageSize.getWidth() / 2, 15, {
    align: "center",
  });

  // Add timestamp
  doc.setFontSize(10);
  doc.setFont(undefined, "normal");
  doc.text(`Generated on: ${dayjs().format("YYYY-MM-DD HH:mm:ss")}`, 14, 22);

  // Configure table
  doc.autoTable({
    startY: 30,
    head: [headers.map((h) => h.header)],
    body: data,
    theme: "grid",
    headStyles: {
      fillColor: [240, 240, 240],
      textColor: [0, 0, 0],
      fontStyle: "bold",
      halign: "center",
      lineWidth: 0.5,
      lineColor: [200, 200, 200],
      fontSize: 10,
    },
    bodyStyles: {
      fontSize: 9,
      lineColor: [200, 200, 200],
      lineWidth: 0.5,
    },
    alternateRowStyles: {
      fillColor: [248, 248, 248],
    },
    columnStyles: headers.reduce((acc, h, i) => {
      acc[i] = { cellWidth: h.width };
      return acc;
    }, {}),
    margin: { top: 30, right: 14, bottom: 20, left: 14 },
    didDrawPage: (data) => {
      // Add page number at the bottom
      doc.setFontSize(10);
      doc.text(
        `Page ${data.pageNumber} of ${data.pageCount}`,
        doc.internal.pageSize.getWidth() / 2,
        doc.internal.pageSize.getHeight() - 10,
        { align: "center" }
      );
    },
  });

  // Save with formatted filename including timestamp
  const filename = `patient_list_${dayjs().format("YYYYMMDD_HHmmss")}.pdf`;
  doc.save(filename);
};

export const handleDownloadPdfOccupations = (occupations) => {
  // Return if no data
  if (!occupations || occupations.length === 0) return;

  const formatDate = (dateString) => {
    try {
      return dayjs(dateString, "YYYY-MM-DD HH:mm:ss A").format(
        "YYYY-MM-DD HH:mm"
      );
    } catch (e) {
      return dateString;
    }
  };

  // Initialize PDF document
  const doc = new jsPDF({
    orientation: "portrait",
    unit: "mm",
    format: "a4",
  });

  // Define table headers with custom widths
  const headers = [
    { header: "Id", width: 20 },
    { header: "Title", width: 40 },
    { header: "Value", width: 40 },
    { header: "Created At", width: 40 },
    { header: "Updated At", width: 40 },
  ];

  // Map data with formatting
  const data = occupations.map((occupations) => [
    occupations.id || "",
    occupations.title || "",
    occupations.value || "",
    formatDate(occupations.created_at),
    formatDate(occupations.updated_at),
  ]);

  // Add title
  doc.setFontSize(16);
  doc.setFont(undefined, "bold");
  doc.text("Occupations List", doc.internal.pageSize.getWidth() / 2, 15, {
    align: "center",
  });

  // Add timestamp
  doc.setFontSize(10);
  doc.setFont(undefined, "normal");
  doc.text(`Generated on: ${dayjs().format("YYYY-MM-DD HH:mm:ss")}`, 14, 22);

  // Configure table
  doc.autoTable({
    startY: 30,
    head: [headers.map((h) => h.header)],
    body: data,
    theme: "grid",
    headStyles: {
      fillColor: [240, 240, 240],
      textColor: [0, 0, 0],
      fontStyle: "bold",
      halign: "center",
      lineWidth: 0.5,
      lineColor: [200, 200, 200],
      fontSize: 10,
    },
    bodyStyles: {
      fontSize: 9,
      lineColor: [200, 200, 200],
      lineWidth: 0.5,
    },
    alternateRowStyles: {
      fillColor: [248, 248, 248],
    },
    columnStyles: headers.reduce((acc, h, i) => {
      acc[i] = { cellWidth: h.width };
      return acc;
    }, {}),
    margin: { top: 30, right: 14, bottom: 20, left: 14 },
    didDrawPage: (data) => {
      // Add page number at the bottom
      doc.setFontSize(10);
      doc.text(
        `Page ${data.pageNumber} of ${data.pageCount}`,
        doc.internal.pageSize.getWidth() / 2,
        doc.internal.pageSize.getHeight() - 10,
        { align: "center" }
      );
    },
  });

  // Save with formatted filename including timestamp
  const filename = `occupations_list_${dayjs().format("YYYYMMDD_HHmmss")}.pdf`;
  doc.save(filename);
};

export const handleDownloadPdfLabs = (labs) => {
  // Return if no data
  if (!labs || labs.length === 0) return;

  const formatDate = (dateString) => {
    try {
      return dayjs(dateString, "YYYY-MM-DD HH:mm:ss A").format(
        "YYYY-MM-DD HH:mm"
      );
    } catch (e) {
      return dateString;
    }
  };

  // Initialize PDF document
  const doc = new jsPDF({
    orientation: "portrait",
    unit: "mm",
    format: "a4",
  });

  // Define table headers with custom widths
  const headers = [
    { header: "Id", width: 40 },
    { header: "Name", width: 55 },
    { header: "Created At", width: 45 },
    { header: "Updated At", width: 45 },
  ];

  // Map data with formatting
  const data = labs.map((labs) => [
    labs.id || "",
    labs.name || "",
    formatDate(labs.created_at),
    formatDate(labs.updated_at),
  ]);

  // Add title
  doc.setFontSize(16);
  doc.setFont(undefined, "bold");
  doc.text("Labs List", doc.internal.pageSize.getWidth() / 2, 15, {
    align: "center",
  });

  // Add timestamp
  doc.setFontSize(10);
  doc.setFont(undefined, "normal");
  doc.text(`Generated on: ${dayjs().format("YYYY-MM-DD HH:mm:ss")}`, 14, 22);

  // Configure table
  doc.autoTable({
    startY: 30,
    head: [headers.map((h) => h.header)],
    body: data,
    theme: "grid",
    headStyles: {
      fillColor: [240, 240, 240],
      textColor: [0, 0, 0],
      fontStyle: "bold",
      halign: "center",
      lineWidth: 0.5,
      lineColor: [200, 200, 200],
      fontSize: 10,
    },
    bodyStyles: {
      fontSize: 9,
      lineColor: [200, 200, 200],
      lineWidth: 0.5,
    },
    alternateRowStyles: {
      fillColor: [248, 248, 248],
    },
    columnStyles: headers.reduce((acc, h, i) => {
      acc[i] = { cellWidth: h.width };
      return acc;
    }, {}),
    margin: { top: 30, right: 14, bottom: 20, left: 14 },
    didDrawPage: (data) => {
      // Add page number at the bottom
      doc.setFontSize(10);
      doc.text(
        `Page ${data.pageNumber} of ${data.pageCount}`,
        doc.internal.pageSize.getWidth() / 2,
        doc.internal.pageSize.getHeight() - 10,
        { align: "center" }
      );
    },
  });

  // Save with formatted filename including timestamp
  const filename = `labs_list_${dayjs().format("YYYYMMDD_HHmmss")}.pdf`;
  doc.save(filename);
};

export const handleDownloadPdfAdminUser = (adminUser) => {
  // Return if no data
  if (!adminUser || adminUser.length === 0) return;

  const formatDate = (dateString) => {
    try {
      return dayjs(dateString, "YYYY-MM-DD HH:mm:ss A").format(
        "YYYY-MM-DD HH:mm"
      );
    } catch (e) {
      return dateString;
    }
  };

  // Initialize PDF document
  const doc = new jsPDF({
    orientation: "portrait",
    unit: "mm",
    format: "a4",
  });

  // Define table headers with custom widths
  const headers = [
    { header: "Id", width: 10 },
    { header: "Email", width: 55 },
    { header: "Current Sign In at", width: 35 },
    { header: "Sign In Count", width: 20 },
    { header: "Created At", width: 35 },
    { header: "Updated At", width: 35 },
  ];

  // Map data with formatting
  const data = adminUser.map((adminUser) => [
    adminUser.id || "",
    adminUser.email || "",
    adminUser.current_sign_in_at || "",
    adminUser.sign_in_count || 0,
    formatDate(adminUser.created_at),
    formatDate(adminUser.updated_at),
  ]);

  // Add title
  doc.setFontSize(16);
  doc.setFont(undefined, "bold");
  doc.text("Admin User List", doc.internal.pageSize.getWidth() / 2, 15, {
    align: "center",
  });

  // Add timestamp
  doc.setFontSize(10);
  doc.setFont(undefined, "normal");
  doc.text(`Generated on: ${dayjs().format("YYYY-MM-DD HH:mm:ss")}`, 14, 22);

  // Configure table
  doc.autoTable({
    startY: 30,
    head: [headers.map((h) => h.header)],
    body: data,
    theme: "grid",
    headStyles: {
      fillColor: [240, 240, 240],
      textColor: [0, 0, 0],
      fontStyle: "bold",
      halign: "center",
      lineWidth: 0.5,
      lineColor: [200, 200, 200],
      fontSize: 10,
    },
    bodyStyles: {
      fontSize: 9,
      lineColor: [200, 200, 200],
      lineWidth: 0.5,
    },
    alternateRowStyles: {
      fillColor: [248, 248, 248],
    },
    columnStyles: headers.reduce((acc, h, i) => {
      acc[i] = { cellWidth: h.width };
      return acc;
    }, {}),
    margin: { top: 30, right: 14, bottom: 20, left: 14 },
    didDrawPage: (data) => {
      // Add page number at the bottom
      doc.setFontSize(10);
      doc.text(
        `Page ${data.pageNumber} of ${data.pageCount}`,
        doc.internal.pageSize.getWidth() / 2,
        doc.internal.pageSize.getHeight() - 10,
        { align: "center" }
      );
    },
  });

  // Save with formatted filename including timestamp
  const filename = `adminUser_list_${dayjs().format("YYYYMMDD_HHmmss")}.pdf`;
  doc.save(filename);
};
export const handleDownloadPdfRoles = (roles) => {
  // Return if no data
  if (!roles || roles.length === 0) return;

  const formatDate = (dateString) => {
    try {
      return dayjs(dateString, "YYYY-MM-DD HH:mm:ss A").format(
        "YYYY-MM-DD HH:mm"
      );
    } catch (e) {
      return dateString;
    }
  };

  // Initialize PDF document
  const doc = new jsPDF({
    orientation: "portrait",
    unit: "mm",
    format: "a4",
  });

  // Define table headers with custom widths
  const headers = [
    { header: "Id", width: 40 },
    { header: "Name", width: 55 },
    { header: "Created At", width: 45 },
    { header: "Updated At", width: 45 },
  ];

  // Map data with formatting
  const data = roles.map((roles) => [
    roles.id || "",
    roles.name || "",
    formatDate(roles.created_at),
    formatDate(roles.updated_at),
  ]);

  // Add title
  doc.setFontSize(16);
  doc.setFont(undefined, "bold");
  doc.text("Roles List", doc.internal.pageSize.getWidth() / 2, 15, {
    align: "center",
  });

  // Add timestamp
  doc.setFontSize(10);
  doc.setFont(undefined, "normal");
  doc.text(`Generated on: ${dayjs().format("YYYY-MM-DD HH:mm:ss")}`, 14, 22);

  // Configure table
  doc.autoTable({
    startY: 30,
    head: [headers.map((h) => h.header)],
    body: data,
    theme: "grid",
    headStyles: {
      fillColor: [240, 240, 240],
      textColor: [0, 0, 0],
      fontStyle: "bold",
      halign: "center",
      lineWidth: 0.5,
      lineColor: [200, 200, 200],
      fontSize: 10,
    },
    bodyStyles: {
      fontSize: 9,
      lineColor: [200, 200, 200],
      lineWidth: 0.5,
    },
    alternateRowStyles: {
      fillColor: [248, 248, 248],
    },
    columnStyles: headers.reduce((acc, h, i) => {
      acc[i] = { cellWidth: h.width };
      return acc;
    }, {}),
    margin: { top: 30, right: 14, bottom: 20, left: 14 },
    didDrawPage: (data) => {
      // Add page number at the bottom
      doc.setFontSize(10);
      doc.text(
        `Page ${data.pageNumber} of ${data.pageCount}`,
        doc.internal.pageSize.getWidth() / 2,
        doc.internal.pageSize.getHeight() - 10,
        { align: "center" }
      );
    },
  });

  // Save with formatted filename including timestamp
  const filename = `roles_list_${dayjs().format("YYYYMMDD_HHmmss")}.pdf`;
  doc.save(filename);
};

export const handleDownloadPdfInventory = (patients) => {
  // Return if no data
  if (!patients || patients.length === 0) return;

  const formatDate = (dateString) => {
    try {
      return dayjs(dateString, "YYYY-MM-DD HH:mm:ss A").format(
        "YYYY-MM-DD HH:mm"
      );
    } catch (e) {
      return dateString;
    }
  };

  // Initialize PDF document
  const doc = new jsPDF({
    orientation: "landscape",
    unit: "mm",
    format: "a4",
  });

  // Define table headers with custom widths
  const headers = [
    { header: "ID", width: 15 },
    { header: "Name", width: 20 },
    { header: "Stock Left", width: 15 },
    { header: "Critical Stock Limit", width: 25 },
    { header: "Price", width: 15 },
    { header: "Purchased At", width: 25 },
    { header: "Brand", width: 15 },
    { header: "Units Purchased", width: 25 },
    { header: "Auto Purchase Threshold", width: 35 },
    { header: "Medical Tests", width: 80 },
  ];

  // Map data with formatting
  const data = patients.map((item) => [
    item.id || "",
    item.name || "",
    item.stock_left || "",
    item.critical_stock_limit || "",
    item.price || "",
    item.purchased_at ? formatDate(item.purchased_at) : "",
    item.brand || "",
    item.units_purchased || "",
    item.auto_purchase_threshold || "",
    item.medical_tests
      ? item.medical_tests.map((test) => test.name).join(", ")
      : "",
  ]);

  // Add title
  doc.setFontSize(16);
  doc.setFont(undefined, "bold");
  doc.text("Patient List", doc.internal.pageSize.getWidth() / 2, 15, {
    align: "center",
  });

  // Add timestamp
  doc.setFontSize(10);
  doc.setFont(undefined, "normal");
  doc.text(`Generated on: ${dayjs().format("YYYY-MM-DD HH:mm:ss")}`, 14, 22);

  // Configure table
  doc.autoTable({
    startY: 30,
    head: [headers.map((h) => h.header)],
    body: data,
    theme: "grid",
    headStyles: {
      fillColor: [240, 240, 240],
      textColor: [0, 0, 0],
      fontStyle: "bold",
      halign: "center",
      lineWidth: 0.5,
      lineColor: [200, 200, 200],
      fontSize: 8,
    },
    bodyStyles: {
      fontSize: 6,
      lineColor: [200, 200, 200],
      lineWidth: 0.5,
    },
    alternateRowStyles: {
      fillColor: [248, 248, 248],
    },
    columnStyles: headers.reduce((acc, h, i) => {
      acc[i] = { cellWidth: h.width };
      return acc;
    }, {}),
    margin: { top: 30, right: 14, bottom: 20, left: 14 },
    didDrawPage: (data) => {
      // Add page number at the bottom
      doc.setFontSize(10);
      doc.text(
        `Page ${data.pageNumber} of ${data.pageCount}`,
        doc.internal.pageSize.getWidth() / 2,
        doc.internal.pageSize.getHeight() - 10,
        { align: "center" }
      );
    },
  });

  // Save with formatted filename including timestamp
  const filename = `lab_inventory_list_${dayjs().format(
    "YYYYMMDD_HHmmss"
  )}.pdf`;
  doc.save(filename);
};
export const handleDownloadPdfDoctor = (patients) => {
  // Return if no data
  if (!patients || patients.length === 0) return;

  const formatDate = (dateString) => {
    try {
      return dayjs(dateString, "YYYY-MM-DD HH:mm:ss A").format(
        "YYYY-MM-DD HH:mm"
      );
    } catch (e) {
      return dateString;
    }
  };

  // Initialize PDF document
  const doc = new jsPDF({
    orientation: "landscape",
    unit: "mm",
    format: "a4",
  });

  // Define table headers with custom widths
  const headers = [
    { header: "ID", width: 10 },
    { header: "First Name", width: 20 },
    { header: "Last Name", width: 20 },
    { header: "Mobile", width: 20 },
    { header: "Email", width: 30 },
    { header: "Gender", width: 15 },
    { header: "Date of Birth", width: 25 },
    { header: "Marital Status", width: 20 },
    { header: "Active", width: 10 },
    { header: "Specialty Type", width: 20 },
    { header: "Created At", width: 25 },
    { header: "Updated At", width: 25 },
    { header: "Details", width: 45 },
  ];

  // Map data with formatting
  const data = patients?.map((item) => [
    item.id || "",
    item.first_name || "",
    item.last_name || "",
    item.mobile || "",
    item.email || "",
    item.gender || "",
    item.dob || "",
    item.marital_status || "",
    item.active ? "Yes" : "No",
    item.sp_type || "",
    item.created_at ? formatDate(item.created_at) : "",
    item.updated_at ? formatDate(item.updated_at) : "",
    item?.details || "",
  ]);

  // Add title
  doc.setFontSize(16);
  doc.setFont(undefined, "bold");
  doc.text("Specialists List", doc.internal.pageSize.getWidth() / 2, 15, {
    align: "center",
  });

  // Add timestamp
  doc.setFontSize(10);
  doc.setFont(undefined, "normal");
  doc.text(`Generated on: ${dayjs().format("YYYY-MM-DD HH:mm:ss")}`, 14, 22);

  // Configure table
  doc.autoTable({
    startY: 30,
    head: [headers.map((h) => h.header)],
    body: data,
    theme: "grid",
    headStyles: {
      fillColor: [240, 240, 240],
      textColor: [0, 0, 0],
      fontStyle: "bold",
      halign: "center",
      lineWidth: 0.5,
      lineColor: [200, 200, 200],
      fontSize: 8,
    },
    bodyStyles: {
      fontSize: 6,
      lineColor: [200, 200, 200],
      lineWidth: 0.5,
    },
    alternateRowStyles: {
      fillColor: [248, 248, 248],
    },
    columnStyles: headers.reduce((acc, h, i) => {
      acc[i] = { cellWidth: h.width };
      return acc;
    }, {}),
    margin: { top: 30, right: 14, bottom: 20, left: 14 },
    didDrawPage: (data) => {
      // Add page number at the bottom
      doc.setFontSize(10);
      doc.text(
        `Page ${data.pageNumber} of ${data.pageCount}`,
        doc.internal.pageSize.getWidth() / 2,
        doc.internal.pageSize.getHeight() - 10,
        { align: "center" }
      );
    },
  });

  // Save with formatted filename including timestamp
  const filename = `specialists_list_${dayjs().format("YYYYMMDD_HHmmss")}.pdf`;
  doc.save(filename);
};
//  education
export const handleDownloadPdfEdu = (suppliers) => {
  const doc = new jsPDF();
  if (suppliers?.length === 0) return;
  const headers = [
    Object.keys(suppliers[0]).map(
      (key) => key?.charAt(0)?.toUpperCase() + key?.slice(1)?.replace(/_/g, " ")
    ),
  ];

  // Map supplier data to a format suitable for the table
  const data = suppliers.map((supplier) => Object.values(supplier));

  doc.text("Education List", 14, 10); // Title
  doc.autoTable({
    head: headers,
    body: data,
    startY: 20,
    theme: "grid",
    headStyles: {
      fillColor: [255, 255, 255],
      textColor: [0, 0, 0],
      fontStyle: "bold",
      border: "1px solid black",
      lineWidth: 0.5,
    },
    styles: { fontSize: 10 },
  });

  doc.save("Education.pdf");
};
// holidays
export const handleDownloadPdfHolidays = (suppliers) => {
  const doc = new jsPDF();
  if (suppliers?.length === 0) return;
  const headers = [
    Object.keys(suppliers[0]).map(
      (key) => key?.charAt(0)?.toUpperCase() + key?.slice(1)?.replace(/_/g, " ")
    ),
  ];

  // Map supplier data to a format suitable for the table
  const data = suppliers.map((supplier) => Object.values(supplier));

  doc.text("Holidays List", 14, 10); // Title
  doc.autoTable({
    head: headers,
    body: data,
    startY: 20,
    theme: "grid",
    headStyles: {
      fillColor: [255, 255, 255],
      textColor: [0, 0, 0],
      fontStyle: "bold",
      border: "1px solid black",
      lineWidth: 0.5,
    },
    styles: { fontSize: 10 },
  });

  doc.save("holidays.pdf");
};
// collection
export const handleDownloadPdfCollection = (patients) => {
  // Return if no data
  if (!patients || patients.length === 0) return;

  // Initialize PDF document
  const doc = new jsPDF({
    unit: "mm",
    format: "a4",
  });

  // Define table headers with custom widths
  const headers = [
    { header: "Patient UID", width: 25 },
    { header: "Name", width: 20 },
    { header: "Status", width: 25 },
    { header: "Invoice Type", width: 25 },
    { header: "Department", width: 20 },
    { header: "Total", width: 20 },
    { header: "Grand Total", width: 20 },
    { header: "Mobile", width: 25 },
  ];

  // Map data with formatting
  const data = patients?.map((item) => [
    item.patient_uid,
    item.name,
    item.status,
    item.invoice_type,
    item.department_name,
    item.total,
    item.grand_total,
    item.mobile,
  ]);

  // Add title
  doc.setFontSize(16);
  doc.setFont(undefined, "bold");
  doc.text("Collection List", doc.internal.pageSize.getWidth() / 2, 15, {
    align: "center",
  });

  // Add timestamp
  doc.setFontSize(10);
  doc.setFont(undefined, "normal");
  doc.text(`Generated on: ${dayjs().format("YYYY-MM-DD HH:mm:ss")}`, 14, 22);

  // Configure table
  doc.autoTable({
    startY: 30,
    head: [headers.map((h) => h.header)],
    body: data,
    theme: "grid",
    headStyles: {
      fillColor: [240, 240, 240],
      textColor: [0, 0, 0],
      fontStyle: "bold",
      halign: "center",
      lineWidth: 0.5,
      lineColor: [200, 200, 200],
      fontSize: 8,
    },
    bodyStyles: {
      fontSize: 6,
      lineColor: [200, 200, 200],
      lineWidth: 0.5,
    },
    alternateRowStyles: {
      fillColor: [248, 248, 248],
    },
    columnStyles: headers.reduce((acc, h, i) => {
      acc[i] = { cellWidth: h.width };
      return acc;
    }, {}),
    margin: { top: 30, right: 14, bottom: 20, left: 14 },
    didDrawPage: (data) => {
      // Add page number at the bottom
      doc.setFontSize(10);
      doc.text(
        `Page ${data.pageNumber} of ${data.pageCount}`,
        doc.internal.pageSize.getWidth() / 2,
        doc.internal.pageSize.getHeight() - 10,
        { align: "center" }
      );
    },
  });

  // Save with formatted filename including timestamp
  const filename = `collection${dayjs().format("YYYYMMDD_HHmmss")}.pdf`;
  doc.save(filename);
};
// admission pdf
export const handleDownloadPdfAdmission = (patients) => {
  // Return if no data
  if (!patients || patients.length === 0) return;

  // Initialize PDF document
  const doc = new jsPDF({
    unit: "mm",
    format: "a4",
  });

  // Define table headers with custom widths
  const headers = [
    { header: "Id", width: 10 },
    { header: "Room No", width: 10 },
    { header: "Check-in", width: 30 },
    { header: "Check-out", width: 30 },
    { header: "Patient", width: 20 },
    { header: "Ward No", width: 10 },
    { header: "Bed No", width: 10 },
    { header: "Created At", width: 30 },
    { header: "Updated At", width: 30 },
  ];

  // Map data with formatting
  const data = patients?.map((item) => [
    item.id,
    item.room_no,
    item.checkin,
    item.checkout,
    item.patient?.full_name,
    item.ward_no,
    item.bed_no,
    item.created_at,
    item.updated_at,
  ]);

  // Add title
  doc.setFontSize(16);
  doc.setFont(undefined, "bold");
  doc.text("Admission List", doc.internal.pageSize.getWidth() / 2, 15, {
    align: "center",
  });

  // Add timestamp
  doc.setFontSize(10);
  doc.setFont(undefined, "normal");
  doc.text(`Generated on: ${dayjs().format("YYYY-MM-DD HH:mm:ss")}`, 14, 22);

  // Configure table
  doc.autoTable({
    startY: 30,
    head: [headers.map((h) => h.header)],
    body: data,
    theme: "grid",
    headStyles: {
      fillColor: [240, 240, 240],
      textColor: [0, 0, 0],
      fontStyle: "bold",
      halign: "center",
      lineWidth: 0.5,
      lineColor: [200, 200, 200],
      fontSize: 8,
    },
    bodyStyles: {
      fontSize: 6,
      lineColor: [200, 200, 200],
      lineWidth: 0.5,
    },
    alternateRowStyles: {
      fillColor: [248, 248, 248],
    },
    columnStyles: headers.reduce((acc, h, i) => {
      acc[i] = { cellWidth: h.width };
      return acc;
    }, {}),
    margin: { top: 30, right: 14, bottom: 20, left: 14 },
    didDrawPage: (data) => {
      // Add page number at the bottom
      doc.setFontSize(10);
      doc.text(
        `Page ${data.pageNumber} of ${data.pageCount}`,
        doc.internal.pageSize.getWidth() / 2,
        doc.internal.pageSize.getHeight() - 10,
        { align: "center" }
      );
    },
  });

  // Save with formatted filename including timestamp
  const filename = `admission${dayjs().format("YYYYMMDD_HHmmss")}.pdf`;
  doc.save(filename);
};

// shortcode

import React from "react";
import Header from "../Header";
import Sidebar from "../Sidebar";
import { Link, useParams } from "react-router-dom";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { useQuery } from "@tanstack/react-query";

import { useSelector } from "react-redux";
import { Chip, Skeleton } from "@mui/material";
import { getLabInventoryDetails } from "../../connections/getLabInventoryDetails";
// import { Table } from "antd";
const LabInventoryDetail = () => {
  const {
    user: { user },
  } = useSelector((store) => store);
  const { id } = useParams();
  const { data: labInventoryData, isLoading } = useQuery({
    queryKey: ["getLabInventoryDetails", id],
    queryFn: () => getLabInventoryDetails(user?.token, id),
    enabled: !!id,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });

  // const columns = [
  //   {
  //     title: "ID",
  //     dataIndex: "id",
  //     key: "id",
  //   },
  //   {
  //     title: "Name",
  //     dataIndex: "name",
  //     key: "name",
  //   },
  //   {
  //     title: "Stock Left",
  //     dataIndex: "stock_left",
  //     key: "stock_left",
  //     render: (stock_left) => (stock_left === null ? "N/A" : stock_left),
  //   },
  //   {
  //     title: "Critical Stock Limit",
  //     dataIndex: "critical_stock_limit",
  //     key: "critical_stock_limit",
  //     render: (critical_stock_limit) =>
  //       critical_stock_limit === null ? "N/A" : critical_stock_limit,
  //   },
  //   {
  //     title: "Price",
  //     dataIndex: "price",
  //     key: "price",
  //     render: (price) => (price === null ? "N/A" : price),
  //   },
  //   {
  //     title: "Purchased At",
  //     dataIndex: "purchased_at",
  //     key: "purchased_at",
  //     render: (purchased_at) => (purchased_at === null ? "N/A" : purchased_at),
  //   },
  //   {
  //     title: "Brand",
  //     dataIndex: "brand",
  //     key: "brand",
  //     render: (brand) => (brand === "" ? "N/A" : brand),
  //   },
  //   {
  //     title: "Units Purchased",
  //     dataIndex: "units_purchased",
  //     key: "units_purchased",
  //     render: (units_purchased) =>
  //       units_purchased === null ? "N/A" : units_purchased,
  //   },
  //   {
  //     title: "Auto Purchase Threshold",
  //     dataIndex: "auto_purchase_threshold",
  //     key: "auto_purchase_threshold",
  //     render: (auto_purchase_threshold) =>
  //       auto_purchase_threshold === null ? "N/A" : auto_purchase_threshold,
  //   },
  //   {
  //     title: "Medical Tests",
  //     dataIndex: "medical_tests",
  //     key: "medical_tests",
  //     render: (_, records) => {
  //       return records?.medical_tests?.map((test, index) => (
  //         <Link
  //           key={index}
  //           // className="badge badge-primary m-1"
  //           // style={{
  //           //   backgroundColor: "#2e37a4",
  //           //   color: "#fff",
  //           // }}
  //           to={`/admin/medical_tests/${test?.id}`}
  //         >
  //           <Chip
  //             key={index}
  //             size="small"
  //             label={test.name}
  //             style={{
  //               margin: "4px",
  //               backgroundColor: "#2e37a4",
  //               color: "#fff",
  //               cursor: "pointer",
  //             }}
  //           />
  //         </Link>
  //       ));
  //     },
  //   },
  // ];

  return (
    <>
      <Header />
      <Sidebar
        id="menu-item4"
        id1="menu-items4"
        activeClassName="pharmacy-list"
      />
      <>
        <div className="page-wrapper">
          <div className="content">
            {/* Page Header */}
            <div className="page-header">
              <div className="row">
                <div className="col-sm-12">
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to={-1}>Lab Inventory</Link>
                    </li>
                    <li className="breadcrumb-item">
                      <i className="feather-chevron-right">
                        <FeatherIcon icon="chevron-right" />
                      </i>
                    </li>
                    <li className="breadcrumb-item active">
                      <Link to={-1}> Lab Inventory Detail</Link>
                    </li>
                    <li className="breadcrumb-item">
                      <i className="feather-chevron-right">
                        <FeatherIcon icon="chevron-right" />
                      </i>
                    </li>
                    <li className="breadcrumb-item active">{id}</li>
                  </ul>
                </div>
              </div>
            </div>
            {/* /Page Header */}
            <div className="row">
              <div className="col-sm-12">
                <div className="card card-table show-entire">
                  <div className="card-body">
                    {/* content Header */}
                    <div className="page-table-header mb-2">
                      <div className="row align-items-center">
                        <div className="col">
                          <div className="doctor-table-blk">
                            <h3>Lab Inventory Details</h3>
                            <div className="doctor-search-blk"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {isLoading ? (
                      <div>
                        <Skeleton
                          variant="rectangular"
                          width="100%"
                          height={430} // Same height as your chart
                        />
                      </div>
                    ) : (
                      <div className="content-details">
                        <div className="col-lg-12">
                          <div className="card-box">
                            <div className="card-block">
                              <ul className="personal-info">
                                <li className="pb-1">
                                  <span className="title">ID:</span>
                                  <span className="text">
                                    {labInventoryData?.id || "N/A"}
                                  </span>
                                </li>
                                <li className="pb-1">
                                  <span className="title">Name:</span>
                                  <span className="text">
                                    {labInventoryData?.name || "N/A"}
                                  </span>
                                </li>
                                <li className="pb-1">
                                  <span className="title">Stock Left:</span>
                                  <span className="text">
                                    {labInventoryData?.stock_left === null
                                      ? "N/A"
                                      : labInventoryData?.stock_left}
                                  </span>
                                </li>
                                <li className="pb-1">
                                  <span className="title">
                                    Critical Stock Limit:
                                  </span>
                                  <span className="text">
                                    {labInventoryData?.critical_stock_limit ===
                                    null
                                      ? "N/A"
                                      : labInventoryData?.critical_stock_limit}
                                  </span>
                                </li>
                                <li className="pb-1">
                                  <span className="title">Price:</span>
                                  <span className="text">
                                    {labInventoryData?.price === null
                                      ? "N/A"
                                      : `₹${labInventoryData?.price}`}
                                  </span>
                                </li>
                                <li className="pb-1">
                                  <span className="title">Purchased At:</span>
                                  <span className="text">
                                    {labInventoryData?.purchased_at === null
                                      ? "N/A"
                                      : labInventoryData?.purchased_at}
                                  </span>
                                </li>
                                <li className="pb-1">
                                  <span className="title">Brand:</span>
                                  <span className="text">
                                    {labInventoryData?.brand === ""
                                      ? "N/A"
                                      : labInventoryData?.brand}
                                  </span>
                                </li>
                                <li className="pb-1">
                                  <span className="title">
                                    Units Purchased:
                                  </span>
                                  <span className="text">
                                    {labInventoryData?.units_purchased === null
                                      ? "N/A"
                                      : labInventoryData?.units_purchased}
                                  </span>
                                </li>
                                <li className="pb-1">
                                  <span className="title">
                                    Auto Purchase Threshold:
                                  </span>
                                  <span className="text">
                                    {labInventoryData?.auto_purchase_threshold ===
                                    null
                                      ? "N/A"
                                      : labInventoryData?.auto_purchase_threshold}
                                  </span>
                                </li>
                                <li className="pb-1">
                                  <span className="title">Medical Tests:</span>
                                  <span className="text">
                                    {labInventoryData?.medical_tests &&
                                    labInventoryData.medical_tests.length > 0
                                      ? labInventoryData.medical_tests.map(
                                          (test, index) => (
                                            <Link
                                              key={index}
                                              to={`/admin/medical_tests/${test?.id}`}
                                            >
                                              <Chip
                                                key={index}
                                                size="small"
                                                label={test.name}
                                                style={{
                                                  margin: "4px",
                                                  backgroundColor: "#2e37a4",
                                                  color: "#fff",
                                                  cursor: "pointer",
                                                }}
                                              />
                                            </Link>
                                          )
                                        )
                                      : "N/A"}
                                  </span>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      // <div className="table-responsive">
                      //   <Table
                      //     columns={columns}
                      //     dataSource={[labInventoryData]}
                      //     pagination={false}
                      //     scroll={{ x: "max-content" }}
                      //   />
                      // </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </>
  );
};

export default LabInventoryDetail;

import React from "react";
import Header from "../../Header";
import Sidebar from "../../Sidebar";
import { Link, useParams } from "react-router-dom";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { Skeleton } from "@mui/material";
// import { Table } from "antd";
import { getVideoDetails } from "../../../connections/getVideoDetails";
// const columns = [
//   {
//     title: "Title",
//     dataIndex: "title",
//   },
//   {
//     title: "Link",
//     dataIndex: "link",
//     render: (link) => (
//       <Link
//         style={{
//           whiteSpace: "normal",
//           wordBreak: "break-word",
//           maxWidth: "250px",
//           display: "block",
//           overflow: "hidden",
//           textOverflow: "ellipsis",
//         }}
//         to={link}
//         rel="noreferrer"
//         target="_blank"
//       >
//         {link}
//       </Link>
//     ),
//   },
//   {
//     title: "Video Category",
//     dataIndex: "video_category",
//     render: (video_category) => (
//       <Link to={`/admin/prescription/video_categories/${video_category?.id}`}>
//         {video_category?.title}
//       </Link>
//     ),
//   },
//   {
//     title: "Created At",
//     dataIndex: "created_at",
//     render: (date) => (
//       <>{dayjs(date, "YYYY-MM-DD HH:mm:ss A").format("MMMM DD, YYYY HH:mm")}</>
//     ),
//   },
//   {
//     title: "Updated At",
//     dataIndex: "updated_at",
//     render: (date) => (
//       <>{dayjs(date, "YYYY-MM-DD HH:mm:ss A").format("MMMM DD, YYYY HH:mm")}</>
//     ),
//   },
// ];
const ShowVideo = () => {
  const {
    user: { user },
  } = useSelector((store) => store);
  const { id } = useParams();

  const { data: videoDataById, isLoading } = useQuery({
    queryKey: ["getVideoDetails", id],
    queryFn: () => getVideoDetails(user?.token, id),
    enabled: !!id,
    // staleTime: Infinity, // Keep the data fresh indefinitely

    refetchOnWindowFocus: false, // Don't refetch when window regains focus
    refetchOnReconnect: false, // Don't refetch when reconnecting
  });
  // const videoList = videoDataById ? [videoDataById] : [];

  return (
    <>
      <Header />
      <Sidebar activeClassName="videos-dashboard" />
      <>
        <div className="page-wrapper">
          <div className="content">
            {/* Page Header */}
            <div className="page-header">
              <div className="row">
                <div className="col-sm-12">
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item active">
                      <Link to={-1}>Video Details</Link>
                    </li>
                    <li className="breadcrumb-item">
                      <i className="feather-chevron-right">
                        <FeatherIcon icon="chevron-right" />
                      </i>
                    </li>
                    <li className="breadcrumb-item active">{id}</li>
                  </ul>
                </div>
              </div>
            </div>
            {/* /Page Header */}
            <div className="row">
              <div className="col-sm-12">
                <div className="card card-table show-entire">
                  <div className="card-body">
                    {/* content Header */}
                    <div className="page-table-header mb-2">
                      <div className="row align-items-center">
                        <div className="col">
                          <div className="doctor-table-blk">
                            <h3>Video Details</h3>
                            <div className="doctor-search-blk"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {isLoading ? (
                      <div>
                        <Skeleton
                          variant="rectangular"
                          width="100%"
                          height={430} // Same height as your chart
                        />
                      </div>
                    ) : (
                      <div className="content-details">
                        <div className="col-lg-12">
                          <div className="card-box">
                            <div className="card-block">
                              <ul className="personal-info">
                                <li className="pb-1">
                                  <span className="title">Title:</span>
                                  <span className="text">
                                    {videoDataById?.title || "N/A"}
                                  </span>
                                </li>
                                <li className="pb-1">
                                  <span className="title">Link:</span>
                                  <span className="text">
                                    {videoDataById?.link ? (
                                      <Link
                                        style={{
                                          whiteSpace: "normal",
                                          wordBreak: "break-word",
                                          maxWidth: "250px",
                                          display: "block",
                                          overflow: "hidden",
                                          textOverflow: "ellipsis",
                                        }}
                                        to={videoDataById?.link}
                                        rel="noreferrer"
                                        target="_blank"
                                      >
                                        {videoDataById?.link}
                                      </Link>
                                    ) : (
                                      "N/A"
                                    )}
                                  </span>
                                </li>
                                <li className="pb-1">
                                  <span className="title">Video Category:</span>
                                  <span className="text">
                                    {videoDataById?.video_category ? (
                                      <Link
                                        to={`/admin/prescription/video_categories/${videoDataById?.video_category?.id}`}
                                      >
                                        {videoDataById?.video_category?.title ||
                                          "N/A"}
                                      </Link>
                                    ) : (
                                      "N/A"
                                    )}
                                  </span>
                                </li>
                                <li className="pb-1">
                                  <span className="title">Created At:</span>
                                  <span className="text">
                                    {videoDataById?.created_at
                                      ? dayjs(
                                          videoDataById?.created_at,
                                          "YYYY-MM-DD HH:mm:ss A"
                                        ).format("MMMM DD, YYYY HH:mm")
                                      : "N/A"}
                                  </span>
                                </li>
                                <li className="pb-1">
                                  <span className="title">Updated At:</span>
                                  <span className="text">
                                    {videoDataById?.updated_at
                                      ? dayjs(
                                          videoDataById?.updated_at,
                                          "YYYY-MM-DD HH:mm:ss A"
                                        ).format("MMMM DD, YYYY HH:mm")
                                      : "N/A"}
                                  </span>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      // <div className="table-responsive doctor-list">
                      //   <Table
                      //     pagination={false}
                      //     columns={columns}
                      //     dataSource={videoList}
                      //     rowKey={(record) => record.id}
                      //     scroll={{ x: "max-content" }}
                      //   />
                      // </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </>
  );
};
export default ShowVideo;

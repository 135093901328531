import React, { useState } from "react";
import Header from "../Header";
import Sidebar from "../Sidebar";
import { Link, useNavigate } from "react-router-dom";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import Select from "react-select";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { postStaff } from "../../connections/postStaff";
import { useQuery } from "@tanstack/react-query";
import { getRolesList } from "../../connections/getRolesList";
const AddStaff = () => {
  const [formState, setFormState] = useState({ active: false });
  const navigate = useNavigate();
  const {
    user: { user },
  } = useSelector((store) => store);
  const handleChange = (name, value) => {
    setFormState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const { data: getAllRoles, isLoading } = useQuery({
    queryKey: ["getRolesList", "all"],
    queryFn: () => getRolesList(user?.token, "all", null, null),
    enabled: !!user?.token,

    refetchOnWindowFocus: false, // Don't refetch when window regains focus
    refetchOnReconnect: false, // Don't refetch when reconnecting
  });
  let data = getAllRoles?.payload?.roles || [];

  const options =
    (data?.length > 0 &&
      data?.map((item) => ({
        value: item.id,
        label: item.name,
      }))) ||
    [];

  const handleSubmit = async (e) => {
    e.preventDefault();
    const payload = {
      backops_user: formState,
    };
    if (!formState?.uid) {
      toast.error("UID is required");
      return;
    }
    if (!formState?.first_name || !formState?.last_name) {
      toast.error("first and last name is required");
      return;
    }
    if (!formState?.password) {
      toast.error("Password is required");
      return;
    }
    if (formState.password.length < 6) {
      toast.error("Password must be at least 6 characters long");
      return;
    }

    if (formState.password.length > 128) {
      toast.error("Password cannot exceed 128 characters");
      return;
    }
    try {
      const result = await postStaff(user?.token, payload);
      if (result?.status?.status === "SUCCESS") {
        toast.success("Staff Added Successfully");
        setFormState({});
        navigate("/admin/staff_list");
      }
    } catch (error) {
      // toast.error(error?.message);
      console.log(error.message);
    }
  };

  return (
    <div>
      <Header />
      <Sidebar activeClassName="staff-list" />
      <>
        <div className="page-wrapper">
          <div className="content">
            {/* Page Header */}
            <div className="page-header">
              <div className="row">
                <div className="col-sm-12">
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to={-1}>Staff </Link>
                    </li>
                    <li className="breadcrumb-item">
                      <i className="feather-chevron-right">
                        <FeatherIcon icon="chevron-right" />
                      </i>
                    </li>
                    <li className="breadcrumb-item active">Add Staff</li>
                  </ul>
                </div>
              </div>
            </div>
            {/* /Page form */}
            <div className="row">
              <div className="col-sm-12">
                <div className="card">
                  <div className="card-body">
                    <form>
                      <div className="row">
                        <div className="col-12">
                          <div className="form-heading">
                            <h4>Add Staff</h4>
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-3">
                          <div className="form-group local-forms">
                            <label>
                              Uid
                              <span className="login-danger">*</span>
                            </label>
                            <input
                              value={formState?.uid}
                              name="uid"
                              onChange={(e) =>
                                handleChange("uid", e.target.value)
                              }
                              className="form-control"
                              type="text"
                              required={true}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-3">
                          <div className="form-group local-forms">
                            <label>
                              First Name
                              <span className="login-danger">*</span>
                            </label>
                            <input
                              value={formState?.first_name}
                              name="first_name"
                              onChange={(e) =>
                                handleChange("first_name", e.target.value)
                              }
                              className="form-control"
                              type="text"
                              required={true}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-3">
                          <div className="form-group local-forms">
                            <label>
                              Last Name
                              <span className="login-danger">*</span>
                            </label>
                            <input
                              value={formState?.last_name}
                              name="last_name"
                              onChange={(e) =>
                                handleChange("last_name", e.target.value)
                              }
                              className="form-control"
                              type="text"
                              required={true}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-3">
                          <div className="form-group local-forms">
                            <label>
                              Email
                              {/* <span className="login-danger">*</span> */}
                            </label>
                            <input
                              value={formState?.email}
                              name="email"
                              onChange={(e) =>
                                handleChange("email", e.target.value)
                              }
                              className="form-control"
                              type="text"
                              //   required={true}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-3">
                          <div className="form-group local-forms">
                            <label>
                              Mobile
                              {/* <span className="login-danger">*</span> */}
                            </label>
                            <input
                              value={formState?.mobile}
                              name="mobile"
                              onChange={(e) =>
                                handleChange("mobile", e.target.value)
                              }
                              className="form-control"
                              type="number"
                              //   required={true}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-3">
                          <div className="form-group local-forms">
                            <label>
                              Password
                              <span className="login-danger">*</span>
                            </label>
                            <input
                              value={formState?.password}
                              name="password"
                              onChange={(e) =>
                                handleChange("password", e.target.value)
                              }
                              className="form-control"
                              type="password"
                              required={true}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-3">
                          <div className="form-group local-forms">
                            <label>
                              Roles
                              {/* <span className="login-danger">*</span> */}
                            </label>
                            <Select
                              isLoading={isLoading}
                              options={options}
                              isMulti
                              isSearchable
                              value={options?.filter((option) =>
                                formState.role_ids?.includes(option.value)
                              )}
                              onChange={(selectedOptions) => {
                                handleChange(
                                  "role_ids",
                                  selectedOptions.map((option) => option.value)
                                );
                              }}
                              placeholder="Select Roles"
                              className="react-select-container"
                              classNamePrefix="react-select"
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-3">
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: 10,
                            }}
                            // className="form-group local-forms"
                          >
                            <label>
                              Active
                              {/* <span className="login-danger">*</span> */}
                            </label>
                            <input
                              value={formState?.active}
                              name="active"
                              checked={formState?.active}
                              onChange={(e) =>
                                handleChange("active", e.target.checked)
                              }
                              //   className="form-control"
                              type="checkbox"
                              required={true}
                            />
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="doctor-submit text-end">
                            <button
                              onClick={handleSubmit}
                              type="submit"
                              className="btn btn-primary submit-form me-2"
                            >
                              Submit
                            </button>
                            <button
                              onClick={(e) => {
                                e.preventDefault();
                                navigate("/admin/staff_list");
                              }}
                              type="submit"
                              className="btn btn-primary cancel-form"
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </div>
  );
};

export default AddStaff;

import React from "react";
import Header from "../Header";
import Sidebar from "../Sidebar";
import { Link, useParams } from "react-router-dom";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { useQuery } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { Skeleton } from "@mui/material";
import { getDoctorDetails } from "../../connections/getDoctorDetails";
import { Table } from "antd";
import dayjs from "dayjs";

// const docDetailsColumns = [
//   {
//     title: "Id",
//     dataIndex: "id",
//     // sorter: (a, b) => a.ConsultingDoctor.length - b.ConsultingDoctor.length,/
//   },
//   {
//     title: "uid",
//     dataIndex: "uid",
//     // sorter: (a, b) => a.ConsultingDoctor.length - b.ConsultingDoctor.length,/
//   },
//   {
//     title: "First Name",
//     dataIndex: "first_name",
//     // sorter: (a, b) => a.name - b.name,
//   },
//   {
//     title: "last Name",
//     dataIndex: "last_name",
//     // sorter: (a, b) => a.name - b.name,
//   },
//   {
//     title: "Mobile",
//     dataIndex: "mobile",
//     //   sorter: (a, b) => a.ConsultingDoctor.length - b.ConsultingDoctor.length,
//     // render: (date) => <>{dayjs(date).format("MMMM DD, YYYY HH:mm")}</>,
//   },
//   {
//     title: "Email",
//     dataIndex: "email",
//   },
//   {
//     title: "Gender",
//     dataIndex: "gender",
//   },
//   {
//     title: "DOB",
//     dataIndex: "dob",
//   },
//   {
//     title: "Marital Status",
//     dataIndex: "marital_status",
//   },
//   {
//     title: "Active",
//     dataIndex: "active",
//     render: (text) => (
//       <span
//         className={
//           text ? "custom-badge status-green" : "custom-badge status-pink"
//         }
//       >
//         {" "}
//         {text ? "True" : "False"}
//       </span>
//     ),
//   },
//   {
//     title: "SP Type",
//     dataIndex: "sp_type",
//   },
//   {
//     title: "Created At",
//     dataIndex: "created_at",
//     render: (date) => (
//       <>{dayjs(date, "YYYY-MM-DD HH:mm:ss A").format("MMMM DD, YYYY HH:mm")}</>
//     ),
//   },
//   {
//     title: "Updated At",
//     dataIndex: "updated_at",
//     render: (date) => (
//       <>{dayjs(date, "YYYY-MM-DD HH:mm:ss A").format("MMMM DD, YYYY HH:mm")}</>
//     ),
//   },
//   {
//     title: "Details",
//     dataIndex: "details",
//     render: (text) => (
//       <span
//         style={{
//           textAlign: "start",
//           // wordWrap: "break-word",
//           // whiteSpace: "normal",
//         }}
//         dangerouslySetInnerHTML={{
//           __html: text,
//         }}
//       />
//     ),
//   },
// ];

const ViewDoctor = () => {
  const {
    user: { user },
  } = useSelector((store) => store);
  const { id } = useParams();
  const { data: getDocDetail, isLoading } = useQuery({
    queryKey: ["getDoctorDetails", id],
    queryFn: () => getDoctorDetails(user?.token, id),
    enabled: !!id,
    // staleTime: Infinity, // Keep the data fresh indefinitely

    refetchOnWindowFocus: false, // Don't refetch when window regains focus
    refetchOnReconnect: false, // Don't refetch when reconnecting
  });
  // console.log(getDocDetail)
  // const doctorsListData = getDocDetail ? [getDocDetail] : [];
  const investigationColumn = [
    {
      title: "S.No",
      key: "sno",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Medical Test",
      dataIndex: "test_name",
      key: "test_name",
    },

    {
      title: "Active",
      dataIndex: "is_investigation",
      key: "is_investigation",
      render: (is_investigation) =>
        is_investigation ? (
          <span className="custom-badge status-green">
            {is_investigation.toString()}
          </span>
        ) : (
          <span className="custom-badge status-pink">False</span>
        ),
    },
  ];
  const columns = [
    {
      title: "S.No",
      key: "sno",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Plan Title",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "Discount (%)",
      dataIndex: "discount",
      key: "discount",
    },
  ];
  const columnsRevenue = [
    {
      title: "S.No",
      key: "sno",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Value",
      dataIndex: "value",
      key: "value",
    },
    {
      title: "Department",
      dataIndex: ["department", "name"],
      key: "department",
    },
  ];
  return (
    <>
      <Header />
      <Sidebar activeClassName="doctor-list" />
      <>
        <div className="page-wrapper">
          <div className="content">
            {/* Page Header */}
            <div className="page-header">
              <div className="row">
                <div className="col-sm-12">
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item active">
                      <Link to={-1}>Specialist Details</Link>
                    </li>
                    <li className="breadcrumb-item">
                      <i className="feather-chevron-right">
                        <FeatherIcon icon="chevron-right" />
                      </i>
                    </li>
                    <li className="breadcrumb-item active">{id}</li>
                  </ul>
                </div>
              </div>
            </div>
            {/* /Page Header */}
            <div className="row">
              <div className="col-sm-12">
                <div className="card card-table show-entire">
                  <div className="card-body">
                    {/* content Header */}
                    <div className="page-table-header mb-2">
                      <div className="row align-items-center">
                        <div className="col">
                          <div className="doctor-table-blk">
                            <h3>Specialist Details</h3>
                            <div className="doctor-search-blk"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {isLoading ? (
                      <div>
                        <Skeleton
                          variant="rectangular"
                          width="100%"
                          height={430} // Same height as your chart
                        />
                      </div>
                    ) : (
                      <div className="content-details">
                        <div className="col-lg-12">
                          <div className="card-box">
                            <div className="card-block">
                              <ul className="personal-info">
                                <li className="pb-1">
                                  <span className="title">Id:</span>
                                  <span className="text">
                                    {getDocDetail?.id || "N/A"}
                                  </span>
                                </li>
                                <li className="pb-1">
                                  <span className="title">UID:</span>
                                  <span className="text">
                                    {getDocDetail?.uid || "N/A"}
                                  </span>
                                </li>
                                <li className="pb-1">
                                  <span className="title">First Name:</span>
                                  <span className="text">
                                    {getDocDetail?.first_name || "N/A"}
                                  </span>
                                </li>
                                <li className="pb-1">
                                  <span className="title">Last Name:</span>
                                  <span className="text">
                                    {getDocDetail?.last_name || "N/A"}
                                  </span>
                                </li>
                                <li className="pb-1">
                                  <span className="title">Mobile:</span>
                                  <span className="text">
                                    {getDocDetail?.mobile || "N/A"}
                                  </span>
                                </li>
                                <li className="pb-1">
                                  <span className="title">Email:</span>
                                  <span className="text">
                                    {getDocDetail?.email || "N/A"}
                                  </span>
                                </li>
                                <li className="pb-1">
                                  <span className="title">Gender:</span>
                                  <span className="text">
                                    {getDocDetail?.gender || "N/A"}
                                  </span>
                                </li>
                                <li className="pb-1">
                                  <span className="title">DOB:</span>
                                  <span className="text">
                                    {getDocDetail?.dob || "N/A"}
                                  </span>
                                </li>
                                <li className="pb-1">
                                  <span className="title">Marital Status:</span>
                                  <span className="text">
                                    {getDocDetail?.marital_status || "N/A"}
                                  </span>
                                </li>
                                <li className="pb-1">
                                  <span className="title">Active:</span>
                                  <span className="text">
                                    {getDocDetail?.active ? (
                                      <span className="custom-badge status-green">
                                        True
                                      </span>
                                    ) : (
                                      <span className="custom-badge status-pink">
                                        False
                                      </span>
                                    )}
                                  </span>
                                </li>
                                <li className="pb-1">
                                  <span className="title">SP Type:</span>
                                  <span className="text">
                                    {getDocDetail?.sp_type || "N/A"}
                                  </span>
                                </li>
                                <li className="pb-1">
                                  <span className="title">Created At:</span>
                                  <span className="text">
                                    {getDocDetail?.created_at
                                      ? dayjs(
                                          getDocDetail?.created_at,
                                          "YYYY-MM-DD HH:mm:ss A"
                                        ).format("MMMM DD, YYYY HH:mm")
                                      : "N/A"}
                                  </span>
                                </li>
                                <li className="pb-1">
                                  <span className="title">Updated At:</span>
                                  <span className="text">
                                    {getDocDetail?.updated_at
                                      ? dayjs(
                                          getDocDetail?.updated_at,
                                          "YYYY-MM-DD HH:mm:ss A"
                                        ).format("MMMM DD, YYYY HH:mm")
                                      : "N/A"}
                                  </span>
                                </li>
                                <li className="pb-1">
                                  <span className="title">Details:</span>
                                  <span
                                    className="text"
                                    dangerouslySetInnerHTML={{
                                      __html: getDocDetail?.details || "N/A",
                                    }}
                                  />
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div
                            style={{ marginLeft: "1rem" }}
                            className="col-md-12 col-12 mb-2 p-2"
                          >
                            <div className="doctor-table-blk">
                              <h3>Investigations</h3>
                              <div className="doctor-search-blk"></div>
                            </div>
                          </div>
                          <div
                            style={{ marginLeft: "1rem" }}
                            className="table-responsive doctor-list"
                          >
                            <Table
                              columns={investigationColumn}
                              dataSource={
                                getDocDetail?.specialist_medical_tests
                              }
                              rowKey="id"
                              pagination={false} // Disable pagination
                              scroll={{ x: "max-content" }}
                            />
                          </div>
                          {/* <div className="table-responsive doctor-list">
                            <Table
                              pagination={false}
                              columns={docDetailsColumns}
                              dataSource={doctorsListData}
                              rowKey={(record) => record.id}
                              scroll={{ x: "max-content" }}
                            />
                          </div> */}
                          <div
                            style={{ marginLeft: "1rem" }}
                            className="col-md-12 col-12 mb-2 p-2"
                          >
                            <div className="doctor-table-blk">
                              <h3>Plans</h3>
                              <div className="doctor-search-blk"></div>
                            </div>
                          </div>
                          <div
                            style={{ marginLeft: "1rem" }}
                            className="table-responsive doctor-list"
                          >
                            <Table
                              columns={columns}
                              dataSource={getDocDetail?.specialist_plans}
                              rowKey="id"
                              pagination={false} // Disable pagination
                              scroll={{ x: "max-content" }}
                            />
                          </div>

                          <div
                            style={{ marginLeft: "1rem" }}
                            className="col-md-12 col-12 mb-2 p-2"
                          >
                            <div className="doctor-table-blk">
                              <h3>Revenue</h3>
                              <div className="doctor-search-blk"></div>
                            </div>
                          </div>
                          <div
                            style={{ marginLeft: "1rem" }}
                            className="table-responsive doctor-list"
                          >
                            <Table
                              columns={columnsRevenue}
                              dataSource={getDocDetail?.revenue_shares}
                              rowKey="id"
                              pagination={false} // Disable pagination
                              scroll={{ x: "max-content" }}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </>
  );
};

export default ViewDoctor;

/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from "react";
import { Link, Navigate, useSearchParams } from "react-router-dom";
import {
  blog,
  dashboard,
  doctor,
  doctorschedule,
  logout,
  menuicon04,
  menuicon06,
  menuicon08,
  menuicon09,
  menuicon10,
  menuicon11,
  menuicon12,
  menuicon14,
  menuicon15,
  menuicon16,
  patients,
  sidemenu,
} from "./imagepath";
import Scrollbars from "react-custom-scrollbars-2";
import { useDispatch, useSelector } from "react-redux";
import { setUserLogout } from "../redux/Slices/userSlice";
import { Tooltip } from "@mui/material";

const Sidebar = (props) => {
  const dispatch = useDispatch();
  const {
    user: { user },
  } = useSelector((store) => store);
  const [sidebar, setSidebar] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  // const [client, setClient] = useState(null);
  // const [showStatic] = useState(null);
  const [openMenu, setOpenMenu] = useState(() => {
    const savedMenu = sessionStorage.getItem("openSidebarMenu");
    return savedMenu || null;
  });
  const [scrollPosition, setScrollPosition] = useState(() => {
    return Number(sessionStorage.getItem("sidebarScrollPosition")) || 0;
  });

  const scrollbarsRef = useRef(null);
  // Update localStorage whenever openMenu changes
  useEffect(() => {
    if (openMenu) {
      sessionStorage.setItem("openSidebarMenu", openMenu);
    } else {
      sessionStorage.removeItem("openSidebarMenu");
    }
  }, [openMenu]);

  const handleClickDash = (e, menuName) => {
    e.preventDefault();
    // If clicking the same menu, close it. If clicking a different menu, open it
    const newMenuState = openMenu === menuName ? null : menuName;
    setOpenMenu(newMenuState);
  };

  const handleClick = (e, item, item1, item3) => {
    const div = document.querySelector(`#${item}`);
    const ulDiv = document.querySelector(`.${item1}`);
    e?.target?.className
      ? (ulDiv.style.display = "none")
      : (ulDiv.style.display = "block");
    e?.target?.className
      ? div.classList.remove("subdrop")
      : div.classList.add("subdrop");
  };
  const handleLogout = () => {
    localStorage.removeItem("user");
    // localStorage.removeItem("client");
    sessionStorage.removeItem("sidebarScrollPosition");
    localStorage.removeItem("show_static");

    dispatch(setUserLogout());
    return <Navigate to={"/"} />;
  };
  // useEffect(() => {
  //   // const clientParam = searchParams.get("client");
  //   // const lsValue = localStorage.getItem("client");
  //   const showStaticParam = searchParams.get("show_static");
  //   const lsValue = localStorage.getItem("show_static");

  //   if (showStaticParam === "false") {
  //     localStorage.setItem("show_static", "false");
  //     setSearchParams({ show_static: "false" });
  //   } else if (showStaticParam === "true") {
  //     localStorage.removeItem("show_static");
  //     setSearchParams({});
  //   } else if (!showStaticParam && lsValue === "false") {
  //     setSearchParams({ show_static: "false" });
  //   } else if (!showStaticParam && lsValue === "true") {
  //     localStorage.removeItem("show_static");
  //   }

  //   setShowStatic(showStaticParam || lsValue);
  // }, [searchParams]);
  // useEffect(() => {
  //   const showStaticParam = searchParams.get("show_static");
  //   setShowStatic(showStaticParam);
  // }, [searchParams, showStatic]);

  const expandMenu = () => {
    document.body.classList.remove("expand-menu");
  };
  const expandMenuOpen = () => {
    document.body.classList.add("expand-menu");
  };
  const handleRegularMenuClick = () => {
    if (openMenu) {
      setOpenMenu(null);
      sessionStorage.removeItem("openSidebarMenu");
    }
    if (window.innerWidth < 991.98) {
      const mobileBtn = document.getElementById("mobile_btn");

      // Ensure that #mobile_btn exists and is visible
      if (mobileBtn && mobileBtn.style.display !== "none") {
        // Toggle body and html classes to control slide and menu open states
        document.body.classList.toggle("slide-nav");
        document
          .getElementsByTagName("html")[0]
          .classList.toggle("menu-opened");

        // Toggle sidebar overlay state
        const sidebarOverlay =
          document.getElementsByClassName("sidebar-overlay")[0];
        if (sidebarOverlay) {
          sidebarOverlay.classList.toggle("opened");
        }
      }
    }
  };
  const handleRegularSubMenuClick = () => {
    if (window.innerWidth < 991.98) {
      const mobileBtn = document.getElementById("mobile_btn");

      // Ensure that #mobile_btn exists and is visible
      if (mobileBtn && mobileBtn.style.display !== "none") {
        // Toggle body and html classes to control slide and menu open states
        document.body.classList.toggle("slide-nav");
        document
          .getElementsByTagName("html")[0]
          .classList.toggle("menu-opened");

        // Toggle sidebar overlay state
        const sidebarOverlay =
          document.getElementsByClassName("sidebar-overlay")[0];
        if (sidebarOverlay) {
          sidebarOverlay.classList.toggle("opened");
        }
      }
    }
  };

  const renderUi = (path) => {
    switch (path) {
      case "admin":
        return (
          <ul>
            <li className="menu-title">Main</li>
            <li className="submenu">
              <Link
                to="#"
                id="dashboard-menu"
                onClick={(e) => handleClickDash(e, "Dashboard")}
                className={openMenu === "Dashboard" ? "subdrop" : ""}
              >
                <span className="menu-side">
                  <img src={dashboard} alt="" />
                </span>
                <span> Dashboard </span>
                <span className="menu-arrow" />
              </Link>
              <ul
                style={{
                  display: openMenu === "Dashboard" ? "block" : "none",
                }}
                className="menu-items"
              >
                <li onClick={handleRegularSubMenuClick}>
                  <Link
                    className={
                      props?.activeClassName === "admin-dashboard"
                        ? "active"
                        : ""
                    }
                    to="/admin/dashboard"
                  >
                    Analytics
                  </Link>
                </li>
                <li onClick={handleRegularSubMenuClick}>
                  <Link
                    className={
                      props?.activeClassName === "doctor-dashboard"
                        ? "active"
                        : ""
                    }
                    to="/admin/doctor-dashboard"
                  >
                    Doctor Dashboard
                  </Link>
                </li>
              </ul>
            </li>
            {/* <li>
              <Link
                onClick={handleRegularMenuClick}
                className={
                  props?.activeClassName === "account-list" ? "active" : ""
                }
                to="/admin/account"
              >
                <span className="menu-side">
                  <i className="fa-solid fa-users"></i>
                </span>{" "}
                <span>Account</span>
              </Link>
            </li> */}
            <li>
              <Link
                onClick={handleRegularMenuClick}
                className={
                  props?.activeClassName === "activity-list" ? "active" : ""
                }
                to="/admin/user-activity"
              >
                <span className="menu-side">
                  <i className="fa-solid fa-chart-line"></i>
                </span>{" "}
                <span>Activity</span>
              </Link>
            </li>
            <li>
              <Link
                onClick={handleRegularMenuClick}
                className={
                  props?.activeClassName === "admission-list" ? "active" : ""
                }
                to="/admin/admission"
              >
                <span className="menu-side">
                  <i className="fa-solid fa-bed-pulse"></i>
                </span>{" "}
                <span>Admissions</span>
              </Link>
            </li>
            <li>
              <Link
                onClick={handleRegularMenuClick}
                className={
                  props?.activeClassName === "appointment-slot-list"
                    ? "active"
                    : ""
                }
                to="/admin/appointment_slot"
              >
                <span className="menu-side">
                  <img src={menuicon04} alt="" />
                </span>{" "}
                <span>Appointment Slot</span>
              </Link>
            </li>
            <li>
              <Link
                onClick={handleRegularMenuClick}
                className={
                  props?.activeClassName === "appoinment-list" ? "active" : ""
                }
                to="/appoinmentlist"
              >
                <span className="menu-side">
                  <img src={menuicon04} alt="" />
                </span>{" "}
                <span>Appointments</span>
              </Link>
            </li>
            <li>
              <Link
                onClick={handleRegularMenuClick}
                className={
                  props?.activeClassName === "admin-user-list" ? "active" : ""
                }
                to="/admin/admin-user"
              >
                <span className="menu-side">
                  <i className="fa-solid fa-user-tie"></i>
                </span>{" "}
                <span>Admin User</span>
              </Link>
            </li>
            <li>
              <Link
                onClick={handleRegularMenuClick}
                className={
                  props?.activeClassName === "consumable-list" ? "active" : ""
                }
                to="/admin/consumable"
              >
                <span className="menu-side">
                  <i className="fa-solid fa-capsules"></i>{" "}
                </span>{" "}
                <span> Consumable </span>
              </Link>
            </li>
            <li style={{ marginLeft: 2 }}>
              <Tooltip placement="right" title="Consumable Category">
                <Link
                  onClick={handleRegularMenuClick}
                  className={
                    props?.activeClassName === "consumable-category-list"
                      ? "active"
                      : ""
                  }
                  to="/admin/consumable_categories"
                >
                  <span className="menu-side">
                    <i className="fa-solid fa-pills"></i>
                  </span>{" "}
                  <span className="truncate-v-1"> Consumable Category</span>
                </Link>
              </Tooltip>
            </li>
            <li>
              <Link
                onClick={handleRegularMenuClick}
                className={
                  props?.activeClassName === "collections-list" ? "active" : ""
                }
                to="/admin/collections"
              >
                <span className="menu-side">
                  <i className="fa-regular fa-money-bill-1"></i>
                </span>{" "}
                <span>Collections</span>
              </Link>
            </li>

            <li>
              <Link
                onClick={handleRegularMenuClick}
                className={
                  props?.activeClassName === "department-list" ? "active" : ""
                }
                to="/departmentlist"
              >
                <span className="menu-side">
                  <img src={menuicon06} alt="" />
                </span>{" "}
                <span>Department</span>
              </Link>
            </li>
            <li>
              <Link
                onClick={handleRegularMenuClick}
                className={
                  props?.activeClassName === "education-list" ? "active" : ""
                }
                to="/admin/education"
              >
                <span className="menu-side">
                  <i className="fa-solid fa-graduation-cap"></i>
                </span>{" "}
                <span>Education</span>
              </Link>
            </li>
            <li>
              <Link
                onClick={handleRegularMenuClick}
                className={
                  props?.activeClassName === "holidays-list" ? "active" : ""
                }
                to="/admin/holidays"
              >
                <span className="menu-side">
                  <i className="fa-regular fa-calendar-days"></i>
                </span>{" "}
                <span>Holidays</span>
              </Link>
            </li>
            <li>
              <Link
                onClick={handleRegularMenuClick}
                className={
                  props?.activeClassName === "invoice-list" ? "active" : ""
                }
                to="/admin/invoice-list"
              >
                <span className="menu-side">
                  <img src={menuicon15} alt="" />
                </span>{" "}
                <span>Invoices</span>
              </Link>
            </li>
            <li>
              <Link
                onClick={handleRegularMenuClick}
                className={
                  props?.activeClassName === "labs-list" ? "active" : ""
                }
                to="/admin/labs"
              >
                <span className="menu-side">
                  <i className="fa-solid fa-microscope"></i>{" "}
                </span>{" "}
                <span>Labs</span>
              </Link>
            </li>
            <li>
              <Link
                onClick={handleRegularMenuClick}
                className={
                  props?.activeClassName === "lab-inventory-list"
                    ? "active"
                    : ""
                }
                to="/admin/lab_inventory"
              >
                <span className="menu-side">
                  <i className="fa-solid fa-flask"></i>
                </span>{" "}
                <span>Lab Inventory</span>
              </Link>
            </li>
            <li>
              <Link
                onClick={handleRegularMenuClick}
                className={
                  props?.activeClassName === "medical-test-list" ? "active" : ""
                }
                to="/admin/medical_tests"
              >
                <span className="menu-side">
                  <i className="fa-solid fa-vial-virus"></i>
                </span>{" "}
                <span>Medical Test</span>
              </Link>
            </li>
            <li>
              <Tooltip placement="right" title="Medical Test Category">
                <Link
                  onClick={handleRegularMenuClick}
                  className={
                    props?.activeClassName === "medical-test-cat-list"
                      ? "active"
                      : ""
                  }
                  to="/admin/medical_tests_category"
                >
                  <span className="menu-side">
                    <i className="fa-solid fa-flask-vial"></i>
                  </span>{" "}
                  <span className="truncate-v-1">Medical Test Category</span>
                </Link>
              </Tooltip>
            </li>
            <li>
              <Link
                onClick={handleRegularMenuClick}
                className={
                  props?.activeClassName === "medicine-list" ? "active" : ""
                }
                to="/admin/medicines"
              >
                <span className="menu-side">
                  <i className="fa-solid fa-pills"></i>
                </span>{" "}
                <span>Medicine</span>
              </Link>
            </li>
            <li>
              <Link
                onClick={handleRegularMenuClick}
                className={
                  props?.activeClassName === "medicine-salt-list"
                    ? "active"
                    : ""
                }
                to="/admin/medicine-salts"
              >
                <span className="menu-side">
                  <i className="fas fa-vial"></i>{" "}
                </span>{" "}
                <span>Medicine Salts</span>
              </Link>
            </li>
            <li>
              <Link
                onClick={handleRegularMenuClick}
                className={
                  props?.activeClassName === "origination--list" ? "active" : ""
                }
                to="/admin/organization"
              >
                <span className="menu-side">
                  <i className="fa-solid fa-user-doctor"></i>{" "}
                </span>{" "}
                <span>Organization</span>
              </Link>
            </li>
            {/* <li>
              <Tooltip placement="right" title="Organization Settings">
                <Link
                  onClick={handleRegularMenuClick}
                  className={
                    props?.activeClassName === "origination-settings-list"
                      ? "active"
                      : ""
                  }
                  to="/admin/organization_settings"
                >
                  <span className="menu-side">
                    <i className="fa-solid fa-user-doctor"></i>{" "}
                  </span>{" "}
                  <span className="truncate-v-1">Organization Settings</span>
                </Link>
              </Tooltip>
            </li> */}
            <li>
              <Link
                onClick={handleRegularMenuClick}
                className={
                  props?.activeClassName === "occupation-list" ? "active" : ""
                }
                to="/admin/occupation"
              >
                <span className="menu-side">
                  <i className="fa-solid fa-user-doctor"></i>{" "}
                </span>{" "}
                <span>Occupation</span>
              </Link>
            </li>
            <li>
              <Link
                onClick={handleRegularMenuClick}
                className={
                  props?.activeClassName === "patient-list" ? "active" : ""
                }
                to="/admin/patients"
              >
                <span className="menu-side">
                  <i className="fa-solid fa-bed-pulse"></i>{" "}
                </span>{" "}
                <span>Patients</span>
              </Link>
            </li>
            {/* <li>
              <Link
                onClick={handleRegularMenuClick}
                className={
                  props?.activeClassName === "permission-list" ? "active" : ""
                }
                to="/admin/permission"
              >
                <span className="menu-side">
                  <i className="fa-solid fa-hospital-user"></i>
                </span>{" "}
                <span>Permission</span>
              </Link>
            </li> */}
            <li>
              <Link
                onClick={handleRegularMenuClick}
                className={
                  props?.activeClassName === "purchase-order-list"
                    ? "active"
                    : ""
                }
                to="/admin/purchase_orders"
              >
                <span className="menu-side">
                  <i className="fa-regular fa-money-bill-1"></i>
                </span>{" "}
                <span> Purchase Orders</span>
              </Link>
            </li>
            <li>
              <Link
                onClick={handleRegularMenuClick}
                className={
                  props?.activeClassName === "pharmacy-list" ? "active" : ""
                }
                to="/admin/pharmacy"
              >
                <span className="menu-side">
                  <i className="fas fa-clinic-medical"></i>{" "}
                </span>{" "}
                <span>Pharmacy</span>
              </Link>
            </li>

            <li className="submenu">
              <Link
                to="#"
                id="dashboard-menu"
                onClick={(e) => handleClickDash(e, "Prescription")}
                className={openMenu === "Prescription" ? "subdrop" : ""}
              >
                <span className="menu-side">
                  <img src={dashboard} alt="" />
                </span>
                <span> Prescription </span>
                <span className="menu-arrow" />
              </Link>
              <ul
                style={{
                  display: openMenu === "Prescription" ? "block" : "none",
                }}
                className="menu-items"
              >
                <li onClick={handleRegularSubMenuClick}>
                  <Link
                    className={
                      props?.activeClassName ===
                      "medicine_descriptions-dashboard"
                        ? "active"
                        : ""
                    }
                    to="/admin/prescription/medicine_descriptions"
                  >
                    Medicine Descriptions
                  </Link>
                </li>
                <li onClick={handleRegularSubMenuClick}>
                  <Link
                    className={
                      props?.activeClassName ===
                      "medical_test_shortcodes-dashboard"
                        ? "active"
                        : ""
                    }
                    to="/admin/prescription/medical_test_shortcodes"
                  >
                    Medical Test Shortcodes
                  </Link>
                </li>
                <li onClick={handleRegularSubMenuClick}>
                  <Link
                    className={
                      props?.activeClassName === "treatments-dashboard"
                        ? "active"
                        : ""
                    }
                    to="/admin/prescription/treatments"
                  >
                    Treatments
                  </Link>
                </li>
                <li onClick={handleRegularSubMenuClick}>
                  <Link
                    className={
                      props?.activeClassName === "diagnosis-dashboard"
                        ? "active"
                        : ""
                    }
                    to="/admin/prescription/diagnosis"
                  >
                    Diagnosis
                  </Link>
                </li>
                <li onClick={handleRegularSubMenuClick}>
                  <Link
                    className={
                      props?.activeClassName ===
                      "special_instructions-dashboard"
                        ? "active"
                        : ""
                    }
                    to="/admin/prescription/special_instructions"
                  >
                    Special Instructions
                  </Link>
                </li>
                <li onClick={handleRegularSubMenuClick}>
                  <Link
                    className={
                      props?.activeClassName === "medicine_remarks-dashboard"
                        ? "active"
                        : ""
                    }
                    to="/admin/prescription/medicine_remarks"
                  >
                    Medicine Remarks
                  </Link>
                </li>
                <li onClick={handleRegularSubMenuClick}>
                  <Link
                    className={
                      props?.activeClassName === "followup_lists-dashboard"
                        ? "active"
                        : ""
                    }
                    to="/admin/prescription/followup_lists"
                  >
                    Followup Lists
                  </Link>
                </li>
                <li onClick={handleRegularSubMenuClick}>
                  <Link
                    className={
                      props?.activeClassName === "videos-dashboard"
                        ? "active"
                        : ""
                    }
                    to="/admin/prescription/videos"
                  >
                    Videos
                  </Link>
                </li>
                <li onClick={handleRegularSubMenuClick}>
                  <Link
                    className={
                      props?.activeClassName === "video_categories-dashboard"
                        ? "active"
                        : ""
                    }
                    to="/admin/prescription/video_categories"
                  >
                    Video Categories
                  </Link>
                </li>
              </ul>
            </li>
            <li>
              <Link
                onClick={handleRegularMenuClick}
                className={
                  props?.activeClassName === "revenue-shares-list"
                    ? "active"
                    : ""
                }
                to="/admin/revenue_shares"
              >
                <span className="menu-side">
                  <i className="fa-solid fa-street-view"></i>{" "}
                </span>{" "}
                <span>Revenue Share</span>
              </Link>
            </li>
            <li>
              <Link
                onClick={handleRegularMenuClick}
                className={
                  props?.activeClassName === "referrers-list" ? "active" : ""
                }
                to="/admin/referrers"
              >
                <span className="menu-side">
                  <i className="fa-solid fa-street-view"></i>{" "}
                </span>{" "}
                <span>Referrers</span>
              </Link>
            </li>
            <li>
              <Link
                onClick={handleRegularMenuClick}
                className={
                  props?.activeClassName === "roles-list" ? "active" : ""
                }
                to="/admin/roles"
              >
                <span className="menu-side">
                  <i className="fa-solid fa-street-view"></i>{" "}
                </span>{" "}
                <span>Roles</span>
              </Link>
            </li>
            <li>
              <Link
                onClick={handleRegularMenuClick}
                className={
                  props?.activeClassName === "staff-list" ? "active" : ""
                }
                to="/admin/staff_list"
              >
                <span className="menu-side">
                  <i className="fa-solid fa-stethoscope"></i>
                </span>{" "}
                <span>Staff</span>
              </Link>
            </li>
            <li>
              <Link
                onClick={handleRegularMenuClick}
                className={
                  props?.activeClassName === "doctor-list" ? "active" : ""
                }
                to="/admin/doctor"
              >
                <span className="menu-side">
                  <i className="fa-solid fa-stethoscope"></i>
                </span>{" "}
                <span>Specialists</span>
              </Link>
            </li>
            <li>
              <Link
                onClick={handleRegularMenuClick}
                className={
                  props?.activeClassName === "suppliers-list" ? "active" : ""
                }
                to="/admin/suppliers"
              >
                <span className="menu-side">
                  <i className="fa-solid fa-house-medical-circle-check"></i>
                </span>{" "}
                <span>Suppliers</span>
              </Link>
            </li>
            <li>
              <Link
                onClick={handleRegularMenuClick}
                className={
                  props?.activeClassName === "pharmacies-list" ? "active" : ""
                }
                to="/admin/pharmacies"
              >
                <span className="menu-side">
                  <i className="fas fa-clinic-medical"></i>
                </span>{" "}
                <span>Pharmacies</span>
              </Link>
            </li>
          </ul>
        );
      case "pharmacist":
        return (
          <ul>
            <li className="menu-title">Main</li>
            <li className="submenu">
              <Link
                to="#"
                id="dashboard-menu"
                onClick={(e) => handleClickDash(e, "Dashboard")}
                className={openMenu === "Dashboard" ? "subdrop" : ""}
              >
                <span className="menu-side">
                  <img src={dashboard} alt="" />
                </span>
                <span> Dashboard </span>
                <span className="menu-arrow" />
              </Link>
              <ul
                style={{
                  display: openMenu === "Dashboard" ? "block" : "none",
                }}
                className="menu-items"
              >
                <li>
                  <Link
                    className={
                      props?.activeClassName === "admin-dashboard"
                        ? "active"
                        : ""
                    }
                    to="/admin/dashboard"
                  >
                    Analytics
                  </Link>
                </li>
                <li>
                  <Link
                    className={
                      props?.activeClassName === "doctor-dashboard"
                        ? "active"
                        : ""
                    }
                    to="/doctor-dashboard"
                  >
                    Doctor Dashboard
                  </Link>
                </li>
              </ul>
            </li>
            <li>
              <Link
                onClick={handleRegularMenuClick}
                className={
                  props?.activeClassName === "invoice-list" ? "active" : ""
                }
                to="/admin/invoice-list"
              >
                <span className="menu-side">
                  <img src={menuicon15} alt="" />
                </span>{" "}
                <span>Invoices</span>
              </Link>
            </li>
            <li>
              <Link
                onClick={handleRegularMenuClick}
                className={
                  props?.activeClassName === "medicine-list" ? "active" : ""
                }
                to="/admin/medicines"
              >
                <span className="menu-side">
                  <i className="fa-solid fa-pills"></i>
                </span>{" "}
                <span>Medicine</span>
              </Link>
            </li>
            <li>
              <Link
                onClick={handleRegularMenuClick}
                className={
                  props?.activeClassName === "medicine-salt-list"
                    ? "active"
                    : ""
                }
                to="/admin/medicine-salts"
              >
                <span className="menu-side">
                  <i className="fa-solid fa-pills"></i>
                </span>{" "}
                <span>Medicine Salts</span>
              </Link>
            </li>
            <li>
              <Link
                onClick={handleRegularMenuClick}
                className={
                  props?.activeClassName === "purchase-order-list"
                    ? "active"
                    : ""
                }
                to="/admin/purchase_orders"
              >
                <span className="menu-side">
                  <i className="fa-regular fa-money-bill-1"></i>
                </span>{" "}
                <span> Purchase Orders</span>
              </Link>
            </li>
            <li>
              <Link
                onClick={handleRegularMenuClick}
                className={
                  props?.activeClassName === "pharmacy-list" ? "active" : ""
                }
                to="/admin/pharmacy"
              >
                <span className="menu-side">
                  <i className="fas fa-clinic-medical"></i>{" "}
                </span>{" "}
                <span>Pharmacy</span>
              </Link>
            </li>
            <li>
              <Link
                onClick={handleRegularMenuClick}
                className={
                  props?.activeClassName === "suppliers-list" ? "active" : ""
                }
                to="/admin/suppliers"
              >
                <span className="menu-side">
                  <i className="fa-solid fa-house-medical-circle-check"></i>
                </span>{" "}
                <span>Suppliers</span>
              </Link>
            </li>

            <li>
              <Link
                onClick={handleRegularMenuClick}
                className={
                  props?.activeClassName === "department-list" ? "active" : ""
                }
                to="/departmentlist"
              >
                <span className="menu-side">
                  <img src={menuicon10} alt="" />
                </span>{" "}
                <span>Department</span>
              </Link>
            </li>
          </ul>
        );
      default:
        return null;
    }
  };

  const handleScroll = () => {
    if (scrollbarsRef?.current) {
      const scrollTop = scrollbarsRef?.current?.getScrollTop();
      sessionStorage.setItem("sidebarScrollPosition", scrollTop);
    }
  };

  useEffect(() => {
    // Restore scroll position after mounting
    if (scrollbarsRef?.current) {
      scrollbarsRef?.current?.scrollTop(scrollPosition);
    }
  }, [scrollPosition]);

  return (
    <>
      <div className="sidebar" id="sidebar">
        <Scrollbars
          onScrollFrame={handleScroll}
          className="sidebar-scroll"
          classID="sidebar-scroll"
          autoHide
          autoHideTimeout={1000}
          autoHideDuration={200}
          autoHeight
          autoHeightMin={0}
          autoHeightMax="95vh"
          thumbMinSize={30}
          universal={false}
          hideTracksWhenNotNeeded={true}
          ref={scrollbarsRef} //
        >
          <div className="sidebar-inner slimscroll">
            <div
              id="sidebar-menu"
              style={{
                minHeight: "95vh",
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "column",
                // overflowY:"scroll"
              }}
              className="sidebar-menu"
              onMouseLeave={expandMenu}
              onMouseOver={expandMenuOpen}
            >
              {/* {showStatic == "false" ? (
                <ul>
                  <li className="menu-title">Main</li>
                  <li className="submenu">
                    <Link
                      to="#"
                      id="dashboard-menu"
                      onClick={(e) => handleClickDash(e, "Dashboard")}
                      className={openMenu === "Dashboard" ? "subdrop" : ""}
                    >
                      <span className="menu-side">
                        <img src={dashboard} alt="" />
                      </span>
                      <span> Dashboard </span>
                      <span className="menu-arrow" />
                    </Link>
                    <ul
                      style={{
                        display: openMenu === "Dashboard" ? "block" : "none",
                      }}
                      className="menu-items"
                    >
                      <li>
                        <Link
                          className={
                            props?.activeClassName === "admin-dashboard"
                              ? "active"
                              : ""
                          }
                          to="/admin/dashboard"
                        >
                          Analytics
                        </Link>
                      </li>
                      <li>
                        <Link
                          className={
                            props?.activeClassName === "doctor-dashboard"
                              ? "active"
                              : ""
                          }
                          to="/doctor-dashboard"
                        >
                          Doctor Dashboard
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li className="submenu">
                    <Link
                      to="#"
                      id="menu-item1"
                      onClick={(e) => {
                        // setSidebar('Doctors')
                        handleClick(e, "menu-item1", "menu-items1");
                      }}
                    >
                      <span className="menu-side">
                        <img src={doctor} alt="" />
                      </span>{" "}
                      <span> Doctors </span> <span className="menu-arrow" />
                    </Link>
                    <ul
                      style={{
                        display: sidebar === "Doctors" ? "block" : "none",
                      }}
                      className="menu-items1"
                    >
                      <li>
                        <Link
                          className={
                            props?.activeClassName === "doctor-list"
                              ? "active"
                              : ""
                          }
                          to="/doctorlist"
                        >
                          Doctor List
                        </Link>
                      </li>
                      <li>
                        <Link
                          className={
                            props?.activeClassName === "add-doctor"
                              ? "active"
                              : ""
                          }
                          to="/add-doctor"
                        >
                          Add Doctor
                        </Link>
                      </li>
                      <li>
                        <Link
                          className={
                            props?.activeClassName === "edit-doctor"
                              ? "active"
                              : ""
                          }
                          to="/editdoctor"
                        >
                          Edit Doctor
                        </Link>
                      </li>
                      <li>
                        <Link
                          className={
                            props?.activeClassName === "doctor-profile"
                              ? "active"
                              : ""
                          }
                          to="/doctorprofile"
                        >
                          Doctor Profile
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li className="submenu">
                    <Link
                      to="#"
                      id="menu-item2"
                      onClick={(e) =>
                        handleClick(e, "menu-item2", "menu-items2")
                      }
                    >
                      <span className="menu-side">
                        <img src={patients} alt="" />
                      </span>{" "}
                      <span>Patients </span> <span className="menu-arrow" />
                    </Link>
                    <ul style={{ display: "none" }} className="menu-items2">
                      <li>
                        <Link
                          className={
                            props?.activeClassName === "patient-list"
                              ? "active"
                              : ""
                          }
                          to="/patientslist"
                        >
                          Patients List
                        </Link>
                      </li>
                      <li>
                        <Link
                          className={
                            props?.activeClassName === "add-patient"
                              ? "active"
                              : ""
                          }
                          to="/addpatients"
                        >
                          Add Patients
                        </Link>
                      </li>
                      <li>
                        <Link
                          className={
                            props?.activeClassName === "edit-patient"
                              ? "active"
                              : ""
                          }
                          to="/editpatients"
                        >
                          Edit Patients
                        </Link>
                      </li>
                      <li>
                        <Link
                          className={
                            props?.activeClassName === "patient" ? "active" : ""
                          }
                          to="/patientsprofile"
                        >
                          Patients Profile
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li className="submenu">
                    <Link
                      to="#"
                      id="menu-item3"
                      onClick={(e) =>
                        handleClick(e, "menu-item3", "menu-items3")
                      }
                    >
                      <span className="menu-side">
                        <img src={menuicon08} alt="" />
                      </span>{" "}
                      <span> Staff </span> <span className="menu-arrow" />
                    </Link>
                    <ul style={{ display: "none" }} className="menu-items3">
                      <li>
                        <Link
                          className={
                            props?.activeClassName === "staff-list"
                              ? "active"
                              : ""
                          }
                          to="/stafflist"
                        >
                          Staff List
                        </Link>
                      </li>
                      <li>
                        <Link
                          className={
                            props?.activeClassName === "add-staff"
                              ? "active"
                              : ""
                          }
                          to="/addstaff"
                        >
                          Add Staff
                        </Link>
                      </li>
                      <li>
                        <Link
                          className={
                            props?.activeClassName === "staff-profile"
                              ? "active"
                              : ""
                          }
                          to="/staffprofile"
                        >
                          Staff Profile
                        </Link>
                      </li>
                      <li>
                        <Link
                          className={
                            props?.activeClassName === "leaves" ? "active" : ""
                          }
                          to="/leave"
                        >
                          Leaves
                        </Link>
                      </li>
                      <li>
                        <Link
                          className={
                            props?.activeClassName === "holidays"
                              ? "active"
                              : ""
                          }
                          to="/holiday"
                        >
                          Holidays
                        </Link>
                      </li>
                      <li>
                        <Link
                          className={
                            props?.activeClassName === "attendance"
                              ? "active"
                              : ""
                          }
                          to="/attendence"
                        >
                          Attendance
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li className="submenu">
                    <Link
                      to="#"
                      id="menu-item4"
                      onClick={(e) =>
                        handleClick(e, "menu-item4", "menu-items4")
                      }
                    >
                      <span className="menu-side">
                        <img src={menuicon04} alt="" />
                      </span>{" "}
                      <span> Appointments </span>{" "}
                      <span className="menu-arrow" />
                    </Link>
                    <ul style={{ display: "none" }} className="menu-items4">
                      <li>
                        <Link
                          className={
                            props?.activeClassName === "appoinment-list"
                              ? "active"
                              : ""
                          }
                          to="/appoinmentlist"
                        >
                          Appointment List
                        </Link>
                      </li>
                      <li>
                        <Link
                          className={
                            props?.activeClassName === "add-appoinment"
                              ? "active"
                              : ""
                          }
                          to="/addappoinments"
                        >
                          Add Appointment
                        </Link>
                      </li>
                      <li>
                        <Link
                          className={
                            props?.activeClassName === "edit-appoinment"
                              ? "active"
                              : ""
                          }
                          to="/editappoinments"
                        >
                          Edit Appointment
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li className="submenu">
                    <Link
                      to="#"
                      id="menu-item5"
                      onClick={(e) =>
                        handleClick(e, "menu-item5", "menu-items5")
                      }
                    >
                      <span className="menu-side">
                        <img src={doctorschedule} alt="" />
                      </span>{" "}
                      <span> Doctor Schedule </span>{" "}
                      <span className="menu-arrow" />
                    </Link>
                    <ul style={{ display: "none" }} className="menu-items5">
                      <li>
                        <Link
                          className={
                            props?.activeClassName === "shedule-list"
                              ? "active"
                              : ""
                          }
                          to="/schedulelist"
                        >
                          Schedule List
                        </Link>
                      </li>
                      <li>
                        <Link
                          className={
                            props?.activeClassName === "add-shedule"
                              ? "active"
                              : ""
                          }
                          to="/addschedule"
                        >
                          Add Schedule
                        </Link>
                      </li>
                      <li>
                        <Link
                          className={
                            props?.activeClassName === "edit-shedule"
                              ? "active"
                              : ""
                          }
                          to="/editschedule"
                        >
                          Edit Schedule
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li className="submenu">
                    <Link
                      to="#"
                      id="menu-item6"
                      onClick={(e) =>
                        handleClick(e, "menu-item6", "menu-items6")
                      }
                    >
                      <span className="menu-side">
                        <img src={menuicon06} alt="" />
                      </span>{" "}
                      <span> Departments </span> <span className="menu-arrow" />
                    </Link>
                    <ul style={{ display: "none" }} className="menu-items6">
                      <li>
                        <Link
                          className={
                            props?.activeClassName === "department-list"
                              ? "active"
                              : ""
                          }
                          to="/departmentlist"
                        >
                          Department List
                        </Link>
                      </li>
                      <li>
                        <Link
                          className={
                            props?.activeClassName === "add-department"
                              ? "active"
                              : ""
                          }
                          to="/add-department"
                        >
                          Add Department
                        </Link>
                      </li>
                      <li>
                        <Link
                          className={
                            props?.activeClassName === "edit-department"
                              ? "active"
                              : ""
                          }
                          to="/editdepartment"
                        >
                          Edit Department
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li className="submenu">
                    <Link
                      to="#"
                      id="menu-item7"
                      onClick={(e) =>
                        handleClick(e, "menu-item7", "menu-items7")
                      }
                    >
                      <span className="menu-side">
                        <img src={sidemenu} alt="" />
                      </span>{" "}
                      <span> Accounts </span> <span className="menu-arrow" />
                    </Link>
                    <ul style={{ display: "none" }} className="menu-items7">
                      <li>
                        <Link
                          className={
                            props?.activeClassName === "invoice-list"
                              ? "active"
                              : ""
                          }
                          to="/invoicelist"
                        >
                          Invoices
                        </Link>
                      </li>
                      <li>
                        <Link
                          className={
                            props?.activeClassName === "payments"
                              ? "active"
                              : ""
                          }
                          to="/payments"
                        >
                          Payments
                        </Link>
                      </li>
                      <li>
                        <Link
                          className={
                            props?.activeClassName === "expenses"
                              ? "active"
                              : ""
                          }
                          to="/expenses"
                        >
                          Expenses
                        </Link>
                      </li>
                      <li>
                        <Link
                          className={
                            props?.activeClassName === "taxes" ? "active" : ""
                          }
                          to="/taxes"
                        >
                          Taxes
                        </Link>
                      </li>
                      <li>
                        <Link
                          className={
                            props?.activeClassName === "provident-fund"
                              ? "active"
                              : ""
                          }
                          to="/providentfund"
                        >
                          Provident Fund
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li className="submenu">
                    <Link
                      to="#"
                      id="menu-item8"
                      onClick={(e) =>
                        handleClick(e, "menu-item8", "menu-items8")
                      }
                    >
                      <span className="menu-side">
                        <img src={menuicon09} alt="" />
                      </span>{" "}
                      <span> Payroll </span> <span className="menu-arrow" />
                    </Link>
                    <ul style={{ display: "none" }} className="menu-items8">
                      <li>
                        <Link
                          className={
                            props?.activeClassName === "employee-salary"
                              ? "active"
                              : ""
                          }
                          to="/employeesalary"
                        >
                          {" "}
                          Employee Salary{" "}
                        </Link>
                      </li>
                      <li>
                        <Link
                          className={
                            props?.activeClassName === "employee-payslip"
                              ? "active"
                              : ""
                          }
                          to="/payslip"
                        >
                          {" "}
                          Payslip{" "}
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Link
                      className={
                        props?.activeClassName === "chat" ? "active" : ""
                      }
                      to="/chat"
                    >
                      <span className="menu-side">
                        <img src={menuicon10} alt="" />
                      </span>{" "}
                      <span>Chat</span>
                    </Link>
                  </li>
                  <li className="submenu">
                    <Link
                      to="#"
                      id="menu-item9"
                      onClick={(e) =>
                        handleClick(e, "menu-item9", "menu-items9")
                      }
                    >
                      <span className="menu-side">
                        <img src={menuicon11} alt="" />
                      </span>{" "}
                      <span> Calls</span> <span className="menu-arrow" />
                    </Link>
                    <ul style={{ display: "none" }} className="menu-items9">
                      <li>
                        <Link
                          className={
                            props?.activeClassName === "voice-call"
                              ? "active"
                              : ""
                          }
                          to="/voice-call"
                        >
                          Voice Call
                        </Link>
                      </li>
                      <li>
                        <Link
                          className={
                            props?.activeClassName === "video-call"
                              ? "active"
                              : ""
                          }
                          to="/video-call"
                        >
                          Video Call
                        </Link>
                      </li>
                      <li>
                        <Link
                          className={
                            props?.activeClassName === "incoming-call"
                              ? "active"
                              : ""
                          }
                          to="/incoming-call"
                        >
                          Incoming Call
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li className="submenu">
                    <Link
                      to="#"
                      id="menu-item10"
                      onClick={(e) =>
                        handleClick(e, "menu-item10", "menu-items10")
                      }
                    >
                      <span className="menu-side">
                        <img src={menuicon12} alt="" />
                      </span>{" "}
                      <span> Email</span> <span className="menu-arrow" />
                    </Link>
                    <ul style={{ display: "none" }} className="menu-items10">
                      <li>
                        <Link
                          className={
                            props?.activeClassName === "compose-mail"
                              ? "active"
                              : ""
                          }
                          to="/compose-mail"
                        >
                          Compose Mail
                        </Link>
                      </li>
                      <li>
                        <Link
                          className={
                            props?.activeClassName === "inbox" ? "active" : ""
                          }
                          to="/inbox"
                        >
                          Inbox
                        </Link>
                      </li>
                      <li>
                        <Link
                          className={
                            props?.activeClassName === "mail-view"
                              ? "active"
                              : ""
                          }
                          to="/mail-view"
                        >
                          Mail View
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li className="submenu">
                    <Link
                      to="#"
                      id="menu-item11"
                      onClick={(e) =>
                        handleClick(e, "menu-item11", "menu-items11")
                      }
                    >
                      <span className="menu-side">
                        <img src={blog} alt="" />
                      </span>{" "}
                      <span> Blog</span> <span className="menu-arrow" />
                    </Link>
                    <ul style={{ display: "none" }} className="menu-items11">
                      <li>
                        <Link
                          className={
                            props?.activeClassName === "blog-grid"
                              ? "active"
                              : ""
                          }
                          to="/blogview"
                        >
                          Blog
                        </Link>
                      </li>
                      <li>
                        <Link
                          className={
                            props?.activeClassName === "blog-details"
                              ? "active"
                              : ""
                          }
                          to="/blog"
                        >
                          Blog View
                        </Link>
                      </li>
                      <li>
                        <Link
                          className={
                            props?.activeClassName === "add-blog"
                              ? "active"
                              : ""
                          }
                          to="/addblog"
                        >
                          Add Blog
                        </Link>
                      </li>
                      <li>
                        <Link
                          className={
                            props?.activeClassName === "edit-blog"
                              ? "active"
                              : ""
                          }
                          to="/editblog"
                        >
                          Edit Blog
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Link
                      className={
                        props?.activeClassName === "assests" ? "active" : ""
                      }
                      to="/assests"
                    >
                      <i className="fa fa-cube" /> <span>Assets</span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      className={
                        props?.activeClassName === "activity" ? "active" : ""
                      }
                      to="/user-activity"
                    >
                      <span className="menu-side">
                        <img src={menuicon14} alt="" />
                      </span>{" "}
                      <span>Activities</span>
                    </Link>
                  </li>
                  <li className="submenu">
                    <Link
                      to="#"
                      id="menu-item12"
                      onClick={(e) =>
                        handleClick(e, "menu-item12", "menu-items12")
                      }
                    >
                      <i className="fa fa-flag" /> <span> Reports </span>{" "}
                      <span className="menu-arrow" />
                    </Link>
                    <ul style={{ display: "none" }} className="menu-items12">
                      <li>
                        <Link
                          className={
                            props?.activeClassName === "expenses-report"
                              ? "active"
                              : ""
                          }
                          to="/expense-Report"
                        >
                          {" "}
                          Expense Report{" "}
                        </Link>
                      </li>
                      <li>
                        <Link
                          className={
                            props?.activeClassName === "invoice-report"
                              ? "active"
                              : ""
                          }
                          to="/invoice-report"
                        >
                          {" "}
                          Invoice Report{" "}
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li className="submenu">
                    <Link
                      to="#"
                      id="menu-item13"
                      onClick={(e) =>
                        handleClick(e, "menu-item13", "menu-items13")
                      }
                    >
                      <span className="menu-side">
                        <img src={menuicon15} alt="" />
                      </span>{" "}
                      <span> Invoice </span> <span className="menu-arrow" />
                    </Link>
                    <ul style={{ display: "none" }} className="menu-items13">
                      <li>
                        <Link
                          className={
                            props?.activeClassName === "invoice-list"
                              ? "active"
                              : ""
                          }
                          to="/invoice-list"
                        >
                          {" "}
                          Invoices List{" "}
                        </Link>
                      </li>
                      <li>
                        <Link
                          className={
                            props?.activeClassName === "invoice-grid"
                              ? "active"
                              : ""
                          }
                          to="/invoice-grid"
                        >
                          {" "}
                          Invoices Grid
                        </Link>
                      </li>
                      <li>
                        <Link
                          className={
                            props?.activeClassName === "add-invoice"
                              ? "active"
                              : ""
                          }
                          to="/add-invoice"
                        >
                          {" "}
                          Add Invoices
                        </Link>
                      </li>
                      <li>
                        <Link
                          className={
                            props?.activeClassName === "edit-invoice"
                              ? "active"
                              : ""
                          }
                          to="/edit-invoice"
                        >
                          {" "}
                          Edit Invoices
                        </Link>
                      </li>
                      <li>
                        <Link
                          className={
                            props?.activeClassName === "invoice-details"
                              ? "active"
                              : ""
                          }
                          to="/invoice-details"
                        >
                          {" "}
                          Invoices Details
                        </Link>
                      </li>
                      <li>
                        <Link
                          className={
                            props?.activeClassName === "invoice-settings"
                              ? "active"
                              : ""
                          }
                          to="/invoice-settings"
                        >
                          {" "}
                          Invoices Settings
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Link to="/settings">
                      <span className="menu-side">
                        <img src={menuicon16} alt="" />
                      </span>{" "}
                      <span>Settings</span>
                    </Link>
                  </li>
                  <li className="menu-title">UI Elements</li>
                  <li className="submenu">
                    <Link
                      to="#"
                      id="menu-item14"
                      onClick={(e) =>
                        handleClick(e, "menu-item14", "menu-items14")
                      }
                    >
                      <i className="fa fa-laptop" /> <span> Components</span>{" "}
                      <span className="menu-arrow" />
                    </Link>
                    <ul style={{ display: "none" }} className="menu-items14">
                      <li>
                        <Link
                          className={
                            props?.activeClassName === "uikit" ? "active" : ""
                          }
                          to="/ui-kit"
                        >
                          UI Kit
                        </Link>
                      </li>
                      <li>
                        <Link
                          className={
                            props?.activeClassName === "typography"
                              ? "active"
                              : ""
                          }
                          to="/typography"
                        >
                          Typography
                        </Link>
                      </li>
                      <li>
                        <Link
                          className={
                            props?.activeClassName === "tabs" ? "active" : ""
                          }
                          to="/tab"
                        >
                          Tabs
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li className="submenu">
                    <Link
                      to="#"
                      id="menu-item15"
                      onClick={(e) =>
                        handleClick(e, "menu-item15", "menu-items15")
                      }
                    >
                      <i className="fa fa-edit" /> <span> Forms</span>{" "}
                      <span className="menu-arrow" />
                    </Link>
                    <ul style={{ display: "none" }} className="menu-items15">
                      <li>
                        <Link
                          className={
                            props?.activeClassName === "basic-input"
                              ? "active"
                              : ""
                          }
                          to="/basic-input"
                        >
                          Basic Inputs
                        </Link>
                      </li>
                      <li>
                        <Link
                          className={
                            props?.activeClassName === "input-groups"
                              ? "active"
                              : ""
                          }
                          to="/inputgroup"
                        >
                          Input Groups
                        </Link>
                      </li>
                      <li>
                        <Link
                          className={
                            props?.activeClassName === "horizontal-form"
                              ? "active"
                              : ""
                          }
                          to="/horizontal-form"
                        >
                          Horizontal Form
                        </Link>
                      </li>
                      <li>
                        <Link
                          className={
                            props?.activeClassName === "vertical-form"
                              ? "active"
                              : ""
                          }
                          to="/vertical-form"
                        >
                          Vertical Form
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li className="submenu">
                    <Link
                      to="#"
                      id="menu-item16"
                      onClick={(e) =>
                        handleClick(e, "menu-item16", "menu-items16")
                      }
                    >
                      <i className="fa fa-table" /> <span> Tables</span>{" "}
                      <span className="menu-arrow" />
                    </Link>
                    <ul style={{ display: "none" }} className="menu-items16">
                      <li>
                        <Link
                          className={
                            props?.activeClassName === "basic-table"
                              ? "active"
                              : ""
                          }
                          to="/basic-table"
                        >
                          Basic Tables
                        </Link>
                      </li>
                      <li>
                        <Link
                          className={
                            props?.activeClassName === "data-table"
                              ? "active"
                              : ""
                          }
                          to="/data-table"
                        >
                          Data Table
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Link
                      className={
                        props?.activeClassName === "calendar" ? "active" : ""
                      }
                      to="/calender"
                    >
                      <i className="fa fa-calendar" /> <span>Calendar</span>
                    </Link>
                  </li>
                  <li className="menu-title">Extras</li>
                  <li className="submenu">
                    <Link
                      to="#"
                      id="menu-item17"
                      onClick={(e) =>
                        handleClick(e, "menu-item17", "menu-items17")
                      }
                    >
                      <i className="fa fa-columns" /> <span>Pages</span>{" "}
                      <span className="menu-arrow" />
                    </Link>
                    <ul style={{ display: "none" }} className="menu-items17">
                      <li>
                        <Link to="/login"> Login </Link>
                      </li>
                      <li>
                        <Link
                          className={
                            props?.activeClassName === "register"
                              ? "active"
                              : ""
                          }
                          to="/register"
                        >
                          {" "}
                          Register{" "}
                        </Link>
                      </li>
                      <li>
                        <Link
                          className={
                            props?.activeClassName === "forgotpassword"
                              ? "active"
                              : ""
                          }
                          to="/forgotpassword"
                        >
                          {" "}
                          Forgot Password{" "}
                        </Link>
                      </li>
                      <li>
                        <Link
                          className={
                            props?.activeClassName === "changepassword"
                              ? "active"
                              : ""
                          }
                          to="/changepassword"
                        >
                          {" "}
                          Change Password{" "}
                        </Link>
                      </li>
                      <li>
                        <Link
                          className={
                            props?.activeClassName === "lock-screen"
                              ? "active"
                              : ""
                          }
                          to="/lockscreen"
                        >
                          {" "}
                          Lock Screen{" "}
                        </Link>
                      </li>
                      <li>
                        <Link
                          className={
                            props?.activeClassName === "profile" ? "active" : ""
                          }
                          to="/profile"
                        >
                          {" "}
                          Profile{" "}
                        </Link>
                      </li>
                      <li>
                        <Link
                          className={
                            props?.activeClassName === "gallery" ? "active" : ""
                          }
                          to="/gallery"
                        >
                          {" "}
                          Gallery{" "}
                        </Link>
                      </li>
                      <li>
                        <Link
                          className={
                            props?.activeClassName === "404-error"
                              ? "active"
                              : ""
                          }
                          to="/error"
                        >
                          404 Error{" "}
                        </Link>
                      </li>
                      <li>
                        <Link
                          className={
                            props?.activeClassName === "500-error"
                              ? "active"
                              : ""
                          }
                          to="/server-error"
                        >
                          500 Error{" "}
                        </Link>
                      </li>
                      <li>
                        <Link
                          className={
                            props?.activeClassName === "blank-page"
                              ? "active"
                              : ""
                          }
                          to="/blankpage"
                        >
                          {" "}
                          Blank Page{" "}
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li className="submenu">
                    <Link
                      to="#"
                      id="menu-item18"
                      onClick={(e) =>
                        handleClick(e, "menu-item18", "menu-items18")
                      }
                    >
                      <i className="fa fa-share-alt" /> <span>Multi Level</span>{" "}
                      <span className="menu-arrow" />
                    </Link>
                    <ul style={{ display: "none" }} className="menu-items18">
                      <li className="submenu">
                        <Link
                          to="#"
                          id="menu-item19"
                          onClick={(e) =>
                            handleClick(e, "menu-item19", "menu-items19")
                          }
                        >
                          <span>Level 1</span> <span className="menu-arrow" />
                        </Link>
                        <ul
                          style={{ display: "none" }}
                          className="menu-items19"
                        >
                          <li>
                            <Link to="#">
                              <span>Level 2</span>
                            </Link>
                          </li>
                          <li className="submenu">
                            <Link
                              to="#"
                              id="menu-item20"
                              onClick={(e) =>
                                handleClick(e, "menu-item20", "menu-items20")
                              }
                            >
                              {" "}
                              <span> Level 2</span>{" "}
                              <span className="menu-arrow" />
                            </Link>
                            <ul
                              style={{ display: "none" }}
                              className="menu-items20"
                            >
                              <li>
                                <Link to="#">Level 3</Link>
                              </li>
                              <li>
                                <Link to="#">Level 3</Link>
                              </li>
                            </ul>
                          </li>
                          <li>
                            <Link to="#">
                              <span>Level 2</span>
                            </Link>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <Link to="#">
                          <span>Level 1</span>
                        </Link>
                      </li>
                    </ul>
                  </li>
                </ul>
              ) : ( */}
              {renderUi(user?.role)}
              {/* )} */}
              <div
                // style={{ marginBottom: "auto", border: "1px solid red" }}
                className="logout-btn"
              >
                <Link onClick={handleLogout}>
                  <span className="menu-side">
                    <img src={logout} alt="" />
                  </span>{" "}
                  <span>Logout</span>
                </Link>
              </div>
            </div>
          </div>
        </Scrollbars>
      </div>
    </>
  );
};
export default Sidebar;

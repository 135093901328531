/* eslint-disable no-undef */
import React, { useEffect, useState } from "react";
import Header from "../Header";
import Sidebar from "../Sidebar";
import { Link, useLocation, useNavigate } from "react-router-dom";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import Select from "react-select";
import { putOrg } from "../../connections/putOrg";
import { BASE_URL } from "../../connections/connectionUrl";
import { useQuery } from "@tanstack/react-query";
import { getAllState } from "../../connections/getAllState";

const UpdateOrganization = () => {
  const {
    user: { user },
  } = useSelector((store) => store);
  const navigate = useNavigate();
  const [formState, setFormState] = useState({});

  const location = useLocation();
  const orgData = location.state || null;
  const handleChangeOrg = (field, val) => {
    if (field === "logo" || field === "accreditation") {
      // Create a Blob URL from the file
      const BlobUrl = URL.createObjectURL(val);

      setFormState((prev) => ({
        ...prev,
        [field]: {
          file: val, // Store the original File object
          url: BlobUrl, // Store the Blob URL
        },
      }));
    } else {
      setFormState((prev) => ({
        ...prev,
        [field]: val,
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!formState?.name) {
      toast.error("Name is required");
      return;
    }
    if (!formState?.code) {
      toast.error("Code is required");
      return;
    }
    const formData = new FormData();

    // Append data from formState
    formData.append("organization[name]", formState.name);
    formData.append(
      "organization[accreditation]",
      formState.accreditation?.file
    );
    formData.append("organization[logo]", formState.logo?.file);
    formData.append("organization[address]", formState.address);
    formData.append("organization[city]", formState.city);
    formData.append("organization[code]", formState.code);
    formData.append("organization[contact_number]", formState.contact_number);
    formData.append("organization[email]", formState.email);
    formData.append("organization[footer]", formState.footer);

    // Assuming logo is a file and needs to be appended as a file
    // if (formState.logo instanceof File) {
    //   formData.append("organization[logo]", formState.logo);
    // } else {
    //   formData.append("organization[logo]", formState.logo);
    // }

    formData.append("organization[pincode]", formState.pincode);
    formData.append("organization[state]", formState.state);
    formData.append("organization[website]", formState.website);
    try {
      const result = await putOrg(user?.token, formData);
      if (result?.status?.status === "SUCCESS") {
        toast.success("Organization Updated Successfully");
        navigate("/admin/organization");
        setFormState({});
      }
    } catch (error) {
      toast.error(error.message);
    }
  };
  useEffect(() => {
    if (orgData) {
      setFormState({
        name: orgData?.name || "",
        code: orgData?.code || "",
        address: orgData?.address || "",
        pincode: orgData?.pincode || "",
        city: orgData?.city || "",
        state: orgData?.state || "",
        // footer: orgData?.footer || "",
        website: orgData?.website || "",
        contact_number: orgData?.contact_number || "",
        email: orgData?.email || "",
        logo: { url: `${BASE_URL}${orgData?.logo}` || "" },
        accreditation: {
          url: `${BASE_URL}${orgData?.accreditation}` || "",
        },
      });
    }
  }, [orgData]);
  const { data: getAllStateData, isLoading } = useQuery({
    queryKey: ["getAllState"],
    queryFn: () => getAllState(user?.token),
    enabled: !!user?.token,

    refetchOnWindowFocus: false, // Don't refetch when window regains focus
    refetchOnReconnect: false, // Don't refetch when reconnecting
  });
  let allState = [];
  try {
    allState = getAllStateData?.states || [];
  } catch (error) {
    console.log(error);
  }

  // Mapping the object into an array
  const formattedState = Object.entries(allState).map(([key, value]) => ({
    label: value,
    value: key,
  }));
  return (
    <div>
      <Header />
      <Sidebar activeClassName="origination--list" />
      <>
        <div className="page-wrapper">
          <div className="content">
            {/* Page Header */}
            <div className="page-header">
              <div className="row">
                <div className="col-sm-12">
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to={-1}> Organization Details</Link>
                    </li>
                    <li className="breadcrumb-item">
                      <i className="feather-chevron-right">
                        <FeatherIcon icon="chevron-right" />
                      </i>
                    </li>
                    <li className="breadcrumb-item active">
                      Update Organization Details
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            {/* /Page Header */}
            <div className="row">
              <div className="col-sm-12">
                <div className="card">
                  <div className="card-body">
                    <form>
                      <div className="row">
                        <div className="col-12">
                          <div className="form-heading">
                            <h4>Organization Details</h4>
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-4">
                          <div className="form-group local-forms">
                            <label>
                              Name <span className="login-danger">*</span>
                            </label>
                            <input
                              value={formState?.name}
                              onChange={(e) =>
                                handleChangeOrg("name", e?.target?.value)
                              }
                              className="form-control"
                              type="text"
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-4">
                          <div className="form-group local-forms">
                            <label>
                              Code <span className="login-danger">*</span>
                            </label>
                            <input
                              value={formState?.code}
                              onChange={(e) =>
                                handleChangeOrg("code", e?.target?.value)
                              }
                              className="form-control"
                              type="text"
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-4">
                          <div className="form-group local-forms">
                            <label>Address</label>
                            <input
                              value={formState?.address}
                              onChange={(e) =>
                                handleChangeOrg("address", e?.target?.value)
                              }
                              className="form-control"
                              type="text"
                            />
                          </div>
                        </div>
                        {/* <div className="col-12 col-md-6 col-xl-4">
                          <div className="form-group local-forms">
                            <label>Footer</label>
                            <input
                              value={formState?.footer}
                              onChange={(e) =>
                                handleChangeOrg("footer", e?.target?.value)
                              }
                              className="form-control"
                              type="text"
                            />
                          </div>
                        </div> */}
                        <div className="col-12 col-md-6 col-xl-4">
                          <div className="form-group local-forms">
                            <label>Pincode</label>
                            <input
                              value={formState?.pincode}
                              onChange={(e) =>
                                handleChangeOrg("pincode", e?.target?.value)
                              }
                              className="form-control"
                              type="text"
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-4">
                          <div className="form-group local-forms">
                            <label>Website</label>
                            <input
                              value={formState?.website}
                              onChange={(e) =>
                                handleChangeOrg("website", e?.target?.value)
                              }
                              className="form-control"
                              type="text"
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-4">
                          <div className="form-group local-forms">
                            <label>City</label>
                            <input
                              value={formState?.city}
                              onChange={(e) =>
                                handleChangeOrg("city", e?.target?.value)
                              }
                              className="form-control"
                              type="text"
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-4">
                          <div className="form-group local-forms">
                            <label>State</label>
                            <Select
                              isLoading={isLoading}
                              value={
                                formattedState.find(
                                  (option) => option.value === formState?.state
                                ) || null
                              }
                              onChange={(e) => {
                                handleChangeOrg(
                                  "state",
                                  e?.value // Access the value directly from the selected option
                                );
                              }}
                              //   onInputChange={handleInputChange}
                              options={formattedState}
                              placeholder="select State"
                              menuPortalTarget={document.body}
                              styles={{
                                menuPortal: (base) => ({
                                  ...base,
                                  zIndex: 9999,
                                }),
                                control: (baseStyles, state) => ({
                                  ...baseStyles,
                                  borderColor: state.isFocused
                                    ? "#2e37a4"
                                    : "rgba(46, 55, 164, 0.1)",
                                  boxShadow: state.isFocused
                                    ? "0 0 0 1px #2e37a4"
                                    : "none",
                                  "&:hover": {
                                    borderColor: "rgba(46, 55, 164, 0.1)",
                                  },
                                  borderRadius: "10px",
                                  fontSize: "14px",
                                  minHeight: "45px",
                                }),
                                dropdownIndicator: (base, state) => ({
                                  ...base,
                                  transform: state.selectProps.menuIsOpen
                                    ? "rotate(-180deg)"
                                    : "rotate(0)",
                                  transition: "250ms",
                                  width: "35px",
                                  height: "35px",
                                }),
                              }}
                              isSearchable
                              isClearable
                              components={{
                                IndicatorSeparator: () => null,
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-4">
                          <div className="form-group local-forms">
                            <label>Email</label>
                            <input
                              value={formState?.email}
                              onChange={(e) =>
                                handleChangeOrg("email", e?.target?.value)
                              }
                              className="form-control"
                              type="text"
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-4">
                          <div className="form-group local-forms">
                            <label>Contact Number</label>
                            <input
                              value={formState?.contact_number}
                              onChange={(e) =>
                                handleChangeOrg(
                                  "contact_number",
                                  e?.target?.value
                                )
                              }
                              className="form-control"
                              type="text"
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-4">
                          <div className="form-group local-forms">
                            <label>Logo</label>
                            {formState?.logo?.url ? (
                              <div>
                                <img
                                  src={formState.logo.url} // Use the Blob URL for preview
                                  alt="Logo Preview"
                                  style={{
                                    maxWidth: "100%",
                                    height: "auto",
                                    marginBottom: "10px",
                                  }}
                                  onLoad={(e) =>
                                    URL.revokeObjectURL(e.target.src)
                                  } // Revoke URL after loading
                                />

                                <button
                                  type="button"
                                  onClick={() =>
                                    setFormState((prev) => ({
                                      ...prev,
                                      logo: null, // Remove the file
                                    }))
                                  }
                                  className="btn btn-danger btn-sm"
                                >
                                  Remove
                                </button>
                              </div>
                            ) : (
                              <input
                                style={{ paddingTop: 10 }}
                                onChange={
                                  (e) =>
                                    handleChangeOrg("logo", e.target.files[0]) // Handle file upload
                                }
                                className="form-control"
                                type="file"
                                accept="image/*" // Accept only image files
                              />
                            )}
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-4">
                          <div className="form-group local-forms">
                            <label>Accreditation</label>
                            {formState?.accreditation?.url ? (
                              <div>
                                <img
                                  src={formState.accreditation.url}
                                  alt="Accreditation Preview"
                                  style={{
                                    maxWidth: "100%",
                                    height: "auto",
                                    marginBottom: "10px",
                                  }}
                                  onLoad={(e) =>
                                    URL.revokeObjectURL(e.target.src)
                                  } // Revoke URL to free memory
                                />

                                <button
                                  type="button"
                                  onClick={() =>
                                    setFormState((prev) => ({
                                      ...prev,
                                      accreditation: null, // Remove the file
                                    }))
                                  }
                                  className="btn btn-danger btn-sm"
                                >
                                  Remove
                                </button>
                              </div>
                            ) : (
                              <input
                                style={{ paddingTop: 10 }}
                                onChange={
                                  (e) =>
                                    handleChangeOrg(
                                      "accreditation",
                                      e.target.files[0]
                                    ) // Handle file upload
                                }
                                className="form-control"
                                type="file"
                                accept="application/pdf, image/*"
                              />
                            )}
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="doctor-submit text-end">
                            <button
                              onClick={handleSubmit}
                              type="submit"
                              className="btn btn-primary submit-form me-2"
                            >
                              Submit
                            </button>
                            <button
                              onClick={(e) => {
                                e.preventDefault();
                                navigate("/admin/organization");
                              }}
                              type="submit"
                              className="btn btn-primary cancel-form"
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </div>
  );
};
export default UpdateOrganization;

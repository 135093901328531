import React, { useEffect, useState } from "react";
import Header from "../../Header";
import Sidebar from "../../Sidebar";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import Select from "react-select";

import { putMedicineDescription } from "../../../connections/putMedicineDescription";
import { getDoctor } from "../../../connections/getDoctor";
import { useQuery } from "@tanstack/react-query";

const UpdateMedicineDescription = () => {
  const {
    user: { user },
  } = useSelector((store) => store);
  const [formState, setFormState] = useState({});

  const navigate = useNavigate();
  const location = useLocation();
  const medicineDescriptionData = location.state || null;
  const handleChange = (name, value) => {
    setFormState((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const { data: getDoctorData, isLoading } = useQuery({
    queryKey: ["getDoctor", "all"],
    queryFn: () => getDoctor(user?.token, "all", null, null),
    refetchOnWindowFocus: false, // Don't refetch when window regains focus
    refetchOnReconnect: false, // Don't refetch when reconnecting
  });
  const allDoctorOptions = getDoctorData?.payload?.specialists || [];
  // Format options for react-select
  const formattedOptions = allDoctorOptions
    ?.filter((el) => el?.sp_type === "doctor")
    ?.map((test) => ({
      value: test.id,
      label: test.first_name + " " + test.last_name,
    }));
  const handleAddMedicineDescription = async (e) => {
    if (!formState.value) {
      toast.error("Value is required");
      return;
    }
    e.preventDefault();
    const payload = {
      medicine_description: formState,
    };

    try {
      const data = await putMedicineDescription(
        user?.token,
        payload,
        medicineDescriptionData?.id
      );
      if (data.status.status === "SUCCESS") {
        toast.success("Medicine Description Updated Successfully");
        setFormState({});
        navigate("/admin/prescription/medicine_descriptions");
      }
    } catch (error) {
      // toast.error(error.message);
      console.log(error);
    }
  };
  useEffect(() => {
    if (medicineDescriptionData) {
      setFormState((prev) => ({
        ...prev,
        doctor_id: medicineDescriptionData?.doctor_id,
        value: medicineDescriptionData?.value,
        search_code: medicineDescriptionData?.search_code,
      }));
    }
  }, [medicineDescriptionData]);
  return (
    <>
      <Header />
      <Sidebar activeClassName="medicine_descriptions-dashboard" />
      <>
        <div className="page-wrapper">
          <div className="content">
            {/* Page Header */}
            <div className="page-header">
              <div className="row">
                <div className="col-sm-12">
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to={-1}>Medicine Description</Link>
                    </li>
                    <li className="breadcrumb-item">
                      <i className="feather-chevron-right">
                        <FeatherIcon icon="chevron-right" />
                      </i>
                    </li>
                    <li className="breadcrumb-item active">
                      Update Medicine Description
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            {/* /Page Header */}
            <div className="row">
              <div className="col-sm-12">
                <div className="card">
                  <div className="card-body">
                    <form>
                      <div className="row">
                        <div className="col-12">
                          <div className="form-heading">
                            <h4>Update Medicine Description</h4>
                          </div>
                        </div>

                        <div className="col-12 col-md-6 col-xl-4">
                          <div className="form-group local-forms">
                            <label>
                              Doctor
                              {/* <span className="login-danger">*</span> */}
                            </label>
                            <Select
                              isLoading={isLoading}
                              options={formattedOptions}
                              value={
                                formattedOptions.find(
                                  (option) =>
                                    option.value === formState?.doctor_id
                                ) || null
                              }
                              onChange={(e) =>
                                handleChange("doctor_id", e?.value)
                              }
                              styles={{
                                menu: (provided) => ({
                                  ...provided,
                                  zIndex: 9999, // Set your desired z-index here
                                }),
                                menuPortal: (base) => ({
                                  ...base,
                                  zIndex: 999999,
                                }),
                                control: (baseStyles, state) => ({
                                  ...baseStyles,
                                  borderColor: state.isFocused
                                    ? "#2e37a4"
                                    : "rgba(46, 55, 164, 0.1)",
                                  boxShadow: state.isFocused
                                    ? "0 0 0 1px #2e37a4"
                                    : "none",
                                  "&:hover": {
                                    borderColor: "rgba(46, 55, 164, 0.1)",
                                  },
                                  borderRadius: "10px",
                                  fontSize: "14px",
                                  minHeight: "45px",
                                }),
                                dropdownIndicator: (base, state) => ({
                                  ...base,
                                  transform: state.selectProps.menuIsOpen
                                    ? "rotate(-180deg)"
                                    : "rotate(0)",
                                  transition: "250ms",
                                  width: "35px",
                                  height: "35px",
                                }),
                              }}
                              isSearchable
                              isClearable
                              placeholder="Choose Doctor"
                              className="react-select"
                              classNamePrefix="select"
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-4">
                          <div className="form-group local-forms">
                            <label>
                              Value <span className="login-danger">*</span>
                            </label>
                            <input
                              value={formState?.value}
                              name="value"
                              onChange={(e) =>
                                handleChange("value", e.target.value)
                              }
                              className="form-control"
                              type="text"
                              required={true}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-4">
                          <div className="form-group local-forms">
                            <label>
                              Search code{" "}
                              {/* <span className="login-danger">*</span> */}
                            </label>
                            <input
                              value={formState?.search_code}
                              name="search_code"
                              onChange={(e) =>
                                handleChange("search_code", e.target.value)
                              }
                              className="form-control"
                              type="text"
                              // required={true}
                            />
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="doctor-submit text-end">
                            <button
                              onClick={handleAddMedicineDescription}
                              type="submit"
                              className="btn btn-primary submit-form me-2"
                            >
                              Submit
                            </button>
                            <button
                              onClick={(e) => {
                                e.preventDefault();
                                navigate(
                                  "/admin/prescription/medicine_descriptions"
                                );
                              }}
                              type="submit"
                              className="btn btn-primary cancel-form"
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </>
  );
};

export default UpdateMedicineDescription;

import React, { useState } from "react";
import Header from "../Header";
import Sidebar from "../Sidebar";
import { Link, useNavigate } from "react-router-dom";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";

import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { postAdminUser } from "../../connections/postAdminUser";

const AddAdminUser = () => {
  const {
    user: { user },
  } = useSelector((store) => store);
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    email: "",
    password:"",
    password_confirmation:""
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    
    e.preventDefault();

    if (!formData.email) {
        toast.error("Email is required");
        return;
      }
      if (!formData.password) {
        toast.error("Password is required");
        return;
      }
      if (!formData.password_confirmation) {
        toast.error("Password Confirmation is required");
        return;
      }

    const payload = {
        admin_user: formData,
    };

    try {
      const result = await postAdminUser(user?.token, payload);
      if (result?.status?.status === "SUCCESS") {
        toast.success("Admin User Added Successfully");
        navigate("/admin/admin-user");
        setFormData({
          email: "",
          password:"",
    password_confirmation:""
        });
      }
    } catch (error) {
      // toast.error(error.message);
      console.log(error.message);
    }
  };
  return (
    <>
      <Header />
      <Sidebar activeClassName="admin-user-list" />
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="row">
              <div className="col-sm-12">
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to={-1}>Admin User</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <i className="feather-chevron-right">
                      <FeatherIcon icon="chevron-right" />
                    </i>
                  </li>
                  <li className="breadcrumb-item active">Add Admin User</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <div className="card">
                <div className="card-body">
                  <form onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="col-12">
                        <div className="form-heading">
                          <h4>Admin User</h4>
                        </div>
                      </div>
                      <div className="col-12 col-md-6 col-xl-6">
                        <div className="form-group local-forms">
                          <label>
                            Email <span className="login-danger">*</span>
                          </label>
                          <input
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            className="form-control"
                            type="text"
                            required={true}
                          />
                        </div>
                      </div>
                      <div className="col-12 col-md-6 col-xl-6">
                        <div className="form-group local-forms">
                          <label>
                            Password <span className="login-danger">*</span>
                          </label>
                          <input
                            name="password"
                            value={formData.password}
                            onChange={handleChange}
                            className="form-control"
                            type="password"
                            required={true}
                          />
                        </div>
                      </div>
                      <div className="col-12 col-md-6 col-xl-6">
                        <div className="form-group local-forms">
                          <label>
                          Password Confirmation <span className="login-danger">*</span>
                          </label>
                          <input
                            name="password_confirmation"
                            value={formData.password_confirmation}
                            onChange={handleChange}
                            className="form-control"
                            type="password"
                            required={true}
                          />
                        </div>
                      </div>

                      <div className="col-12">
                        <div className="doctor-submit text-end">
                          <button
                            type="submit"
                            className="btn btn-primary submit-form me-2"
                          >
                            Create Admin User
                          </button>
                          <button
                            type="button"
                            onClick={() => navigate("/admin/admin-user")}
                            className="btn btn-primary cancel-form"
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddAdminUser;

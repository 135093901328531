import { useEffect, useState } from "react";
import Header from "../Header";
import Sidebar from "../Sidebar";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { useSelector } from "react-redux";
import Select, { components } from "react-select";
import { DatePicker } from "antd";
import dayjs from "dayjs";

import { putSpecialist } from "../../connections/putSpecialist";
import { useQuery } from "@tanstack/react-query";
import { getAllSpTypes } from "../../connections/getAllSpTypes";
import { getAllDepartments } from "../../connections/getAllDepartments";
import { getMedicalTest } from "../../connections/getMedicalTest";
import useDebounce from "../../utils/hooks";
import { Search } from "react-feather";
const EditDoctor = () => {
  const {
    user: { user },
  } = useSelector((store) => store);
  const { data: getSpType } = useQuery({
    queryKey: ["getAllSpTypes"],
    queryFn: () => getAllSpTypes(user?.token),

    // staleTime: Infinity, // Keep the data fresh indefinitely

    refetchOnWindowFocus: false, // Don't refetch when window regains focus
    refetchOnReconnect: false, // Don't refetch when reconnecting
  });
  const [formState, setFormState] = useState({
    first_name: "",
    last_name: "",
    mobile: "",
    email: "",
    gender: "",
    dob: "",
    marital_status: "",
    active: "",
    details: "",
    sp_type: "",
  });
  const [removedPlan, setRemovedPlan] = useState(null);
  const [removedRevenue, setRemovedRevenue] = useState(null);
  const [removeInvestigation, setRemoveInvestigation] = useState(null);
  const [investigations, setInvestigations] = useState([]);
  const [medicalInput, setMedicalInput] = useState("");

  const [specialist_plans_attributes, setSpecialist_plans_attributes] =
    useState([]);
  const [revenue_shares_attributes, setRevenue_shares_attributes] = useState(
    []
  );
  const navigate = useNavigate();
  const location = useLocation();
  const specialistData = location.state || null;
  const handleChange = (name, value) => {
    setFormState((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const handleChangePlan = (index, field, value) => {
    const newPlan = [...specialist_plans_attributes];
    newPlan[index] = { ...newPlan[index], [field]: value };
    setSpecialist_plans_attributes(newPlan);
  };
  const handleChangeRevenue = (index, field, value) => {
    const newPlan = [...revenue_shares_attributes];
    newPlan[index] = { ...newPlan[index], [field]: value };
    setRevenue_shares_attributes(newPlan);
  };
  const handleChangeInvestigations = (index, field, value, label) => {
    const newInvestigations = [...investigations];
    newInvestigations[index] = {
      ...newInvestigations[index],
      [field]: value,
      test_name: label,
    };
    setInvestigations(newInvestigations);
  };
  const genderOptions = [
    {
      value: "male",
      label: "Male",
    },
    {
      value: "female",
      label: "Female",
    },
    {
      value: "other",
      label: "Other",
    },
  ];
  const [marital_status] = useState([
    { value: "unmarried", label: "Unmarried" },
    { value: "married", label: "Married" },
    { value: "divorced", label: "Divorced" },
  ]);
  const allSpType = getSpType?.sp_types?.map((el) => ({
    value: el,
    label: el,
  }));
  const spType = allSpType || [];
  const { data: getDepartmentData, isLoading: deptLoader } = useQuery({
    queryKey: ["getAllDepartments", "all"],
    queryFn: () => getAllDepartments(user?.token, "all", null, null),
    refetchOnWindowFocus: false, // Don't refetch when window regains focus
    refetchOnReconnect: false, // Don't refetch when reconnecting
  });
  const allDepartments = getDepartmentData?.payload?.departments || [];
  const departmentOption = allDepartments?.map((el) => ({
    value: el.id,
    label: el?.name,
  }));

  const debouncedInputMedicine = useDebounce(medicalInput, 1000);

  const { data: medicalTestData, isLoading } = useQuery({
    queryKey: ["getMedicalTest", debouncedInputMedicine],
    queryFn: () =>
      getMedicalTest(user?.token, "null", 1, 10, debouncedInputMedicine),
    enabled: !!debouncedInputMedicine,

    refetchOnWindowFocus: false, // Don't refetch when window regains focus
    refetchOnReconnect: false, // Don't refetch when reconnecting
  });
  const AllMedicalTestOptions = medicalTestData?.payload?.medical_tests || [];
  const formattedMedicalTestOptions = AllMedicalTestOptions?.map((el) => ({
    value: el?.id,
    label: el?.name,
  }));
  const handleMedicalChange = (inputValue) => {
    // e.preventDefault();
    setMedicalInput(inputValue);
  };
  const handleAddInvestigation = (e) => {
    e.preventDefault();
    setInvestigations((prev) => [
      ...prev,
      {
        medical_test_id: "",
        is_investigation: false,
      },
    ]);
  };
  const handleRemoveInvestigationRow = (e, idx, el) => {
    e.preventDefault();

    const newRow = [...investigations];
    setRemoveInvestigation({ ...el, _destroy: "1" });
    const updatedRow = newRow?.filter((_, index) => index !== idx);
    setInvestigations(updatedRow);
  };
  const handleAddSpecialistPlansRow = (e) => {
    e.preventDefault();

    setSpecialist_plans_attributes((prev) => [
      ...prev,
      {
        title: "",
        amount: "",
        discount: "",
      },
    ]);
  };
  const handleRemoveSpecialistPlansRow = (e, idx, el) => {
    e.preventDefault();

    const newRow = [...specialist_plans_attributes];
    setRemovedPlan({ ...el, _destroy: "1" });
    const updatedRow = newRow?.filter((_, index) => index !== idx);
    setSpecialist_plans_attributes(updatedRow);
  };
  const handleAddRevenueShareRow = (e) => {
    e.preventDefault();
    setRevenue_shares_attributes((prev) => [
      ...prev,
      {
        value: "",
        department_id: "",
      },
    ]);
  };
  const handleRemoveRevenue = (e, idx, el) => {
    e.preventDefault();
    const newRow = [...revenue_shares_attributes];
    setRemovedRevenue({ ...el, _destroy: "1" });

    const updatedRow = newRow?.filter((_, index) => index !== idx);
    setRevenue_shares_attributes(updatedRow);
  };
  const handleAddDoctor = async (e) => {
    // console.log(revenue_shares_attributes, "revenue_shares_attributes");
    e.preventDefault();
    const payload = {
      specialist: {
        ...formState,
        ...(revenue_shares_attributes.length > 0 && {
          revenue_shares_attributes: [
            ...revenue_shares_attributes,
            removedRevenue ? removedRevenue : false,
          ].filter(Boolean),
        }),
        ...(specialist_plans_attributes.length > 0 && {
          specialist_plans_attributes: [
            ...specialist_plans_attributes,
            removedPlan ? removedPlan : false,
          ].filter(Boolean),
        }),
        ...(investigations.length > 0 && {
          specialist_medical_tests_attributes: [
            ...investigations,
            removeInvestigation ? removeInvestigation : false,
          ].filter(Boolean),
        }),
      },
    };

    if (!formState?.sp_type) {
      toast.error("Please Select Sp Type");
      return;
    }
    if (!formState?.first_name) {
      toast.error(" First Name Required");
      return;
    }
    if (!formState?.last_name) {
      toast.error(" Last Name Required");
      return;
    }
    const hasInvalidAmount = specialist_plans_attributes.some(
      (item) => item.amount < 0 || item.amount === ""
    );
    // console.log(hasInvalidAmount, "First Name Required"),
    // console.log(specialist_plans_attributes);
    if (hasInvalidAmount) {
      toast.error("Each specialist plan must have a valid amount");
      return;
    }
    const hasInvalidInvestigation = investigations.some(
      (item) => item.medical_test_id === ""
    );
    if (hasInvalidInvestigation) {
      toast.error("Each Investigation  must have a valid investigation");
      return;
    }
    const hasInvalidRevenueShare = revenue_shares_attributes.some(
      (item) =>
        !item.value ||
        item.value === "" ||
        !item.department_id ||
        item.department_id === ""
    );

    if (hasInvalidRevenueShare) {
      toast.error(
        "Each revenue share must have a valid Value and Department ID Or remove row"
      );
      return;
    }
    try {
      const data = await putSpecialist(
        user?.token,
        payload,
        specialistData?.id
      );
      if (data.status.status === "SUCCESS") {
        toast.success("Specialist Updated Successfully");
        setFormState({});
        navigate("/admin/doctor");
      }
    } catch (error) {
      // toast.error(error.message);
      console.log(error);
    }
  };

  useEffect(() => {
    if (specialistData) {
      // Set formState with specialist's basic details
      setFormState((prev) => ({
        ...prev,
        first_name: specialistData.first_name || "",
        last_name: specialistData.last_name || "",
        mobile: specialistData.mobile || "",
        email: specialistData.email || "",
        gender: specialistData.gender || "",
        dob: specialistData.dob || "",
        marital_status: specialistData.marital_status || "",
        active: specialistData.active || false,
        details: specialistData.details || "",
        sp_type: specialistData.sp_type || "",
        investigations: specialistData.investigations || [],
      }));

      // Map revenue_shares data if available
      setRevenue_shares_attributes(
        specialistData.revenue_shares?.map((share) => ({
          id: share.id,
          value: share.value,
          department_id: share.department.id,
          department_name: share.department.name,
          department_code: share.department.code,
          _destroy: "0",
        })) || []
      );
      setInvestigations(
        specialistData?.specialist_medical_tests?.map((el) => ({
          ...el,
          _destroy: "0",
        }))
      );
      // Map specialist_plans data if available
      setSpecialist_plans_attributes(
        specialistData.specialist_plans?.map((plan) => ({
          id: plan.id,
          title: plan.title,
          amount: plan.amount,
          discount: plan.discount,
          specialist_id: plan.specialist_id,
          _destroy: "0",
        })) || []
      );
    }
  }, [specialistData]);
  console.log(specialistData, "specialist", investigations);
  return (
    <div>
      <Header />
      <Sidebar activeClassName="doctor-list" />
      <>
        <div className="page-wrapper">
          <div className="content">
            {/* Page Header */}
            <div className="page-header">
              <div className="row">
                <div className="col-sm-12">
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to={-1}>Specialist </Link>
                    </li>
                    <li className="breadcrumb-item">
                      <i className="feather-chevron-right">
                        <FeatherIcon icon="chevron-right" />
                      </i>
                    </li>
                    <li className="breadcrumb-item active">
                      Update Specialist
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            {/* /Page Header */}
            <div className="row">
              <div className="col-sm-12">
                <div className="card">
                  <div className="card-body">
                    <form>
                      <div className="row">
                        <div className="col-12">
                          <div className="form-heading">
                            <h4>Update Specialist</h4>
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-4">
                          <div className="form-group local-forms">
                            <label>
                              First name
                              <span className="login-danger">*</span>
                            </label>
                            <input
                              value={formState?.first_name}
                              name="first_name"
                              onChange={(e) =>
                                handleChange("first_name", e.target.value)
                              }
                              className="form-control"
                              type="text"
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-4">
                          <div className="form-group local-forms">
                            <label>
                              Last Name
                              <span className="login-danger">*</span>
                            </label>
                            <input
                              value={formState?.last_name}
                              name="last_name"
                              onChange={(e) =>
                                handleChange("last_name", e.target.value)
                              }
                              className="form-control"
                              type="text"
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-4">
                          <div className="form-group local-forms">
                            <label>
                              Mobile
                              {/* <span className="login-danger">*</span> */}
                            </label>
                            <input
                              value={formState?.mobile}
                              name="mobile"
                              onChange={(e) =>
                                handleChange("mobile", e.target.value)
                              }
                              className="form-control"
                              type="text"
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-4">
                          <div className="form-group local-forms">
                            <label>
                              Email
                              {/* <span className="login-danger">*</span> */}
                            </label>
                            <input
                              value={formState?.email}
                              name="email"
                              onChange={(e) =>
                                handleChange("email", e.target.value)
                              }
                              className="form-control"
                              type="email"
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-4">
                          <div className="form-group local-forms">
                            <label>
                              Gender
                              {/* <span className="login-danger">*</span> */}
                            </label>
                            <Select
                              value={
                                genderOptions.find(
                                  (option) => option.value === formState.gender
                                ) || null
                              }
                              onChange={(e) => handleChange("gender", e?.value)}
                              options={genderOptions}
                              components={{
                                IndicatorSeparator: () => null, // Hide separator line between the dropdown indicator and the control
                              }}
                              styles={{
                                control: (baseStyles, state) => ({
                                  ...baseStyles,
                                  borderColor: state.isFocused
                                    ? "none"
                                    : "2px solid rgba(46, 55, 164, 0.1);",
                                  boxShadow: state.isFocused
                                    ? "0 0 0 1px #2e37a4"
                                    : "none",
                                  "&:hover": {
                                    borderColor: state.isFocused
                                      ? "none"
                                      : "2px solid rgba(46, 55, 164, 0.1)",
                                  },
                                  borderRadius: "10px",
                                  fontSize: "14px",
                                  minHeight: "45px",
                                }),
                                dropdownIndicator: (base, state) => ({
                                  ...base,
                                  transform: state.selectProps.menuIsOpen
                                    ? "rotate(-180deg)"
                                    : "rotate(0)",
                                  transition: "250ms",
                                  width: "35px",
                                  height: "35px",
                                }),
                                menu: (base) => ({ ...base, zIndex: 9999 }),
                              }}
                              isClearable
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-4">
                          <div className="form-group local-forms cal-icon">
                            <label>
                              Date Of Birth
                              {/* <span className="login-danger">*</span> */}
                            </label>

                            <DatePicker
                              value={
                                formState.dob ? dayjs(formState.dob) : null
                              }
                              className="form-control datetimepicker"
                              onChange={(_, dateString) =>
                                handleChange("dob", dateString)
                              }
                              suffixIcon={null}
                              style={{
                                control: (baseStyles, state) => ({
                                  ...baseStyles,
                                  borderColor:
                                    "2px solid rgba(46, 55, 164, 0.1)",
                                  "&:hover": {
                                    borderColor: state.isFocused
                                      ? "none"
                                      : "none",
                                  },
                                }),
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-4">
                          <div className="form-group local-forms">
                            <label>
                              Marital Status{" "}
                              {/* <span className="login-danger">*</span> */}
                            </label>
                            <Select
                              value={marital_status.find(
                                (option) =>
                                  option.value === formState?.marital_status
                              )}
                              onChange={(e) =>
                                handleChange("marital_status", e.value)
                              }
                              options={marital_status}
                              id="search-commodity"
                              components={{
                                IndicatorSeparator: () => null,
                              }}
                              styles={{
                                menu: (base) => ({ ...base, zIndex: 9999 }),
                                control: (baseStyles, state) => ({
                                  ...baseStyles,
                                  borderColor: state.isFocused
                                    ? "none"
                                    : "2px solid rgba(46, 55, 164, 0.1);",
                                  boxShadow: state.isFocused
                                    ? "0 0 0 1px #2e37a4"
                                    : "none",
                                  "&:hover": {
                                    borderColor: state.isFocused
                                      ? "none"
                                      : "2px solid rgba(46, 55, 164, 0.1)",
                                  },
                                  borderRadius: "10px",
                                  fontSize: "14px",
                                  minHeight: "45px",
                                }),
                                dropdownIndicator: (base, state) => ({
                                  ...base,
                                  transform: state.selectProps.menuIsOpen
                                    ? "rotate(-180deg)"
                                    : "rotate(0)",
                                  transition: "250ms",
                                  width: "35px",
                                  height: "35px",
                                }),
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-4">
                          <div className="form-group local-forms">
                            <label>
                              SP Type <span className="login-danger">*</span>
                            </label>
                            <Select
                              value={
                                spType.find(
                                  (option) => option.value === formState.sp_type
                                ) || null
                              }
                              onChange={(e) =>
                                handleChange("sp_type", e?.value)
                              }
                              name="sp_type"
                              options={spType}
                              components={{
                                IndicatorSeparator: () => null, // Hide separator line between the dropdown indicator and the control
                              }}
                              styles={{
                                menu: (base) => ({ ...base, zIndex: 9999 }),
                                control: (baseStyles, state) => ({
                                  ...baseStyles,
                                  borderColor: state.isFocused
                                    ? "none"
                                    : "2px solid rgba(46, 55, 164, 0.1);",
                                  boxShadow: state.isFocused
                                    ? "0 0 0 1px #2e37a4"
                                    : "none",
                                  "&:hover": {
                                    borderColor: state.isFocused
                                      ? "none"
                                      : "2px solid rgba(46, 55, 164, 0.1)",
                                  },
                                  borderRadius: "10px",
                                  fontSize: "14px",
                                  minHeight: "45px",
                                }),
                                dropdownIndicator: (base, state) => ({
                                  ...base,
                                  transform: state.selectProps.menuIsOpen
                                    ? "rotate(-180deg)"
                                    : "rotate(0)",
                                  transition: "250ms",
                                  width: "35px",
                                  height: "35px",
                                }),
                              }}
                              isClearable
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-4">
                          <div className="form-group select-gender">
                            <label className="gen-label">
                              Status
                              {/* <span className="login-danger">*</span> */}
                            </label>
                            <div className="form-check-inline">
                              <label className="form-check-label">
                                <input
                                  type="radio"
                                  name="gender"
                                  value={true}
                                  checked={formState?.active === true}
                                  onChange={(e) =>
                                    handleChange(
                                      "active",
                                      Boolean(e.target.value)
                                    )
                                  }
                                  className="form-check-input"
                                />
                                Active
                              </label>
                            </div>
                            <div className="form-check-inline">
                              <label className="form-check-label">
                                <input
                                  type="radio"
                                  name="gender"
                                  checked={formState?.active === false}
                                  className="form-check-input"
                                  value={false}
                                  onChange={() => handleChange("active", false)}
                                />
                                In Active
                              </label>
                            </div>
                          </div>
                        </div>

                        <div className="col-12 col-md-6 col-xl-6">
                          <div className="form-group">
                            <label>
                              Details
                              {/* <span className="login-danger">*</span> */}
                            </label>
                            <textarea
                              rows={1}
                              value={formState?.details}
                              name="details"
                              onChange={(e) =>
                                handleChange("details", e.target.value)
                              }
                              className="form-control"
                              type="text"
                            />
                          </div>
                        </div>
                        {/* {investigations data} */}
                        <div className="col-12 mt-4">
                          <div className="form-heading">
                            <h4>Investigation</h4>
                          </div>
                        </div>
                        {investigations?.length > 0 &&
                          investigations?.map((el, index) => (
                            <div className="row" key={index}>
                              <div className="col-12 col-md-4">
                                <div className="form-group local-forms">
                                  <label>
                                    Investigations
                                    <span className="login-danger">*</span>
                                  </label>
                                  <Select
                                    isLoading={isLoading}
                                    onInputChange={handleMedicalChange}
                                    components={{
                                      DropdownIndicator: (props) => (
                                        <components.DropdownIndicator
                                          {...props}
                                        >
                                          <Search style={{ color: "#999" }} />
                                        </components.DropdownIndicator>
                                      ),
                                      IndicatorSeparator: () => null,
                                    }}
                                    options={formattedMedicalTestOptions}
                                    value={
                                      {
                                        value: el?.id || el?.medical_test_id,
                                        label: el?.test_name,
                                      } || null
                                    }
                                    onChange={(e) => {
                                      // console.log(selectedLabels); // Debugging the selected labels
                                      handleChangeInvestigations(
                                        index,
                                        "medical_test_id",
                                        e?.value,
                                        e?.label
                                      ); // Update formState with labels
                                    }}
                                    styles={{
                                      menu: (provided) => ({
                                        ...provided,
                                        zIndex: 9999,
                                      }),
                                      menuPortal: (base) => ({
                                        ...base,
                                        zIndex: 999999,
                                      }),
                                      control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        borderColor: state.isFocused
                                          ? "#2e37a4"
                                          : "rgba(46, 55, 164, 0.1)",
                                        boxShadow: state.isFocused
                                          ? "0 0 0 1px #2e37a4"
                                          : "none",
                                        "&:hover": {
                                          borderColor: "rgba(46, 55, 164, 0.1)",
                                        },
                                        borderRadius: "10px",
                                        fontSize: "14px",
                                        minHeight: "45px",
                                      }),
                                      dropdownIndicator: (base, state) => ({
                                        ...base,
                                        transform: state.selectProps.menuIsOpen
                                          ? "rotate(-180deg)"
                                          : "rotate(0)",
                                        transition: "250ms",
                                        width: "35px",
                                        height: "35px",
                                      }),
                                    }}
                                    isSearchable
                                    placeholder="Choose option"
                                    className="react-select"
                                    classNamePrefix="select"
                                  />
                                </div>
                              </div>
                              <div className="col-12 col-md-6 col-xl-4">
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "10px",
                                  }}
                                  className=""
                                >
                                  <label>IsInvestigations</label>
                                  <input
                                    style={{ height: "15px", width: "15px" }}
                                    checked={el.is_investigation}
                                    name="details"
                                    rows={1}
                                    onChange={(e) =>
                                      handleChangeInvestigations(
                                        index,
                                        "is_investigation",
                                        e.target.checked
                                      )
                                    }
                                    // className="form-control"
                                    type="checkbox"
                                  />
                                </div>
                              </div>
                              <div className="col-12 col-md-6 col-xl-4">
                                <button
                                  onClick={(e) =>
                                    handleRemoveInvestigationRow(e, index, el)
                                  }
                                  className="btn btn-primary submit-form me-2 mb-4"
                                >
                                  Remove
                                </button>
                              </div>
                            </div>
                          ))}
                        <div className="col-12 col-md-6 col-xl-6 mb-4">
                          <button
                            onClick={handleAddInvestigation}
                            type="button"
                            className="btn btn-primary submit-form me-2"
                          >
                            Add Investigation
                          </button>
                        </div>
                        {/* {specialist_attributes Plan } */}
                        <div className="col-12 mt-4">
                          <div className="form-heading">
                            <h4>Specialist Plans</h4>
                          </div>
                        </div>
                        {specialist_plans_attributes?.length > 0 &&
                          specialist_plans_attributes?.map((el, index) => (
                            <div className="row" key={index}>
                              <div className="col-12 col-md-6 col-xl-3">
                                <div className="form-group local-forms">
                                  <label>
                                    Title
                                    {/* <span className="login-danger">*</span> */}
                                  </label>
                                  <input
                                    value={el?.title}
                                    name="title"
                                    onChange={(e) =>
                                      handleChangePlan(
                                        index,
                                        "title",
                                        e.target.value
                                      )
                                    }
                                    className="form-control"
                                    type="text"
                                    required={true}
                                  />
                                </div>
                              </div>
                              <div className="col-12 col-md-6 col-xl-3">
                                <div className="form-group local-forms">
                                  <label>
                                    Amount
                                    <span className="login-danger">*</span>
                                  </label>
                                  <input
                                    value={el?.amount}
                                    name="amount"
                                    onChange={(e) =>
                                      handleChangePlan(
                                        index,
                                        "amount",
                                        e.target.value
                                      )
                                    }
                                    className="form-control"
                                    type="number"
                                    required={true}
                                  />
                                </div>
                              </div>
                              <div className="col-12 col-md-6 col-xl-3">
                                <div className="form-group local-forms">
                                  <label>
                                    Discount
                                    {/* <span className="login-danger">*</span> */}
                                  </label>
                                  <input
                                    value={el?.discount}
                                    name="discount"
                                    onChange={(e) =>
                                      handleChangePlan(
                                        index,
                                        "discount",
                                        e.target.value
                                      )
                                    }
                                    className="form-control"
                                    type="number"
                                    required={true}
                                  />
                                </div>
                              </div>
                              <div className="col-12 col-md-6 col-xl-3">
                                <button
                                  onClick={(e) =>
                                    handleRemoveSpecialistPlansRow(e, index, el)
                                  }
                                  className="btn btn-primary submit-form me-2 mb-4"
                                >
                                  Remove
                                </button>
                              </div>
                            </div>
                          ))}
                        <div className="col-12 col-md-6 col-xl-6 mb-4">
                          <button
                            onClick={handleAddSpecialistPlansRow}
                            type="button"
                            className="btn btn-primary submit-form me-2"
                          >
                            Create Plan
                          </button>
                        </div>
                        {/* {Revenue share Plan } */}
                        <div className="col-12 mt-4">
                          <div className="form-heading">
                            <h4>Revenue Shares</h4>
                          </div>
                        </div>
                        {revenue_shares_attributes?.length > 0 &&
                          revenue_shares_attributes?.map((el, index) => (
                            <div className="row" key={index}>
                              <div className="col-12 col-md-6 col-xl-4">
                                <div className="form-group local-forms">
                                  <label>
                                    Value
                                    <span className="login-danger">*</span>
                                  </label>
                                  <input
                                    value={el?.value}
                                    name="amount"
                                    onChange={(e) =>
                                      handleChangeRevenue(
                                        index,
                                        "value",
                                        e.target.value
                                      )
                                    }
                                    className="form-control"
                                    type="number"
                                    required={true}
                                  />
                                </div>
                              </div>
                              <div className="col-12 col-md-4 col-xl-3">
                                <div className="form-group local-forms">
                                  <label>
                                    Department
                                    <span className="login-danger">*</span>{" "}
                                  </label>
                                  <Select
                                    isLoading={deptLoader}
                                    value={
                                      (departmentOption?.length > 0 &&
                                        departmentOption.find(
                                          (option) =>
                                            option.value === el?.department_id
                                        )) ||
                                      null
                                    }
                                    onChange={(e) =>
                                      handleChangeRevenue(
                                        index,
                                        "department_id",
                                        e?.value
                                      )
                                    }
                                    options={departmentOption}
                                    menuPortalTarget={document.body}
                                    styles={{
                                      menu: (base) => ({
                                        ...base,
                                        zIndex: 9999,
                                      }),
                                      menuPortal: (base) => ({
                                        ...base,
                                        zIndex: 9999,
                                      }),
                                    }}
                                    id="search-commodity"
                                    components={{
                                      IndicatorSeparator: () => null,
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="col-12 col-md-6 col-xl-4">
                                <button
                                  onClick={(e) =>
                                    handleRemoveRevenue(e, index, el)
                                  }
                                  className="btn btn-primary submit-form me-2 mb-4"
                                >
                                  Remove
                                </button>
                              </div>
                            </div>
                          ))}
                        <div className="col-12 col-md-6 col-xl-6">
                          <button
                            onClick={handleAddRevenueShareRow}
                            type="button"
                            className="btn btn-primary submit-form me-2 mb-4"
                          >
                            Create Revenue Share
                          </button>
                        </div>
                        <div className="col-12">
                          <div className="doctor-submit text-end">
                            <button
                              onClick={handleAddDoctor}
                              type="submit"
                              className="btn btn-primary submit-form me-2"
                            >
                              Update Specialist
                            </button>
                            <button
                              onClick={(e) => {
                                e.preventDefault();
                                navigate("/admin/doctor");
                              }}
                              type="submit"
                              className="btn btn-primary cancel-form"
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </div>
  );
};
export default EditDoctor;

import { toast } from "react-toastify";
import { BASE_URL } from "./connectionUrl";

export const postFollowUpList = async (token, payload) => {
  try {
    const response = await fetch(
      // eslint-disable-next-line no-undef
      `${BASE_URL}/api/v1/admin/followup_lists`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
        body: JSON.stringify(payload),
      }
    );

    const data = await response.json();
    if (data.status.status === "SUCCESS") {
      return data;
    } else {
      toast.error(data?.status?.message || "something went wrong");
    }
  } catch (error) {
    toast.error(error.message || "something went wrong");
  }
};

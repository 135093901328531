import React, { useEffect, useState } from "react";
import Header from "../../Header";
import Sidebar from "../../Sidebar";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { putVideoCategory } from "../../../connections/putVideoCategory";

const UpdateVideoCategory = () => {
  const {
    user: { user },
  } = useSelector((store) => store);
  const [formState, setFormState] = useState({ doctor_id: "1" });
  const navigate = useNavigate();
  const location = useLocation();
  const videoCatData = location.state || null;
  const handleChange = (name, value) => {
    setFormState((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleAddVideoCat = async (e) => {
    e.preventDefault();
    const payload = {
      video_category: formState,
    };
    if (!formState?.title) {
      toast?.error("Title Required");
      return;
    }
    try {
      const data = await putVideoCategory(
        user?.token,
        payload,
        videoCatData?.id
      );
      if (data.status.status === "SUCCESS") {
        toast.success("Video Category Updated Successfully");
        setFormState({});
        navigate("/admin/prescription/video_categories");
      }
    } catch (error) {
      // toast.error(error.message);
      console.log(error);
    }
  };
  useEffect(() => {
    if (videoCatData) {
      setFormState((prev) => ({
        ...prev,
        title: videoCatData?.title,
      }));
    }
  }, [videoCatData]);
  return (
    <>
      <Header />
      <Sidebar activeClassName="video_categories-dashboard" />
      <>
        <div className="page-wrapper">
          <div className="content">
            {/* Page Header */}
            <div className="page-header">
              <div className="row">
                <div className="col-sm-12">
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to={-1}>Video Categories</Link>
                    </li>
                    <li className="breadcrumb-item">
                      <i className="feather-chevron-right">
                        <FeatherIcon icon="chevron-right" />
                      </i>
                    </li>
                    <li className="breadcrumb-item active">
                      Update Video Categories
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            {/* /Page Header */}
            <div className="row">
              <div className="col-sm-12">
                <div className="card">
                  <div className="card-body">
                    <form>
                      <div className="row">
                        <div className="col-12">
                          <div className="form-heading">
                            <h4>Update Video Categories</h4>
                          </div>
                        </div>

                        <div className="col-12 col-md-12 col-xl-12">
                          <div className="form-group local-forms">
                            <label>
                              Title <span className="login-danger">*</span>
                            </label>
                            <input
                              value={formState?.title}
                              name="title"
                              onChange={(e) =>
                                handleChange("title", e.target.value)
                              }
                              className="form-control"
                              type="text"
                              required={true}
                            />
                          </div>
                        </div>

                        <div className="col-12">
                          <div className="doctor-submit text-end">
                            <button
                              onClick={handleAddVideoCat}
                              type="submit"
                              className="btn btn-primary submit-form me-2"
                            >
                              Submit
                            </button>
                            <button
                              onClick={(e) => {
                                e.preventDefault();
                                navigate(
                                  "/admin/prescription/video_categories"
                                );
                              }}
                              type="submit"
                              className="btn btn-primary cancel-form"
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </>
  );
};

export default UpdateVideoCategory;

import { toast } from "react-toastify";
import { BASE_URL } from "./connectionUrl";

export const getSpecialInstruction = async (
  token,
  type = null,
  page = null,
  per_page = null,
  sort_column,
  sort_order,
  filter
) => {
  let endpoint =
    type === "all"
      ? `/api/v1/admin/special_instructions?type=${type}`
      : `/api/v1/admin/special_instructions?page=${page}&per_page=${per_page}`;
  if (sort_column && sort_order) {
    endpoint += `&sort_column=${sort_column}&sort_order=${sort_order}`;
  }
  if (filter) {
    Object.entries(filter).forEach(([key, value]) => {
      if (value !== null && value !== "") {
        endpoint += `&${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
      }
    });
  }
  try {
    const response = await fetch(
      // eslint-disable-next-line no-undef
      `${BASE_URL}${endpoint}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      }
    );

    const data = await response.json();
    // if (data.status.status === "SUCCESS") {
    return data;
  } catch (error) {
    toast.error(error.message || "something went wrong");
  }
};

import React from "react";
import Header from "../Header";
import Sidebar from "../Sidebar";
import { Link, useParams } from "react-router-dom";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";

import { useSelector } from "react-redux";

import { Skeleton } from "@mui/material";
import { getSuppliersDetail } from "../../connections/getSuppliersDetail";
// import { Table } from "antd";
// const columns = [
//   {
//     title: "Id",
//     dataIndex: "id",
//     // sorter: (a, b) => a.ConsultingDoctor.length - b.ConsultingDoctor.length,/
//   },
//   {
//     title: "Name",
//     dataIndex: "name",
//     // sorter: (a, b) => a.name - b.name,
//   },
//   {
//     title: "Phone",
//     dataIndex: "phone",
//     //   sorter: (a, b) => a.ConsultingDoctor.length - b.ConsultingDoctor.length,
//     // render: (date) => <>{dayjs(date).format("MMMM DD, YYYY HH:mm")}</>,
//   },
//   {
//     title: "Gst No",
//     dataIndex: "gst_no",
//     //   sorter: (a, b) => a.Treatment.length - b.Treatment.length,
//     // render: (date) => <>{dayjs(date).format("MMMM DD, YYYY HH:mm")}</>,
//   },
//   {
//     title: "Address",
//     dataIndex: "address",
//     //   sorter: (a, b) => a.Mobile.length - b.Mobile.length,
//     // render: (date) => <>{dayjs(date).format("MMMM DD, YYYY")}</>,
//   },
//   {
//     title: "Email",
//     dataIndex: "email",
//     //   sorter: (a, b) => a.Email.length - b.Email.length,
//     //   render: (text, record) => (
//     //     <>
//     //       <Link to="#">{record.Mobile}</Link>
//     //     </>
//     //   ),
//   },
//   {
//     title: "Created At",
//     dataIndex: "created_at",
//     render: (date) => (
//       <>
//         {dayjs(date, "YYYY-MM-DD HH:mm:ss A").format("MMMM DD, YYYY HH:mm")}
//       </>
//     ),
//   },
//   {
//     title: "Updated At",
//     dataIndex: "updated_at",
//     render: (date) => (
//       <>
//         {dayjs(date, "YYYY-MM-DD HH:mm:ss A").format("MMMM DD, YYYY HH:mm")}
//       </>
//     ),
//   },

// ];
const SuppliersDetail = () => {
  const {
    user: { user },
  } = useSelector((store) => store);
  const { id } = useParams();

  const { data: getSuppliersDetails, isLoading } = useQuery({
    queryKey: ["getSuppliersDetail", id],
    queryFn: () => getSuppliersDetail(user?.token, id),
    enabled: !!id,
    // staleTime: Infinity, // Keep the data fresh indefinitely

    refetchOnWindowFocus: false, // Don't refetch when window regains focus
    refetchOnReconnect: false, // Don't refetch when reconnecting
  });
  // const suppliersListData = getSuppliersDetails ? [getSuppliersDetails] : [];

  return (
    <>
      <Header />
      <Sidebar
        id="menu-item4"
        id1="menu-items4"
        activeClassName="suppliers-list"
      />
      <>
        <div className="page-wrapper">
          <div className="content">
            {/* Page Header */}
            <div className="page-header">
              <div className="row">
                <div className="col-sm-12">
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item active">
                      <Link to={-1}>Supplier Details</Link>
                    </li>
                    <li className="breadcrumb-item">
                      <i className="feather-chevron-right">
                        <FeatherIcon icon="chevron-right" />
                      </i>
                    </li>
                    <li className="breadcrumb-item active">{id}</li>
                  </ul>
                </div>
              </div>
            </div>
            {/* /Page Header */}
            <div className="row">
              <div className="col-sm-12">
                <div className="card card-table show-entire">
                  <div className="card-body">
                    {/* content Header */}
                    <div className="page-table-header mb-2">
                      <div className="row align-items-center">
                        <div className="col">
                          <div className="doctor-table-blk">
                            <h3>Suppliers Details</h3>
                            <div className="doctor-search-blk"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {isLoading ? (
                      <div>
                        <Skeleton
                          variant="rectangular"
                          width="100%"
                          height={430} // Same height as your chart
                        />
                      </div>
                    ) : (
                      <div className="content-details">
                        <div className="col-lg-12">
                          <div className="card-box">
                            <div className="card-block">
                              <ul className="personal-info">
                                <li className="pb-1">
                                  <span className="title">Id:</span>
                                  <span className="text">
                                    {getSuppliersDetails?.id || "N/A"}
                                  </span>
                                </li>
                                <li className="pb-1">
                                  <span className="title">UID:</span>
                                  <span className="text">
                                    {getSuppliersDetails?.uid || "N/A"}
                                  </span>
                                </li>
                                <li className="pb-1">
                                  <span className="title">First Name:</span>
                                  <span className="text">
                                    {getSuppliersDetails?.first_name || "N/A"}
                                  </span>
                                </li>
                                <li className="pb-1">
                                  <span className="title">Last Name:</span>
                                  <span className="text">
                                    {getSuppliersDetails?.last_name || "N/A"}
                                  </span>
                                </li>
                                <li className="pb-1">
                                  <span className="title">Mobile:</span>
                                  <span className="text">
                                    {getSuppliersDetails?.mobile || "N/A"}
                                  </span>
                                </li>
                                <li className="pb-1">
                                  <span className="title">Email:</span>
                                  <span className="text">
                                    {getSuppliersDetails?.email || "N/A"}
                                  </span>
                                </li>
                                <li className="pb-1">
                                  <span className="title">Gender:</span>
                                  <span className="text">
                                    {getSuppliersDetails?.gender || "N/A"}
                                  </span>
                                </li>
                                <li className="pb-1">
                                  <span className="title">DOB:</span>
                                  <span className="text">
                                    {getSuppliersDetails?.dob || "N/A"}
                                  </span>
                                </li>
                                <li className="pb-1">
                                  <span className="title">Marital Status:</span>
                                  <span className="text">
                                    {getSuppliersDetails?.marital_status ||
                                      "N/A"}
                                  </span>
                                </li>
                                <li className="pb-1">
                                  <span className="title">Active:</span>
                                  <span className="text">
                                    {getSuppliersDetails?.active ? (
                                      <span className="custom-badge status-green">
                                        True
                                      </span>
                                    ) : (
                                      <span className="custom-badge status-pink">
                                        False
                                      </span>
                                    )}
                                  </span>
                                </li>
                                <li className="pb-1">
                                  <span className="title">SP Type:</span>
                                  <span className="text">
                                    {getSuppliersDetails?.sp_type || "N/A"}
                                  </span>
                                </li>
                                <li className="pb-1">
                                  <span className="title">Created At:</span>
                                  <span className="text">
                                    {getSuppliersDetails?.created_at
                                      ? dayjs(
                                          getSuppliersDetails?.created_at,
                                          "YYYY-MM-DD HH:mm:ss A"
                                        ).format("MMMM DD, YYYY HH:mm")
                                      : "N/A"}
                                  </span>
                                </li>
                                <li className="pb-1">
                                  <span className="title">Updated At:</span>
                                  <span className="text">
                                    {getSuppliersDetails?.updated_at
                                      ? dayjs(
                                          getSuppliersDetails?.updated_at,
                                          "YYYY-MM-DD HH:mm:ss A"
                                        ).format("MMMM DD, YYYY HH:mm")
                                      : "N/A"}
                                  </span>
                                </li>
                                <li className="pb-1">
                                  <span className="title">Details:</span>
                                  <span
                                    className="text"
                                    dangerouslySetInnerHTML={{
                                      __html:
                                        getSuppliersDetails?.details || "N/A",
                                    }}
                                  />
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      //   <div className="table-responsive doctor-list">
                      //   <Table
                      //     pagination={false}
                      //     columns={columns}
                      //     dataSource={suppliersListData}
                      //     rowKey={(record) => record.id}
                      //     scroll={{ x: "max-content" }}

                      //   />
                      // </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </>
  );
};

export default SuppliersDetail;

import { toast } from "react-toastify";
import { BASE_URL } from "./connectionUrl";

export const getMedicalTestCatDetail = async (token, id) => {
  try {
    const response = await fetch(
      // eslint-disable-next-line no-undef
      `${BASE_URL}/api/v1/admin/medical_test_categories/${id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      }
    );

    const data = await response.json();
    // if (data.status.status === "SUCCESS") {
    return data?.payload;
  } catch (error) {
    toast.error(error.message || "something went wrong");
  }
};

import { useState } from "react";
import Header from "../Header";
import Sidebar from "../Sidebar";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { postReferrers } from "../../connections/postReferrers";
const AddReferrers = () => {
  const {
    user: { user },
  } = useSelector((store) => store);
  const [formState, setFormState] = useState({});
  const navigate = useNavigate();
  const handleChange = (name, value) => {
    setFormState((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleAddData = async (e) => {
    e.preventDefault();

    const payload = {
      referrer: formState,
    };
    if (!formState?.full_name) {
      toast.error("Full name is required");
      return;
    }
    try {
      const data = await postReferrers(user?.token, payload);
      if (data.status.status === "SUCCESS") {
        toast.success("Referrer Added Successfully");
        setFormState({});
        navigate("/admin/referrers");
      }
    } catch (error) {
      //   toast.error(error.message);
      console.log(error.message);
    }
  };
  return (
    <>
      <Header />
      <Sidebar activeClassName="referrers-list" />
      <>
        <div className="page-wrapper">
          <div className="content">
            {/* Page Header */}
            <div className="page-header">
              <div className="row">
                <div className="col-sm-12">
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to={-1}>Referrers </Link>
                    </li>
                    <li className="breadcrumb-item">
                      <i className="feather-chevron-right">
                        <FeatherIcon icon="chevron-right" />
                      </i>
                    </li>
                    <li className="breadcrumb-item active">Add Referrers</li>
                  </ul>
                </div>
              </div>
            </div>
            {/* /Page Header */}
            <div className="row">
              <div className="col-sm-12">
                <div className="card">
                  <div className="card-body">
                    <form>
                      <div className="row">
                        <div className="col-12">
                          <div className="form-heading">
                            <h4>Add Referrers</h4>
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-3">
                          <div className="form-group local-forms">
                            <label>
                              Full Name
                              <span className="login-danger">*</span>
                            </label>
                            <input
                              value={formState.full_name}
                              onChange={(e) =>
                                handleChange("full_name", e.target.value)
                              }
                              className="form-control"
                              type="text"
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-3">
                          <div className="form-group local-forms">
                            <label>
                              Email
                              {/* <span className="login-danger">*</span> */}
                            </label>
                            <input
                              value={formState.email}
                              onChange={(e) =>
                                handleChange("email", e.target.value)
                              }
                              className="form-control"
                              type="email"
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-3">
                          <div className="form-group local-forms">
                            <label>
                              Phone
                              {/* <span className="login-danger">*</span> */}
                            </label>
                            <input
                              value={formState.phone}
                              onChange={(e) => {
                                const value = e.target.value.replace(/\D/g, "");
                                if (value.length <= 10) {
                                  handleChange("phone", value);
                                }
                              }}
                              maxLength={10}
                              className="form-control"
                              type="number"
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-3">
                          <div className="form-group local-forms">
                            <label>
                              Address
                              {/* <span className="login-danger">*</span> */}
                            </label>
                            <input
                              value={formState.address}
                              onChange={(e) =>
                                handleChange("address", e.target.value)
                              }
                              className="form-control"
                              type="text"
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-3">
                          <div className="form-group local-forms">
                            <label>
                              City
                              {/* <span className="login-danger">*</span> */}
                            </label>
                            <input
                              value={formState.city}
                              onChange={(e) =>
                                handleChange("city", e.target.value)
                              }
                              className="form-control"
                              type="text"
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-3">
                          <div className="form-group local-forms">
                            <label>
                              Qualification
                              {/* <span className="login-danger">*</span> */}
                            </label>
                            <input
                              value={formState.qualification}
                              onChange={(e) =>
                                handleChange("qualification", e.target.value)
                              }
                              className="form-control"
                              type="text"
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-3">
                          <div className="form-group local-forms">
                            <label>
                              Profession
                              {/* <span className="login-danger">*</span> */}
                            </label>
                            <input
                              value={formState.profession}
                              onChange={(e) =>
                                handleChange("profession", e.target.value)
                              }
                              className="form-control"
                              type="text"
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-3">
                          <div className="form-group local-forms">
                            <label>
                              Letter Text
                              {/* <span className="login-danger">*</span> */}
                            </label>
                            <input
                              value={formState.letter_text}
                              onChange={(e) =>
                                handleChange("letter_text", e.target.value)
                              }
                              className="form-control"
                              type="text"
                            />
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="doctor-submit text-end">
                            <button
                              onClick={handleAddData}
                              type="submit"
                              className="btn btn-primary submit-form me-2"
                            >
                              Submit
                            </button>
                            <button
                              onClick={(e) => {
                                e.preventDefault();
                                navigate("/admin/referrers");
                              }}
                              type="submit"
                              className="btn btn-primary cancel-form"
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </>
  );
};

export default AddReferrers;

import React from "react";
import Header from "../Header";
import Sidebar from "../Sidebar";
import { Link, useParams } from "react-router-dom";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { useQuery } from "@tanstack/react-query";

import { useSelector } from "react-redux";
import dayjs from "dayjs";
import { Skeleton } from "@mui/material";
import { Table } from "antd";
import { onShowSizeChange, itemRender } from "../Pagination";
import { getMedicineSaltsDetails } from "../../connections/getMedicineSaltsDetails";
const MedicineSaltDetail = () => {
  const {
    user: { user },
  } = useSelector((store) => store);
  const { id } = useParams();

  const { data: getMedicineDetail, isLoading } = useQuery({
    queryKey: ["getMedicineDetails", id],
    queryFn: () => getMedicineSaltsDetails(user?.token, id),
    enabled: !!id,
    refetchOnWindowFocus: false, // Don't refetch when window regains focus
    refetchOnReconnect: false, // Don't refetch when reconnecting
  });
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Company",
      dataIndex: "company",
      key: "company",
    },
    {
      title: "Form Type",
      dataIndex: "form_type",
      key: "form_type",
    },
    {
      title: "Discount",
      dataIndex: "discount",
      key: "discount",
    },
    {
      title: "Batch",
      dataIndex: "batch",
      key: "batch",
    },
    {
      title: "Manufacture Date",
      dataIndex: "manufacture_date",
      key: "manufacture_date",
      render: (manufacture_date) =>
        manufacture_date
          ? dayjs(manufacture_date).format("YYYY-MM-DD")
          : "EMPTY",
    },
    {
      title: "Expiry Date",
      dataIndex: "expiry_date",
      key: "expiry_date",
      render: (expiry_date) =>
        expiry_date ? dayjs(expiry_date).format("YYYY-MM-DD") : "EMPTY",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "Purchase Cost",
      dataIndex: "purchase_cost",
      key: "purchase_cost",
    },
    {
      title: "Packing",
      dataIndex: "packing",
      key: "packing",
    },
    {
      title: "Stock Left",
      dataIndex: "stock_left",
      key: "stock_left",
    },
    {
      title: "Purchased At",
      dataIndex: "purchased_at",
      key: "purchased_at",
      render: (purchased_at) =>
        purchased_at ? dayjs(purchased_at).format("YYYY-MM-DD") : "EMPTY",
    },
    {
      title: "Delivered At",
      dataIndex: "delivered_at",
      key: "delivered_at",
      render: (delivered_at) =>
        delivered_at ? dayjs(delivered_at).format("YYYY-MM-DD") : "EMPTY",
    },
    {
      title: "GST",
      dataIndex: "gst",
      key: "gst",
    },
    {
      title: "Units Purchased",
      dataIndex: "units_purchased",
      key: "units_purchased",
    },
    {
      title: "Auto Purchase Threshold",
      dataIndex: "auto_purchase_threshold",
      key: "auto_purchase_threshold",
    },
    {
      title: "Flat Price",
      dataIndex: "flat_price",
      key: "flat_price",
    },
    {
      title: "HSN Code",
      dataIndex: "hsn_code",
      key: "hsn_code",
    },
    {
      title: "CGST",
      dataIndex: "cgst",
      key: "cgst",
    },
    {
      title: "SGST",
      dataIndex: "sgst",
      key: "sgst",
    },
  ];
  // const columnsParent = [
  //   {
  //     title: "ID",
  //     dataIndex: "id",
  //     key: "id",
  //   },
  //   {
  //     title: "Medicine Salt Name",
  //     dataIndex: "name",
  //     key: "name",
  //   },
  //   {
  //     title: "Created At",
  //     dataIndex: "created_at",
  //     key: "created_at",
  //   },
  //   {
  //     title: "Updated At",
  //     dataIndex: "updated_at",
  //     key: "updated_at",
  //   },
  // ];
  return (
    <>
      <Header />
      <Sidebar activeClassName="medicine-salt-list" />
      <>
        <div className="page-wrapper">
          <div className="content">
            {/* Page Header */}
            <div className="page-header">
              <div className="row">
                <div className="col-sm-12">
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to={-1}>Medicine Salts</Link>
                    </li>
                    <li className="breadcrumb-item">
                      <i className="feather-chevron-right">
                        <FeatherIcon icon="chevron-right" />
                      </i>
                    </li>
                    <li className="breadcrumb-item active">
                      <Link to={-1}> Medicine Salts Detail</Link>
                    </li>
                    <li className="breadcrumb-item">
                      <i className="feather-chevron-right">
                        <FeatherIcon icon="chevron-right" />
                      </i>
                    </li>
                    <li className="breadcrumb-item active">{id}</li>
                  </ul>
                </div>
              </div>
            </div>
            {/* /Page Header */}
            <div className="row">
              <div className="col-sm-12">
                <div className="card card-table show-entire">
                  <div className="card-body">
                    {/* content Header */}
                    <div className="page-table-header mb-2">
                      <div className="row align-items-center">
                        <div className="col">
                          <div className="doctor-table-blk">
                            <h3>Medicine Salts Details</h3>
                            <div className="doctor-search-blk"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {isLoading ? (
                      <div>
                        <Skeleton
                          variant="rectangular"
                          width="100%"
                          height={430} // Same height as your chart
                        />
                      </div>
                    ) : (
                      <div className="content-details">
                        <div className="col-lg-12">
                          <div className="card-box">
                            <div className="card-block">
                              <ul className="personal-info">
                                <li className="pb-1">
                                  <span className="title">ID:</span>
                                  <span className="text">
                                    {getMedicineDetail?.id || "N/A"}
                                  </span>
                                </li>
                                <li className="pb-1">
                                  <span className="title">
                                    Medicine Salt Name:
                                  </span>
                                  <span className="text">
                                    {getMedicineDetail?.name || "N/A"}
                                  </span>
                                </li>
                                <li className="pb-1">
                                  <span className="title">Created At:</span>
                                  <span className="text">
                                    {getMedicineDetail?.created_at || "N/A"}
                                  </span>
                                </li>
                                <li className="pb-1">
                                  <span className="title">Updated At:</span>
                                  <span className="text">
                                    {getMedicineDetail?.updated_at || "N/A"}
                                  </span>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        {/* <div className="table-responsive doctor-list">
                          <Table
                            id="table-to-print"
                            scroll={{ x: "max-content" }}
                            columns={columnsParent}
                            dataSource={[getMedicineDetail]}
                            // rowSelection={rowSelection}
                            rowKey={(record) => record.id}
                          />
                        </div> */}

                        {/* { items  list}  */}
                        {getMedicineDetail?.medicines?.length > 0 && (
                          <div
                            style={{ marginLeft: "1rem" }}
                            className="doctor-table-blk"
                          >
                            <h3>Medicines</h3>
                            <div className="doctor-search-blk"></div>
                          </div>
                        )}
                        {getMedicineDetail?.medicines?.length > 0 && (
                          <div
                            style={{ marginLeft: "1rem" }}
                            className="table-responsive doctor-list"
                          >
                            <Table
                              id="table-to-print"
                              pagination={{
                                total: getMedicineDetail?.medicines?.length,
                                showTotal: (total, range) =>
                                  `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                                //showSizeChanger: true,
                                onShowSizeChange: onShowSizeChange,
                                itemRender: itemRender,
                              }}
                              scroll={{ x: "max-content" }}
                              columns={columns}
                              dataSource={getMedicineDetail?.medicines}
                              // rowSelection={rowSelection}
                              rowKey={(record) => record.id}
                            />
                          </div>
                        )}
                        {/* </div> */}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </>
  );
};

export default MedicineSaltDetail;

// <table>
//   <tr>
//     <td>igtfdfg</td>
//     <td>Knocky</td>
//     <td>Flor</td>
//     <td>Ella</td>
//     <td>Juan</td>
//   </tr>
//   <tr>
//     <td>Breed</td>
//     <td>Jack Russell</td>
//     <td>Poodle</td>
//     <td>Streetdog</td>
//     <td>Cocker Spaniel</td>
//   </tr>
//   <tr>
//     <td>Age</td>
//     <td>16</td>
//     <td>9</td>
//     <td>10</td>
//     <td>5</td>
//   </tr>
//   <tr>
//     <td>Owner</td>
//     <td>Mother-in-law</td>
//     <td>Me</td>
//     <td>Me</td>
//     <td>Sister-in-law</td>
//   </tr>
//   <tr>
//     <td>Eating Habits</td>
//     <td>Eats everyones leftovers</td>
//     <td>Nibbles at food</td>
//     <td>Hearty eater</td>
//     <td>Will eat till he explodes</td>
//   </tr>
// </table>

import React, { useState } from "react";
import Header from "../Header";
import Sidebar from "../Sidebar";
import { Link, useNavigate } from "react-router-dom";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import {
  imagesend,
  pdficon,
  pdficon3,
  plusicon,
  refreshicon,
  pdficon5,
} from "../imagepath";
import { useSelector } from "react-redux";
import { useQuery } from "@tanstack/react-query";
import { getConsumable } from "../../connections/getConsumable";
import { Table } from "antd";

import {
  handleDownloadCsv,
  handlePrintConsumable,
  handleShowJson,
} from "../../utils/constant/constant";
import { putEnableDisableConsumable } from "../../connections/putEnableDisableConsumable";
import { toast } from "react-toastify";

const ConsumableList = () => {
  //   const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  // const [consumableActive, setConsumableActive] = useState(null);
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const {
    user: { user },
  } = useSelector((store) => store);

  const {
    data: getConsumableListData,
    isLoading: consumableListLoader,
    refetch: refetchConsumable,
  } = useQuery({
    queryKey: ["getConsumable", page, pageSize],
    queryFn: () => getConsumable(user?.token, null, page, pageSize),
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });

  const handlePageChange = (newPage, newPageSize) => {
    setPage(newPage);
    setPageSize(newPageSize);
  };
  const handleEnableDisableConsumable = async (value, id) => {
    const data = await putEnableDisableConsumable(user?.token, value, id);
    if (data.status.status === "SUCCESS") {
      toast.success(`Consumable ${value ? "Enable" : "Disable"} Successfully`);
      refetchConsumable();
    }
  };
  //   const onSelectChange = (newSelectedRowKeys) => {
  //     console.log("selectedRowKeys changed: ", selectedRowKeys);

  //     setSelectedRowKeys(newSelectedRowKeys);
  //   };

  //   const rowSelection = {
  //     selectedRowKeys,
  //     onChange: onSelectChange,
  //   };
  const columns = [
    {
      title: "Id",
      dataIndex: "id",
    },
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "Amount",
      dataIndex: "amount",
    },
    {
      title: "Consumable Category",
      dataIndex: "consumable_category",
      render: (_, record) => (
        <Link
          style={{ textDecoration: "underline" }}
          to={`/admin/consumable_categories/${record?.consumable_category?.id}`}
        >
          {record?.consumable_category?.name}
        </Link>
      ),
    },
    {
      title: "Discount",
      dataIndex: "discount",
    },

    {
      title: "Unit",
      dataIndex: "unit",
    },
    {
      title: "Active",
      dataIndex: "active",
      // render: (text) => <>{text ? "Yes" : "No"}</>,
      render: (text) => (
        <div>
          {text === true && (
            <span className="custom-badge status-green">{"Yes"}</span>
          )}
          {text === false && (
            <span className="custom-badge status-pink">{"NO"}</span>
          )}
          {text === null && (
            <span className="custom-badge status-pink">{"NO"}</span>
          )}
        </div>
      ),
    },
    {
      title: "",
      dataIndex: "FIELD8",
      render: (_, record) => (
        <>
          <div className="text-end">
            <div className="dropdown dropdown-action">
              <Link
                to="#"
                className="action-icon dropdown-toggle"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i className="fas fa-ellipsis-v" />
              </Link>
              <div
                style={{ top: "-10rem !important" }}
                className="dropdown-menu dropdown-menu-end"
              >
                <Link
                  className="dropdown-item"
                  to={`/admin/consumable/${record?.id}`}
                >
                  <i className="fa-regular fa-eye m-r-5" />
                  View
                </Link>
                <button
                  className="dropdown-item"
                  onClick={() =>
                    navigate("/admin/update_consumable", { state: record })
                  }
                >
                  <i className="far fa-edit me-2" />
                  Edit
                </button>
                {record?.active ? (
                  <Link
                    className="dropdown-item"
                    // to="#"
                    // data-bs-toggle="modal"
                    // data-bs-target="#delete_patient"
                    onClick={() => {
                      handleEnableDisableConsumable(false, record?.id);
                    }}
                  >
                    <i className="fa fa-ban" aria-hidden="true"></i> Disable
                  </Link>
                ) : (
                  <Link
                    className="dropdown-item"
                    // to="#"
                    // data-bs-toggle="modal"
                    // data-bs-target="#delete_patient"
                    onClick={() => {
                      handleEnableDisableConsumable(true, record?.id);
                    }}
                  >
                    <i className="fa fa-toggle-on" aria-hidden="true"></i>{" "}
                    Enable
                  </Link>
                )}
              </div>
            </div>
          </div>
        </>
      ),
    },
  ];

  return (
    <div>
      <Header />
      <Sidebar activeClassName="consumable-list" />
      <>
        <div className="page-wrapper">
          <div className="content">
            {/* Page Header */}
            <div className="page-header">
              <div className="row">
                <div className="col-sm-12">
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="#">Consumable</Link>
                    </li>
                    <li className="breadcrumb-item">
                      <i className="feather-chevron-right">
                        <FeatherIcon icon="chevron-right" />
                      </i>
                    </li>
                    <li className="breadcrumb-item active">Consumable List</li>
                  </ul>
                </div>
              </div>
            </div>
            {/* /Page content table */}
            <div className="row">
              <div className="col-sm-12">
                <div className="card card-table show-entire">
                  <div className="card-body">
                    {/* Table Header */}
                    <div className="page-table-header mb-2">
                      <div className="row align-items-center">
                        <div className="col">
                          <div className="doctor-table-blk">
                            <h3>Consumable List</h3>
                            <div className="doctor-search-blk">
                              <div className="add-group">
                                <Link
                                  to="/admin/add_consumable"
                                  className="btn btn-primary add-pluss ms-2"
                                >
                                  <img src={plusicon} alt="#" />
                                </Link>
                                <Link
                                  onClick={refetchConsumable}
                                  className="btn btn-primary add-pluss ms-2"
                                >
                                  <img src={refreshicon} alt="#" />
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-auto text-end float-end ms-auto download-grp">
                          <img
                            className=" me-2"
                            onClick={() =>
                              handlePrintConsumable(
                                getConsumableListData?.payload?.consumables
                              )
                            }
                            src={pdficon}
                            alt="#"
                            style={{ cursor: "pointer" }}
                          />

                          <img
                            onClick={() =>
                              handleDownloadCsv(
                                getConsumableListData?.payload?.consumables
                              )
                            }
                            className=" me-2"
                            src={pdficon3}
                            alt="#"
                            style={{ cursor: "pointer" }}
                          />

                          <img
                            onClick={() =>
                              handleShowJson(
                                getConsumableListData?.payload?.consumables
                              )
                            }
                            width={"25px"}
                            height={"26px"}
                            src={pdficon5}
                            alt="#"
                            style={{ cursor: "pointer" }}
                          />
                        </div>
                      </div>
                    </div>
                    {/* /Table Header */}
                    <div className="table-responsive doctor-list">
                      <Table
                        loading={consumableListLoader}
                        pagination={{
                          current: page,
                          pageSize: pageSize,
                          total: getConsumableListData?.meta?.total_count,
                          showTotal: (total, range) =>
                            `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                          onChange: handlePageChange,
                        }}
                        columns={columns}
                        dataSource={getConsumableListData?.payload?.consumables}
                        scroll={{ x: "max-content" }}
                        rowKey={(record) => record.id}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          id="delete_patient"
          className="modal fade delete-modal"
          role="dialog"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body text-center">
                <img src={imagesend} alt="#" width={50} height={46} />
                <h3>Are you sure want to delete this ?</h3>
                <div className="m-t-20">
                  {" "}
                  <Link
                    to="#"
                    className="btn btn-white me-2"
                    data-bs-dismiss="modal"
                  >
                    Close
                  </Link>
                  <button
                    // onClick={deleteSupplier}
                    type="submit"
                    className="btn btn-danger"
                    data-bs-dismiss="modal"
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div
            id="delete_patient"
            className="modal fade delete-modal"
            role="dialog"
          >
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-body text-center">
                  <img src={imagesend} alt="#" width={50} height={46} />
                  <h3>Are you sure want to delete this ?</h3>
                  <div className="m-t-20">
                    {" "}
                    <Link
                      to="#"
                      className="btn btn-white me-2"
                      data-bs-dismiss="modal"
                    >
                      Close
                    </Link>
                    <button
                      data-bs-dismiss="modal"
                      type="submit"
                      className="btn btn-danger"
                    >
                      Delete
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </div>
  );
};

export default ConsumableList;

import React from "react";
import Header from "../Header";
import Sidebar from "../Sidebar";
import { Link, useParams } from "react-router-dom";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { useQuery } from "@tanstack/react-query";

import { useSelector } from "react-redux";

import { Skeleton } from "@mui/material";
import dayjs from "dayjs";
// import { Table } from "antd";
import { getAdminUserDetail } from "../../connections/getAdminUserDetail";

const AdminUserDetails = () => {
  const {
    user: { user },
  } = useSelector((store) => store);
  const { id } = useParams();
  const { data: getAdminUserData, isLoading } = useQuery({
    queryKey: ["getAdminUserDetail", id],
    queryFn: () => getAdminUserDetail(user?.token, id),
    enabled: !!id,
    // staleTime: Infinity, // Keep the data fresh indefinitely

    refetchOnWindowFocus: false, // Don't refetch when window regains focus
    refetchOnReconnect: false, // Don't refetch when reconnecting
  });
  const formatDate = (date) =>
    dayjs(date, "YYYY-MM-DD HH:mm:ss").format("MMMM DD, YYYY HH:mm");
  return (
    <>
      <Header />
      <Sidebar activeClassName="admin-user-list" />
      <>
        <div className="page-wrapper">
          <div className="content">
            {/* Page Header */}
            <div className="page-header">
              <div className="row">
                <div className="col-sm-12">
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item active">
                      <Link to={-1}>Admin User Details</Link>
                    </li>
                    <li className="breadcrumb-item">
                      <i className="feather-chevron-right">
                        <FeatherIcon icon="chevron-right" />
                      </i>
                    </li>
                    <li className="breadcrumb-item active">{id}</li>
                  </ul>
                </div>
              </div>
            </div>
            {/* /Page Header */}
            <div className="row">
              <div className="col-sm-12">
                <div className="card card-table show-entire">
                  <div className="card-body">
                    {/* content Header */}
                    <div className="page-table-header mb-2">
                      <div className="row align-items-center">
                        <div className="col">
                          <div className="doctor-table-blk">
                            <h3>Admin User Details</h3>
                          </div>
                        </div>
                      </div>
                    </div>
                    {isLoading ? (
                      <div>
                        <Skeleton
                          variant="rectangular"
                          width="100%"
                          height={430} // Same height as your chart
                        />
                      </div>
                    ) : (
                      <div
                        style={{ marginLeft: "1rem", marginTop: "20px" }}
                        className="col-md-7"
                      >
                        <ul className="personal-info">
                          <li className="pb-1">
                            <span className="title">Id:</span>
                            <span className="text">{getAdminUserData?.id}</span>
                          </li>
                          <li className="pb-1">
                            <span className="title">Role:</span>
                            <span className="text">
                              {getAdminUserData?.role}
                            </span>
                          </li>
                          <li className="pb-1">
                            <span className="title">Email:</span>
                            <span className="text">
                              {getAdminUserData?.email}
                            </span>
                          </li>

                          <li className="pb-1">
                            <span className="title">Current Sign In at:</span>
                            <span className="text">
                              {getAdminUserData?.current_sign_in_at || "NA"}
                            </span>
                          </li>
                          <li className="pb-1">
                            <span className="title">Sign In Count:</span>
                            <span className="text">
                              {getAdminUserData?.sign_in_count}
                            </span>
                          </li>
                          <li className="pb-1">
                            <span className="title">Created At:</span>
                            <span className="text">
                              {formatDate(getAdminUserData?.created_at)}
                            </span>
                          </li>
                          <li className="pb-1">
                            <span className="title">Updated At:</span>
                            <span className="text">
                              {formatDate(getAdminUserData?.updated_at)}
                            </span>
                          </li>
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </>
  );
};

export default AdminUserDetails;

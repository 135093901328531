import React, { useState } from "react";

import { Link, useNavigate, useParams } from "react-router-dom";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { useQuery } from "@tanstack/react-query";

import { useSelector } from "react-redux";

import { Skeleton } from "@mui/material";
import Header from "../../Header";
import Sidebar from "../../Sidebar";
import { getInvoiceDetails } from "../../../connections/getInvoiceDetails";
import dayjs from "dayjs";
import { Table } from "antd";
import { imagesend } from "../../imagepath";
import { toast } from "react-toastify";
import { deleteInvoiceItem } from "../../../connections/deleteInvoiceItem";
const Invoice_Details = () => {
  const [deleteId, setDeleteId] = useState(null);
  const {
    user: { user },
  } = useSelector((store) => store);
  const { id } = useParams();
  const navigate = useNavigate();
  const {
    data: getSuppliersDetails,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ["getInvoiceDetails", id],
    queryFn: () => getInvoiceDetails(user?.token, id),
    enabled: !!id,
    cacheTime: 1000 * 60 * 60,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      render: (text) => text || "N/A", // Display "N/A" if null
    },
    {
      title: "Type",
      dataIndex: "invoice_itemable_type",
      key: "invoice_itemable_type",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (amount) => `₹${amount.toFixed(2)}`, // Format amount with currency symbol
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
      render: (text) => text || "N/A",
    },
    {
      title: "Discount",
      dataIndex: "discount",
      key: "discount",
      render: (text) => text || "N/A",
    },

    {
      title: "Refund",
      dataIndex: "refund",
      key: "refund",
      render: (refund) => (refund ? "Yes" : "No"),
    },

    {
      title: "",
      dataIndex: "FIELD8",
      render: (_, record) => (
        <>
          <div className="text-end">
            <div className="dropdown dropdown-action">
              <Link
                to="#"
                className="action-icon dropdown-toggle"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i className="fas fa-ellipsis-v" />
              </Link>
              <div className="dropdown-menu dropdown-menu-end">
                <Link
                  className="dropdown-item"
                  to={`/admin/invoice-item-details/${record?.id}`}
                >
                  <i className="fa-regular fa-eye m-r-5" />
                  View
                </Link>
                <button
                  className="dropdown-item"
                  onClick={() =>
                    navigate("/admin/update-invoice-item-details", {
                      state: record,
                    })
                  }
                  // to={{
                  //   pathname: "/admin/update-suppliers",
                  //   state: { supplier: record },
                  //   data: "ddddddddddddd",
                  // }}
                >
                  <i className="far fa-edit me-2" />
                  Edit
                </button>
                <Link
                  className="dropdown-item"
                  to="#"
                  data-bs-toggle="modal"
                  data-bs-target="#delete_patient"
                  onClick={() => {
                    setDeleteId(record?.id);
                  }}
                >
                  <i className="fa fa-trash-alt m-r-5"></i> Delete
                </Link>
              </div>
            </div>
          </div>
        </>
      ),
    },
  ];
  // const columnsParent = [
  //   {
  //     title: "Name",
  //     dataIndex: "name",
  //     key: "name",
  //   },
  //   {
  //     title: "Patient",
  //     dataIndex: ["patient", "full_name"],
  //     key: "patient",
  //   },
  //   {
  //     title: "Uid",
  //     dataIndex: "uid",
  //     key: "uid",
  //   },
  //   {
  //     title: "Invoiceable",
  //     dataIndex: "invoiceable_id",
  //     key: "invoiceable",
  //     render: (invoiceable_id, record) =>
  //       invoiceable_id ? (
  //         <Link
  //           to={`/admin/appointment/${invoiceable_id}`}
  //           style={{ textDecoration: "underline" }}
  //         >
  //           {record.invoiceable_type} {invoiceable_id || "Empty"}
  //         </Link>
  //       ) : (
  //         "Empty"
  //       ),
  //   },
  //   {
  //     title: "Patient Uid",
  //     dataIndex: ["patient", "uid"],
  //     key: "patientUid",
  //   },
  //   {
  //     title: "Mobile",
  //     dataIndex: "mobile",
  //     key: "mobile",
  //   },
  //   {
  //     title: "Status",
  //     dataIndex: "status",
  //     key: "status",
  //   },
  //   {
  //     title: "Invoice Type",
  //     dataIndex: "invoice_type",
  //     key: "invoiceType",
  //   },
  //   {
  //     title: "Total",
  //     dataIndex: "total",
  //     key: "total",
  //   },
  //   {
  //     title: "Printed",
  //     dataIndex: "printed",
  //     key: "printed",
  //     render: (printed) => (printed ? "Yes" : "No"),
  //   },
  //   {
  //     title: "Discount",
  //     dataIndex: "discount",
  //     key: "discount",
  //   },
  //   {
  //     title: "Discount Type",
  //     dataIndex: "discount_type",
  //     key: "discountType",
  //     render: (discount_type) => discount_type || "EMPTY",
  //   },
  //   {
  //     title: "Grand Total",
  //     dataIndex: "grand_total",
  //     key: "grandTotal",
  //     render: (grand_total) => grand_total || "EMPTY",
  //   },
  //   {
  //     title: "Generated At",
  //     dataIndex: "generated_at",
  //     key: "generatedAt",
  //     render: (generated_at) =>
  //       dayjs(generated_at, "YYYY-MM-DD HH:mm:ss A").format(
  //         "MMMM DD, YYYY HH:mm"
  //       ),
  //   },
  // ];
  const handleDeleteInvoiceItem = async (e) => {
    e.preventDefault();
    const data = await deleteInvoiceItem(user?.token, deleteId);
    if (data.status.status === "SUCCESS") {
      toast.success("Invoice Item Deleted Successfully");
      refetch();
    }
  };
  return (
    <>
      <Header />
      <Sidebar activeClassName="invoice-list" />
      <>
        <div className="page-wrapper">
          <div className="content">
            {/* Page Header */}
            <div className="page-header">
              <div className="row">
                <div className="col-sm-12">
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item active">
                      <Link to={-1}>Invoice Details</Link>
                    </li>
                    <li className="breadcrumb-item">
                      <i className="feather-chevron-right">
                        <FeatherIcon icon="chevron-right" />
                      </i>
                    </li>
                    <li className="breadcrumb-item active">{id}</li>
                  </ul>
                </div>
              </div>
            </div>
            {/* /Page Header */}
            <div className="row">
              <div className="col-sm-12">
                <div className="card card-table show-entire">
                  <div className="card-body">
                    {/* content Header */}
                    <div className="page-table-header mb-2">
                      <div className="row align-items-center">
                        <div className="col">
                          <div className="doctor-table-blk">
                            <h3>Invoice Details</h3>
                            <div className="doctor-search-blk"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {isLoading ? (
                      <div>
                        <Skeleton
                          variant="rectangular"
                          width="100%"
                          height={430} // Same height as your chart
                        />
                      </div>
                    ) : (
                      <div className="content-details">
                        <div style={{ marginLeft: 20 }} className="row">
                          <div className="content-details">
                            <div className="col-lg-12">
                              <div className="card-box">
                                <div className="card-block">
                                  <ul className="personal-info">
                                    <li className="pb-1">
                                      <span className="title">Name:</span>
                                      <span className="text">
                                        {getSuppliersDetails?.name || "Empty"}
                                      </span>
                                    </li>
                                    <li className="pb-1">
                                      <span className="title">Patient:</span>
                                      <span className="text">
                                        {getSuppliersDetails?.patient
                                          ?.full_name || "Empty"}
                                      </span>
                                    </li>
                                    <li className="pb-1">
                                      <span className="title">Uid:</span>
                                      <span className="text">
                                        {getSuppliersDetails?.uid || "Empty"}
                                      </span>
                                    </li>
                                    <li className="pb-1">
                                      <span className="title">
                                        Invoiceable:
                                      </span>
                                      <span className="text">
                                        {getSuppliersDetails?.invoiceable_id ? (
                                          <Link
                                            to={`/admin/appointment/${getSuppliersDetails.invoiceable_id}`}
                                            style={{
                                              textDecoration: "underline",
                                            }}
                                          >
                                            {
                                              getSuppliersDetails.invoiceable_type
                                            }{" "}
                                            {getSuppliersDetails.invoiceable_id}
                                          </Link>
                                        ) : (
                                          "Empty"
                                        )}
                                      </span>
                                    </li>
                                    <li className="pb-1">
                                      <span className="title">
                                        Patient Uid:
                                      </span>
                                      <span className="text">
                                        {getSuppliersDetails?.patient?.uid ||
                                          "Empty"}
                                      </span>
                                    </li>
                                    <li className="pb-1">
                                      <span className="title">Mobile:</span>
                                      <span className="text">
                                        {getSuppliersDetails?.mobile || "Empty"}
                                      </span>
                                    </li>
                                    <li className="pb-1">
                                      <span className="title">Status:</span>
                                      <span className="text">
                                        {getSuppliersDetails?.status || "Empty"}
                                      </span>
                                    </li>
                                    <li className="pb-1">
                                      <span className="title">
                                        Invoice Type:
                                      </span>
                                      <span className="text">
                                        {getSuppliersDetails?.invoice_type ||
                                          "Empty"}
                                      </span>
                                    </li>
                                    <li className="pb-1">
                                      <span className="title">Total:</span>
                                      <span className="text">
                                        {getSuppliersDetails?.total || "Empty"}
                                      </span>
                                    </li>
                                    <li className="pb-1">
                                      <span className="title">Printed:</span>
                                      <span className="text">
                                        {getSuppliersDetails?.printed
                                          ? "Yes"
                                          : "No"}
                                      </span>
                                    </li>
                                    <li className="pb-1">
                                      <span className="title">Discount:</span>
                                      <span className="text">
                                        {getSuppliersDetails?.discount ||
                                          "Empty"}
                                      </span>
                                    </li>
                                    <li className="pb-1">
                                      <span className="title">
                                        Discount Type:
                                      </span>
                                      <span className="text">
                                        {getSuppliersDetails?.discount_type ||
                                          "EMPTY"}
                                      </span>
                                    </li>
                                    <li className="pb-1">
                                      <span className="title">
                                        Grand Total:
                                      </span>
                                      <span className="text">
                                        {getSuppliersDetails?.grand_total ||
                                          "EMPTY"}
                                      </span>
                                    </li>
                                    <li className="pb-1">
                                      <span className="title">
                                        Generated At:
                                      </span>
                                      <span className="text">
                                        {getSuppliersDetails?.generated_at
                                          ? dayjs(
                                              getSuppliersDetails.generated_at,
                                              "YYYY-MM-DD HH:mm:ss A"
                                            ).format("MMMM DD, YYYY HH:mm")
                                          : "Empty"}
                                      </span>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* <div className="table-responsive">
                            <Table
                              columns={columnsParent}
                              dataSource={[getSuppliersDetails]}
                              pagination={false}
                              scroll={{ x: "max-content" }}
                            />
                          </div> */}
                          {getSuppliersDetails?.invoice_items?.length > 0 && (
                            <div
                              style={{ borderBottom: "1px solid #e8e8e8" }}
                              className="col-md-12 col-12 mb-2 p-2"
                            >
                              <strong>Invoice Items:</strong>{" "}
                              <div className="table-responsive doctor-list">
                                <Table
                                  columns={columns}
                                  dataSource={
                                    getSuppliersDetails?.invoice_items
                                  }
                                  rowKey={(record) => record.id}
                                />
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            id="delete_patient"
            className="modal fade delete-modal"
            role="dialog"
          >
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-body text-center">
                  <img src={imagesend} alt="#" width={50} height={46} />
                  <h3>Are you sure want to delete this ?</h3>
                  <div className="m-t-20">
                    {" "}
                    <Link
                      to="#"
                      className="btn btn-white"
                      data-bs-dismiss="modal"
                    >
                      Close
                    </Link>
                    <button
                      onClick={handleDeleteInvoiceItem}
                      type="submit"
                      className="btn btn-danger"
                      data-bs-dismiss="modal"
                    >
                      Delete
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </>
  );
};

export default Invoice_Details;

/* eslint-disable no-undef */
import { toast } from "react-toastify";
import { BASE_URL } from "./connectionUrl";

export const getCollectionsInvoiceList = async (
  token,
  start_date,
  end_date,
  staff_id,
  type
) => {
  const endpoint =
    staff_id === null
      ? `/api/v1/admin/collections?start_date=${start_date}&end_date=${end_date}${
          type ? `&type=${type}` : ""
        }`
      : `/api/v1/admin/collections?staff_id=${staff_id}&start_date=${start_date}&end_date=${end_date}${
          type ? `&type=${type}` : ""
        }`;

  try {
    const response = await fetch(`${BASE_URL}${endpoint}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    });

    const data = await response.json();
    if (data.status.status === "SUCCESS") {
      return data;
    } else {
      toast.error(data?.status?.message || "something went wrong");
    }
  } catch (error) {
    toast.error(error.message || "something went wrong");
  }
};

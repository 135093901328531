/* eslint-disable react/prop-types */
import React, { useEffect } from "react";
import ApexCharts from "apexcharts";

const DonutChart = ({ getSummaryCount }) => {
  useEffect(() => {
    const patientPercentage = Math.abs(
      (
        getSummaryCount?.total_patients / (getSummaryCount?.new_patients || 1)
      ).toFixed(0) * 100
    );
    const nonPatientPercentage = Math.abs(
      (
        getSummaryCount?.total_non_patient_invoices /
        (getSummaryCount?.new_non_patient_invoices || 1)
      ).toFixed(0) * 100
    );

    const chartElement = document.querySelector("#donut-chart-dash");
    // console.log(patientPercentage, nonPatientPercentage);
    if (chartElement) {
      const donutChartOptions = {
        chart: {
          height: 290,
          type: "donut",
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          pie: {
            startAngle: -90,
            endAngle: 90,
            donut: {
              size: "45%",
              labels: {
                show: false, // Ensures that nothing is shown in the center
              },
            },
          },
        },
        dataLabels: {
          enabled: true, // Enable data labels if you want to show percentages
          formatter: function (val) {
            return val.toFixed(1) + "%"; // Show percentage values
          },
        },
        series: [patientPercentage, nonPatientPercentage],
        labels: ["Patient", "Non Patient"],
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
        legend: {
          position: "bottom",
        },
      };

      try {
        const donut = new ApexCharts(chartElement, donutChartOptions);
        donut.render();
      } catch (error) {
        console.error("Error rendering chart:", error);
      }
    } else {
      console.error("Chart element not found");
    }
  }, [getSummaryCount]);

  return <div id="donut-chart-dash"></div>;
};

export default DonutChart;

import { toast } from "react-toastify";
import { BASE_URL } from "./connectionUrl";

export const getDoctorDayWiseAppointment = async (
  token,
  start_date,
  end_date,
  page,
  per_page
) => {
  try {
    const response = await fetch(
      // eslint-disable-next-line no-undef
      `${BASE_URL}/api/v1/analytics/dashboard/doctors_wise_appointments?start_date=${start_date}&end_date=${end_date}&page=${page}&per_page=${per_page}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      }
    );

    const data = await response.json();
    if (data.status.status === "SUCCESS") {
      return data;
    } else {
      toast.error(data?.status?.message || "something went wrong");
    }
  } catch (error) {
    toast.error(error.message || "something went wrong");
  }
};

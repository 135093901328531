import React, { useState } from "react";
import { Table } from "antd";
import Header from "../Header";
import Sidebar from "../Sidebar";

import {
  imagesend,
  pdficon,
  pdficon3,
  plusicon,
  refreshicon,
  pdficon5,
  searchnormal,
} from "../imagepath";

import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useQuery } from "@tanstack/react-query";
import { generateCsv, handleShowJson } from "../../utils/constant/constant";
import { toast } from "react-toastify";
import { getMedicalTest } from "../../connections/getMedicalTest";
import { putEnableDisableMedicalTest } from "../../connections/putEnableDisableMedicalTest";
import dayjs from "dayjs";
import jsPDF from "jspdf";
import useDebounce from "../../utils/hooks";
const MedicalTest = () => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  // const [deleteId, setDeleteId] = useState({ type: false, id: null });

  const navigate = useNavigate();
  const {
    user: { user },
  } = useSelector((store) => store);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const [medicalInput, setMedicalInput] = useState("");
  const debouncedInputMedicine = useDebounce(medicalInput, 1000);

  const {
    data: medicalTestData,
    isLoading: medicalTestLoader,
    refetch: medicalTestRefetch,
  } = useQuery({
    queryKey: ["getMedicalTest", page, pageSize, debouncedInputMedicine],
    queryFn: () =>
      getMedicalTest(user?.token, null, page, pageSize, debouncedInputMedicine),
    enabled: !!user?.token,

    refetchOnWindowFocus: false, // Don't refetch when window regains focus
    refetchOnReconnect: false, // Don't refetch when reconnecting
  });

  const onSelectChange = (newSelectedRowKeys) => {
    console.log("selectedRowKeys changed: ", selectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const handleMedicalChange = (e) => {
    e.preventDefault();
    setMedicalInput(e?.target?.value);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const handlePageChange = (newPage, newPageSize) => {
    setPage(newPage);
    setPageSize(newPageSize);
    // refetchSuppliersList();
  };
  // const handleDeleteOccupation = async (e) => {
  //   e.preventDefault();

  //   try {
  //     const data = await deleteOccupation(user?.token, deleteId);
  //     if (data.status.status === "SUCCESS") {
  //       toast.success("Occupation Deleted Successfully");
  //       medicalTestRefetch();
  //     }
  //   } catch (error) {
  //     toast.error(error.message);
  //   }
  // };
  const handleEnableDisableMedicalTest = async (value, id) => {
    const data = await putEnableDisableMedicalTest(user?.token, value, id);
    if (data.status.status === "SUCCESS") {
      toast.success(
        `Medical Test ${value ? "Enable" : "Disable"} Successfully`
      );
      medicalTestRefetch();
    }
  };
  const columns = [
    {
      title: "Id",
      dataIndex: "id",
    },
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "Amount",
      dataIndex: "amount",
    },
    {
      title: "Discount",
      dataIndex: "discount",
    },
    {
      title: "Medical Test Category",
      dataIndex: "medical_test_category",
      render: (medical_test_category) => (
        <Link
          to={`/admin/medical_tests_category/${medical_test_category?.id}`}
          style={{ textDecoration: "underline" }}
        >
          {medical_test_category?.name}
        </Link>
      ),
    },
    {
      title: "Female",
      dataIndex: "female_min",
      render: (_, record) => (
        <>
          {record?.female_min}
          {"-"}
          {record?.female_max}
        </>
      ),
    },
    {
      title: "Male",
      dataIndex: "male_min",
      render: (_, record) => (
        <>
          {record?.male_min}
          {"-"}
          {record?.male_max}
        </>
      ),
    },
    {
      title: "Composite",
      dataIndex: "composite",
      render: (composite) => <span>{composite ? "Yes" : "No"}</span>,
    },
    {
      title: "Unit",
      dataIndex: "unit",
    },
    {
      title: "Cost To Lab",
      dataIndex: "cost_to_lab",
    },
    {
      title: "Active",
      dataIndex: "active",
      render: (_, record) => (
        <div className="status-toggle d-flex justify-content-between align-items-center">
          <input
            type="checkbox"
            id={`status_${record?.id}`} // Dynamic ID based on record ID
            className="check"
            checked={record?.active} // Controlled by state
            onChange={async (e) => {
              const newValue = e.target.checked;
              try {
                await handleEnableDisableMedicalTest(newValue, record?.id); // Call API with the correct ID
              } catch (error) {
                toast.error("Failed to update status"); // Handle failure gracefully
              }
            }}
          />
          <label
            htmlFor={`status_${record?.id}`}
            className="checktoggle"
            style={{
              backgroundColor: record?.active ? "green" : "red",
              color: "white !important",
            }}
          >
            Checkbox
          </label>
        </div>
      ),
    },
    {
      title: "",
      dataIndex: "FIELD8",
      render: (_, record) => (
        <>
          <div className="text-end">
            <div className="dropdown dropdown-action">
              <Link
                to="#"
                className="action-icon dropdown-toggle"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i className="fas fa-ellipsis-v" />
              </Link>
              <div className="dropdown-menu dropdown-menu-end">
                <Link
                  className="dropdown-item"
                  to={`/admin/medical_tests/${record?.id}`}
                >
                  <i className="fa-regular fa-eye m-r-5" />
                  View
                </Link>
                <button
                  className="dropdown-item"
                  onClick={() =>
                    navigate("/admin/update-medical_tests", { state: record })
                  }
                >
                  <i className="far fa-edit me-2" />
                  Edit
                </button>

                {/* {record?.active ? (
                  <Link
                    className="dropdown-item"
                    // to="#"
                    data-bs-toggle="modal"
                    data-bs-target="#delete_patient"
                    // onClick={() => {
                    //   handleEnableDisableMedicalTest(false, record?.id);
                    // }}
                    onClick={() => setDeleteId({ type: false, id: record?.id })}
                  >
                    <i className="fa fa-ban" aria-hidden="true"></i> Disable
                  </Link>
                ) : (
                  <Link
                    className="dropdown-item"
                    // to="#"
                    data-bs-toggle="modal"
                    data-bs-target="#delete_patient"
                    onClick={() => setDeleteId({ type: true, id: record?.id })}
                  >
                    <i className="fa fa-toggle-on" aria-hidden="true"></i>{" "}
                    Enable
                  </Link>
                )} */}
              </div>
            </div>
          </div>
        </>
      ),
    },
  ];
  const handleDownloadPdfOccupations = (occupations) => {
    // Return if no data
    if (!occupations || occupations.length === 0) return;

    // const formatDate = (dateString) => {
    //   try {
    //     return dayjs(dateString, "YYYY-MM-DD HH:mm:ss A").format(
    //       "YYYY-MM-DD HH:mm"
    //     );
    //   } catch (e) {
    //     return dateString;
    //   }
    // };

    // Initialize PDF document
    const doc = new jsPDF({
      orientation: "portrait",
      unit: "mm",
      format: "a4",
    });

    // Define table headers with custom widths
    // Define the headers
    const headers = [
      //   { header: "Id", width: 10 },
      { header: "Name", width: 20 },
      { header: "Amount", width: 15 },
      { header: "Discount", width: 15 },
      { header: "Cost to Lab", width: 20 },
      { header: "Unit", width: 10 },
      { header: "Female", width: 20 },
      { header: "Male", width: 20 },
      { header: "Active", width: 10 },
      { header: "Composite", width: 10 },
      { header: "Composite Fields", width: 15 },
      { header: "Lab Name", width: 15 },
      { header: "Medical Test Category", width: 15 },
      //   { header: "Created At", width: 20 },
      //   { header: "Updated At", width: 20 },
    ];

    // Map data with formatting
    const data = occupations.map((occupation) => [
      //   occupation.id || "",
      occupation.name || "",
      occupation.amount || "",
      occupation.discount || "",
      occupation.cost_to_lab || "",
      occupation.unit || "",
      occupation.female_min + "-" + occupation.female_max || "",

      occupation.male_min + "-" + occupation.male_max || "",

      occupation.active ? "Yes" : "No", // Convert boolean to Yes/No
      occupation.composite ? "Yes" : "No", // Convert boolean to Yes/No
      occupation.composite_fields?.join(", ") || "", // Join array into a string
      occupation.lab?.name || "", // Lab name
      occupation.medical_test_category?.name || "", // Medical test category name
      //   formatDate(occupation.created_at),
      //   formatDate(occupation.updated_at),
    ]);

    // Add title
    doc.setFontSize(16);
    doc.setFont(undefined, "bold");
    doc.text("Medical Test List", doc.internal.pageSize.getWidth() / 2, 15, {
      align: "center",
    });

    // Add timestamp
    doc.setFontSize(10);
    doc.setFont(undefined, "normal");
    doc.text(`Generated on: ${dayjs().format("YYYY-MM-DD HH:mm:ss")}`, 14, 22);

    // Configure table
    doc.autoTable({
      startY: 30,
      head: [headers.map((h) => h.header)],
      body: data,
      theme: "grid",
      headStyles: {
        fillColor: [240, 240, 240],
        textColor: [0, 0, 0],
        fontStyle: "bold",
        halign: "center",
        lineWidth: 0.5,
        lineColor: [200, 200, 200],
        fontSize: 10,
      },
      bodyStyles: {
        fontSize: 9,
        lineColor: [200, 200, 200],
        lineWidth: 0.5,
      },
      alternateRowStyles: {
        fillColor: [248, 248, 248],
      },
      columnStyles: headers.reduce((acc, h, i) => {
        acc[i] = { cellWidth: h.width };
        return acc;
      }, {}),
      margin: { top: 30, right: 14, bottom: 20, left: 14 },
      didDrawPage: (data) => {
        // Add page number at the bottom
        doc.setFontSize(10);
        doc.text(
          `Page ${data.pageNumber} of ${data.pageCount}`,
          doc.internal.pageSize.getWidth() / 2,
          doc.internal.pageSize.getHeight() - 10,
          { align: "center" }
        );
      },
    });

    // Save with formatted filename including timestamp
    const filename = `Medical_test${dayjs().format("YYYYMMDD_HHmmss")}.pdf`;
    doc.save(filename);
  };

  return (
    <>
      <Header />
      <Sidebar activeClassName="medical-test-list" />
      <div className="page-wrapper">
        <div className="content">
          {/* Page Header */}
          <div className="page-header">
            <div className="row">
              <div className="col-sm-12">
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="#">Medical Test </Link>
                  </li>
                  <li className="breadcrumb-item">
                    <i className="feather-chevron-right" />
                  </li>
                  <li className="breadcrumb-item active">Medical Test List</li>
                </ul>
              </div>
            </div>
          </div>
          {/* /Page Header */}
          <div className="row">
            <div className="col-sm-12">
              <div className="card card-table show-entire">
                <div className="card-body">
                  {/* Table Header */}
                  <div className="page-table-header mb-2">
                    <div className="row align-items-center">
                      <div className="col">
                        <div className="doctor-table-blk">
                          <h3>Medical Test List</h3>
                          <div className="top-nav-search table-search-blk">
                            <span
                              style={{ width: "100%", position: "relative" }}
                            >
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Search here"
                                value={medicalInput}
                                onChange={handleMedicalChange}
                              />
                              <Link className="btn">
                                <img src={searchnormal} alt="#" />
                              </Link>
                            </span>
                          </div>
                          <div className="doctor-search-blk">
                            <div className="add-group">
                              <Link
                                to="/admin/add-medical_tests"
                                className="btn btn-primary add-pluss ms-2"
                              >
                                <img src={plusicon} alt="#" />
                              </Link>
                              <Link
                                onClick={medicalTestRefetch}
                                className="btn btn-primary doctor-refresh ms-2"
                              >
                                <img src={refreshicon} alt="#" />
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-auto text-end float-end ms-auto download-grp">
                        {/* <Link id="download-pdf" to="#" className=" me-2"> */}
                        <img
                          className=" me-2"
                          onClick={() =>
                            handleDownloadPdfOccupations(
                              medicalTestData?.payload?.medical_tests
                            )
                          }
                          src={pdficon}
                          alt="#"
                          style={{ cursor: "pointer" }}
                        />
                        {/* </Link> */}
                        {/* <Link
                           
                            id="download-csv"
                            className=" me-2"
                          > */}
                        <img
                          onClick={() =>
                            generateCsv(medicalTestData?.payload?.medical_tests)
                          }
                          className=" me-2"
                          src={pdficon3}
                          alt="#"
                          style={{ cursor: "pointer" }}
                        />
                        {/* </Link> */}
                        {/* <Link id="show-json" to="#"> */}
                        <img
                          onClick={() =>
                            handleShowJson(
                              medicalTestData?.payload?.medical_tests
                            )
                          }
                          width={"25px"}
                          height={"26px"}
                          src={pdficon5}
                          alt="#"
                          style={{ cursor: "pointer" }}
                        />
                        {/* </Link> */}
                      </div>
                    </div>
                  </div>
                  {/* /Table Header */}
                  <div className="table-responsive doctor-list">
                    <Table
                      loading={medicalTestLoader}
                      pagination={{
                        current: page,
                        pageSize: pageSize,
                        total: medicalTestData?.meta?.total_count,
                        showTotal: (total, range) =>
                          `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                        onChange: handlePageChange,
                      }}
                      columns={columns}
                      dataSource={medicalTestData?.payload?.medical_tests}
                      rowSelection={rowSelection}
                      rowKey={(record) => record.id}
                      scroll={{ x: "max-content" }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="delete_patient"
        className="modal fade delete-modal"
        role="dialog"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body text-center">
              <img src={imagesend} alt="#" width={50} height={46} />
              <h3>
                {/* Are you sure want to {deleteId?.type ? "Enable" : "Disable"}{" "}
                this ? */}
              </h3>
              <div className="m-t-20">
                {" "}
                <Link
                  to="#"
                  className="btn btn-white me-2"
                  data-bs-dismiss="modal"
                >
                  Close
                </Link>
                <button
                  // onClick={() =>
                  //   handleEnableDisableMedicalTest(deleteId?.type, deleteId?.id)
                  // }
                  type="submit"
                  className="btn btn-danger"
                  data-bs-dismiss="modal"
                >
                  {/* {deleteId?.type ? "Enable" : "Disable"} */}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MedicalTest;

import React, { useState } from "react";
import { Table } from "antd";
import Header from "../Header";
import Sidebar from "../Sidebar";

import {
  imagesend,
  pdficon,
  pdficon3,
  plusicon,
  refreshicon,
  pdficon5,
} from "../imagepath";

import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useQuery } from "@tanstack/react-query";
import { generateCsv, handleShowJson } from "../../utils/constant/constant";
import { toast } from "react-toastify";
import { getOrgSettings } from "../../connections/getOrgSettings";
import { deleteOrgSettings } from "../../connections/deleteOrgSettings";
import jsPDF from "jspdf";
import dayjs from "dayjs";
import { Dropdown, Menu } from "antd";

const OrgSettings = () => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [deleteId, setDeleteId] = useState(null);

  const navigate = useNavigate();
  const {
    user: { user },
  } = useSelector((store) => store);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(50);

  const {
    data: OrgListData,
    isLoading: OrgListLoader,
    refetch: OrgListRefetch,
  } = useQuery({
    queryKey: ["getOrgSettings", page, pageSize],
    queryFn: () => getOrgSettings(user?.token, null, page, pageSize),
    enabled: !!user?.token,

    refetchOnWindowFocus: false, // Don't refetch when window regains focus
    refetchOnReconnect: false, // Don't refetch when reconnecting
  });
  const onSelectChange = (newSelectedRowKeys) => {
    console.log("selectedRowKeys changed: ", selectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const columns = [
    {
      title: "Organization",
      dataIndex: "organization",
      render: (organization) => (
        <Link
          to={"/admin/organization"}
          style={{ textDecoration: "underline" }}
        >
          {organization?.name}
        </Link>
      ),
    },
    {
      title: "Page Type",
      dataIndex: "page_type",
    },
    {
      title: "Is Active",
      dataIndex: "is_active",
      render: (text) => (
        <span
          className={
            text ? "custom-badge status-green" : "custom-badge status-pink"
          }
        >
          {" "}
          {text ? "True" : "False"}
        </span>
      ),
    },
    {
      title: "Header Height",
      dataIndex: "header_height",
    },
    {
      title: "Footer Height",
      dataIndex: "footer_height",
    },
    {
      title: "Review Duration",
      dataIndex: "review_duration",
    },
    // {
    //   title: "",
    //   dataIndex: "FIELD8",
    //   render: (_, record) => (
    //     <>
    //       <div className="text-end">
    //         <div className="dropdown dropdown-action">
    //           <Link
    //             to="#"
    //             className="action-icon dropdown-toggle"
    //             data-bs-toggle="dropdown"
    //             aria-expanded="false"
    //           >
    //             <i className="fas fa-ellipsis-v" />
    //           </Link>
    //           <div
    //             id="dropdownParent"
    //             style={{
    //               top: "-100px !important",
    //               right: "20px !important",
    //             }}
    //             className="dropdown-menu dropdown-menu-end"
    //           >
    //             <Link
    //               className="dropdown-item"
    //               to={`/admin/occupation/${record?.id}`}
    //             >
    //               <i className="fa-regular fa-eye m-r-5" />
    //               View
    //             </Link>
    //             <button
    //               className="dropdown-item"
    //               onClick={() =>
    //                 navigate("/edit_occupations/", { state: record })
    //               }
    //             >
    //               <i className="far fa-edit me-2" />
    //               Edit
    //             </button>
    //             <Link
    //               className="dropdown-item"
    //               onClick={() => setDeleteId(record.id)}
    //               data-bs-toggle="modal"
    //               data-bs-target="#delete_patient"
    //             >
    //               <i className="fa fa-trash-alt m-r-5"></i> Delete
    //             </Link>
    //           </div>
    //         </div>
    //       </div>
    //     </>
    //   ),
    // },
    {
      title: "",
      dataIndex: "FIELD8",
      render: (_, record) => (
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item key="view">
                <Link to={`/admin/organization_settings/${record?.id}`}>
                  <i className="fa-regular fa-eye m-r-5" /> View
                </Link>
              </Menu.Item>
              <Menu.Item
                key="edit"
                onClick={() =>
                  navigate("/admin/update-organization_settings", {
                    state: record,
                  })
                }
              >
                <i className="far fa-edit me-2" /> Edit
              </Menu.Item>
              <Menu.Item
                className="dropdown-item"
                to="#"
                data-bs-toggle="modal"
                data-bs-target="#delete_patient"
                key="delete"
                onClick={() => setDeleteId(record?.id)}
              >
                <i className="fa fa-trash-alt m-r-5" /> Delete
              </Menu.Item>
            </Menu>
          }
          trigger={["click"]}
          placement="bottomRight"
        >
          <Link
            to="#"
            style={{ marginRight: 5 }}
            className="action-icon"
            // data-bs-toggle="dropdown"
            // aria-expanded="false"
          >
            <i className="fas fa-ellipsis-v" />
          </Link>
        </Dropdown>
      ),
    },
  ];
  const handlePageChange = (newPage, newPageSize) => {
    setPage(newPage);
    setPageSize(newPageSize);
    // refetchSuppliersList();
  };
  const handleDeleteData = async (e) => {
    e.preventDefault();
    try {
      const data = await deleteOrgSettings(user?.token, deleteId);
      if (data.status.status === "SUCCESS") {
        toast.success("Organization Settings Deleted Successfully");
        OrgListRefetch();
      }
    } catch (error) {
      toast.error(error.message);
    }
  };
  const downloadPdf = (patients) => {
    // Return if no data
    if (!patients || patients.length === 0) return;

    // Initialize PDF document
    const doc = new jsPDF({
      unit: "mm",
      format: "a4",
    });

    // Define table headers with custom widths
    const headers = [
      { header: "Organization", width: 50 },
      { header: "Page Type", width: 25 },
      { header: "Is Active", width: 25 },
      { header: "Header Height", width: 25 },
      { header: "Footer Height", width: 25 },
      { header: "Review Duration", width: 30 },
    ];

    // Map data with formatting
    const data = patients?.map((item) => [
      item?.organization?.name,
      item?.page_type,
      item?.is_active ? "Yes" : "No",
      item.header_height,
      item.footer_height,
      item?.review_duration,
    ]);

    // Add title
    doc.setFontSize(14);
    doc.setFont(undefined, "bold");
    doc.text(
      "Organization Settings List",
      doc.internal.pageSize.getWidth() / 2,
      15,
      {
        align: "center",
      }
    );

    // Add timestamp
    doc.setFontSize(10);
    doc.setFont(undefined, "normal");
    doc.text(`Generated on: ${dayjs().format("YYYY-MM-DD HH:mm:ss")}`, 14, 22);

    // Configure table
    doc.autoTable({
      startY: 30,
      head: [headers.map((h) => h.header)],
      body: data,
      theme: "grid",
      headStyles: {
        fillColor: [240, 240, 240],
        textColor: [0, 0, 0],
        fontStyle: "bold",
        align: "center",
        lineWidth: 0.5,
        lineColor: [200, 200, 200],
        fontSize: 8,
      },
      bodyStyles: {
        fontSize: 8,
        lineColor: [200, 200, 200],
        lineWidth: 0.5,
      },
      alternateRowStyles: {
        fillColor: [248, 248, 248],
      },
      columnStyles: headers.reduce((acc, h, i) => {
        acc[i] = { cellWidth: h.width };
        return acc;
      }, {}),
      margin: { top: 30, right: 14, bottom: 20, left: 14 },
      didDrawPage: (data) => {
        // Add page number at the bottom
        doc.setFontSize(10);
        doc.text(
          `Page ${data.pageNumber} of ${data.pageCount}`,
          doc.internal.pageSize.getWidth() / 2,
          doc.internal.pageSize.getHeight() - 10,
          { align: "center" }
        );
      },
    });

    // Save with formatted filename including timestamp
    const filename = `Organization-Settings${dayjs().format(
      "YYYYMMDD_HHmmss"
    )}.pdf`;
    doc.save(filename);
  };
  return (
    <>
      <Header />
      <Sidebar activeClassName="origination-settings-list" />
      <div className="page-wrapper">
        <div className="content">
          {/* Page Header */}
          <div className="page-header">
            <div className="row">
              <div className="col-sm-12">
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="#">Organization Settings</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <i className="feather-chevron-right" />
                  </li>
                  <li className="breadcrumb-item active">
                    Organization Settings List
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="settings-menu-links">
            <ul className="nav nav-tabs menu-tabs">
              <li className="nav-item ">
                <Link className="nav-link " to="/admin/organization">
                  Organization Details
                </Link>
              </li>
              <li className="nav-item active">
                <Link className="nav-link" to="/admin/organization_settings">
                  Organization Settings
                </Link>
              </li>
            </ul>
          </div>
          {/* /Page Header */}
          <div className="row">
            <div className="col-sm-12">
              <div className="card card-table show-entire">
                <div className="card-body">
                  {/* Table Header */}
                  <div className="page-table-header mb-2">
                    <div className="row align-items-center">
                      <div className="col">
                        <div className="doctor-table-blk">
                          <h3>Organization Settings List</h3>
                          <div className="doctor-search-blk">
                            <div className="add-group">
                              <Link
                                to="/admin/add-organization_settings"
                                className="btn btn-primary add-pluss ms-2"
                              >
                                <img src={plusicon} alt="#" />
                              </Link>
                              <Link
                                onClick={OrgListRefetch}
                                className="btn btn-primary doctor-refresh ms-2"
                              >
                                <img src={refreshicon} alt="#" />
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-auto text-end float-end ms-auto download-grp">
                        {/* <Link id="download-pdf" to="#" className=" me-2"> */}
                        <img
                          className=" me-2"
                          onClick={() =>
                            downloadPdf(
                              OrgListData?.payload?.organization_settings
                            )
                          }
                          src={pdficon}
                          alt="#"
                          style={{ cursor: "pointer" }}
                        />
                        {/* </Link> */}
                        {/* <Link
                           
                            id="download-csv"
                            className=" me-2"
                          > */}
                        <img
                          onClick={() =>
                            generateCsv(
                              OrgListData?.payload?.organization_settings
                            )
                          }
                          className=" me-2"
                          src={pdficon3}
                          alt="#"
                          style={{ cursor: "pointer" }}
                        />
                        {/* </Link> */}
                        {/* <Link id="show-json" to="#"> */}
                        <img
                          onClick={() =>
                            handleShowJson(
                              OrgListData?.payload?.organization_settings
                            )
                          }
                          width={"25px"}
                          height={"26px"}
                          src={pdficon5}
                          alt="#"
                          style={{ cursor: "pointer" }}
                        />
                        {/* </Link> */}
                      </div>
                    </div>
                  </div>
                  {/* /Table Header */}
                  <div className="table-responsive doctor-list">
                    <Table
                      loading={OrgListLoader}
                      pagination={{
                        current: page,
                        pageSize: pageSize,
                        total: OrgListData?.meta?.total_count,
                        showTotal: (total, range) =>
                          `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                        onChange: handlePageChange,
                      }}
                      columns={columns}
                      dataSource={OrgListData?.payload?.organization_settings}
                      rowSelection={rowSelection}
                      rowKey={(record) => record.id}
                      scroll={{ x: "max-content" }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="notification-box">
          <div className="msg-sidebar notifications msg-noti">
            <div className="topnav-dropdown-header">
              <span>Messages</span>
            </div>
            <div className="drop-scroll msg-list-scroll" id="msg_list">
              <ul className="list-box">
                <li>
                  <Link to="#">
                    <div className="list-item">
                      <div className="list-left">
                        <span className="avatar">R</span>
                      </div>
                      <div className="list-body">
                        <span className="message-author">Richard Miles </span>
                        <span className="message-time">12:28 AM</span>
                        <div className="clearfix" />
                        <span className="message-content">
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                        </span>
                      </div>
                    </div>
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <div className="list-item new-message">
                      <div className="list-left">
                        <span className="avatar">J</span>
                      </div>
                      <div className="list-body">
                        <span className="message-author">John Doe</span>
                        <span className="message-time">1 Aug</span>
                        <div className="clearfix" />
                        <span className="message-content">
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                        </span>
                      </div>
                    </div>
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <div className="list-item">
                      <div className="list-left">
                        <span className="avatar">T</span>
                      </div>
                      <div className="list-body">
                        <span className="message-author">
                          {" "}
                          Tarah Shropshire{" "}
                        </span>
                        <span className="message-time">12:28 AM</span>
                        <div className="clearfix" />
                        <span className="message-content">
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                        </span>
                      </div>
                    </div>
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <div className="list-item">
                      <div className="list-left">
                        <span className="avatar">M</span>
                      </div>
                      <div className="list-body">
                        <span className="message-author">Mike Litorus</span>
                        <span className="message-time">12:28 AM</span>
                        <div className="clearfix" />
                        <span className="message-content">
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                        </span>
                      </div>
                    </div>
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <div className="list-item">
                      <div className="list-left">
                        <span className="avatar">C</span>
                      </div>
                      <div className="list-body">
                        <span className="message-author">
                          {" "}
                          Catherine Manseau{" "}
                        </span>
                        <span className="message-time">12:28 AM</span>
                        <div className="clearfix" />
                        <span className="message-content">
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                        </span>
                      </div>
                    </div>
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <div className="list-item">
                      <div className="list-left">
                        <span className="avatar">D</span>
                      </div>
                      <div className="list-body">
                        <span className="message-author">
                          {" "}
                          Domenic Houston{" "}
                        </span>
                        <span className="message-time">12:28 AM</span>
                        <div className="clearfix" />
                        <span className="message-content">
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                        </span>
                      </div>
                    </div>
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <div className="list-item">
                      <div className="list-left">
                        <span className="avatar">B</span>
                      </div>
                      <div className="list-body">
                        <span className="message-author"> Buster Wigton </span>
                        <span className="message-time">12:28 AM</span>
                        <div className="clearfix" />
                        <span className="message-content">
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                        </span>
                      </div>
                    </div>
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <div className="list-item">
                      <div className="list-left">
                        <span className="avatar">R</span>
                      </div>
                      <div className="list-body">
                        <span className="message-author"> Rolland Webber </span>
                        <span className="message-time">12:28 AM</span>
                        <div className="clearfix" />
                        <span className="message-content">
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                        </span>
                      </div>
                    </div>
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <div className="list-item">
                      <div className="list-left">
                        <span className="avatar">C</span>
                      </div>
                      <div className="list-body">
                        <span className="message-author"> Claire Mapes </span>
                        <span className="message-time">12:28 AM</span>
                        <div className="clearfix" />
                        <span className="message-content">
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                        </span>
                      </div>
                    </div>
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <div className="list-item">
                      <div className="list-left">
                        <span className="avatar">M</span>
                      </div>
                      <div className="list-body">
                        <span className="message-author">Melita Faucher</span>
                        <span className="message-time">12:28 AM</span>
                        <div className="clearfix" />
                        <span className="message-content">
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                        </span>
                      </div>
                    </div>
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <div className="list-item">
                      <div className="list-left">
                        <span className="avatar">J</span>
                      </div>
                      <div className="list-body">
                        <span className="message-author">Jeffery Lalor</span>
                        <span className="message-time">12:28 AM</span>
                        <div className="clearfix" />
                        <span className="message-content">
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                        </span>
                      </div>
                    </div>
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <div className="list-item">
                      <div className="list-left">
                        <span className="avatar">L</span>
                      </div>
                      <div className="list-body">
                        <span className="message-author">Loren Gatlin</span>
                        <span className="message-time">12:28 AM</span>
                        <div className="clearfix" />
                        <span className="message-content">
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                        </span>
                      </div>
                    </div>
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <div className="list-item">
                      <div className="list-left">
                        <span className="avatar">T</span>
                      </div>
                      <div className="list-body">
                        <span className="message-author">Tarah Shropshire</span>
                        <span className="message-time">12:28 AM</span>
                        <div className="clearfix" />
                        <span className="message-content">
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                        </span>
                      </div>
                    </div>
                  </Link>
                </li>
              </ul>
            </div>
            <div className="topnav-dropdown-footer">
              <Link to="#">See all messages</Link>
            </div>
          </div>
        </div>
      </div>
      <div
        id="delete_patient"
        className="modal fade delete-modal"
        role="dialog"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body text-center">
              <img src={imagesend} alt="#" width={50} height={46} />
              <h3>Are you sure want to delete this ?</h3>
              <div className="m-t-20">
                {" "}
                <Link
                  to="#"
                  className="btn btn-white me-2"
                  data-bs-dismiss="modal"
                >
                  Close
                </Link>
                <button
                  onClick={handleDeleteData}
                  type="submit"
                  className="btn btn-danger"
                  data-bs-dismiss="modal"
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OrgSettings;

import React, { useState } from "react";
import { Dropdown, Menu, Table } from "antd";
import Header from "../../Header";
import Sidebar from "../../Sidebar";
import {
  imagesend,
  pdficon,
  pdficon3,
  plusicon,
  refreshicon,
  pdficon5,
} from "../../imagepath";
// import { onShowSizeChange, itemRender } from "../Pagination";
import { Link, useNavigate } from "react-router-dom";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { useSelector } from "react-redux";
import { useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import { generateCsv, handleShowJson } from "../../../utils/constant/constant";
import { toast } from "react-toastify";
import jsPDF from "jspdf";
import { getTreatment } from "../../../connections/getTreatment";
import { deleteTreatment } from "../../../connections/deleteTreatment";

const Treatment = () => {
  const [deleteId, setDeleteId] = useState("");
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(50);

  const {
    user: { user },
  } = useSelector((store) => store);
  const navigate = useNavigate();
  const {
    data: treatmentData,
    isLoading: treatmentLoader,
    refetch: treatmentRefetch,
  } = useQuery({
    queryKey: ["getTreatment", page, pageSize],
    queryFn: () => getTreatment(user?.token, null, page, pageSize),
    refetchOnWindowFocus: false, // Don't refetch when window regains focus
    refetchOnReconnect: false, // Don't refetch when reconnecting
  });

  const onSelectChange = (newSelectedRowKeys) => {
    console.log("selectedRowKeys changed: ", selectedRowKeys);

    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const columns = [
    {
      title: "Id",
      dataIndex: "id",
    },

    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "Diagnosis",
      dataIndex: "diagnosis",
      render: (diagnosis) => (
        <span
          style={{
            whiteSpace: "normal",
            wordBreak: "break-word",
            maxWidth: "150px",
            display: "block",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {diagnosis?.length > 0 ? diagnosis?.join("    ") : null}
        </span>
      ),
    },
    {
      title: "Medical Tests",
      dataIndex: "medical_tests",
      render: (medical_tests) => (
        <span
          style={{
            whiteSpace: "normal",
            wordBreak: "break-word",
            maxWidth: "350px",
            display: "block",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {medical_tests?.length > 0 ? medical_tests?.join(",    ") : null}
        </span>
      ),
    },
    {
      title: "Special Instructions",
      dataIndex: "special_instructions",
      render: (special_instructions) => (
        <span
          style={{
            whiteSpace: "normal",
            wordBreak: "break-word",
            maxWidth: "250px",
            display: "block",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {special_instructions?.length > 0
            ? special_instructions?.join(",    ")
            : null}
        </span>
      ),
    },
    {
      title: "Self Notes",
      dataIndex: "self_notes",
    },
    {
      title: "Created At",
      dataIndex: "created_at",
      render: (date) => (
        <span
          style={{
            whiteSpace: "normal",
            wordBreak: "break-word",
            maxWidth: "150px",
            display: "block",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {dayjs(date, "YYYY-MM-DD HH:mm:ss A").format("MMMM DD, YYYY HH:mm")}
        </span>
      ),
    },
    {
      title: "Updated At",
      dataIndex: "updated_at",
      render: (date) => (
        <span
          style={{
            whiteSpace: "normal",
            wordBreak: "break-word",
            maxWidth: "150px",
            display: "block",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {dayjs(date, "YYYY-MM-DD HH:mm:ss A").format("MMMM DD, YYYY HH:mm")}
        </span>
      ),
    },
    {
      title: "",
      dataIndex: "FIELD8",
      render: (_, record) => (
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item key="view">
                <Link to={`/admin/prescription/treatments/${record?.id}`}>
                  <i className="fa-regular fa-eye m-r-5" /> View
                </Link>
              </Menu.Item>
              <Menu.Item
                key="edit"
                onClick={() =>
                  navigate("/admin/prescription/update-treatments", {
                    state: record,
                  })
                }
              >
                <i className="far fa-edit me-2" /> Edit
              </Menu.Item>
              <Menu.Item
                className="dropdown-item"
                to="#"
                data-bs-toggle="modal"
                data-bs-target="#delete_patient"
                key="delete"
                onClick={() => setDeleteId(record?.id)}
              >
                <i className="fa fa-trash-alt m-r-5" /> Delete
              </Menu.Item>
            </Menu>
          }
          trigger={["click"]}
          placement="bottomRight"
        >
          <Link
            to="#"
            style={{ marginRight: 5 }}
            className="action-icon"
            // data-bs-toggle="dropdown"
            // aria-expanded="false"
          >
            <i className="fas fa-ellipsis-v" />
          </Link>
        </Dropdown>
      ),
    },
  ];
  const handleDeleteTreatment = async () => {
    try {
      const data = await deleteTreatment(user?.token, deleteId);
      if (data.status.status === "SUCCESS") {
        toast.success("Treatment Deleted Successfully", deleteId);
        treatmentRefetch();
        setDeleteId(null);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };
  const handlePageChange = (newPage, newPageSize) => {
    setPage(newPage);
    setPageSize(newPageSize);
    // treatmentLoader();
  };
  const downloadPdf = (patients) => {
    // Return if no data
    if (!patients || patients.length === 0) return;

    // Initialize PDF document
    const doc = new jsPDF({
      unit: "mm",
      format: "a4",
    });

    // Define table headers with custom widths
    const headers = [
      { header: "Id", width: 10 },
      { header: "Name", width: 15 },
      { header: "Self Notes", width: 20 },
      { header: "Diagnosis", width: 20 },
      { header: "Special Instructions", width: 20 },
      { header: "Medical Tests", width: 50 },
      { header: "Created At", width: 25 },
      { header: "Updated At", width: 25 },
    ];

    // Map data with formatting
    const data = patients?.map((item) => [
      item.id,
      item.name,
      item.self_notes,
      item.diagnosis.join(", "), // Join the diagnosis array into a string
      item.special_instructions.join(", "), // Join the special instructions array into a string
      item.medical_tests.join(", "), // Join the medical tests array into a string
      item.created_at,
      item.updated_at,
    ]);

    // Add title
    doc.setFontSize(14);
    doc.setFont(undefined, "bold");
    doc.text("Treatment List", doc.internal.pageSize.getWidth() / 2, 15, {
      align: "center",
    });

    // Add timestamp
    doc.setFontSize(10);
    doc.setFont(undefined, "normal");
    doc.text(`Generated on: ${dayjs().format("YYYY-MM-DD HH:mm:ss")}`, 14, 22);

    // Configure table
    doc.autoTable({
      startY: 30,
      head: [headers.map((h) => h.header)],
      body: data,
      theme: "grid",
      headStyles: {
        fillColor: [240, 240, 240],
        textColor: [0, 0, 0],
        fontStyle: "bold",
        align: "center",
        lineWidth: 0.5,
        lineColor: [200, 200, 200],
        fontSize: 8,
      },
      bodyStyles: {
        fontSize: 6,
        lineColor: [200, 200, 200],
        lineWidth: 0.5,
      },
      alternateRowStyles: {
        fillColor: [248, 248, 248],
      },
      columnStyles: headers.reduce((acc, h, i) => {
        acc[i] = { cellWidth: h.width };
        return acc;
      }, {}),
      margin: { top: 30, right: 14, bottom: 20, left: 14 },
      didDrawPage: (data) => {
        // Add page number at the bottom
        doc.setFontSize(10);
        doc.text(
          `Page ${data.pageNumber} of ${data.pageCount}`,
          doc.internal.pageSize.getWidth() / 2,
          doc.internal.pageSize.getHeight() - 10,
          { align: "center" }
        );
      },
    });

    // Save with formatted filename including timestamp
    const filename = `Treatment${dayjs().format("YYYYMMDD_HHmmss")}.pdf`;
    doc.save(filename);
  };

  return (
    <>
      <Header />
      <Sidebar activeClassName="treatments-dashboard" />
      <>
        <div className="page-wrapper">
          <div className="content">
            {/* Page Header */}
            <div className="page-header">
              <div className="row">
                <div className="col-sm-12">
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="#">Treatment</Link>
                    </li>
                    <li className="breadcrumb-item">
                      <i className="feather-chevron-right">
                        <FeatherIcon icon="chevron-right" />
                      </i>
                    </li>
                    <li className="breadcrumb-item active">Treatment List</li>
                  </ul>
                </div>
              </div>
            </div>
            {/* /Page Header */}
            <div className="row">
              <div className="col-sm-12">
                <div className="card card-table show-entire">
                  <div className="card-body">
                    {/* Table Header */}
                    <div className="page-table-header mb-2">
                      <div className="row align-items-center">
                        <div className="col">
                          <div className="doctor-table-blk">
                            <h3>Treatment List</h3>
                            <div className="doctor-search-blk">
                              <div className="add-group">
                                <Link
                                  to="/admin/prescription/add-treatments"
                                  className="btn btn-primary add-pluss ms-2"
                                >
                                  <img src={plusicon} alt="#" />
                                </Link>
                                <Link
                                  onClick={treatmentRefetch}
                                  className="btn btn-primary add-pluss ms-2"
                                >
                                  <img src={refreshicon} alt="#" />
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-auto text-end float-end ms-auto download-grp">
                          {/* <Link id="download-pdf" to="#" className=" me-2"> */}
                          <img
                            className=" me-2"
                            onClick={() =>
                              downloadPdf(treatmentData?.payload?.treatments)
                            }
                            src={pdficon}
                            alt="#"
                            style={{ cursor: "pointer" }}
                          />
                          {/* </Link> */}
                          {/* <Link
                           
                            id="download-csv"
                            className=" me-2"
                          > */}
                          <img
                            onClick={() =>
                              generateCsv(treatmentData?.payload?.treatments)
                            }
                            className=" me-2"
                            src={pdficon3}
                            alt="#"
                            style={{ cursor: "pointer" }}
                          />
                          {/* </Link> */}
                          {/* <Link id="show-json" to="#"> */}
                          <img
                            onClick={() =>
                              handleShowJson(treatmentData?.payload?.treatments)
                            }
                            width={"25px"}
                            height={"26px"}
                            src={pdficon5}
                            alt="#"
                            style={{ cursor: "pointer" }}
                          />
                          {/* </Link> */}
                        </div>
                      </div>
                    </div>
                    {/* /Table Header */}
                    <div className="table-responsive doctor-list">
                      <Table
                        loading={treatmentLoader}
                        pagination={{
                          current: page,
                          pageSize: pageSize,
                          total: treatmentData?.meta?.total_count,
                          showTotal: (total, range) =>
                            `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                          onChange: handlePageChange,
                        }}
                        columns={columns}
                        dataSource={treatmentData?.payload?.treatments}
                        rowSelection={rowSelection}
                        rowKey={(record) => record.id}
                        scroll={{ x: "max-content" }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          id="delete_patient"
          className="modal fade delete-modal"
          role="dialog"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body text-center">
                <img src={imagesend} alt="#" width={50} height={46} />
                <h3>Are you sure want to delete this ?</h3>
                <div className="m-t-20">
                  {" "}
                  <Link
                    to="#"
                    className="btn btn-white me-2"
                    data-bs-dismiss="modal"
                  >
                    Close
                  </Link>
                  <button
                    onClick={handleDeleteTreatment}
                    type="submit"
                    className="btn btn-danger"
                    data-bs-dismiss="modal"
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div
            id="delete_patient"
            className="modal fade delete-modal"
            role="dialog"
          >
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-body text-center">
                  <img src={imagesend} alt="#" width={50} height={46} />
                  <h3>Are you sure want to delete this ?</h3>
                  <div className="m-t-20">
                    {" "}
                    <Link
                      to="#"
                      className="btn btn-white me-2"
                      data-bs-dismiss="modal"
                    >
                      Close
                    </Link>
                    <button
                      data-bs-dismiss="modal"
                      type="submit"
                      className="btn btn-danger"
                    >
                      Delete
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </>
  );
};

export default Treatment;

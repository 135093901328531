import React from "react";
import Header from "../Header";
import Sidebar from "../Sidebar";
import { Link, useParams } from "react-router-dom";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { useQuery } from "@tanstack/react-query";

import { useSelector } from "react-redux";

import { Skeleton } from "@mui/material";

import dayjs from "dayjs";
// import { Table } from "antd";
import { getRevenueShareDetails } from "../../connections/getRevenueShareDetails";

const ShowRevenueShare = () => {
  const {
    user: { user },
  } = useSelector((store) => store);
  const { id } = useParams();
  const { data: revenueShareData, isLoading } = useQuery({
    queryKey: ["getShowConsumable", id],
    queryFn: () => getRevenueShareDetails(user?.token, id),
    enabled: !!id,
    cacheTime: 1000 * 60 * 60,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchOnMount: false,
  });
  // const columns = [
  //   {
  //     title: "Id",
  //     dataIndex: "id",
  //   },
  //   {
  //     title: "Specialist",
  //     dataIndex: "specialist",
  //     render: (specialist) => (
  //       <Link
  //         to={`/admin/doctor/${specialist?.id}`}
  //         style={{ textDecoration: "underline" }}
  //       >
  //         {specialist?.first_name ? specialist?.first_name : ""}{" "}
  //         {specialist?.last_name ? specialist?.last_name : ""}
  //       </Link>
  //     ),
  //   },
  //   {
  //     title: "Value",
  //     dataIndex: "value",
  //   },

  //   {
  //     title: "Department",
  //     dataIndex: "department",
  //     render: (department) => (
  //       <Link
  //         to={`/department_details/${department?.id}`}
  //         style={{ textDecoration: "underline" }}
  //       >
  //         {department?.name}
  //       </Link>
  //     ),
  //   },
  //   {
  //     title: "Created At",
  //     dataIndex: "created_at",
  //     //   sorter: (a, b) => a.ConsultingDoctor.length - b.ConsultingDoctor.length,
  //     render: (date) => (
  //       <>
  //         {" "}
  //         {dayjs(date, "YYYY-MM-DD HH:mm:ss A").format("MMMM DD, YYYY HH:mm")}
  //       </>
  //     ),
  //   },
  //   {
  //     title: "Updated At",
  //     dataIndex: "updated_at",
  //     //   sorter: (a, b) => a.Treatment.length - b.Treatment.length,
  //     render: (date) => (
  //       <>
  //         {" "}
  //         {dayjs(date, "YYYY-MM-DD HH:mm:ss A").format("MMMM DD, YYYY HH:mm")}
  //       </>
  //     ),
  //   },
  // ];
  return (
    <>
      <Header />
      <Sidebar activeClassName="revenue-shares-list" />
      <>
        <div className="page-wrapper">
          <div className="content">
            {/* Page Header */}
            <div className="page-header">
              <div className="row">
                <div className="col-sm-12">
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item active">
                      <Link to={-1}>Revenue Share Details</Link>
                    </li>
                    <li className="breadcrumb-item">
                      <i className="feather-chevron-right">
                        <FeatherIcon icon="chevron-right" />
                      </i>
                    </li>
                    <li className="breadcrumb-item active">{id}</li>
                  </ul>
                </div>
              </div>
            </div>
            {/* /Page Header */}
            <div className="row">
              <div className="col-sm-12">
                <div className="card card-table show-entire">
                  <div className="card-body">
                    {/* content Header */}
                    <div className="page-table-header mb-2">
                      <div className="row align-items-center">
                        <div className="col">
                          <div className="doctor-table-blk">
                            <h3>Revenue Share Details</h3>
                            <div className="doctor-search-blk"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {isLoading ? (
                      <div>
                        <Skeleton
                          variant="rectangular"
                          width="100%"
                          height={430}
                        />
                      </div>
                    ) : (
                      <div className="content-details">
                        <div className="col-lg-12">
                          <div className="card-box">
                            <div className="card-block">
                              <ul className="personal-info">
                                <li className="pb-1">
                                  <span className="title">Id:</span>
                                  <span className="text">
                                    {revenueShareData?.id || "N/A"}
                                  </span>
                                </li>
                                <li className="pb-1">
                                  <span className="title">Specialist:</span>
                                  <span className="text">
                                    {revenueShareData?.specialist?.first_name}{" "}
                                    {revenueShareData?.specialist?.last_name
                                      ? revenueShareData?.specialist?.last_name
                                      : "N/A"}
                                  </span>
                                </li>
                                <li className="pb-1">
                                  <span className="title">Value:</span>
                                  <span className="text">
                                    {revenueShareData?.value || "N/A"}
                                  </span>
                                </li>
                                <li className="pb-1">
                                  <span className="title">Department:</span>
                                  <span className="text">
                                    {revenueShareData?.department?.name ? (
                                      <Link
                                        to={`/department_details/${revenueShareData?.department?.id}`}
                                        style={{ textDecoration: "underline" }}
                                      >
                                        {revenueShareData?.department?.name}
                                      </Link>
                                    ) : (
                                      "N/A"
                                    )}
                                  </span>
                                </li>
                                <li className="pb-1">
                                  <span className="title">Created At:</span>
                                  <span className="text">
                                    {dayjs(
                                      revenueShareData?.created_at,
                                      "YYYY-MM-DD HH:mm:ss A"
                                    ).format("MMMM DD, YYYY HH:mm") || "N/A"}
                                  </span>
                                </li>
                                <li className="pb-1">
                                  <span className="title">Updated At:</span>
                                  <span className="text">
                                    {dayjs(
                                      revenueShareData?.updated_at,
                                      "YYYY-MM-DD HH:mm:ss A"
                                    ).format("MMMM DD, YYYY HH:mm") || "N/A"}
                                  </span>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      // <div className="table-responsive">
                      //   <Table
                      //     columns={columns}
                      //     dataSource={[revenueShareData]}
                      //     pagination={false}
                      //     scroll={{ x: "max-content" }}
                      //   />
                      // </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </>
  );
};

export default ShowRevenueShare;

// slices/userSlice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: null, // Store user details
  isAuthenticated: false, // Store authentication status
  subDomainValue: localStorage.getItem("subDomain") || "",
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserLogin: (state, action) => {
      state.user = action.payload;
      state.isAuthenticated = true;
    },
    setUserLogout: (state) => {
      state.user = null;
      state.isAuthenticated = false;
    },
    setSubDomainRoot: (state, action) => {
      state.subDomainValue = action.payload;
    },
  },
});

export const { setUserLogin, setUserLogout, setSubDomainRoot } =
  userSlice.actions;
export default userSlice.reducer;

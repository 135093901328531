import React from "react";
import Header from "../Header";
import Sidebar from "../Sidebar";
import { Link, useParams } from "react-router-dom";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { useQuery } from "@tanstack/react-query";

import { useSelector } from "react-redux";

import { Skeleton } from "@mui/material";
// import dayjs from "dayjs";
import { getPharmacyDetails } from "../../connections/getPharmacyDetails";
import { Table } from "antd";
// import { Table } from "antd";

const ShowPharmacy = () => {
  const {
    user: { user },
  } = useSelector((store) => store);
  const { id } = useParams();
  const { data: pharmacyDetailsData, isLoading } = useQuery({
    queryKey: ["getPharmacyDetails", id],
    queryFn: () => getPharmacyDetails(user?.token, id),
    enabled: !!id,

    refetchOnWindowFocus: false, // Don't refetch when window regains focus
    refetchOnReconnect: false, // Don't refetch when reconnecting
  });
  const columns = [
    {
      title: "ID",
      dataIndex: "id",
    },
    {
      title: "Name (uid)",
      dataIndex: "uid",
      key: "item",
    },
    {
      title: "Accountable Type",
      dataIndex: "accountable_type",
    },
    {
      title: "Accountable",
      dataIndex: "accountable",
    },
    // {
    //   title: "Created At",
    //   dataIndex: "created_at",
    //   //   sorter: (a, b) => a.ConsultingDoctor.length - b.ConsultingDoctor.length,
    //   render: (date) => (
    //     <>
    //       {" "}
    //       {dayjs(date, "YYYY-MM-DD HH:mm:ss A").format("MMMM DD, YYYY HH:mm")}
    //     </>
    //   ),
    // },
    // {
    //   title: "Updated At",
    //   dataIndex: "updated_at",
    //   //   sorter: (a, b) => a.Treatment.length - b.Treatment.length,
    //   render: (date) => (
    //     <>
    //       {" "}
    //       {dayjs(date, "YYYY-MM-DD HH:mm:ss A").format("MMMM DD, YYYY HH:mm")}
    //     </>
    //   ),
    // },
  ];

  return (
    <>
      <Header />
      <Sidebar activeClassName="pharmacies-list" />
      <>
        <div className="page-wrapper">
          <div className="content">
            {/* Page Header */}
            <div className="page-header">
              <div className="row">
                <div className="col-sm-12">
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item active">
                      <Link to={-1}>Pharmacy Details</Link>
                    </li>
                    <li className="breadcrumb-item">
                      <i className="feather-chevron-right">
                        <FeatherIcon icon="chevron-right" />
                      </i>
                    </li>
                    <li className="breadcrumb-item active">{id}</li>
                  </ul>
                </div>
              </div>
            </div>
            {/* /Page Header */}
            <div className="row">
              <div className="col-sm-12">
                <div className="card card-table show-entire">
                  <div className="card-body">
                    {/* content Header */}
                    <div className="page-table-header mb-2">
                      <div className="row align-items-center">
                        <div className="col">
                          <div className="doctor-table-blk">
                            <h3>Pharmacy Details</h3>
                            <div className="doctor-search-blk"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {isLoading ? (
                      <div>
                        <Skeleton
                          variant="rectangular"
                          width="100%"
                          height={430} // Same height as your chart
                        />
                      </div>
                    ) : (
                      <div className="content-details">
                        <div className="col-lg-12">
                          <div className="card-box mb-0">
                            <div className="card-block">
                              <ul className="personal-info">
                                <li className="pb-1">
                                  <span className="title">Id:</span>{" "}
                                  <span className="text">
                                    {pharmacyDetailsData?.id || "Empty"}
                                  </span>
                                </li>
                                <li className="pb-1">
                                  <span className="title">Name:</span>{" "}
                                  <span className="text">
                                    {pharmacyDetailsData?.name || "Empty"}
                                  </span>
                                </li>
                                <li className="pb-1">
                                  <span className="title">GST IN No.:</span>{" "}
                                  <span className="text">
                                    {pharmacyDetailsData?.gstin_no || "Empty"}
                                  </span>
                                </li>
                                <li className="pb-1">
                                  <span className="title">DL No.:</span>{" "}
                                  <span className="text">
                                    {pharmacyDetailsData?.dl_no || "Empty"}
                                  </span>
                                </li>
                              </ul>
                            </div>
                          </div>
                          {/* { items  list}  */}
                          {pharmacyDetailsData?.accounts?.length > 0 && (
                            <div className="doctor-table-blk col-md-12 col-12 mb-2 p-2">
                              <h3>Accounts</h3>
                              <div className="doctor-search-blk"></div>
                            </div>
                          )}
                          {pharmacyDetailsData?.accounts?.length > 0 && (
                            <div className="table-responsive doctor-list pl-4">
                              <Table
                                id="table-to-print"
                                pagination={false}
                                // pagination={{
                                //   total: pharmacyDetailsData?.accounts?.length,
                                //   showTotal: (total, range) =>
                                //     `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                                //   //showSizeChanger: true,
                                //   onShowSizeChange: onShowSizeChange,
                                //   itemRender: itemRender,
                                // }}
                                columns={columns}
                                dataSource={pharmacyDetailsData?.accounts}
                                // rowSelection={rowSelection}
                                rowKey={(record) => record.id}
                                scroll={{ x: "max-content" }}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </>
  );
};

export default ShowPharmacy;

import React from "react";
import Header from "../Header";
import Sidebar from "../Sidebar";
import { Link, useParams } from "react-router-dom";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { useQuery } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { Skeleton } from "@mui/material";
import { getPatientDetail } from "../../connections/getPatientDetail";
// import { Table } from "antd";
import dayjs from "dayjs";

// const columns = [
//   {
//     title: "Uid",
//     dataIndex: "uid",
//   },
//   {
//     title: "Full Name",
//     dataIndex: "full_name",
//   },
//   {
//     title: "First Name",
//     dataIndex: "first_name",
//   },
//   {
//     title: "Last Name",
//     dataIndex: "last_name",
//   },
//   //
//   {
//     title: "Mobile",
//     dataIndex: "mobile",
//   },
//   {
//     title: "Marital Status",
//     dataIndex: "marital_status",
//   },
//   {
//     title: "Gender",
//     dataIndex: "gender",
//   },
//   {
//     title: "Dob",
//     dataIndex: "dob",
//   },
//   {
//     title: "Pincode",
//     dataIndex: "pincode",
//   },
//   {
//     title: "Address",
//     dataIndex: "address",
//   },
//   {
//     title: "City",
//     dataIndex: "city",
//   },
//   {
//     title: "Active",
//     dataIndex: "active",
//   },
//   {
//     title: "Diet",
//     dataIndex: "diet",
//   },
//   {
//     title: "Age",
//     dataIndex: "age",
//   },
//   {
//     title: "Occupation",
//     dataIndex: "occupation",
//     render: (_, record) => (
//       <Link to={`/admin/occupation/${record?.occupation_id}`}>
//         {record?.occupation}
//       </Link>
//     ),
//   },
//   {
//     title: "Education",
//     dataIndex: "education",
//     render: (_, record) => (
//       <Link to={`/admin/education/${record?.education_id}`}>
//         {record?.education}
//       </Link>
//     ),
//   },
//   {
//     title: "Created At",
//     dataIndex: "created_at",
//     render: (date) => (
//       <>{dayjs(date, "YYYY-MM-DD HH:mm:ss A").format("MMMM DD, YYYY HH:mm")}</>
//     ),
//   },
//   {
//     title: "Updated At",
//     dataIndex: "updated_at",
//     render: (date) => (
//       <>{dayjs(date, "YYYY-MM-DD HH:mm:ss A").format("MMMM DD, YYYY HH:mm")}</>
//     ),
//   },
// ];

const PatientDetails = () => {
  const {
    user: { user },
  } = useSelector((store) => store);
  const { id } = useParams();
  const { data: getPatientDetailData, isLoading } = useQuery({
    queryKey: ["getPatientDetailData", id],
    queryFn: () => getPatientDetail(user?.token, id),
    enabled: !!id,
    // staleTime: Infinity, // Keep the data fresh indefinitely

    refetchOnWindowFocus: false, // Don't refetch when window regains focus
    refetchOnReconnect: false, // Don't refetch when reconnecting
  });
  // const patientListData = getPatientDetailData ? [getPatientDetailData] : [];

  return (
    <>
      <Header />
      <Sidebar
        id="menu-item4"
        id1="menu-items4"
        activeClassName="patient-list"
      />
      <>
        <div className="page-wrapper">
          <div className="content">
            {/* Page Header */}
            <div className="page-header">
              <div className="row">
                <div className="col-sm-12">
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item active">
                      <Link to={-1}>Patient Details</Link>
                    </li>
                    <li className="breadcrumb-item">
                      <i className="feather-chevron-right">
                        <FeatherIcon icon="chevron-right" />
                      </i>
                    </li>
                    <li className="breadcrumb-item active">{id}</li>
                  </ul>
                </div>
              </div>
            </div>
            {/* /Page Header */}
            <div className="row">
              <div className="col-sm-12">
                <div className="card card-table show-entire">
                  <div className="card-body">
                    {/* content Header */}
                    <div className="page-table-header mb-2">
                      <div className="row align-items-center">
                        <div className="col">
                          <div className="doctor-table-blk">
                            <h3>patient Details</h3>
                            <div className="doctor-search-blk"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {isLoading ? (
                      <div>
                        <Skeleton
                          variant="rectangular"
                          width="100%"
                          height={430} // Same height as your chart
                        />
                      </div>
                    ) : (
                      <div className="content-details">
                        <div className="col-lg-12">
                          <div className="card-box">
                            <div className="card-block">
                              <ul className="personal-info">
                                <li className="pb-1">
                                  <span className="title">Uid:</span>
                                  <span className="text">
                                    {getPatientDetailData?.uid || "N/A"}
                                  </span>
                                </li>
                                <li className="pb-1">
                                  <span className="title">Full Name:</span>
                                  <span className="text">
                                    {getPatientDetailData?.full_name || "N/A"}
                                  </span>
                                </li>
                                <li className="pb-1">
                                  <span className="title">First Name:</span>
                                  <span className="text">
                                    {getPatientDetailData?.first_name || "N/A"}
                                  </span>
                                </li>
                                <li className="pb-1">
                                  <span className="title">Last Name:</span>
                                  <span className="text">
                                    {getPatientDetailData?.last_name || "N/A"}
                                  </span>
                                </li>
                                <li className="pb-1">
                                  <span className="title">Mobile:</span>
                                  <span className="text">
                                    {getPatientDetailData?.mobile || "N/A"}
                                  </span>
                                </li>
                                <li className="pb-1">
                                  <span className="title">Marital Status:</span>
                                  <span className="text">
                                    {getPatientDetailData?.marital_status ||
                                      "N/A"}
                                  </span>
                                </li>
                                <li className="pb-1">
                                  <span className="title">Gender:</span>
                                  <span className="text">
                                    {getPatientDetailData?.gender || "N/A"}
                                  </span>
                                </li>
                                <li className="pb-1">
                                  <span className="title">Dob:</span>
                                  <span className="text">
                                    {getPatientDetailData?.dob || "N/A"}
                                  </span>
                                </li>
                                <li className="pb-1">
                                  <span className="title">Pincode:</span>
                                  <span className="text">
                                    {getPatientDetailData?.pincode || "N/A"}
                                  </span>
                                </li>
                                <li className="pb-1">
                                  <span className="title">Address:</span>
                                  <span className="text">
                                    {getPatientDetailData?.address || "N/A"}
                                  </span>
                                </li>
                                <li className="pb-1">
                                  <span className="title">City:</span>
                                  <span className="text">
                                    {getPatientDetailData?.city || "N/A"}
                                  </span>
                                </li>
                                <li className="pb-1">
                                  <span className="title">Active:</span>
                                  <span className="text">
                                    {getPatientDetailData?.active
                                      ? "Yes"
                                      : "No" || "N/A"}
                                  </span>
                                </li>
                                <li className="pb-1">
                                  <span className="title">Diet:</span>
                                  <span className="text">
                                    {getPatientDetailData?.diet || "N/A"}
                                  </span>
                                </li>
                                <li className="pb-1">
                                  <span className="title">Age:</span>
                                  <span className="text">
                                    {getPatientDetailData?.age || "N/A"}
                                  </span>
                                </li>
                                <li className="pb-1">
                                  <span className="title">Occupation:</span>
                                  <span className="text">
                                    {getPatientDetailData?.occupation ? (
                                      <Link
                                        to={`/admin/occupation/${getPatientDetailData?.occupation_id}`}
                                      >
                                        {getPatientDetailData?.occupation}
                                      </Link>
                                    ) : (
                                      "N/A"
                                    )}
                                  </span>
                                </li>
                                <li className="pb-1">
                                  <span className="title">Education:</span>
                                  <span className="text">
                                    {getPatientDetailData?.education ? (
                                      <Link
                                        to={`/admin/education/${getPatientDetailData?.education_id}`}
                                      >
                                        {getPatientDetailData?.education}
                                      </Link>
                                    ) : (
                                      "N/A"
                                    )}
                                  </span>
                                </li>
                                <li className="pb-1">
                                  <span className="title">Created At:</span>
                                  <span className="text">
                                    {getPatientDetailData?.created_at
                                      ? dayjs(
                                          getPatientDetailData.created_at,
                                          "YYYY-MM-DD HH:mm:ss A"
                                        ).format("MMMM DD, YYYY HH:mm")
                                      : "N/A"}
                                  </span>
                                </li>
                                <li className="pb-1">
                                  <span className="title">Updated At:</span>
                                  <span className="text">
                                    {getPatientDetailData?.updated_at
                                      ? dayjs(
                                          getPatientDetailData.updated_at,
                                          "YYYY-MM-DD HH:mm:ss A"
                                        ).format("MMMM DD, YYYY HH:mm")
                                      : "N/A"}
                                  </span>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      // <div className="content-details">
                      //   <div style={{ marginLeft: 20 }} className="row">

                      //     <div className="table-responsive doctor-list">
                      //       <Table
                      //         pagination={false}
                      //         columns={columns}
                      //         dataSource={patientListData}
                      //         rowKey={(record) => record.id}
                      //         scroll={{ x: "max-content" }}
                      //       />
                      //     </div>
                      //   </div>
                      // </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </>
  );
};
export default PatientDetails;

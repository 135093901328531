import React from "react";
import Header from "../../Header";
import Sidebar from "../../Sidebar";
import { Link, useParams } from "react-router-dom";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { Skeleton } from "@mui/material";
// import { Table } from "antd";
import { getShortCodeDetails } from "../../../connections/getShortCodeDetails";
// const columns = [
//   {
//     title: "Title",
//     dataIndex: "name",
//   },
//   {
//     title: "Sort",
//     dataIndex: "sort",
//   },
//   {
//     title: "Medical Tests",
//     dataIndex: "medical_tests",
//     render: (medical_tests) => (
//       <span
//         style={{
//           whiteSpace: "normal",
//           wordBreak: "break-word",
//           maxWidth: "350px",
//           display: "block",
//           overflow: "hidden",
//           textOverflow: "ellipsis",
//         }}
//       >
//         {medical_tests?.map((el, index) => (
//           <span key={el.id}>
//             <Link
//               style={{ textDecoration: "underline" }}
//               to={`/admin/medical_tests/${el.id}`}
//             >
//               {el.name}
//             </Link>
//             {index < medical_tests.length - 1 && " , "}
//           </span>
//         ))}
//       </span>
//     ),
//   },
//   {
//     title: "Created At",
//     dataIndex: "created_at",
//     render: (date) => (
//       <>{dayjs(date, "YYYY-MM-DD HH:mm:ss A").format("MMMM DD, YYYY HH:mm")}</>
//     ),
//   },
// ];
const ShowShortCode = () => {
  const {
    user: { user },
  } = useSelector((store) => store);
  const { id } = useParams();

  const { data: getShortCodeData, isLoading } = useQuery({
    queryKey: ["getShortCodeDetails", id],
    queryFn: () => getShortCodeDetails(user?.token, id),
    enabled: !!id,
    // staleTime: Infinity, // Keep the data fresh indefinitely

    refetchOnWindowFocus: false, // Don't refetch when window regains focus
    refetchOnReconnect: false, // Don't refetch when reconnecting
  });
  // const suppliersListData = getShortCodeData ? [getShortCodeData] : [];

  return (
    <>
      <Header />
      <Sidebar activeClassName="medical_test_shortcodes-dashboard" />
      <>
        <div className="page-wrapper">
          <div className="content">
            {/* Page Header */}
            <div className="page-header">
              <div className="row">
                <div className="col-sm-12">
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item active">
                      <Link to={-1}>Medical Test Shortcode Details</Link>
                    </li>
                    <li className="breadcrumb-item">
                      <i className="feather-chevron-right">
                        <FeatherIcon icon="chevron-right" />
                      </i>
                    </li>
                    <li className="breadcrumb-item active">{id}</li>
                  </ul>
                </div>
              </div>
            </div>
            {/* /Page Header */}
            <div className="row">
              <div className="col-sm-12">
                <div className="card card-table show-entire">
                  <div className="card-body">
                    {/* content Header */}
                    <div className="page-table-header mb-2">
                      <div className="row align-items-center">
                        <div className="col">
                          <div className="doctor-table-blk">
                            <h3>Medical Test Shortcode Details</h3>
                            <div className="doctor-search-blk"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {isLoading ? (
                      <div>
                        <Skeleton
                          variant="rectangular"
                          width="100%"
                          height={430} // Same height as your chart
                        />
                      </div>
                    ) : (
                      <div className="content-details">
                        <div className="col-lg-12">
                          <div className="card-box">
                            <div className="card-block">
                              <ul className="personal-info">
                                <li className="pb-1">
                                  <span className="title">Title:</span>
                                  <span className="text">
                                    {getShortCodeData?.name || "N/A"}
                                  </span>
                                </li>
                                <li className="pb-1">
                                  <span className="title">Sort:</span>
                                  <span className="text">
                                    {getShortCodeData?.sort || "N/A"}
                                  </span>
                                </li>
                                <li className="pb-1">
                                  <span className="title">Medical Tests:</span>
                                  <span
                                    className="text"
                                    style={{
                                      whiteSpace: "normal",
                                      wordBreak: "break-word",
                                      maxWidth: "350px",
                                      display: "block",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                    }}
                                  >
                                    {getShortCodeData?.medical_tests?.length > 0
                                      ? getShortCodeData?.medical_tests?.map(
                                          (el, index) => (
                                            <span key={el?.id}>
                                              <Link
                                                style={{
                                                  textDecoration: "underline",
                                                }}
                                                to={`/admin/medical_tests/${el?.id}`}
                                              >
                                                {el?.name}
                                              </Link>
                                              {index <
                                                getShortCodeData?.medical_tests
                                                  ?.length -
                                                  1 && " | "}
                                            </span>
                                          )
                                        )
                                      : "N/A"}
                                  </span>
                                </li>
                                <li className="pb-1">
                                  <span className="title">Created At:</span>
                                  <span className="text">
                                    {getShortCodeData?.created_at
                                      ? dayjs(
                                          getShortCodeData?.created_at,
                                          "YYYY-MM-DD HH:mm:ss A"
                                        ).format("MMMM DD, YYYY HH:mm")
                                      : "N/A"}
                                  </span>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      // <div className="table-responsive doctor-list">
                      //   <Table
                      //     pagination={false}
                      //     columns={columns}
                      //     dataSource={suppliersListData}
                      //     rowKey={(record) => record.id}
                      //     scroll={{ x: "max-content" }}
                      //   />
                      // </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </>
  );
};
export default ShowShortCode;

import React from "react";
import Header from "../Header";
import Sidebar from "../Sidebar";
import { Link, useNavigate, useParams } from "react-router-dom";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { useQuery } from "@tanstack/react-query";

import { useSelector } from "react-redux";
import dayjs from "dayjs";
import { Skeleton } from "@mui/material";
import { Table } from "antd";
import { onShowSizeChange, itemRender } from "../Pagination";
import { getMedicineDetails } from "../../connections/getMedicineDetails";

// const medicineColumns = [
//   {
//     title: "Name",
//     dataIndex: "name",
//     // sorter: (a, b) => a.name - b.name,
//   },
//   {
//     title: "Company",
//     dataIndex: "company",
//     //   sorter: (a, b) => a.ConsultingDoctor.length - b.ConsultingDoctor.length,
//     // render: (date) => <>{dayjs(date).format("MMMM DD, YYYY HH:mm")}</>,
//   },
//   {
//     title: "Amount",
//     dataIndex: "amount",
//     //   sorter: (a, b) => a.Treatment.length - b.Treatment.length,
//     // render: (date) => <>{dayjs(date).format("MMMM DD, YYYY HH:mm")}</>,
//   },
//   {
//     title: "Discount",
//     dataIndex: "discount",
//     //   sorter: (a, b) => a.Mobile.length - b.Mobile.length,
//     // render: (date) => <>{dayjs(date).format("MMMM DD, YYYY")}</>,
//   },
//   {
//     title: "Packing",
//     dataIndex: "packing",
//   },
//   {
//     title: "Total Stock Left",
//     dataIndex: "total_stock_left",
//   },
//   {
//     title: "Expiry Date",
//     dataIndex: "expiry_date",
//     render: (date) => {
//       if (!date) return null;

//       const parsedDate = dayjs(date, "YYYY-MM-DD");
//       const formattedDate = parsedDate.format("MMMM DD, YYYY");
//       const isExpiringSoon = parsedDate.isBefore(dayjs().add(2, "month"));
//       const style = isExpiringSoon
//         ? {
//             backgroundColor: "red",
//             color: "white",
//             padding: "5px 10px",
//             borderRadius: "4px",
//           }
//         : {};

//       return <span style={style}>{formattedDate}</span>;
//     },
//   },
//   {
//     title: "Form Type",
//     dataIndex: "form_type",
//   },
//   {
//     title: "Medicine Salt",
//     dataIndex: "medicine_salt",
//     render: (_, record) => (
//       <Link
//         to={`/admin/medicine-salts/${record?.medicine_salt?.id}`}
//         style={{
//           whiteSpace: "normal",
//           wordBreak: "break-word",
//           maxWidth: "150px",
//           display: "block",
//           overflow: "hidden",
//           textOverflow: "ellipsis",
//         }}
//       >
//         {record?.medicine_salt?.name || ""}
//       </Link>
//     ),
//   },
//   // {
//   //   title: "",
//   //   dataIndex: "FIELD8",
//   //   render: (_, record) => (
//   //     <>
//   //       <div className="text-end">
//   //         <div className="dropdown dropdown-action">
//   //           <Link
//   //             to={`/admin/pharmacy/${record?.id}`}
//   //             // className="action-icon dropdown-toggle"
//   //             // data-bs-toggle="dropdown"
//   //             // aria-expanded="false"
//   //           >
//   //             <i className="fa-solid fa-eye"></i>
//   //           </Link>
//   //           {/* <div className="dropdown-menu dropdown-menu-end"> */}
//   //           {/* <Link
//   //               className="dropdown-item"
//   //               to={`/admin/pharmacy/${record?.id}`}
//   //             >
//   //               <i className="fa-regular fa-eye m-r-5" />
//   //               View
//   //             </Link> */}
//   //           {/* <button
//   //               className="dropdown-item"
//   //               onClick={() =>
//   //                 navigate("/admin/update_medicines", { state: record })
//   //               }
//   //             >
//   //               <i className="far fa-edit me-2" />
//   //               Edit
//   //             </button> */}
//   //           {/* {user?.role === "admin" && (
//   //               <Link
//   //                 className="dropdown-item"
//   //                 to="#"
//   //                 data-bs-toggle="modal"
//   //                 data-bs-target="#delete_patient"
//   //                 onClick={() => {
//   //                   setMedId(record?.id);
//   //                 }}
//   //               >
//   //                 <i className="fa fa-trash-alt m-r-5"></i> Delete
//   //               </Link>
//   //             )} */}
//   //           {/* </div> */}
//   //         </div>
//   //       </div>
//   //     </>
//   //   ),
//   // },
// ];

const PharmacyDetail = () => {
  const {
    user: { user },
  } = useSelector((store) => store);
  const { id } = useParams();
  const navigate = useNavigate();
  const { data: getMedicineDetail, isLoading } = useQuery({
    queryKey: ["getMedicineDetails", id],
    queryFn: () => getMedicineDetails(user?.token, id),
    enabled: !!id,
    refetchOnWindowFocus: false, // Don't refetch when window regains focus
    refetchOnReconnect: false, // Don't refetch when reconnecting
  });
  // console.log(getMedicineDetail)
  // const pharmacyListData = getMedicineDetail ? [getMedicineDetail] : [];

  const columns = [
    {
      title: "Sno",
      dataIndex: "id",
      key: "id",
      render: (text, record, index) => index + 1, // Display row index
    },
    {
      title: "Batch",
      dataIndex: "batch_no",
      key: "item",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "Flat Price",
      dataIndex: "flat_price",
      key: "flat_price",
    },
    {
      title: "Form Type",
      dataIndex: "form_type",
      key: "form_type",
    },
    {
      title: "Expiry",
      dataIndex: "expiry_date",
      key: "expiry_date",
      render: (date) => {
        if (!date) return null;

        const parsedDate = dayjs(date, "YYYY-MM-DD");
        const formattedDate = parsedDate.format("MMMM DD, YYYY");
        const isExpiringSoon = parsedDate.isBefore(dayjs().add(2, "month"));
        const style = isExpiringSoon
          ? {
              backgroundColor: "red",
              color: "white",
              padding: "5px 10px",
              borderRadius: "4px",
            }
          : {};

        return <span style={style}>{formattedDate}</span>;
      },
    },
    {
      title: "Packing",
      dataIndex: "packing",
      key: "packing",
    },
    {
      title: "Stock Left",
      dataIndex: "stock_left",
      key: "stock_left",
    },
    {
      title: "",
      dataIndex: "",
      render: (_, record) => (
        <>
          <div className="text-end">
            <div className="dropdown dropdown-action">
              <button
                className="dropdown-item"
                onClick={() =>
                  navigate("/admin/update-batch-pharmacy", { state: record })
                }
              >
                <i className="fa-solid fa-pen-to-square"></i>
              </button>
              {/* <div className="dropdown-menu dropdown-menu-end"> */}
              {/* <Link
                  className="dropdown-item"
                  to={`/admin/pharmacy/${record?.id}`}
                >
                  <i className="fa-regular fa-eye m-r-5" />
                  View
                </Link> */}
              {/* <button
                  className="dropdown-item"
                  onClick={() =>
                    navigate("/admin/update_medicines", { state: record })
                  }
                >
                  <i className="far fa-edit me-2" />
                  Edit
                </button> */}
              {/* {user?.role === "admin" && (
                  <Link
                    className="dropdown-item"
                    to="#"
                    data-bs-toggle="modal"
                    data-bs-target="#delete_patient"
                    onClick={() => {
                      setMedId(record?.id);
                    }}
                  >
                    <i className="fa fa-trash-alt m-r-5"></i> Delete
                  </Link>
                )} */}
              {/* </div> */}
            </div>
          </div>
        </>
      ),
    },
  ];

  return (
    <>
      <Header />
      <Sidebar
        id="menu-item4"
        id1="menu-items4"
        activeClassName="pharmacy-list"
      />
      <>
        <div className="page-wrapper">
          <div className="content">
            {/* Page Header */}
            <div className="page-header">
              <div className="row">
                <div className="col-sm-12">
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to={-1}>Medicine</Link>
                    </li>
                    <li className="breadcrumb-item">
                      <i className="feather-chevron-right">
                        <FeatherIcon icon="chevron-right" />
                      </i>
                    </li>
                    <li className="breadcrumb-item active">
                      <Link to={-1}> Medicine Detail</Link>
                    </li>
                    <li className="breadcrumb-item">
                      <i className="feather-chevron-right">
                        <FeatherIcon icon="chevron-right" />
                      </i>
                    </li>
                    <li className="breadcrumb-item active">{id}</li>
                  </ul>
                </div>
              </div>
            </div>
            {/* /Page Header */}
            <div className="row">
              <div className="col-sm-12">
                <div className="card card-table show-entire">
                  <div className="card-body">
                    {/* content Header */}
                    <div className="page-table-header mb-2">
                      <div className="row align-items-center">
                        <div className="col">
                          <div className="doctor-table-blk">
                            <h3>Medicine Details</h3>
                            <div className="doctor-search-blk"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {isLoading ? (
                      <div>
                        <Skeleton
                          variant="rectangular"
                          width="100%"
                          height={430} // Same height as your chart
                        />
                      </div>
                    ) : (
                      <div className="content-details">
                        <div className="col-lg-12">
                          <div className="card-box">
                            <div className="card-block">
                              <ul className="personal-info">
                                <li className="pb-1">
                                  <span className="title">Name:</span>
                                  <span className="text">
                                    {getMedicineDetail?.name || "N/A"}
                                  </span>
                                </li>
                                <li className="pb-1">
                                  <span className="title">Company:</span>
                                  <span className="text">
                                    {getMedicineDetail?.company || "N/A"}
                                  </span>
                                </li>
                                <li className="pb-1">
                                  <span className="title">Amount:</span>
                                  <span className="text">
                                    {getMedicineDetail?.amount || "N/A"}
                                  </span>
                                </li>
                                <li className="pb-1">
                                  <span className="title">Discount:</span>
                                  <span className="text">
                                    {getMedicineDetail?.discount || "N/A"}
                                  </span>
                                </li>
                                <li className="pb-1">
                                  <span className="title">Packing:</span>
                                  <span className="text">
                                    {getMedicineDetail?.packing || "N/A"}
                                  </span>
                                </li>
                                <li className="pb-1">
                                  <span className="title">
                                    Total Stock Left:
                                  </span>
                                  <span className="text">
                                    {getMedicineDetail?.total_stock_left ||
                                      "N/A"}
                                  </span>
                                </li>
                                <li className="pb-1">
                                  <span className="title">Expiry Date:</span>
                                  <span className="text">
                                    {getMedicineDetail?.expiry_date
                                      ? (() => {
                                          const parsedDate = dayjs(
                                            getMedicineDetail.expiry_date,
                                            "YYYY-MM-DD"
                                          );
                                          const formattedDate =
                                            parsedDate.format("MMMM DD, YYYY");
                                          const isExpiringSoon =
                                            parsedDate.isBefore(
                                              dayjs().add(2, "month")
                                            );
                                          const style = isExpiringSoon
                                            ? {
                                                backgroundColor: "red",
                                                color: "white",
                                                padding: "5px 10px",
                                                borderRadius: "4px",
                                              }
                                            : {};
                                          return (
                                            <span style={style}>
                                              {formattedDate}
                                            </span>
                                          );
                                        })()
                                      : "N/A"}
                                  </span>
                                </li>
                                <li className="pb-1">
                                  <span className="title">Form Type:</span>
                                  <span className="text">
                                    {getMedicineDetail?.form_type || "N/A"}
                                  </span>
                                </li>
                                <li className="pb-1">
                                  <span className="title">Medicine Salt:</span>
                                  <span className="text">
                                    {getMedicineDetail?.medicine_salt?.name ? (
                                      <Link
                                        to={`/admin/medicine-salts/${getMedicineDetail.medicine_salt.id}`}
                                        style={{
                                          whiteSpace: "normal",
                                          wordBreak: "break-word",
                                          maxWidth: "350px",
                                          display: "block",
                                          overflow: "hidden",
                                          textOverflow: "ellipsis",
                                        }}
                                      >
                                        {getMedicineDetail.medicine_salt.name}
                                      </Link>
                                    ) : (
                                      "N/A"
                                    )}
                                  </span>
                                </li>
                              </ul>
                            </div>
                          </div>

                          {/* { items  list}  */}
                          {getMedicineDetail?.medicine_batches?.length > 0 && (
                            <div className="col-md-12 col-12 mb-2 p-2">
                              <h3 style={{ textDecoration: "underline" }}>
                                Batches
                              </h3>
                            </div>
                          )}
                          {getMedicineDetail?.medicine_batches?.length > 0 && (
                            <div className="table-responsive doctor-list">
                              <Table
                                id="table-to-print"
                                pagination={{
                                  total:
                                    getMedicineDetail?.medicine_batches?.length,
                                  showTotal: (total, range) =>
                                    `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                                  //showSizeChanger: true,
                                  onShowSizeChange: onShowSizeChange,
                                  itemRender: itemRender,
                                }}
                                columns={columns}
                                dataSource={getMedicineDetail?.medicine_batches}
                                // rowSelection={rowSelection}
                                rowKey={(record) => record.id}
                                scroll={{ x: "max-content" }}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </>
  );
};

export default PharmacyDetail;

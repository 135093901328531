/* eslint-disable no-undef */
import { toast } from "react-toastify";
import { BASE_URL } from "./connectionUrl";

export const getAllSpTypes = async (token) => {
  const endpoint = `/api/v1/admin/specialists/sp_types`;

  try {
    const response = await fetch(`${BASE_URL}${endpoint}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    });

    const data = await response.json();
    if (data.status.status === "SUCCESS") {
      return data?.payload;
    } else {
      toast.error(data?.status?.message || "something went wrong");
    }
  } catch (error) {
    toast.error(error.message || "something went wrong");
  }
};

import React, { useEffect, useState } from "react";
import { Dropdown, Menu, Table } from "antd";
import Header from "../Header";
import Sidebar from "../Sidebar";
import {
  imagesend,
  pdficon,
  pdficon3,
  plusicon,
  refreshicon,
  pdficon5,
  searchnormal,
} from "../imagepath";
// import { onShowSizeChange, itemRender } from "../Pagination";
import { Link, useNavigate } from "react-router-dom";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { useSelector } from "react-redux";
import { useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import {
  generateCsv,
  handleDownloadPdfAdmission,
  handleShowJson,
} from "../../utils/constant/constant";
import { toast } from "react-toastify";

import { getAdmissionsList } from "../../connections/getAdmissionsList";
import { deleteAdmission } from "../../connections/deleteAdmission";
import Select from "react-select";
import useDebounce from "../../utils/hooks";
import { getPatientsList } from "../../connections/getPatientsList";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
const AdmissionsList = () => {
  const [deleteId, setDeleteId] = useState("");
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const [sortColumns, setSortColumns] = useState("");
  const [sortOrder, setSortOrder] = useState("");
  const [filter, setFilter] = useState({});
  const [inputValue, setInputValue] = useState("");
  const [showFilter, setShowFilter] = useState(false);
  const {
    user: { user },
  } = useSelector((store) => store);
  const navigate = useNavigate();
  const debouncedInputValue = useDebounce(inputValue, 1000);
  const { data: getSearchedPatient } = useQuery({
    queryKey: ["getAdmissionsList", debouncedInputValue, 1, 10],
    queryFn: () => getPatientsList(user?.token, debouncedInputValue, 1, 10),
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    enabled: !!debouncedInputValue,
  });
  const {
    data: getAdmissionsData,
    isLoading: admissionLoader,
    refetch: refetchAdmission,
    isRefetching,
  } = useQuery({
    queryKey: ["getAdmissionsList", page, pageSize, sortColumns, sortOrder],
    queryFn: () =>
      getAdmissionsList(
        user?.token,
        null,
        page,
        pageSize,
        sortColumns,
        sortOrder,
        filter
      ),
    refetchOnWindowFocus: false, // Don't refetch when window regains focus
    refetchOnReconnect: false, // Don't refetch when reconnecting
  });

  const onSelectChange = (newSelectedRowKeys) => {
    // console.log("selectedRowKeys changed: ", selectedRowKeys);

    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const handleTableChange = async (filters, sorter) => {
    // Extract sorting details
    const sortColumn = sorter.field;
    const sortOrder =
      sorter.order === "ascend"
        ? "asc"
        : sorter.order === "descend"
        ? "desc"
        : null;
    // console.log("sorter", sorter);
    setSortColumns(sortColumn);
    setSortOrder(sortOrder);
  };
  const handleFilterChange = (key, value) => {
    setFilter((prev) => {
      const updatedFilter = { ...prev };
      if (value === null || value === "") {
        delete updatedFilter[key];
      } else {
        updatedFilter[key] = value;
      }

      return updatedFilter;
    });
  };
  const handleSubmitFilter = () => {
    refetchAdmission(
      user?.token,
      null,
      page,
      pageSize,
      sortColumns,
      sortOrder,
      filter
    );
  };
  const handleInputChange = (newInputValue) => {
    setInputValue(newInputValue);
  };
  const columns = [
    {
      title: "Id",
      dataIndex: "id",
      sorter: true,
    },
    {
      title: "Room No.",
      dataIndex: "room_no",
      sorter: true,
    },
    {
      title: "Check In",
      dataIndex: "checkin",
      // sorter: true,
      render: (date) => (
        <>
          {dayjs(date, "YYYY-MM-DD HH:mm:ss A").format("MMMM DD, YYYY HH:mm")}
        </>
      ),
    },
    {
      title: "Check Out",
      dataIndex: "checkout",
      // sorter: true,
      render: (date) => (
        <>
          {dayjs(date, "YYYY-MM-DD HH:mm:ss A").format("MMMM DD, YYYY HH:mm")}
        </>
      ),
    },
    {
      title: "Patient",
      dataIndex: "patient",

      render: (_, record) => (
        <Link
          style={{ textDecoration: "underline" }}
          to={`/admin/patient/${record?.patient?.id}`}
        >
          {record?.patient?.full_name}
        </Link>
      ),
    },
    {
      title: "Ward No",
      dataIndex: "ward_no",
      // sorter: true,
    },
    {
      title: "Bed No",
      dataIndex: "bed_no",
      sorter: true,
    },
    {
      title: "Created At",
      dataIndex: "created_at",
      sorter: true,
      render: (date) => (
        <>
          {dayjs(date, "YYYY-MM-DD HH:mm:ss A").format("MMMM DD, YYYY HH:mm")}
        </>
      ),
    },
    {
      title: "Updated At",
      dataIndex: "updated_at",
      // sorter: true,
      render: (date) => (
        <>
          {dayjs(date, "YYYY-MM-DD HH:mm:ss A").format("MMMM DD, YYYY HH:mm")}
        </>
      ),
    },
    // {
    //   title: "",
    //   dataIndex: "FIELD8",
    //   render: (_, record) => (
    //     <>
    //       <div className="text-end">
    //         <div className="dropdown dropdown-action">
    //           <Link
    //             to="#"
    //             className="action-icon dropdown-toggle"
    //             data-bs-toggle="dropdown"
    //             aria-expanded="false"
    //           >
    //             <i className="fas fa-ellipsis-v" />
    //           </Link>
    //           <div className="dropdown-menu dropdown-menu-end">
    //             <Link
    //               className="dropdown-item"
    //               to={`/admin/admission/${record?.id}`}
    //             >
    //               <i className="fa-regular fa-eye m-r-5" />
    //               View
    //             </Link>
    //             <button
    //               className="dropdown-item"
    //               onClick={() =>
    //                 navigate("/admin/update_admission", { state: record })
    //               }
    //               // to={{
    //               //   pathname: "/admin/update-suppliers",
    //               //   state: { supplier: record },
    //               //   data: "ddddddddddddd",
    //               // }}
    //             >
    //               <i className="far fa-edit me-2" />
    //               Edit
    //             </button>
    //             <Link
    //               className="dropdown-item"
    //               to="#"
    //               data-bs-toggle="modal"
    //               data-bs-target="#delete_patient"
    //               onClick={() => {
    //                 setDeleteId(record?.id);
    //               }}
    //             >
    //               <i className="fa fa-trash-alt m-r-5"></i> Delete
    //             </Link>
    //           </div>
    //         </div>
    //       </div>
    //     </>
    //   ),
    // },
    {
      title: "",
      dataIndex: "FIELD8",
      render: (_, record) => (
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item key="view">
                <Link to={`/admin/admission/${record?.id}`}>
                  <i className="fa-regular fa-eye m-r-5" /> View
                </Link>
              </Menu.Item>
              <Menu.Item
                key="edit"
                onClick={() =>
                  navigate("/admin/update_admission", { state: record })
                }
              >
                <i className="far fa-edit me-2" /> Edit
              </Menu.Item>
              <Menu.Item
                className="dropdown-item"
                to="#"
                data-bs-toggle="modal"
                data-bs-target="#delete_patient"
                key="delete"
                onClick={() => {
                  setDeleteId(record?.id);
                }}
              >
                <i className="fa fa-trash-alt m-r-5" /> Delete
              </Menu.Item>
            </Menu>
          }
          trigger={["click"]}
          placement="bottomRight"
        >
          <Link
            to="#"
            style={{ marginRight: 5 }}
            className="action-icon"
            // data-bs-toggle="dropdown"
            // aria-expanded="false"
          >
            <i className="fas fa-ellipsis-v" />
          </Link>
        </Dropdown>
      ),
    },
  ];
  const deleteAdmissionFun = async () => {
    try {
      const data = await deleteAdmission(user?.token, deleteId);
      if (data?.status?.status === "SUCCESS") {
        toast.success("Admission Deleted Successfully", deleteId);
        refetchAdmission();
        setDeleteId(null);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };
  const handlePageChange = (newPage, newPageSize) => {
    setPage(newPage);
    setPageSize(newPageSize);
    // refetchAdmission();
  };
  useEffect(() => {
    if (!showFilter) {
      setFilter({});
    }
  }, [showFilter]);
  return (
    <>
      <Header />
      <Sidebar activeClassName="admission-list" />
      <>
        <div className="page-wrapper">
          <div className="content">
            {/* Page Header */}
            <div className="page-header">
              <div className="row">
                <div className="col-sm-12">
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="#">Admissions</Link>
                    </li>
                    <li className="breadcrumb-item">
                      <i className="feather-chevron-right">
                        <FeatherIcon icon="chevron-right" />
                      </i>
                    </li>
                    <li className="breadcrumb-item active">Admissions List</li>
                  </ul>
                </div>
              </div>
            </div>
            {/* /Page Header */}
            <div className="row">
              <div className="col-sm-12">
                <div className="card card-table show-entire">
                  <div className="card-body">
                    <div className="page-table-header mb-2">
                      <div className="row align-items-center">
                        {showFilter && (
                          <div className="col filter-container">
                            <div className="doctor-table-blk">
                              <h3 style={{ marginBottom: 2 }}> Filter</h3>
                            </div>
                            <div className="row mt-2">
                              <div className="col-12 col-md-4 col-lg-2">
                                <div className="form-group local-forms">
                                  <label>
                                    <img
                                      width="15px"
                                      height="15px"
                                      style={{ marginRight: 2 }}
                                      src={searchnormal}
                                      alt="#"
                                    />
                                    Search
                                  </label>
                                  <input
                                    value={filter.q}
                                    onChange={(e) =>
                                      handleFilterChange("q", e.target.value)
                                    }
                                    className="form-control"
                                    type="text"
                                    placeholder="Search here"
                                  />
                                </div>
                              </div>
                              <div className="col-12 col-md-4 col-lg-2">
                                <div className="form-group local-forms">
                                  <label>
                                    Patient
                                    {/* <span className="login-danger">*</span> */}
                                  </label>
                                  <Select
                                    inputValue={inputValue}
                                    onInputChange={handleInputChange}
                                    onChange={(e) =>
                                      handleFilterChange(
                                        "patient_id",
                                        e ? e.value : null
                                      )
                                    }
                                    isClearable
                                    placeholder="Search"
                                    options={
                                      getSearchedPatient?.payload?.patients?.map(
                                        (patient) => ({
                                          label: patient.full_name,
                                          value: patient.id,
                                        })
                                      ) || []
                                    }
                                    menuPortalTarget={document.body}
                                    styles={{
                                      menuPortal: (base) => ({
                                        ...base,
                                        zIndex: 9999,
                                      }),
                                      control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        borderColor: state.isFocused
                                          ? "#2e37a4"
                                          : "rgba(46, 55, 164, 0.1)",
                                        boxShadow: state.isFocused
                                          ? "0 0 0 1px #2e37a4"
                                          : "none",
                                        "&:hover": {
                                          borderColor: "rgba(46, 55, 164, 0.1)",
                                        },
                                        borderRadius: "10px",
                                        fontSize: "14px",
                                        // minHeight: "45px",
                                      }),
                                      dropdownIndicator: (base, state) => ({
                                        ...base,
                                        transform: state.selectProps.menuIsOpen
                                          ? "rotate(-180deg)"
                                          : "rotate(0)",
                                        transition: "250ms",
                                        width: "35px",
                                        height: "38px",
                                      }),
                                    }}
                                    id="search-commodity"
                                    components={{
                                      IndicatorSeparator: () => null,
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="col-12 col-md-4 col-lg-2">
                                <div className="form-group local-forms">
                                  <label>
                                    Created at start
                                    {/* <span className="login-danger">*</span> */}
                                  </label>
                                  <input
                                    type="date"
                                    value={filter?.created_at_start}
                                    onChange={(e) =>
                                      handleFilterChange(
                                        "created_at_start",
                                        e.target.value
                                      )
                                    }
                                    className="form-control"
                                  />
                                </div>
                              </div>
                              <div className="col-12 col-md-4 col-lg-2">
                                <div className="form-group local-forms">
                                  <label>
                                    Created at end
                                    {/* <span className="login-danger">*</span> */}
                                  </label>
                                  <input
                                    type="date"
                                    value={filter?.created_at_end}
                                    onChange={(e) =>
                                      handleFilterChange(
                                        "created_at_end",
                                        e.target.value
                                      )
                                    }
                                    className="form-control"
                                  />
                                </div>
                              </div>
                              <div className="col-12 col-md-4 col-lg-2">
                                <div className="form-group local-forms">
                                  <label>
                                    Updated at start
                                    {/* <span className="login-danger">*</span> */}
                                  </label>
                                  <input
                                    type="date"
                                    value={filter?.updated_at_start}
                                    onChange={(e) =>
                                      handleFilterChange(
                                        "updated_at_start",
                                        e.target.value
                                      )
                                    }
                                    className="form-control"
                                  />
                                </div>
                              </div>
                              <div className="col-12 col-md-4 col-lg-2">
                                <div className="form-group local-forms">
                                  <label>
                                    Updated at end
                                    {/* <span className="login-danger">*</span> */}
                                  </label>
                                  <input
                                    type="date"
                                    value={filter?.updated_at_end}
                                    onChange={(e) =>
                                      handleFilterChange(
                                        "updated_at_end",
                                        e.target.value
                                      )
                                    }
                                    className="form-control"
                                  />
                                </div>
                              </div>
                              <div className="col-12 col-md-4 col-lg-2">
                                <div className="form-group local-forms">
                                  <label>
                                    Check in
                                    {/* <span className="login-danger">*</span> */}
                                  </label>
                                  <input
                                    type="date"
                                    value={filter?.checkin}
                                    onChange={(e) =>
                                      handleFilterChange(
                                        "checkin",
                                        e.target.value
                                      )
                                    }
                                    className="form-control"
                                  />
                                </div>
                              </div>
                              <div className="col-12 col-md-4 col-lg-2">
                                <div className="form-group local-forms">
                                  <label>
                                    Check out
                                    {/* <span className="login-danger">*</span> */}
                                  </label>
                                  <input
                                    type="date"
                                    value={filter?.checkout}
                                    onChange={(e) =>
                                      handleFilterChange(
                                        "checkout",
                                        e.target.value
                                      )
                                    }
                                    className="form-control"
                                  />
                                </div>
                              </div>
                              <div className="col-12 col-md-4 col-lg-2">
                                <div className="form-group local-forms">
                                  <label>
                                    Bed No
                                    {/* <span className="login-danger">*</span> */}
                                  </label>
                                  <input
                                    value={filter.bed_no}
                                    onChange={(e) =>
                                      handleFilterChange(
                                        "bed_no",
                                        e.target.value
                                      )
                                    }
                                    className="form-control"
                                    type="text"
                                    placeholder="Bed no"
                                  />
                                </div>
                              </div>
                              <div className="col-12 col-md-4 col-lg-2">
                                <div className="form-group local-forms">
                                  <label>
                                    Room No
                                    {/* <span className="login-danger">*</span> */}
                                  </label>
                                  <input
                                    value={filter.room_no}
                                    onChange={(e) =>
                                      handleFilterChange(
                                        "room_no",
                                        e.target.value
                                      )
                                    }
                                    className="form-control"
                                    type="text"
                                    placeholder="Room no"
                                  />
                                </div>
                              </div>
                              <div className="col-12 col-md-3 col-lg-2 mb-2">
                                <button
                                  onClick={handleSubmitFilter}
                                  className="btn btn-primary p-2"
                                >
                                  Apply Filter
                                </button>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>

                    {/* Table Header */}
                    <div className="page-table-header mb-2">
                      <div className="row align-items-center">
                        <div className="col">
                          <div className="doctor-table-blk">
                            <h3>Admissions List</h3>
                            <div className="doctor-search-blk">
                              <div className="add-group">
                                <Link
                                  to="/admin/add_admission"
                                  className="btn btn-primary add-pluss ms-2"
                                >
                                  <img src={plusicon} alt="#" />
                                </Link>
                                <Link
                                  onClick={refetchAdmission}
                                  className="btn btn-primary add-pluss ms-2"
                                >
                                  <img
                                    // onClick={refetchAdmission}
                                    src={refreshicon}
                                    alt="#"
                                  />
                                </Link>
                                <Link
                                  onClick={() => setShowFilter(!showFilter)}
                                  className="btn btn-primary add-pluss ms-2"
                                >
                                  <FilterAltIcon
                                    sx={{
                                      ":hover": {
                                        color: "white",
                                      },
                                    }}
                                  />
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-auto text-end float-end ms-auto download-grp">
                          {/* <Link id="download-pdf" to="#" className=" me-2"> */}
                          <img
                            className=" me-2"
                            onClick={() =>
                              handleDownloadPdfAdmission(
                                getAdmissionsData?.payload?.admissions
                              )
                            }
                            src={pdficon}
                            alt="#"
                            style={{ cursor: "pointer" }}
                          />
                          {/* </Link> */}
                          {/* <Link
                           
                            id="download-csv"
                            className=" me-2"
                          > */}
                          <img
                            onClick={() =>
                              generateCsv(
                                getAdmissionsData?.payload?.admissions
                              )
                            }
                            className=" me-2"
                            src={pdficon3}
                            alt="#"
                            style={{ cursor: "pointer" }}
                          />
                          {/* </Link> */}
                          {/* <Link id="show-json" to="#"> */}
                          <img
                            onClick={() =>
                              handleShowJson(
                                getAdmissionsData?.payload?.admissions
                              )
                            }
                            width={"25px"}
                            height={"26px"}
                            src={pdficon5}
                            alt="#"
                            style={{ cursor: "pointer" }}
                          />
                          {/* </Link> */}
                        </div>
                      </div>
                    </div>
                    {/* /Table Header */}
                    <div className="table-responsive doctor-list">
                      <Table
                        loading={admissionLoader || isRefetching}
                        pagination={{
                          current: page,
                          pageSize: pageSize,
                          total: getAdmissionsData?.meta?.total_count,
                          showTotal: (total, range) =>
                            `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                          onChange: handlePageChange,
                        }}
                        columns={columns}
                        dataSource={getAdmissionsData?.payload?.admissions}
                        rowSelection={rowSelection}
                        rowKey={(record) => record.id}
                        scroll={{ x: "max-content" }}
                        onChange={(val, filter, sorter) => {
                          // console.log(val, filter, sorter, extra);
                          handleTableChange(filter, sorter);
                        }}
                        locale={{
                          triggerDesc: "Sort descending",
                          triggerAsc: "Sort ascending",
                          cancelSort: "Cancel sort",
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="notification-box">
            <div className="msg-sidebar notifications msg-noti">
              <div className="topnav-dropdown-header">
                <span>Messages</span>
              </div>
              <div className="drop-scroll msg-list-scroll" id="msg_list">
                <ul className="list-box">
                  <li>
                    <Link to="#">
                      <div className="list-item">
                        <div className="list-left">
                          <span className="avatar">R</span>
                        </div>
                        <div className="list-body">
                          <span className="message-author">Richard Miles </span>
                          <span className="message-time">12:28 AM</span>
                          <div className="clearfix" />
                          <span className="message-content">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                          </span>
                        </div>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <div className="list-item new-message">
                        <div className="list-left">
                          <span className="avatar">J</span>
                        </div>
                        <div className="list-body">
                          <span className="message-author">John Doe</span>
                          <span className="message-time">1 Aug</span>
                          <div className="clearfix" />
                          <span className="message-content">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                          </span>
                        </div>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <div className="list-item">
                        <div className="list-left">
                          <span className="avatar">T</span>
                        </div>
                        <div className="list-body">
                          <span className="message-author">
                            {" "}
                            Tarah Shropshire{" "}
                          </span>
                          <span className="message-time">12:28 AM</span>
                          <div className="clearfix" />
                          <span className="message-content">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                          </span>
                        </div>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <div className="list-item">
                        <div className="list-left">
                          <span className="avatar">M</span>
                        </div>
                        <div className="list-body">
                          <span className="message-author">Mike Litorus</span>
                          <span className="message-time">12:28 AM</span>
                          <div className="clearfix" />
                          <span className="message-content">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                          </span>
                        </div>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <div className="list-item">
                        <div className="list-left">
                          <span className="avatar">C</span>
                        </div>
                        <div className="list-body">
                          <span className="message-author">
                            {" "}
                            Catherine Manseau{" "}
                          </span>
                          <span className="message-time">12:28 AM</span>
                          <div className="clearfix" />
                          <span className="message-content">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                          </span>
                        </div>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <div className="list-item">
                        <div className="list-left">
                          <span className="avatar">D</span>
                        </div>
                        <div className="list-body">
                          <span className="message-author">
                            {" "}
                            Domenic Houston{" "}
                          </span>
                          <span className="message-time">12:28 AM</span>
                          <div className="clearfix" />
                          <span className="message-content">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                          </span>
                        </div>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <div className="list-item">
                        <div className="list-left">
                          <span className="avatar">B</span>
                        </div>
                        <div className="list-body">
                          <span className="message-author">
                            {" "}
                            Buster Wigton{" "}
                          </span>
                          <span className="message-time">12:28 AM</span>
                          <div className="clearfix" />
                          <span className="message-content">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                          </span>
                        </div>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <div className="list-item">
                        <div className="list-left">
                          <span className="avatar">R</span>
                        </div>
                        <div className="list-body">
                          <span className="message-author">
                            {" "}
                            Rolland Webber{" "}
                          </span>
                          <span className="message-time">12:28 AM</span>
                          <div className="clearfix" />
                          <span className="message-content">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                          </span>
                        </div>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <div className="list-item">
                        <div className="list-left">
                          <span className="avatar">C</span>
                        </div>
                        <div className="list-body">
                          <span className="message-author"> Claire Mapes </span>
                          <span className="message-time">12:28 AM</span>
                          <div className="clearfix" />
                          <span className="message-content">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                          </span>
                        </div>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <div className="list-item">
                        <div className="list-left">
                          <span className="avatar">M</span>
                        </div>
                        <div className="list-body">
                          <span className="message-author">Melita Faucher</span>
                          <span className="message-time">12:28 AM</span>
                          <div className="clearfix" />
                          <span className="message-content">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                          </span>
                        </div>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <div className="list-item">
                        <div className="list-left">
                          <span className="avatar">J</span>
                        </div>
                        <div className="list-body">
                          <span className="message-author">Jeffery Lalor</span>
                          <span className="message-time">12:28 AM</span>
                          <div className="clearfix" />
                          <span className="message-content">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                          </span>
                        </div>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <div className="list-item">
                        <div className="list-left">
                          <span className="avatar">L</span>
                        </div>
                        <div className="list-body">
                          <span className="message-author">Loren Gatlin</span>
                          <span className="message-time">12:28 AM</span>
                          <div className="clearfix" />
                          <span className="message-content">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                          </span>
                        </div>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <div className="list-item">
                        <div className="list-left">
                          <span className="avatar">T</span>
                        </div>
                        <div className="list-body">
                          <span className="message-author">
                            Tarah Shropshire
                          </span>
                          <span className="message-time">12:28 AM</span>
                          <div className="clearfix" />
                          <span className="message-content">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                          </span>
                        </div>
                      </div>
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="topnav-dropdown-footer">
                <Link to="#">See all messages</Link>
              </div>
            </div>
          </div>
        </div>
        <div
          id="delete_patient"
          className="modal fade delete-modal"
          role="dialog"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body text-center">
                <img src={imagesend} alt="#" width={50} height={46} />
                <h3>Are you sure want to delete this ?</h3>
                <div className="m-t-20">
                  {" "}
                  <Link
                    to="#"
                    className="btn btn-white me-2"
                    data-bs-dismiss="modal"
                  >
                    Close
                  </Link>
                  <button
                    onClick={deleteAdmissionFun}
                    type="submit"
                    className="btn btn-danger"
                    data-bs-dismiss="modal"
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div
            id="delete_patient"
            className="modal fade delete-modal"
            role="dialog"
          >
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-body text-center">
                  <img src={imagesend} alt="#" width={50} height={46} />
                  <h3>Are you sure want to delete this ?</h3>
                  <div className="m-t-20">
                    {" "}
                    <Link
                      to="#"
                      className="btn btn-white me-2"
                      data-bs-dismiss="modal"
                    >
                      Close
                    </Link>
                    <button
                      data-bs-dismiss="modal"
                      type="submit"
                      className="btn btn-danger"
                    >
                      Delete
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </>
  );
};

export default AdmissionsList;

import React, { useEffect } from "react";
import { useState } from "react";
import Header from "../Header";
import Sidebar from "../Sidebar";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { DatePicker } from "antd";
import dayjs from "dayjs";
import useDebounce from "../../utils/hooks";
import { getPatientsList } from "../../connections/getPatientsList";
import { useQuery } from "@tanstack/react-query";
import Select from "react-select";
import { putAdmission } from "../../connections/putAdmission";

const UpdateAdmission = () => {
  const [searchData, setSearchData] = useState({ q: "" });
  const [inputValue, setInputValue] = useState("");
  const {
    user: { user },
  } = useSelector((store) => store);
  const debouncedInputValue = useDebounce(inputValue, 1000);
  const location = useLocation();
  const admissionData = location.state || null;
  const { data: getSearchedPatient } = useQuery({
    queryKey: ["getAdmissionsList", searchData.q, 1, 10],
    queryFn: () => getPatientsList(user?.token, searchData.q, 1, 10),
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    enabled: !!debouncedInputValue,
  });
  const [formState, setFormState] = useState({});
  const navigate = useNavigate();
  const handleChange = (name, value) => {
    setFormState((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleAddAdmission = async (e) => {
    e.preventDefault();
    const payload = {
      admission: formState,
    };
    if (!formState?.checkin) {
      toast.error("Please enter Check-In Date");
      return;
    }
    if (!formState?.room_no) {
      toast.error("Please enter room no");
      return;
    }
    try {
      // console.log(payload);
      const data = await putAdmission(user?.token, payload, admissionData?.id);
      if (data.status.status === "SUCCESS") {
        toast.success("Admission Added Successfully");
        setFormState({});
        navigate("/admin/admission");
      }
    } catch (error) {
      console.log(error.message);
    }
  }; // Handle input change for react-select
  const handleInputChange = (newInputValue) => {
    setInputValue(newInputValue);
  };
  const getAllPatient =
    getSearchedPatient?.payload?.patients?.map((patient) => ({
      label: patient.full_name,
      value: patient.id,
    })) || [];
  const patientOptions = getAllPatient || [];

  // console.log(patientOptions, "formattedPatientOption");
  const selectedPatient = patientOptions.find(
    (option) =>
      option.value === formState.id || option.label === formState.patient
  );
  // Update searchData when debounced input changes
  useEffect(() => {
    if (debouncedInputValue) {
      setSearchData((prev) => ({ ...prev, q: debouncedInputValue }));
    }
  }, [debouncedInputValue]);
  useEffect(() => {
    if (admissionData) {
      setFormState((prev) => ({
        ...prev,
        checkin: admissionData.checkin,
        checkout: admissionData.checkout,
        ward_no: admissionData.ward_no,
        room_no: admissionData.room_no,
        bed_no: admissionData.bed_no,
        // patient: admissionData?.patient?.full_name,
        patient_id: admissionData?.patient?.id,
      }));
      setInputValue(admissionData?.patient?.full_name);
    }
  }, [admissionData]);

  return (
    <>
      <Header />
      <Sidebar activeClassName="admission-list" />
      <>
        <div className="page-wrapper">
          <div className="content">
            {/* Page Header */}
            <div className="page-header">
              <div className="row">
                <div className="col-sm-12">
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to={-1}>Admissions </Link>
                    </li>
                    <li className="breadcrumb-item">
                      <i className="feather-chevron-right">
                        <FeatherIcon icon="chevron-right" />
                      </i>
                    </li>
                    <li className="breadcrumb-item active">
                      Update Admissions
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            {/* /Page Header */}
            <div className="row">
              <div className="col-sm-12">
                <div className="card">
                  <div className="card-body">
                    <form>
                      <div className="row">
                        <div className="col-12">
                          <div className="form-heading">
                            <h4>Update Admissions</h4>
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-6">
                          <div className="form-group local-forms">
                            <label>
                              Patient
                              <span className="login-danger">*</span>
                            </label>
                            <Select
                              value={selectedPatient}
                              inputValue={inputValue}
                              onInputChange={handleInputChange}
                              onChange={(e) =>
                                handleChange("patient_id", e.value)
                              }
                              placeholder="Search for a patient..."
                              options={patientOptions}
                              menuPortalTarget={document.body}
                              styles={{
                                menuPortal: (base) => ({
                                  ...base,
                                  zIndex: 9999,
                                }),
                                control: (baseStyles, state) => ({
                                  ...baseStyles,
                                  borderColor: state.isFocused
                                    ? "#2e37a4"
                                    : "rgba(46, 55, 164, 0.1)",
                                  boxShadow: state.isFocused
                                    ? "0 0 0 1px #2e37a4"
                                    : "none",
                                  "&:hover": {
                                    borderColor: "rgba(46, 55, 164, 0.1)",
                                  },
                                  borderRadius: "10px",
                                  fontSize: "14px",
                                  // minHeight: "45px",
                                }),
                                dropdownIndicator: (base, state) => ({
                                  ...base,
                                  transform: state.selectProps.menuIsOpen
                                    ? "rotate(-180deg)"
                                    : "rotate(0)",
                                  transition: "250ms",
                                  width: "35px",
                                  height: "35px",
                                }),
                              }}
                              id="search-commodity"
                              components={{
                                IndicatorSeparator: () => null,
                              }}
                            />
                          </div>
                        </div>
                        {/* <div className="col-12 col-md-6 col-xl-6">
                          <div className="form-group local-forms">
                            <label>
                              Patient
                              <span className="login-danger">*</span>
                              <Select
                                inputValue={inputValue}
                                onInputChange={handleInputChange}
                                options={
                                  getSearchedPatient?.payload?.patients?.map(
                                    (patient) => ({
                                      label: patient.full_name,
                                      value: patient.id,
                                    })
                                  ) || []
                                }
                                onChange={(e) =>
                                  handleChange("patient_id", e.value)
                                }
                                menuPortalTarget={document.body}
                                styles={{
                                  container: (provided) => ({
                                    ...provided,
                                    maxWidth: "100% !important",

                                    "@media (min-width: 768px)": {
                                      maxWidth: "100% !important",
                                    },
                                    "@media (min-width: 1024px)": {
                                      maxWidth: "100% !important",
                                    },
                                  }),
                                  menuPortal: (base) => ({
                                    ...base,
                                    zIndex: 9999,
                                  }),
                                  control: (baseStyles, state) => ({
                                    ...baseStyles,
                                    borderColor: state.isFocused
                                      ? "#2e37a4"
                                      : "rgba(46, 55, 164, 0.1)",
                                    boxShadow: state.isFocused
                                      ? "0 0 0 1px #2e37a4"
                                      : "none",
                                    "&:hover": {
                                      borderColor: "rgba(46, 55, 164, 0.1)",
                                    },
                                    borderRadius: "10px",
                                    fontSize: "14px",
                                    minHeight: "45px",
                                  }),
                                  dropdownIndicator: (base, state) => ({
                                    ...base,
                                    transform: state.selectProps.menuIsOpen
                                      ? "rotate(-180deg)"
                                      : "rotate(0)",
                                    transition: "250ms",
                                    width: "35px",
                                    height: "35px",
                                  }),
                                }}
                                id="search-commodity"
                                components={{
                                  IndicatorSeparator: () => null,
                                }}
                                placeholder="Search for a patient..."
                              />
                            </label>
                          </div>
                        </div> */}
                        <div className="col-12 col-md-6 col-xl-6">
                          <div className="form-group local-forms">
                            <label>
                              Check In Date
                              <span className="login-danger">*</span>
                            </label>
                            <DatePicker
                              value={
                                formState.checkin
                                  ? dayjs(formState.checkin)
                                  : null
                              }
                              onChange={(_, dateString) =>
                                handleChange("checkin", dateString)
                              }
                              className="form-control datetimepicker"
                              suffixIcon={null}
                              style={{
                                borderColor: "rgba(46, 55, 164, 0.1)",
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-6">
                          <div className="form-group local-forms">
                            <label>
                              Checkout Date
                              <span className="login-danger">*</span>
                            </label>
                            <DatePicker
                              value={
                                formState.checkout
                                  ? dayjs(formState.checkout)
                                  : null
                              }
                              onChange={(_, dateString) =>
                                handleChange("checkout", dateString)
                              }
                              className="form-control datetimepicker"
                              suffixIcon={null}
                              style={{
                                borderColor: "rgba(46, 55, 164, 0.1)",
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-6">
                          <div className="form-group local-forms">
                            <label>
                              Ward No
                              <span className="login-danger">*</span>
                            </label>
                            <input
                              value={formState.ward_no}
                              onChange={(e) =>
                                handleChange("ward_no", e.target.value)
                              }
                              className="form-control"
                              type="text"
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-6">
                          <div className="form-group local-forms">
                            <label>
                              Bed No
                              <span className="login-danger">*</span>
                            </label>
                            <input
                              value={formState.bed_no}
                              onChange={(e) =>
                                handleChange("bed_no", e.target.value)
                              }
                              className="form-control"
                              type="text"
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-6">
                          <div className="form-group local-forms">
                            <label>
                              Room No
                              <span className="login-danger">*</span>
                            </label>
                            <input
                              value={formState.room_no}
                              onChange={(e) =>
                                handleChange("room_no", e.target.value)
                              }
                              className="form-control"
                              type="text"
                            />
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="doctor-submit text-end">
                            <button
                              onClick={handleAddAdmission}
                              type="submit"
                              className="btn btn-primary submit-form me-2"
                            >
                              Update Admission
                            </button>
                            <button
                              onClick={(e) => {
                                e.preventDefault();
                                navigate("/admin/admission");
                              }}
                              type="submit"
                              className="btn btn-primary cancel-form"
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </>
  );
};

export default UpdateAdmission;

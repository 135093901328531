import React, { useEffect, useState } from "react";

import { Link, useLocation, useNavigate } from "react-router-dom";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import Select from "react-select";
// import { postConsumable } from "../../connections/postConsumable";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import Header from "../../Header";
import Sidebar from "../../Sidebar";
import dayjs from "dayjs";

import { DatePicker } from "antd";
import { putInvoice } from "../../../connections/putInvoice";
import { useQuery } from "@tanstack/react-query";
import { getAllDepartments } from "../../../connections/getAllDepartments";
const Edit_Invoices = () => {
  const [formState, setFormState] = useState({});
  const [invoiceStatus] = useState([
    { id: "generated", value: "Generated" },
    { id: "cancelled", value: "Cancelled" },
    { id: "Refunded", value: "Refunded" },
  ]);
  const [genderOptions] = useState([
    {
      value: "male",
      label: "Male",
    },
    {
      value: "female",
      label: "Female",
    },
    {
      value: "other",
      label: "Other",
    },
  ]);
  const [allDepartment, setAllDepartments] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const invoiceData = location.state || null;
  const {
    user: { user },
  } = useSelector((store) => store);
  const { data: getAllDepartmentsData, isLoading } = useQuery({
    queryKey: ["getAllDepartments", "all"],
    queryFn: () => getAllDepartments(user?.token, "all", null, null),

    cacheTime: 1000 * 60 * 60,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });
  const handleChange = (event) => {
    if (event.target) {
      const { name, value } = event.target;
      setFormState((prevState) => ({ ...prevState, [name]: value }));
    } else {
      setFormState((prevState) => ({
        ...prevState,
        status: event.value,
      }));
    }
  };
  const handleChangeSelect = (field, event) => {
    if (event) {
      setFormState((prevState) => ({
        ...prevState,
        [field]: event.value,
      }));
    } else {
      const newFormState = { ...formState };
      delete newFormState[field];
      setFormState(newFormState);
    }
  };

  const options = invoiceStatus?.map((item) => ({
    value: item.id,
    label: item.value,
  }));

  const handleSubmit = async (e) => {
    if (!formState.name || !formState.status || !formState.mobile) {
      toast.error("Name, status and mobile are required");
      return;
    }
    e.preventDefault();
    const payload = {
      invoice: formState,
    };
    try {
      const result = await putInvoice(user?.token, payload, invoiceData?.id);
      if (result?.status?.status === "SUCCESS") {
        toast.success("Invoice Updated Successfully");
        setFormState({});
        navigate("/admin/invoice-list");
      }
    } catch (error) {
      // toast.error(error?.message);
      console.log(error.message);
    }
  };
  useEffect(() => {
    const data = getAllDepartmentsData?.payload?.departments || [];

    setAllDepartments(
      data?.map((el) => ({
        value: el?.id,
        label: el?.name,
      }))
    );
  }, []);
  useEffect(() => {
    console.log(invoiceData, "inv");
    if (invoiceData) {
      setFormState({
        name: invoiceData?.name,
        uid: invoiceData?.uid,

        mobile: invoiceData?.mobile,
        total: invoiceData?.total,
        grand_total: invoiceData?.grand_total,
        discount_type: invoiceData?.discount_type,
        generated_at: invoiceData?.generated_at,
        age: invoiceData?.age,
        gender: invoiceData?.gender,
        department_id: invoiceData?.department?.id,
        discount: invoiceData?.discount,
        status: invoiceData?.status,
      });
    }
  }, [invoiceData]);

  return (
    <div>
      <Header />
      <Sidebar activeClassName="invoice-list" />
      <>
        <div className="page-wrapper">
          <div className="content">
            {/* Page Header */}
            <div className="page-header">
              <div className="row">
                <div className="col-sm-12">
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to={-1}>Invoice </Link>
                    </li>
                    <li className="breadcrumb-item">
                      <i className="feather-chevron-right">
                        <FeatherIcon icon="chevron-right" />
                      </i>
                    </li>
                    <li className="breadcrumb-item active">Update Invoice</li>
                  </ul>
                </div>
              </div>
            </div>
            {/* /Page form */}
            <div className="row">
              <div className="col-sm-12">
                <div className="card">
                  <div className="card-body">
                    <form>
                      <div className="row">
                        <div className="col-12">
                          <div className="form-heading">
                            <h4>Update Invoice</h4>
                          </div>
                        </div>
                        <div className="col-12 col-md-4 col-xl-3">
                          <div className="form-group local-forms">
                            <label>
                              Name <span className="login-danger">*</span>
                            </label>
                            <input
                              value={formState?.name}
                              name="name"
                              onChange={handleChange}
                              className="form-control"
                              type="text"
                              required={true}
                            />
                          </div>
                        </div>
                        {/* <div className="col-12 col-md-4 col-xl-3">
                          <div className="form-group local-forms">
                            <label>
                              UID
                            
                            </label>
                            <input
                              value={formState?.uid}
                              name="uid"
                              onChange={handleChange}
                              className="form-control"
                              type="text"
                            />
                          </div>
                        </div> */}
                        <div className="col-12 col-md-4 col-xl-3">
                          <div className="form-group local-forms">
                            <label>
                              Status
                              <span className="login-danger">*</span>
                            </label>
                            <Select
                              //   onInputChange={handleSearchConsumableCategory}
                              value={
                                options.find(
                                  (option) => option.value === formState?.status
                                ) || null
                              }
                              isClearable
                              onChange={(e) => handleChangeSelect("status", e)}
                              placeholder="status"
                              options={options}
                              required={true}
                              menuPortalTarget={document.body}
                              styles={{
                                menuPortal: (base) => ({
                                  ...base,
                                  zIndex: 9999,
                                }),
                                control: (baseStyles, state) => ({
                                  ...baseStyles,
                                  borderColor: state.isFocused
                                    ? "#2e37a4"
                                    : "rgba(46, 55, 164, 0.1)",
                                  boxShadow: state.isFocused
                                    ? "0 0 0 1px #2e37a4"
                                    : "none",
                                  "&:hover": {
                                    borderColor: "rgba(46, 55, 164, 0.1)",
                                  },
                                  borderRadius: "10px",
                                  fontSize: "14px",
                                  minHeight: "45px",
                                }),
                                dropdownIndicator: (base, state) => ({
                                  ...base,
                                  transform: state.selectProps.menuIsOpen
                                    ? "rotate(-180deg)"
                                    : "rotate(0)",
                                  transition: "250ms",
                                  width: "35px",
                                  height: "35px",
                                }),
                              }}
                              id="search-commodity"
                              components={{
                                IndicatorSeparator: () => null,
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-4 col-xl-3">
                          <div className="form-group local-forms">
                            <label>
                              Mobile
                              <span className="login-danger">*</span>
                            </label>
                            <input
                              value={formState?.mobile}
                              name="mobile"
                              onChange={handleChange}
                              className="form-control"
                              type="text"
                              required={true}
                            />
                          </div>
                        </div>

                        <div className="col-12 col-md-4 col-xl-3">
                          <div className="form-group local-forms">
                            <label>
                              Total
                              {/* <span className="login-danger">*</span> */}
                            </label>
                            <input
                              value={formState?.total}
                              name="total"
                              onChange={handleChange}
                              className="form-control"
                              type="text"
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-4 col-xl-3">
                          <div className="form-group local-forms">
                            <label>
                              Discount
                              {/* <span className="login-danger">*</span> */}
                            </label>
                            <input
                              value={formState?.discount}
                              name="discount"
                              onChange={handleChange}
                              className="form-control"
                              type="text"
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-4 col-xl-3">
                          <div className="form-group local-forms">
                            <label>
                              Discount type
                              {/* <span className="login-danger">*</span> */}
                            </label>
                            <input
                              value={formState?.discount_type}
                              name="discount_type"
                              onChange={handleChange}
                              className="form-control"
                              type="text"
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-4 col-xl-3">
                          <div className="form-group local-forms">
                            <label>
                              Grand total
                              {/* <span className="login-danger">*</span> */}
                            </label>
                            <input
                              value={formState?.grand_total}
                              name="grand_total"
                              onChange={handleChange}
                              className="form-control"
                              type="text"
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-4 col-xl-3">
                          <div className="form-group local-forms cal-icon">
                            <label>
                              Generated at
                              {/* <span className="login-danger">*</span> */}
                            </label>
                            <DatePicker
                              value={
                                formState?.generated_at
                                  ? dayjs(formState.generated_at)
                                  : null
                              }
                              showTime
                              className="form-control datetimepicker"
                              onChange={(_, dateString) =>
                                setFormState((prevState) => ({
                                  ...prevState,
                                  generated_at: dateString?.split(" ")[0],
                                }))
                              }
                              suffixIcon={null}
                              style={{
                                control: (baseStyles, state) => ({
                                  ...baseStyles,
                                  borderColor: state.isFocused
                                    ? "#2E37A4"
                                    : "2px solid rgba(46, 55, 164, 0.1)",
                                  "&:hover": {
                                    borderColor: state.isFocused
                                      ? "none"
                                      : "none",
                                  },
                                }),
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-4 col-xl-4">
                          <div className="form-group local-forms">
                            <label>
                              Age
                              {/* <span className="login-danger">*</span> */}
                            </label>
                            <input
                              value={formState?.age}
                              name="age"
                              onChange={handleChange}
                              className="form-control"
                              type="text"
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-4 col-xl-4">
                          <div className="form-group local-forms">
                            <label>
                              Gender
                              {/* <span className="login-danger">*</span> */}
                            </label>
                            <Select
                              //   onInputChange={handleSearchConsumableCategory}
                              value={
                                genderOptions.find(
                                  (option) => option.value === formState?.gender
                                ) || null
                              }
                              isClearable
                              onChange={(e) => handleChangeSelect("gender", e)}
                              placeholder="Gender"
                              options={genderOptions}
                              menuPortalTarget={document.body}
                              styles={{
                                menuPortal: (base) => ({
                                  ...base,
                                  zIndex: 9999,
                                }),
                                control: (baseStyles, state) => ({
                                  ...baseStyles,
                                  borderColor: state.isFocused
                                    ? "#2e37a4"
                                    : "rgba(46, 55, 164, 0.1)",
                                  boxShadow: state.isFocused
                                    ? "0 0 0 1px #2e37a4"
                                    : "none",
                                  "&:hover": {
                                    borderColor: "rgba(46, 55, 164, 0.1)",
                                  },
                                  borderRadius: "10px",
                                  fontSize: "14px",
                                  minHeight: "45px",
                                }),
                                dropdownIndicator: (base, state) => ({
                                  ...base,
                                  transform: state.selectProps.menuIsOpen
                                    ? "rotate(-180deg)"
                                    : "rotate(0)",
                                  transition: "250ms",
                                  width: "35px",
                                  height: "35px",
                                }),
                              }}
                              id="search-commodity"
                              components={{
                                IndicatorSeparator: () => null,
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-4 col-xl-4">
                          <div className="form-group local-forms">
                            <label>
                              Department
                              {/* <span className="login-danger">*</span> */}
                            </label>
                            <Select
                              //   onInputChange={handleSearchConsumableCategory}
                              value={
                                allDepartment.find(
                                  (option) =>
                                    option.value === formState?.department_id
                                ) || null
                              }
                              isLoading={isLoading}
                              isClearable
                              onChange={(e) =>
                                handleChangeSelect("department_id", e)
                              }
                              placeholder="Department"
                              options={allDepartment}
                              menuPortalTarget={document.body}
                              styles={{
                                menuPortal: (base) => ({
                                  ...base,
                                  zIndex: 9999,
                                }),
                                control: (baseStyles, state) => ({
                                  ...baseStyles,
                                  borderColor: state.isFocused
                                    ? "#2e37a4"
                                    : "rgba(46, 55, 164, 0.1)",
                                  boxShadow: state.isFocused
                                    ? "0 0 0 1px #2e37a4"
                                    : "none",
                                  "&:hover": {
                                    borderColor: "rgba(46, 55, 164, 0.1)",
                                  },
                                  borderRadius: "10px",
                                  fontSize: "14px",
                                  minHeight: "45px",
                                }),
                                dropdownIndicator: (base, state) => ({
                                  ...base,
                                  transform: state.selectProps.menuIsOpen
                                    ? "rotate(-180deg)"
                                    : "rotate(0)",
                                  transition: "250ms",
                                  width: "35px",
                                  height: "35px",
                                }),
                              }}
                              id="search-commodity"
                              components={{
                                IndicatorSeparator: () => null,
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="doctor-submit text-end">
                            <button
                              onClick={handleSubmit}
                              type="submit"
                              className="btn btn-primary submit-form me-2"
                            >
                              Update Invoice
                            </button>
                            <button
                              onClick={(e) => {
                                e.preventDefault();
                                navigate("/admin/invoice-list");
                              }}
                              type="submit"
                              className="btn btn-primary cancel-form"
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </div>
  );
};

export default Edit_Invoices;

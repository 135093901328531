/* eslint-disable no-undef */
import { toast } from "react-toastify";
import { BASE_URL } from "./connectionUrl";

export const getRolesList = async (
  token,
  type = null,
  page = null,
  per_page = null
) => {
  const endpoint =
    type === null
      ? `/api/v1/admin/roles?page=${page}&per_page=${per_page}`
      : `/api/v1/admin/roles?type=${type}`;
  try {
    const response = await fetch(`${BASE_URL}${endpoint}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    });

    const data = await response.json();
    if (data.status.status === "SUCCESS") {
      return data;
    } else {
      toast.error(data?.status?.message || "something went wrong");
    }
  } catch (error) {
    toast.error(error.message || "something went wrong");
  }
};

import React, { useState } from "react";
import Header from "../Header";
import Sidebar from "../Sidebar";
import { Link, useNavigate } from "react-router-dom";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import Select from "react-select";
// import { DatePicker } from "antd";
import { useQuery } from "@tanstack/react-query";
// import { getPack } from "../../connections/getPack";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { postMedicineSalts } from "../../connections/postMedicineSalts";
import useDebounce from "../../utils/hooks";
import { getAllMedicine } from "../../connections/getAllMedicine";
// import useDebounce from "../../utils/hooks";
const AddMedicineSalts = () => {
  const {
    user: { user },
  } = useSelector((store) => store);
  const navigate = useNavigate();
  const [medDetails, setMedDetails] = useState({});
  const [medicineInput, setMedicineInput] = useState("");
  const [medIds, setMedIds] = useState([]);
  // const [batches, setBatches] = useState([]);
  // const [formType, setFormType] = useState([]);
  // const [pack, setPack] = useState([]);
  // const [inputValue, setInputValue] = useState("");

  // const { data: getPackData } = useQuery({
  //   queryKey: ["getPack"],
  //   queryFn: () => getPack(user?.token),
  //
  //   refetchOnWindowFocus: false, // Don't refetch when window regains focus
  //   refetchOnReconnect: false, // Don't refetch when reconnecting
  // });
  const handleChangeMed = (field, val) => {
    setMedDetails((prev) => ({
      ...prev,
      [field]: val,
    }));
  };
  // medicine Options
  const debouncedInputMedicine = useDebounce(medicineInput, 1000);
  const { data: getAllMedicineData } = useQuery({
    queryKey: ["getAllMedicine", debouncedInputMedicine],
    queryFn: () =>
      getAllMedicine(user?.token, "q", 1, 10, debouncedInputMedicine),
    enabled: !!debouncedInputMedicine,
    cacheTime: 1000 * 60 * 60,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });
  const allMedicine = getAllMedicineData?.payload?.medicines || [];
  const formattedMedicineOptions = allMedicine?.map((el) => ({
    value: el?.id,
    label: el.name,
  }));

  const handleMedicineChange = (value) => {
    setMedicineInput(value);
  };

  const handleChangeMedicine = (value) => {
    setMedIds(value);
  };
  // console.log(medIds);
  // const handleChangeBatch = (index, field, value) => {
  //   const newMedBatch = [...batches];
  //   newMedBatch[index] = { ...newMedBatch[index], [field]: value };
  //   setBatches(newMedBatch);
  // };
  // const handleSearchMedSalt = (inputValue) => {
  //   const query = inputValue?.toLowerCase();
  //   const storeSuppliers = JSON.parse(localStorage.getItem("allMedSalt")) || [];

  //   const filteredList = storeSuppliers.filter((el) =>
  //     el?.name?.toLowerCase().startsWith(query || "")
  //   );

  //   const limitedResults = (query ? filteredList : storeSuppliers)
  //     .slice(0, 10)
  //     .map((el) => ({
  //       value: el?.id,
  //       label: el?.name,
  //     }));

  //   setAllMedSalt(limitedResults);
  // };

  // const formTypeOptions = formType.map((item) => ({
  //   value: item.value,
  //   label: item.value,
  // }));
  // const options = pack?.map((item) => ({
  //   value: item.value,
  //   label: item.value,
  // }));
  // const debouncedInput = useDebounce(inputValue, 700);
  // const handleInputChange = (inputValue) => {
  //   setInputValue(inputValue);
  // };
  // const handleAddRow = (e) => {
  //   e.preventDefault();
  //   setBatches((prev) => [
  //     ...prev,
  //     {
  //       name: "",
  //       discount: 0,
  //       batch: "",
  //       company: "",
  //       amount: 0,
  //       purchase_cost: "",
  //       form_type: "",
  //       packing: "",
  //       stock_left: "",
  //       gst: "",
  //       units_purchased: 0,
  //       auto_purchase_threshold: 0,
  //       expiry_date: "",
  //       manufacture_date: "",
  //       delivered_at: "",
  //       order_at: "",
  //     },
  //   ]);
  // };
  // const handleRemoveRow = (e, idx) => {
  //   e.preventDefault();

  //   const newRow = [...batches];
  //   const updatedRow = newRow?.filter((_, index) => index !== idx);
  //   setBatches(updatedRow);
  // };
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!medDetails?.name) {
      toast.error("Name is required");
      return;
    }
    // // Check if any batch has an empty name or amount
    // const hasInvalidBatch = batches.some(
    //   (batch) => !batch.name || !batch.amount
    // );

    // if (medIds?.length <= 0) {
    //   toast.error("Select one medicine minimum");
    //   return;
    // }
    const payload = {
      medicine_salt: {
        ...medDetails,
      },
      ...(medIds.length > 0 && {
        medicines_ids: medIds?.map((el) => el.value),
      }),
    };

    try {
      const result = await postMedicineSalts(user?.token, payload);
      if (result?.status?.status === "SUCCESS") {
        toast.success("Medicine Salts Added Successfully");
        navigate("/admin/medicine-salts");
        // setBatches([]);
        // setMedDetails({});
      }
    } catch (error) {
      // toast.error(error.message);
      console.log(error.message);
    }
  };
  // useEffect(() => {
  //   const data = JSON.parse(localStorage.getItem("allFormType"));
  //   const formattedFormType = data?.map((el) => ({
  //     value: el,
  //     label: el,
  //   }));
  //   setFormType(formattedFormType);
  //   if (getPackData?.packing?.length > 0) {
  //     const newPack = getPackData?.packing
  //       ?.filter((el) => el !== null)
  //       ?.map((el) => ({
  //         label: el,
  //         value: el,
  //       }));
  //     setPack((prev) => [...prev, ...newPack]);
  //   }
  // }, [getPackData]);
  // useEffect(() => {
  //   if (debouncedInput) {
  //     const optionExists = pack.some((item) => item.value === debouncedInput);

  //     if (!optionExists) {
  //       const newOption = {
  //         value: debouncedInput,
  //         label: debouncedInput,
  //       };
  //       setPack((prevPack) => [...prevPack, newOption]);
  //     }
  //   }
  // }, [debouncedInput, pack]);
  return (
    <div>
      <Header />
      <Sidebar activeClassName="medicine-salt-list" />
      <>
        <div className="page-wrapper">
          <div className="content">
            {/* Page Header */}
            <div className="page-header">
              <div className="row">
                <div className="col-sm-12">
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to={-1}> Medicine Salts</Link>
                    </li>
                    <li className="breadcrumb-item">
                      <i className="feather-chevron-right">
                        <FeatherIcon icon="chevron-right" />
                      </i>
                    </li>
                    <li className="breadcrumb-item active">
                      Add Medicine Salts
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            {/* /Page Header */}
            <div className="row">
              <div className="col-sm-12">
                <div className="card">
                  <div className="card-body">
                    <form>
                      <div className="row">
                        <div className="col-12">
                          <div className="form-heading">
                            <h4>Medicine Salts</h4>
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-6">
                          <div className="form-group local-forms">
                            <label>
                              Name
                              <span className="login-danger">*</span>
                            </label>
                            <input
                              value={medDetails?.name}
                              onChange={(e) =>
                                handleChangeMed("name", e?.target?.value)
                              }
                              className="form-control"
                              type="text"
                              required={true}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-6">
                          <div className="form-group local-forms">
                            <label>
                              Medicine
                              {/* <span className="login-danger">*</span> */}
                            </label>
                            <Select
                              options={formattedMedicineOptions}
                              value={medIds?.map((test) => ({
                                value: test.value,
                                label: test.label,
                              }))}
                              onInputChange={handleMedicineChange}
                              onChange={(selectedOptions) => {
                                handleChangeMedicine(selectedOptions);
                              }}
                              isMulti
                              styles={{
                                menu: (provided) => ({
                                  ...provided,
                                  zIndex: 9999,
                                }),
                                menuPortal: (base) => ({
                                  ...base,
                                  zIndex: 999999,
                                }),
                                control: (baseStyles, state) => ({
                                  ...baseStyles,
                                  borderColor: state.isFocused
                                    ? "#2e37a4"
                                    : "rgba(46, 55, 164, 0.1)",
                                  boxShadow: state.isFocused
                                    ? "0 0 0 1px #2e37a4"
                                    : "none",
                                  "&:hover": {
                                    borderColor: "rgba(46, 55, 164, 0.1)",
                                  },
                                  borderRadius: "10px",
                                  fontSize: "14px",
                                  minHeight: "45px",
                                }),
                                dropdownIndicator: (base, state) => ({
                                  ...base,
                                  transform: state.selectProps.menuIsOpen
                                    ? "rotate(-180deg)"
                                    : "rotate(0)",
                                  transition: "250ms",
                                  width: "35px",
                                  height: "35px",
                                }),
                                option: (provided) => ({
                                  ...provided,
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  maxWidth: "100%",
                                  fontSize: "14px",
                                }),
                              }}
                              // isMulti
                              isSearchable
                              placeholder="Choose medicine"
                              className="react-select"
                              classNamePrefix="select"
                              // components={{ MenuList }}
                            />
                          </div>
                        </div>
                        {/* {part two} */}
                        {/* <div className="col-12">
                          <div className="form-heading">
                            <button
                              className="btn btn-primary cancel-form mb-4"
                              disabled
                            >
                              Brands
                            </button>
                          </div>
                        </div> */}
                        {/* {batches?.length > 0 &&
                          batches?.map((el, index) => (
                            <React.Fragment key={index}>
                              <div className="col-12 col-md-4 col-xl-2">
                                <div className="form-group local-forms">
                                  <label>Name</label>
                                  <input
                                    value={el[index]?.name}
                                    onChange={(e) =>
                                      handleChangeBatch(
                                        index,
                                        "name",
                                        e?.target?.value
                                      )
                                    }
                                    className="form-control"
                                    type="text"
                                  />
                                </div>
                              </div>
                              <div className="col-12 col-md-4 col-xl-2">
                                <div className="form-group local-forms">
                                  <label>
                                    Company
                                  </label>
                                  <input
                                    value={medDetails?.company}
                                    onChange={(e) =>
                                      handleChangeBatch(
                                        index,
                                        "company",
                                        e?.target?.value
                                      )
                                    }
                                    className="form-control"
                                    type="text"
                                  />
                                </div>
                              </div>

                              <div className="col-12 col-md-4 col-xl-2">
                                <div className="form-group local-forms">
                                  <label>Form Type </label>
                                  <Select
                                    value={el[index]?.form_type}
                                    onChange={(e) =>
                                      handleChangeBatch(
                                        index,
                                        "form_type",
                                        e?.value
                                      )
                                    }
                                    options={formTypeOptions}
                                    menuPortalTarget={document.body}
                                    styles={{
                                      menuPortal: (base) => ({
                                        ...base,
                                        zIndex: 9999,
                                      }),
                                    }}
                                    id="search-commodity"
                                    components={{
                                      IndicatorSeparator: () => null,
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="col-12 col-md-4 col-xl-2">
                                <div className="form-group local-forms">
                                  <label>Discount</label>
                                  <input
                                    value={medDetails?.discount}
                                    onChange={(e) =>
                                      handleChangeBatch(
                                        index,
                                        "discount",
                                        e?.target?.value
                                      )
                                    }
                                    className="form-control"
                                    type="number"
                                  />
                                </div>
                              </div>
                              <div className="col-12 col-md-4 col-xl-2">
                                <div className="form-group local-forms">
                                  <label>Batch</label>
                                  <input
                                    value={medDetails?.batch}
                                    onChange={(e) =>
                                      handleChangeBatch(
                                        index,
                                        "batch",
                                        e?.target?.value
                                      )
                                    }
                                    className="form-control"
                                    type="text"
                                  />
                                </div>
                              </div>
                              <div className="col-12 col-md-4 col-xl-2">
                                <div className="form-group local-forms cal-icon">
                                  <label>Manufacture date</label>
                                  <DatePicker
                                    value={el[index]?.manufacture_date}
                                    className="form-control datetimepicker"
                                    onChange={(_, dateString) =>
                                      handleChangeBatch(
                                        index,
                                        "manufacture_date",
                                        dateString
                                      )
                                    }
                                    suffixIcon={null}
                                    style={{
                                      control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        borderColor:
                                          "2px solid rgba(46, 55, 164, 0.1)",
                                        "&:hover": {
                                          borderColor: state.isFocused
                                            ? "none"
                                            : "none",
                                        },
                                      }),
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="col-12 col-md-4 col-xl-2">
                                <div className="form-group local-forms cal-icon">
                                  <label>Expiry date</label>
                                  <DatePicker
                                    value={el[index]?.expiry_date}
                                    className="form-control datetimepicker"
                                    onChange={(_, dateString) =>
                                      handleChangeBatch(
                                        index,
                                        "expiry_date",
                                        dateString
                                      )
                                    }
                                    suffixIcon={null}
                                    style={{
                                      control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        borderColor:
                                          "2px solid rgba(46, 55, 164, 0.1)",
                                        "&:hover": {
                                          borderColor: state.isFocused
                                            ? "none"
                                            : "none",
                                        },
                                      }),
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="col-12 col-md-4 col-xl-2">
                                <div className="form-group local-forms">
                                  <label>Amount</label>
                                  <input
                                    value={el[index]?.amount}
                                    onChange={(e) =>
                                      handleChangeBatch(
                                        index,
                                        "amount",
                                        e?.target?.value
                                      )
                                    }
                                    className="form-control"
                                    type="number"
                                  />
                                </div>
                              </div>
                              <div className="col-12 col-md-4 col-xl-2">
                                <div className="form-group local-forms">
                                  <label>Purchase cost</label>
                                  <input
                                    value={el[index]?.purchase_cost}
                                    onChange={(e) =>
                                      handleChangeBatch(
                                        index,
                                        "purchase_cost",
                                        e?.target?.value
                                      )
                                    }
                                    className="form-control"
                                    type="number"
                                  />
                                </div>
                              </div>
                              <div className="col-12 col-md-4 col-xl-2">
                                <div className="form-group local-forms">
                                  <label>Packing</label>
                                  <Select
                                    value={el[index]?.packing}
                                    onChange={(e) => {
                                      handleChangeBatch(
                                        index,
                                        "packing",
                                        e?.value 
                                      );
                                    }}
                                    onInputChange={handleInputChange}
                                    options={pack}
                                    placeholder="select pack"
                                    menuPortalTarget={document.body}
                                    styles={{
                                      menuPortal: (base) => ({
                                        ...base,
                                        zIndex: 9999,
                                      }),
                                      control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        borderColor: state.isFocused
                                          ? "#2e37a4"
                                          : "rgba(46, 55, 164, 0.1)",
                                        boxShadow: state.isFocused
                                          ? "0 0 0 1px #2e37a4"
                                          : "none",
                                        "&:hover": {
                                          borderColor: "rgba(46, 55, 164, 0.1)",
                                        },
                                        borderRadius: "10px",
                                        fontSize: "14px",
                                        minHeight: "45px",
                                      }),
                                      dropdownIndicator: (base, state) => ({
                                        ...base,
                                        transform: state.selectProps.menuIsOpen
                                          ? "rotate(-180deg)"
                                          : "rotate(0)",
                                        transition: "250ms",
                                        width: "35px",
                                        height: "35px",
                                      }),
                                    }}
                                    components={{
                                      IndicatorSeparator: () => null,
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="col-12 col-md-4 col-xl-2">
                                <div className="form-group local-forms">
                                  <label>Stock left</label>
                                  <input
                                    value={el[index]?.stock_left}
                                    onChange={(e) =>
                                      handleChangeBatch(
                                        index,
                                        "stock_left",
                                        e?.target?.value
                                      )
                                    }
                                    className="form-control"
                                    type="number"
                                  />
                                </div>
                              </div>
                              <div className="col-12 col-md-4 col-xl-2">
                                <div className="form-group local-forms cal-icon">
                                  <label>Order At</label>
                                  <DatePicker
                                    value={el[index]?.purchased_at}
                                    className="form-control datetimepicker"
                                    onChange={(_, dateString) =>
                                      handleChangeBatch(
                                        index,
                                        "purchased_at",
                                        dateString
                                      )
                                    }
                                    suffixIcon={null}
                                    style={{
                                      control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        borderColor:
                                          "2px solid rgba(46, 55, 164, 0.1)",
                                        "&:hover": {
                                          borderColor: state.isFocused
                                            ? "none"
                                            : "none",
                                        },
                                      }),
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="col-12 col-md-4 col-xl-2">
                                <div className="form-group local-forms cal-icon">
                                  <label>Delivered At</label>
                                  <DatePicker
                                    value={el[index]?.delivered_at}
                                    className="form-control datetimepicker"
                                    onChange={(_, dateString) =>
                                      handleChangeBatch(
                                        index,
                                        "delivered_at",
                                        dateString
                                      )
                                    }
                                    suffixIcon={null}
                                    style={{
                                      control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        borderColor:
                                          "2px solid rgba(46, 55, 164, 0.1)",
                                        "&:hover": {
                                          borderColor: state.isFocused
                                            ? "none"
                                            : "none",
                                        },
                                      }),
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="col-12 col-md-4 col-xl-2">
                                <div className="form-group local-forms">
                                  <label>GST</label>
                                  <input
                                    value={medDetails?.srp}
                                    onChange={(e) =>
                                      handleChangeBatch(
                                        index,
                                        "gst",
                                        e?.target?.value
                                      )
                                    }
                                    className="form-control"
                                    type="text"
                                  />
                                </div>
                              </div>
                              <div className="col-12 col-md-4 col-xl-2">
                                <div className="form-group local-forms">
                                  <label>
                                    Units purchased
                                  </label>
                                  <input
                                    value={medDetails?.units_purchased}
                                    onChange={(e) =>
                                      handleChangeBatch(
                                        index,
                                        "units_purchased",
                                        e?.target?.value
                                      )
                                    }
                                    className="form-control"
                                    type="number"
                                  />
                                </div>
                              </div>

                              <div className="col-12 col-md-4 col-xl-2">
                                <div className="form-group local-forms">
                                  <label>
                                    Auto Purchase
                                  </label>
                                  <input
                                    value={medDetails?.auto_purchase_threshold}
                                    onChange={(e) =>
                                      handleChangeBatch(
                                        index,
                                        "auto_purchase_threshold",
                                        e?.target?.value
                                      )
                                    }
                                    className="form-control"
                                    type="number"
                                  />
                                </div>
                              </div>

                              <div
                                style={{ marginBottom: "20px" }}
                                className="col-12 col-md-6 col-xl-3"
                              >
                                <button
                                  style={{
                                    maxWidth: "200px",
                                  }}
                                  onClick={(e) => handleRemoveRow(e, index)}
                                  className="btn btn-primary submit-form me-2 form-control"
                                >
                                  Remove
                                </button>
                              </div>
                            </React.Fragment>
                          ))} */}
                        {/* <div
                          style={{ display: "inline-block" }}
                          className="col-12 col-md-6 col-xl-2"
                        >
                          <button
                            style={{
                              display: "block !important",
                              // maxWidth: "200px",
                            }}
                            className="btn btn-primary submit-form me-2 form-control mb-4"
                            onClick={handleAddRow}
                          >
                            Add New Item
                          </button>
                        </div> */}
                        <div className="col-12">
                          <div className="doctor-submit text-end">
                            <button
                              onClick={handleSubmit}
                              type="submit"
                              className="btn btn-primary submit-form me-2"
                            >
                              Submit
                            </button>
                            <button
                              onClick={(e) => {
                                e.preventDefault();
                                navigate("/admin/medicine-salts");
                              }}
                              type="submit"
                              className="btn btn-primary cancel-form"
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </div>
  );
};

export default AddMedicineSalts;

import React, { useEffect, useState } from "react";
import Header from "../../Header";
import Sidebar from "../../Sidebar";
// import Select from "react-select";
import CountUp from "react-countup";
import {
  // blogimg12,
  // blogimg4,
  // blogimg6,
  doctor_dashboard_01,
  doctor_dashboard_02,
  doctor_dashboard_03,
  // doctor_dashboard_04,
  // imgicon,
  morning_img_02,
  // profileicon,
  // sorticon,
  // sorticon02,
  // timericon,
  // trashicon,
} from "../../imagepath";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
// import RadialPatientsChart from "./DonutChart";
import { Link } from "react-router-dom";
// import ActivityChart from "./ActivityChart";
// import MarketAreaChart from "./IncomeChart";
// import RadialBarChart from "../Patient_Dashboard/FullChart";
import {
  getCurrentMonthStartEndIST,
  getGreeting,
} from "../../../utils/constant/constant";
// import { getAllDashboardSummaryCount } from "../../../connections/getAllDashboardSummaryCount";
import { useQuery } from "@tanstack/react-query";
import { useSelector } from "react-redux";
// import { getAllDoctorDashboardSummary } from "../../../connections/getAllDoctorDashboardSummary";
import { Table } from "antd";
import { getDoctorDayWiseAppointment } from "../../../connections/getDoctorDayWiseAppointment";
//import { getTotalDoctorAppointment } from "../../../connections/getTotalDoctorAppointment";
import { getTotalVisited } from "../../../connections/getTotalVisited";
import { getDepartmentWiseCollection } from "../../../connections/getDepartmentWiseCollection";
import { Skeleton, TextField } from "@mui/material";
import { getStaffWiseCollection } from "../../../connections/getStaffWiseCollection";
import { getDoctorWiseCollection } from "../../../connections/getDoctorWiseCollection";
import { getMedPurchaseDetails } from "../../../connections/getMedPurchaseDetails";
import { getMedSalesDetails } from "../../../connections/getMedSalesDetails";
import { getInventoryStock } from "../../../connections/getInventoryStock";
import dayjs from "dayjs";
import { getAllDashboardSummaryCount } from "../../../connections/getAllDashboardSummaryCount";

// import { CircularProgressbar } from "react-circular-progressbar";
const Doctor_Dashboard = () => {
  const { startDateOfMonth, endDateOfMonth, currentDate } =
    getCurrentMonthStartEndIST();
  const [totalVisitedData, setTotalVisitedData] = useState([]);
  const [totalCountVisited, setTotalCountVisited] = useState(0);
  const [totalTodaysSummary, setTotalTodaysSummary] = useState({});
  const [startDate, setStartDate] = useState(dayjs().format("YYYY-MM-DD"));
  const [endDate, setEndDate] = useState(dayjs().format("YYYY-MM-DD"));
  const [medPurchase, setMedPurchase] = useState({
    startDate: startDateOfMonth,
    endDate: endDateOfMonth,
  });
  // const [medSales, setMedSales] = useState({
  //   startDate: startDateOfMonth,
  //   endDate: endDateOfMonth,
  // });
  // const [inventoryStockDate, setInventoryStockDate] = useState({
  //   startDate: startDateOfMonth,
  //   endDate: endDateOfMonth,
  // });
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const {
    user: { user },
  } = useSelector((store) => store);
  const { data: getAllDoctorDashboardSummaryData } = useQuery({
    queryKey: ["getAllDashboardSummaryCount"],
    queryFn: () =>
      getAllDashboardSummaryCount(user?.token, currentDate, currentDate),
    enabled: !!user?.token,
    cacheTime: 1000 * 60 * 60,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });
  const { data: getAllAppointmentDoctor, isLoading: AppointmentLoader } =
    useQuery({
      queryKey: [
        "getDoctorDayWiseAppointment",
        currentDate,
        currentDate,
        page,
        pageSize,
      ],
      queryFn: () =>
        getDoctorDayWiseAppointment(
          user?.token,
          currentDate,
          currentDate,
          page,
          pageSize
        ),
      enabled: !!user?.token,
      cacheTime: 1000 * 60 * 60,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    });
  // const { data: getTotalAppointmentDoctor } = useQuery({
  //   queryKey: ["getTotalDoctorAppointment", startDateOfMonth, endDateOfMonth],
  //   queryFn: () =>
  //     getTotalDoctorAppointment(user?.token, startDateOfMonth, endDateOfMonth),
  //   enabled: !!user?.token,
  //   cacheTime: 1000 * 60 * 60,
  //   refetchOnWindowFocus: false,
  //   refetchOnReconnect: false,
  // });
  const { data: getAllTotalVisited } = useQuery({
    queryKey: ["getTotalVisited", currentDate, currentDate],
    queryFn: () => getTotalVisited(user?.token, currentDate, currentDate),
    enabled: !!user?.token,
    cacheTime: 1000 * 60 * 60,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });
  const {
    data: getAllDepartmentCollection,
    refetch: refetchDepartmentCollection,
  } = useQuery({
    queryKey: ["getDepartmentWiseCollection"],
    queryFn: () => getDepartmentWiseCollection(user?.token, startDate, endDate),
    enabled: !!user?.token,
    cacheTime: 1000 * 60 * 60,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });
  const {
    data: getStaffWiseCollectionData,
    isLoading: staffTableLoader,
    refetch: staffWiseRefetch,
  } = useQuery({
    queryKey: ["getStaffWiseCollection"],
    queryFn: () => getStaffWiseCollection(user?.token, startDate, endDate),
    enabled: !!user?.token,
    cacheTime: 1000 * 60 * 60,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });
  const {
    data: getDrWiseCollection,
    isLoading: doctorTableLoader,
    refetch: doctorWiseCollectionRefetch,
  } = useQuery({
    queryKey: ["getDoctorWiseCollection"],
    queryFn: () => getDoctorWiseCollection(user?.token, startDate, endDate),
    enabled: !!user?.token,
    cacheTime: 1000 * 60 * 60,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });
  const {
    data: getMedPurchaseData,
    isLoading: MedPurchaseDataLoader,
    refetch: refetchMedPurchase,
  } = useQuery({
    queryKey: ["getMedPurchaseDetails"],
    queryFn: () =>
      getMedPurchaseDetails(
        user?.token,
        medPurchase?.startDate,
        medPurchase?.endDate
      ),
    enabled: !!user?.token,
    cacheTime: 1000 * 60 * 60,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });
  const {
    data: getMedSalesData,
    isLoading: medSalesLoader,
    refetch: refetchMedSales,
  } = useQuery({
    queryKey: ["getMedSalesDetails"],
    queryFn: () =>
      getMedSalesDetails(
        user?.token,
        medPurchase?.startDate,
        medPurchase?.endDate
      ),
    enabled: !!user?.token,
    cacheTime: 1000 * 60 * 60,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });
  const {
    data: getInventoryStockData,
    isLoading: inventoryStockLoader,
    refetch: refetchInventoryStock,
  } = useQuery({
    queryKey: ["getInventoryStock"],
    queryFn: () =>
      getInventoryStock(
        user?.token,
        medPurchase?.startDate,
        medPurchase?.endDate
      ),
    enabled: !!user?.token,
    cacheTime: 1000 * 60 * 60,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });

  const columns = [
    {
      title: " ",
      dataIndex: "full_name",
      align: "center",
    },
    {
      title: "Seen",
      dataIndex: "seen",
      align: "center",
    },
    {
      title: "New Seen",
      dataIndex: "new_seen",
      align: "center",
    },
    {
      title: "Old Seen",
      dataIndex: "old_seen",
      align: "center",
    },
    {
      title: "Missed",
      dataIndex: "missed",
      align: "center",
    },
    {
      title: "Visited",
      dataIndex: "visited",
      align: "center",
    },
    {
      title: "Appointed",
      dataIndex: "appointed",
      align: "center",
    },
  ];
  const columnsVisited = [
    {
      title: " ",
      dataIndex: "category",
      key: "category",
      align: "center",
      render: (category) => (
        <span style={{ textTransform: "capitalize" }}>{category}</span>
      ),
    },
    {
      title: "New",
      dataIndex: "new",
      key: "new",
      align: "center",
    },
    {
      title: "Old",
      dataIndex: "old",
      key: "old",
      align: "center",
    },
    {
      title: "Review",
      dataIndex: "review",
      key: "review",
      align: "center",
    },
    {
      title: "Compl",
      dataIndex: "compl",
      key: "compl",
      align: "center",
    },
    {
      title: "Copy",
      dataIndex: "copy",
      key: "copy",
      align: "center",
    },
  ];
  const StaffCollectionColumns = [
    {
      title: "Name",
      dataIndex: "full_name",
      render: (text, record) => (
        <Link
          style={{ textDecoration: "underline" }}
          to={`/admin/collections/${record?.id}`}
        >
          {text}
        </Link>
      ),
    },
    {
      title: "Appointment",
      dataIndex: "appointment",
    },
    {
      title: "Lab",
      dataIndex: "lab",
    },
    {
      title: "X-Ray",
      dataIndex: "xray",
    },
    {
      title: "Dietician",
      dataIndex: "dietician",
    },
    {
      title: "Phy",
      dataIndex: "phy",
    },
    {
      title: "Total",
      dataIndex: "total",
    },
  ];
  const doctorCollectionColumns = [
    {
      title: "Doctor Name",
      dataIndex: "full_name",
      render: (text, record) => (
        <Link
          to={`/admin/doctor/${record?.id}`}
          style={{ textDecoration: "underline" }}
        >
          {text}
        </Link>
      ),
    },
    {
      title: "Department",
      dataIndex: "department",
    },
    {
      title: "Collection",
      dataIndex: "collection",
    },
    {
      title: "Revenue Share (in figures)",
      dataIndex: "revenue_share_in_figures",
      render: (_, record) => {
        return (
          <>
            <span>
              Total Share: {record?.revenue_share_in_figures?.total_share}
            </span>
            {record?.revenue_share_in_figures?.collection_share?.length > 0 && (
              <ul style={{ paddingLeft: "15px", marginTop: "5px" }}>
                {record.revenue_share_in_figures.collection_share.map(
                  (el, index) => (
                    <li key={index}>
                      <span
                        style={{
                          textTransform: "capitalize",
                          fontSize: "1ppx",
                        }}
                      >
                        {el?.department}:{" "}
                        <strong>{el?.collection_share}</strong>
                      </span>
                    </li>
                  )
                )}
              </ul>
            )}
          </>
        );
      },
    },
    {
      title: "Revenue Share (%)",
      dataIndex: "revenue_share_in_percentage",
      render: (revenue_share_in_percentage) => (
        <>{revenue_share_in_percentage?.toFixed(2)}</>
      ),
    },
    {
      title: "Hospital's Net Profit",
      dataIndex: "hospitals_net_profit",
      render: (hospitals_net_profit) => <>{hospitals_net_profit?.toFixed(2)}</>,
    },
  ];
  const inventoryStockColumns = [
    {
      title: "Item",
      dataIndex: "item",
      key: "item",
    },
    {
      title: "Control",
      dataIndex: "control",
      key: "control",
    },
    {
      title: "Wastage",
      dataIndex: "wastage",
      key: "wastage",
    },
    {
      title: "Films Used",
      dataIndex: "films_used",
      key: "films_used",
    },
    {
      title: "Number of Test",
      dataIndex: "number_of_test",
      key: "number_of_test",
    },
  ];
  const SaltColumns = [
    {
      title: "Item",
      dataIndex: "item",
      key: "item",
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
    },
  ];
  const purchasedColumns = [
    {
      title: "Item",
      dataIndex: "item",
      key: "item",
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
    },
  ];
  const handlePageChange = (newPage, newPageSize) => {
    setPage(newPage);
    setPageSize(newPageSize);
    // refetchSuppliersList();
  };
  const handleStartDateChange = (event) => {
    setStartDate(event.target.value);
  };

  const handleEndDateChange = (event) => {
    // console.log(event.target.value);
    setEndDate(event.target.value);
  };
  const handleSubmitDateFilter = () => {
    refetchDepartmentCollection(startDate, endDate);
    staffWiseRefetch(startDate, endDate);
    doctorWiseCollectionRefetch(startDate, endDate);
  };
  const handleMedPurchaseDate = (e) => {
    const { name, value } = e.target;
    setMedPurchase((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const handleSubmitMedPurchase = () => {
    refetchMedPurchase(medPurchase?.startDate, medPurchase?.endDate);
    refetchInventoryStock(medPurchase?.startDate, medPurchase?.endDate);
    refetchMedSales(medPurchase?.startDate, medPurchase?.endDate);
  };
  // const handleChangeMedSalesDate = (e) => {
  //   const { name, value } = e.target;
  //   setMedSales((prev) => ({
  //     ...prev,
  //     [name]: value,
  //   }));
  // };
  // const handleSubmitMedSales = () => {
  //   refetchMedSales(medSales?.startDate, medSales?.endDate);
  // };
  // const handleChangeInventoryStackDate = (e) => {
  //   const { name, value } = e.target;
  //   setInventoryStockDate((prev) => ({
  //     ...prev,
  //     [name]: value,
  //   }));
  // };
  // const handleSubmitInventoryStack = () => {
  //   refetchInventoryStock(
  //     inventoryStockDate?.startDate,
  //     inventoryStockDate?.endDate
  //   );
  // };
  useEffect(() => {
    if (getAllTotalVisited) {
      const transformedData = Object.entries(getAllTotalVisited).map(
        ([category, values]) => ({
          category, // 'all', 'appointed', or 'waiting'
          ...values,
        })
      );
      const { all } = getAllTotalVisited;

      // Function to calculate the sum of a single object
      const calculateSum = (source) => {
        return (
          (source.new || 0) +
          (source.old || 0) +
          (source.review || 0) +
          (source.compl || 0) +
          (source.copy || 0)
        );
      };

      // Calculate total sum from all objects
      const totalSum = calculateSum(all);
      // console.log(all, totalSum);

      // Save the total sum
      setTotalCountVisited(totalSum);
      setTotalVisitedData(transformedData);
    }
    if (getAllAppointmentDoctor?.payload?.doctors) {
      const doctors = getAllAppointmentDoctor.payload.doctors;

      const totals = doctors.reduce(
        (acc, doctor) => {
          acc.seen += doctor.seen || 0;
          acc.new_seen += doctor.new_seen || 0;
          acc.old_seen += doctor.old_seen || 0;
          acc.missed += doctor.missed || 0;
          acc.visited += doctor.visited || 0;
          acc.appointed += doctor.appointed || 0;
          return acc;
        },
        {
          seen: 0,
          new_seen: 0,
          old_seen: 0,
          missed: 0,
          visited: 0,
          appointed: 0,
        }
      );
      // console.log(totals);
      setTotalTodaysSummary(totals);
    }
  }, [getAllTotalVisited, getAllAppointmentDoctor]);

  return (
    <>
      <Header />
      <Sidebar
        id="menu-item"
        id1="menu-items"
        activeClassName="doctor-dashboard"
      />
      <>
        <div className="page-wrapper">
          <div className="content">
            {/* Page Header */}
            <div className="page-header">
              <div className="row">
                <div className="col-sm-12">
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="#">Dashboard </Link>
                    </li>
                    <li className="breadcrumb-item">
                      <i className="feather-chevron-right">
                        <FeatherIcon icon="chevron-right" />
                      </i>
                    </li>
                    <li className="breadcrumb-item active">Doctor Dashboard</li>
                  </ul>
                </div>
              </div>
            </div>
            {/* /Page Header */}
            <div className="good-morning-blk">
              <div className="row">
                <div className="col-md-6">
                  <div className="morning-user">
                    <h2>
                      {getGreeting()},{" "}
                      <span style={{ textTransform: "capitalize" }}>
                        {user?.email ? user?.email?.split("@")[0] : null}
                      </span>
                    </h2>
                    <p>Have a nice day at work</p>
                  </div>
                </div>
                <div className="col-md-6 position-blk">
                  <div className="morning-img">
                    <img src={morning_img_02} alt="" />
                  </div>
                </div>
              </div>
            </div>
            <div className="doctor-list-blk">
              <div className="row">
                <div className="col-xl-4 col-md-6">
                  <div className="doctor-widget border-right-bg">
                    <div className="doctor-box-icon flex-shrink-0">
                      <img src={doctor_dashboard_01} alt="" />
                    </div>
                    <div className="doctor-content dash-count flex-grow-1">
                      <h4>
                        <CountUp
                          delay={0.4}
                          end={
                            getAllDoctorDashboardSummaryData?.new_appointments
                          }
                          duration={0.6}
                        />
                        {/* <span className="counter-up">
                          /
                          {getAllDoctorDashboardSummaryData?.total_appointments}
                        </span> */}
                        <span className="status-green display-none">+40%</span>
                      </h4>
                      <h5>Appointments</h5>
                    </div>
                  </div>
                </div>
                <div className="col-xl-4 col-md-6">
                  <div className="doctor-widget border-right-bg">
                    <div className="doctor-box-icon flex-shrink-0">
                      <img src={doctor_dashboard_02} alt="" />
                    </div>
                    <div className="doctor-content dash-count flex-grow-1">
                      <h4>
                        <CountUp
                          delay={0.4}
                          end={getAllDoctorDashboardSummaryData?.new_patients}
                          duration={0.6}
                        />
                        {/* <span className="counter-up">
                          /{getAllDoctorDashboardSummaryData?.total_patients}
                        </span> */}
                        <span className="status-green display-none">+40%</span>
                      </h4>
                      <h5>Patients</h5>
                    </div>
                  </div>
                </div>
                <div className="col-xl-4 col-md-6">
                  <div className="doctor-widget ">
                    <div className="doctor-box-icon flex-shrink-0">
                      <img src={doctor_dashboard_03} alt="" />
                    </div>
                    <div className="doctor-content dash-count flex-grow-1">
                      <h4>
                        <CountUp
                          delay={0.4}
                          end={getAllDoctorDashboardSummaryData?.new_invoices}
                          duration={0.6}
                        />
                        {/* <span className="counter-up">
                          /{getAllDoctorDashboardSummaryData?.total_invoices}
                        </span> */}
                        <span className="status-green display-none">+40%</span>
                      </h4>
                      <h5>Invoices</h5>
                    </div>
                  </div>
                </div>
                {/* <div className="col-xl-3 col-md-6">
                  <div className="doctor-widget">
                    <div className="doctor-box-icon flex-shrink-0">
                      <img src={doctor_dashboard_04} alt="" />
                    </div>
                    <div className="doctor-content dash-count flex-grow-1">
                      <h4>
                        INR{" "}
                        <CountUp
                          delay={0.4}
                          end={getAllDoctorDashboardSummaryData?.new_revenue}
                          duration={0.6}
                        />
                        <span className="counter-up">
                          /{getAllDoctorDashboardSummaryData?.total_revenue}
                        </span>
                        <span className="status-green display-none">+50%</span>
                      </h4>
                      <h5>Earnings</h5>
                      <p>
                        <span className="passive-view">
                          <i className="feather-arrow-up-right me-1" />
                          30%
                        </span>{" "}
                        vs last month
                      </p>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 col-sm-12 col-lg-7 col-xl-7 ">
                {/* /Table Header */}
                <div className="card card-table show-entire">
                  <div
                    style={{
                      textAlign: "center",
                      margin: "auto",
                      padding: "20px 0px",
                    }}
                    className="doctor-table-blk"
                  >
                    <h3>{"Today's summary"}</h3>
                  </div>
                  <div className="table-responsive doctor-list">
                    <Table
                      loading={AppointmentLoader}
                      pagination={{
                        current: page,
                        pageSize: pageSize,
                        total: getAllAppointmentDoctor?.meta?.total_count,
                        showTotal: (total, range) =>
                          `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                        onChange: handlePageChange,
                      }}
                      columns={columns}
                      scroll={{ x: "max-content" }}
                      dataSource={getAllAppointmentDoctor?.payload?.doctors}
                      // rowSelection={rowSelection}
                      rowKey={(record) => record.id}
                      summary={() => (
                        <Table.Summary.Row>
                          <Table.Summary.Cell
                            index={0}
                            colSpan={1}
                            align="center"
                            style={{ fontWeight: "bold", textAlign: "center" }}
                          >
                            Total
                          </Table.Summary.Cell>
                          <Table.Summary.Cell index={1} align="center">
                            {totalTodaysSummary?.seen || 0}
                          </Table.Summary.Cell>
                          <Table.Summary.Cell index={2} align="center">
                            {totalTodaysSummary?.new_seen || 0}
                          </Table.Summary.Cell>
                          <Table.Summary.Cell index={3} align="center">
                            {totalTodaysSummary?.old_seen || 0}
                          </Table.Summary.Cell>
                          <Table.Summary.Cell index={4} align="center">
                            {totalTodaysSummary?.missed || 0}
                          </Table.Summary.Cell>
                          <Table.Summary.Cell index={5} align="center">
                            {totalTodaysSummary?.visited || 0}
                          </Table.Summary.Cell>
                          <Table.Summary.Cell index={6} align="center">
                            {totalTodaysSummary?.appointed || 0}
                          </Table.Summary.Cell>
                        </Table.Summary.Row>
                      )}
                    />
                  </div>
                  <div>
                    <ul>
                      <li style={{ fontSize: "11px" }}>
                        Seen means the doctor who generated prescription
                      </li>
                      <li style={{ fontSize: "11px" }}>
                        Missed and Visited are out of the appointments assigned
                        to a doctor
                      </li>
                      <li style={{ fontSize: "11px" }}>
                        {" "}
                        Appointed value as before day start
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-sm-12 col-lg-5 col-xl-5 ">
                {/* /Table Header */}
                <div className="card card-table show-entire">
                  <div
                    style={{
                      textAlign: "center",
                      margin: "auto",
                      padding: "20px 0px",
                    }}
                    className="doctor-table-blk"
                  >
                    <h3>
                      {"Today's Visited"}: {totalCountVisited || 0}
                    </h3>
                  </div>
                  <div className="table-responsive doctor-list">
                    <Table
                      loading={AppointmentLoader}
                      columns={columnsVisited}
                      dataSource={totalVisitedData}
                      // rowSelection={rowSelection}
                      pagination={false}
                      scroll={{ x: "max-content" }}
                      rowKey={(record) => record.category}
                    />
                  </div>
                  <div>
                    <ul>
                      <li style={{ fontSize: "11px", marginTop: "10px" }}>
                        Values are out of total appointments assigned
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="doctor-list-blk">
              <div className="row">
                <div
                  style={{
                    // padding: "10px 30px",
                    display: "flex",
                    backgroundColor: "transparent",
                    alignItems: "center",
                    marginTop: "10px",
                    marginBottom: "20px",
                    justifyContent: "space-between",
                    flexWrap: "wrap",
                  }}
                  // className="card"
                >
                  <h3 style={{ textDecoration: "underline" }}>Collections</h3>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: 10,
                      flexWrap: "wrap",
                    }}
                  >
                    <TextField
                      label="Start Date"
                      type="date"
                      value={startDate}
                      onChange={handleStartDateChange}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      size="small"
                      style={{ width: "140px" }}
                    />

                    <TextField
                      label="End Date"
                      type="date"
                      value={endDate}
                      onChange={handleEndDateChange}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      size="small"
                      style={{
                        width: "140px",
                        marginRight: "10px",
                      }}
                    />
                    <button
                      onClick={handleSubmitDateFilter}
                      className="btn btn-primary submit-form me-2"
                    >
                      Update
                    </button>
                  </div>
                </div>
              </div>
              <div className="row">
                {getAllDepartmentCollection?.length > 0 &&
                  getAllDepartmentCollection.map((el) => (
                    <div
                      style={{
                        transition: "transform 0.3s ease",
                        transform: "scale(1)",
                        minWidth: "250px",
                      }}
                      onMouseEnter={(e) =>
                        (e.currentTarget.style.transform = "scale(1.1)")
                      }
                      onMouseLeave={(e) =>
                        (e.currentTarget.style.transform = "scale(1)")
                      }
                      key={el?.name}
                      className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-2 mb-4 mt-4"
                    >
                      <div
                        className="card text-center p-3"
                        style={{ border: "1px solid lightgrey" }}
                      >
                        <h5
                          style={{ textTransform: "capitalize" }}
                          className="card-title"
                        >
                          {el?.name}
                        </h5>
                        <h3 className="card-text">
                          {el?.amount_collected?.toFixed(1) || "0.00"}
                        </h3>

                        <hr />
                        <div className="d-flex justify-content-between">
                          <span>Collection:</span>
                          <span>{el?.amount_collected || 0}</span>
                        </div>
                        <div className="d-flex justify-content-between">
                          <span>Refunds:</span>
                          <span>
                            {el?.amount_refunded?.toFixed(1) || "0.0"}
                          </span>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
              <div className="row">
                <div className="col-sm-12">
                  <div className="card card-table show-entire">
                    <div className="card-body">
                      {/* Table Header */}
                      {/* <div className="page-table-header mb-2">
                        <div className="row align-items-center">
                          <div className="col"> */}
                      <div className="doctor-table-blk">
                        <h3>Staff Wise Collection</h3>
                      </div>
                      {/* </div>
                        </div>
                      </div> */}
                      {/* /Table Header */}
                      <div
                        style={{
                          border: "1px solid lightgrey",
                          marginTop: "15px",
                        }}
                        className="table-responsive doctor-list "
                      >
                        <Table
                          loading={staffTableLoader}
                          pagination={{
                            current: page,
                            pageSize: pageSize,
                            total:
                              getStaffWiseCollectionData?.meta?.total_count,
                            showTotal: (total, range) =>
                              `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                            onChange: handlePageChange,
                          }}
                          scroll={{ x: "max-content" }}
                          columns={StaffCollectionColumns}
                          dataSource={
                            getStaffWiseCollectionData?.payload?.backops_users
                          }
                          // rowSelection={rowSelection}
                          rowKey={(record) => record.id}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12">
                  <div className="card card-table show-entire">
                    <div className="card-body">
                      {/* Table Header */}
                      {/* <div className="page-table-header mb-2">
                        <div className="row align-items-center">
                          <div className="col"> */}
                      <div className="doctor-table-blk">
                        <h3>Doctor Wise Collection</h3>
                        <div className="doctor-search-blk"></div>
                      </div>
                      {/* </div>
                        </div>
                      </div> */}
                      {/* /Table Header */}
                      <div
                        style={{
                          border: "1px solid lightgrey",
                          marginTop: "15px",
                        }}
                        className="table-responsive doctor-list"
                      >
                        <Table
                          loading={doctorTableLoader}
                          pagination={{
                            current: page,
                            pageSize: pageSize,
                            total: getDrWiseCollection?.meta?.total_count,
                            showTotal: (total, range) =>
                              `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                            onChange: handlePageChange,
                          }}
                          columns={doctorCollectionColumns}
                          dataSource={getDrWiseCollection?.payload?.specialists}
                          // rowSelection={rowSelection}
                          scroll={{ x: "max-content" }}
                          rowKey={(record) => record.id}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="doctor-list-blk mt-2">
              <div className="row">
                <div
                  style={{
                    // padding: "10px 30px",
                    display: "flex",
                    backgroundColor: "transparent",
                    alignItems: "center",

                    marginTop: "10px",
                    marginBottom: "20px",
                    justifyContent: "space-between",
                    flexWrap: "wrap",
                  }}
                  // className="card"
                >
                  <h3 style={{ textDecoration: "underline" }}>Inventory</h3>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: 10,
                      flexWrap: "wrap",
                    }}
                  >
                    <TextField
                      label="Start Date"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={medPurchase?.startDate}
                      onChange={handleMedPurchaseDate}
                      type="date"
                      name="startDate"
                      variant="outlined"
                      size="small"
                      style={{ width: "140px" }}
                    />

                    <TextField
                      value={medPurchase?.endDate}
                      type="date"
                      name="endDate"
                      onChange={handleMedPurchaseDate}
                      label="End Date"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      size="small"
                      style={{
                        width: "140px",
                        marginRight: "10px",
                      }}
                    />
                    <button
                      onClick={handleSubmitMedPurchase}
                      className="btn btn-primary submit-form me-2"
                    >
                      Update
                    </button>
                  </div>
                </div>
              </div>
              <div className="row g-4">
                {/* Card 1: Medicine Purchase */}
                <div className="col-12 col-md-6 col-lg-6">
                  {MedPurchaseDataLoader ? (
                    <Skeleton variant="rectangular" width="100%" height={430} />
                  ) : (
                    <div className="card shadow-sm">
                      {/* <div className="card-header  text-black"> */}
                      <div className="doctor-table-blk">
                        <h3>Medicine Purchase</h3>
                      </div>
                      {/* </div> */}
                      <div className="">
                        {/* <div className="mb-3">
                          <label className="form-label">Start date</label>
                          <input
                            value={medPurchase?.startDate}
                            onChange={handleMedPurchaseDate}
                            type="date"
                            name="startDate"
                            className="form-control form-control-sm"
                          />
                        </div>
                        <div className="mb-3">
                          <label className="form-label">End date</label>
                          <input
                            value={medPurchase?.endDate}
                            type="date"
                            name="endDate"
                            onChange={handleMedPurchaseDate}
                            className="form-control form-control-sm"
                          />
                        </div>
                        <button
                          onClick={handleSubmitMedPurchase}
                          className="btn btn-primary submit-form me-2"
                        >
                          Submit
                        </button> */}
                        <div
                          style={{
                            border: "1px solid lightgrey",
                            marginTop: "15px",
                          }}
                          className="table-responsive doctor-list"
                        >
                          <Table
                            columns={purchasedColumns}
                            dataSource={getMedPurchaseData?.payload}
                            rowKey={(record) => record.id}
                            pagination={false}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </div>

                {/* Card 2: Medicine Sale */}

                <div className="col-12 col-md-6 col-lg-6">
                  {medSalesLoader ? (
                    <Skeleton variant="rectangular" width="100%" height={430} />
                  ) : (
                    <div className="card shadow-sm">
                      {/* <div className="card-header  text-black"> */}
                      <div className="doctor-table-blk">
                        <h3>Medicine Sale</h3>
                      </div>
                      {/* </div> */}
                      <div className="">
                        {/* <div className="mb-3">
                          <label className="form-label">Start date</label>
                          <input
                            value={medSales?.startDate}
                            name="startDate"
                            onChange={handleChangeMedSalesDate}
                            type="date"
                            className="form-control form-control-sm"
                          />
                        </div> */}
                        {/* <div className="mb-3">
                          <label className="form-label">End date</label>
                          <input
                            onChange={handleChangeMedSalesDate}
                            value={medSales?.endDate}
                            name="endDate"
                            type="date"
                            className="form-control form-control-sm"
                          />
                        </div> */}
                        {/* <button
                          onClick={handleSubmitMedSales}
                          className="btn btn-primary submit-form me-2"
                        >
                          Submit
                        </button> */}
                        <div
                          style={{
                            border: "1px solid lightgrey",
                            marginTop: "15px",
                          }}
                          className="table-responsive doctor-list"
                        >
                          <Table
                            columns={SaltColumns}
                            dataSource={getMedSalesData?.payload}
                            rowKey={(record) => record.id}
                            pagination={false}
                            scroll={{ x: "max-content" }}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </div>

                {/* Card 3: Inventory Stocks */}
                <div className="col-12 col-md-12 col-lg-12">
                  {inventoryStockLoader ? (
                    <Skeleton variant="rectangular" width="100%" height={430} />
                  ) : (
                    <div className="card shadow-sm">
                      {/* <div className="card-header  text-black"> */}
                      <div className="doctor-table-blk">
                        <h3>Inventory Stocks</h3>
                      </div>
                      {/* </div> */}
                      <div
                        style={{
                          border: "1px solid lightgrey",
                          marginTop: "15px",
                        }}
                        className="card-body"
                      >
                        {/* <div className="mb-3">
                          <label className="form-label">Start date</label>
                          <input
                            value={inventoryStockDate?.startDate}
                            name="startDate"
                            type="date"
                            onChange={handleChangeInventoryStackDate}
                            className="form-control form-control-sm"
                          />
                        </div>
                        <div className="mb-3">
                          <label className="form-label">End date</label>
                          <input
                            value={inventoryStockDate?.endDate}
                            name="endDate"
                            type="date"
                            onChange={handleChangeInventoryStackDate}
                            className="form-control form-control-sm"
                          />
                        </div>
                        <button
                          onClick={handleSubmitInventoryStack}
                          className="btn btn-primary submit-form me-2"
                        >
                          Submit
                        </button> */}
                        <div className="table-responsive doctor-list">
                          <Table
                            columns={inventoryStockColumns}
                            dataSource={getInventoryStockData?.payload}
                            rowKey={(record) => record.id}
                            style={{ width: "100%" }}
                            pagination={false}
                            // scroll={{ x: "100%" }}
                            scroll={{ x: "max-content" }}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                {/* </div> */}
              </div>
            </div>
          </div>
        </div>
      </>
    </>
  );
};

export default Doctor_Dashboard;

/* eslint-disable react/prop-types */
import React, { createContext, useContext } from "react";
import { useSelector } from "react-redux";

export const RequestType = {
  GET: "get",
  POST: "post",
  PUT: "put",
  DELETE: "delete",
  PATCH: "patch",
};

// Helper function to get the base URL dynamically
function GetBaseUrl(subDomainValue) {
  const subDomain = subDomainValue || localStorage.getItem("subDomain");
  const hostname = window.location.hostname;
  // const [clientCode] = hostname.split(".");
  // https://staging.cyphermd.com
  // https://app.cyphermd.com
  // http://localhost:3000
  const isLocalHost = hostname.includes("localhost");
  const isStaging = hostname.includes("staging");
  const isLive = hostname.includes("app.cyphermd.com") && !isStaging;
  console.log(hostname, "hostname");
  if (isLocalHost) {
    return `https://${subDomain}.staging.cyphermd.com`;
    // return `https://${subDomain}.cyphermd.in`;
  }
  if (isStaging) {
    return `https://${subDomain}.staging.cyphermd.com`;
  }
  if (isLive) {
    return `https://${subDomain}.cyphermd.in`;
  }
  return `https://${subDomain}.cyphermd.in`;
}

const BaseUrlContext = createContext();

export const useBaseUrl = () => useContext(BaseUrlContext);

export let BASE_URL = "";
// console.log(BASE_URL, "base url");
export const BaseUrlProvider = ({ children }) => {
  const { subDomainValue } = useSelector((state) => state.user);
  BASE_URL = GetBaseUrl(subDomainValue);

  return (
    <BaseUrlContext.Provider value={BASE_URL}>
      {children}
    </BaseUrlContext.Provider>
  );
};

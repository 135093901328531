import React, { useState } from "react";
import { Table } from "antd";
import Header from "../Header";
import Sidebar from "../Sidebar";
import {
  imagesend,
  pdficon,
  pdficon3,
  plusicon,
  refreshicon,
  pdficon5,
} from "../imagepath";
// import { onShowSizeChange, itemRender } from "../Pagination";
import { Link, useNavigate } from "react-router-dom";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { useSelector } from "react-redux";
import { useQuery } from "@tanstack/react-query";

import { generateCsv, handleShowJson } from "../../utils/constant/constant";
import { toast } from "react-toastify";
import dayjs from "dayjs";
import jsPDF from "jspdf";
import { getReferrers } from "../../connections/getReferrers";
import { deleteReferrers } from "../../connections/deleteReferrers";

const Referrers = () => {
  const [deleteId, setDeleteId] = useState("");

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(50);

  const {
    user: { user },
  } = useSelector((store) => store);
  const navigate = useNavigate();
  const {
    data: getreferrersData,
    isLoading: referrersLoader,
    refetch: referrersRefetch,
  } = useQuery({
    queryKey: ["getReferrers", page, pageSize],
    queryFn: () => getReferrers(user?.token, null, page, pageSize),
    refetchOnWindowFocus: false, // Don't refetch when window regains focus
    refetchOnReconnect: false, // Don't refetch when reconnecting
  });

  const columns = [
    {
      title: "Id",
      dataIndex: "id",
    },
    {
      title: "Full Name",
      dataIndex: "full_name",
    },
    {
      title: "Email",
      dataIndex: "email",
    },

    {
      title: "Phone",
      dataIndex: "phone",
    },
    {
      title: "Profession",
      dataIndex: "profession",
    },
    {
      title: "Qualification",
      dataIndex: "qualification",
    },
    {
      title: "Address",
      dataIndex: "address",
    },
    {
      title: "City",
      dataIndex: "city",
    },
    {
      title: "Letter Text",
      dataIndex: "letter_text",
    },
    {
      title: "Created At",
      dataIndex: "created_at",
      //   sorter: (a, b) => a.ConsultingDoctor.length - b.ConsultingDoctor.length,
      render: (date) => (
        <>
          {" "}
          {dayjs(date, "YYYY-MM-DD HH:mm:ss A").format("MMMM DD, YYYY HH:mm")}
        </>
      ),
    },
    {
      title: "Updated At",
      dataIndex: "updated_at",
      //   sorter: (a, b) => a.Treatment.length - b.Treatment.length,
      render: (date) => (
        <>
          {" "}
          {dayjs(date, "YYYY-MM-DD HH:mm:ss A").format("MMMM DD, YYYY HH:mm")}
        </>
      ),
    },
    {
      title: "",
      dataIndex: "FIELD8",
      render: (_, record) => (
        <>
          <div className="text-end">
            <div className="dropdown dropdown-action">
              <Link
                to="#"
                className="action-icon dropdown-toggle"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i className="fas fa-ellipsis-v" />
              </Link>
              <div className="dropdown-menu dropdown-menu-end">
                <Link
                  className="dropdown-item"
                  to={`/admin/referrers/${record?.id}`}
                >
                  <i className="fa-regular fa-eye m-r-5" />
                  View
                </Link>
                <button
                  className="dropdown-item"
                  onClick={() =>
                    navigate("/admin/update-referrers", { state: record })
                  }
                >
                  <i className="far fa-edit me-2" />
                  Edit
                </button>
                <Link
                  className="dropdown-item"
                  to="#"
                  data-bs-toggle="modal"
                  data-bs-target="#delete_patient"
                  onClick={() => {
                    setDeleteId(record?.id);
                  }}
                >
                  <i className="fa fa-trash-alt m-r-5"></i> Delete
                </Link>
              </div>
            </div>
          </div>
        </>
      ),
    },
  ];
  const handleDeleteData = async () => {
    try {
      const data = await deleteReferrers(user?.token, deleteId);
      if (data.status.status === "SUCCESS") {
        toast.success("Referrer Deleted Successfully", deleteId);
        referrersRefetch();
        setDeleteId(null);
      }
    } catch (error) {
      //   toast.error(error.message);
      console.log(error);
    }
  };
  const handlePageChange = (newPage, newPageSize) => {
    setPage(newPage);
    setPageSize(newPageSize);
    // referrersRefetch();
  };
  const handleDownloadPdf = (patients) => {
    // Return if no data
    if (!patients || patients.length === 0) return;

    // Initialize PDF document
    const doc = new jsPDF({
      unit: "mm",
      format: "a4",
      orientation: "landscape",
    });

    // Define table headers with custom widths
    const headers = [
      { header: "ID", width: 15 },
      { header: "Full Name", width: 30 },
      { header: "Email", width: 30 },
      { header: "Phone", width: 30 },
      { header: "Profession", width: 30 },
      { header: "Qualification", width: 30 },
      { header: "Address", width: 30 },
      { header: "City", width: 30 },
      { header: "Created At", width: 25 },
      { header: "Updated At", width: 25 },
    ];

    const data = patients.map((item) => [
      item.id,
      item.full_name,
      item.email,
      item.phone,
      item.profession || "N/A",
      item.qualification || "N/A",
      item.address,
      item.city || "N/A",
      item.created_at,
      item.updated_at,
    ]);

    // Add title
    doc.setFontSize(16);
    doc.setFont(undefined, "bold");
    doc.text("Referrers List", doc.internal.pageSize.getWidth() / 2, 15, {
      align: "center",
    });

    // Add timestamp
    doc.setFontSize(10);
    doc.setFont(undefined, "normal");
    doc.text(`Generated on: ${dayjs().format("YYYY-MM-DD HH:mm:ss")}`, 14, 22);

    // Configure table
    doc.autoTable({
      startY: 30,
      head: [headers.map((h) => h.header)],
      body: data,
      theme: "grid",
      headStyles: {
        fillColor: [240, 240, 240],
        textColor: [0, 0, 0],
        fontStyle: "bold",
        halign: "center",
        lineWidth: 0.5,
        lineColor: [200, 200, 200],
        fontSize: 8,
      },
      bodyStyles: {
        fontSize: 6,
        lineColor: [200, 200, 200],
        lineWidth: 0.5,
      },
      alternateRowStyles: {
        fillColor: [248, 248, 248],
      },
      columnStyles: headers.reduce((acc, h, i) => {
        acc[i] = { cellWidth: h.width };
        return acc;
      }, {}),
      margin: { top: 30, right: 14, bottom: 20, left: 14 },
      didDrawPage: (data) => {
        // Add page number at the bottom
        doc.setFontSize(10);
        doc.text(
          `Page ${data.pageNumber} of ${data.pageCount}`,
          doc.internal.pageSize.getWidth() / 2,
          doc.internal.pageSize.getHeight() - 10,
          { align: "center" }
        );
      },
    });

    // Save with formatted filename including timestamp
    const filename = `Referrers${dayjs().format("YYYYMMDD_HHmmss")}.pdf`;
    doc.save(filename);
  };

  return (
    <>
      <Header />
      <Sidebar activeClassName="referrers-list" />
      <>
        <div className="page-wrapper">
          <div className="content">
            {/* Page Header */}
            <div className="page-header">
              <div className="row">
                <div className="col-sm-12">
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="#">Referrers</Link>
                    </li>
                    <li className="breadcrumb-item">
                      <i className="feather-chevron-right">
                        <FeatherIcon icon="chevron-right" />
                      </i>
                    </li>
                    <li className="breadcrumb-item active">Referrers List</li>
                  </ul>
                </div>
              </div>
            </div>
            {/* /Page Header */}
            <div className="row">
              <div className="col-sm-12">
                <div className="card card-table show-entire">
                  <div className="card-body">
                    {/* Table Header */}
                    <div className="page-table-header mb-2">
                      <div className="row align-items-center">
                        <div className="col">
                          <div className="doctor-table-blk">
                            <h3>Referrers List</h3>
                            <div className="doctor-search-blk">
                              <div className="add-group">
                                <Link
                                  to="/admin/add-referrers"
                                  className="btn btn-primary add-pluss ms-2"
                                >
                                  <img src={plusicon} alt="#" />
                                </Link>
                                <Link
                                  onClick={referrersRefetch}
                                  className="btn btn-primary add-pluss ms-2"
                                >
                                  <img
                                    // onClick={referrersRefetch}
                                    src={refreshicon}
                                    alt="#"
                                  />
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-auto text-end float-end ms-auto download-grp">
                          {/* <Link id="download-pdf" to="#" className=" me-2"> */}
                          <img
                            className=" me-2"
                            onClick={() =>
                              handleDownloadPdf(
                                getreferrersData?.payload?.referrers
                              )
                            }
                            src={pdficon}
                            alt="#"
                            style={{ cursor: "pointer" }}
                          />
                          {/* </Link> */}
                          {/* <Link
                           
                            id="download-csv"
                            className=" me-2"
                          > */}
                          <img
                            onClick={() =>
                              generateCsv(getreferrersData?.payload?.referrers)
                            }
                            className=" me-2"
                            src={pdficon3}
                            alt="#"
                            style={{ cursor: "pointer" }}
                          />

                          <img
                            onClick={() =>
                              handleShowJson(
                                getreferrersData?.payload?.referrers
                              )
                            }
                            width={"25px"}
                            height={"26px"}
                            src={pdficon5}
                            alt="#"
                            style={{ cursor: "pointer" }}
                          />
                        </div>
                      </div>
                    </div>
                    {/* /Table Header */}
                    <div className="table-responsive doctor-list">
                      <Table
                        loading={referrersLoader}
                        pagination={{
                          current: page,
                          pageSize: pageSize,
                          total: getreferrersData?.meta?.total_count,
                          showTotal: (total, range) =>
                            `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                          onChange: handlePageChange,
                        }}
                        columns={columns}
                        dataSource={getreferrersData?.payload?.referrers}
                        rowKey={(record) => record.id}
                        scroll={{ x: "max-content" }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          id="delete_patient"
          className="modal fade delete-modal"
          role="dialog"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body text-center">
                <img src={imagesend} alt="#" width={50} height={46} />
                <h3>Are you sure want to delete this ?</h3>
                <div className="m-t-20">
                  {" "}
                  <Link
                    to="#"
                    className="btn btn-white me-2"
                    data-bs-dismiss="modal"
                  >
                    Close
                  </Link>
                  <button
                    onClick={handleDeleteData}
                    type="submit"
                    className="btn btn-danger"
                    data-bs-dismiss="modal"
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div
            id="delete_patient"
            className="modal fade delete-modal"
            role="dialog"
          >
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-body text-center">
                  <img src={imagesend} alt="#" width={50} height={46} />
                  <h3>Are you sure want to delete this ?</h3>
                  <div className="m-t-20">
                    {" "}
                    <Link
                      to="#"
                      className="btn btn-white me-2"
                      data-bs-dismiss="modal"
                    >
                      Close
                    </Link>
                    <button
                      data-bs-dismiss="modal"
                      type="submit"
                      className="btn btn-danger"
                    >
                      Delete
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
      ;
    </>
  );
};

export default Referrers;

import React from "react";
import Header from "../../Header";
import Sidebar from "../../Sidebar";
import { Link, useParams } from "react-router-dom";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { Skeleton } from "@mui/material";
// import { Table } from "antd";
import { getTreatmentDetails } from "../../../connections/getTreatmentDetails";
// const columns = [
//   {
//     title: "Name",
//     dataIndex: "name",
//   },
//   {
//     title: "Diagnosis",
//     dataIndex: "diagnosis",
//     render: (diagnosis) => (
//       <span
//         style={{
//           whiteSpace: "normal",
//           wordBreak: "break-word",
//           maxWidth: "150px",
//           display: "block",
//           overflow: "hidden",
//           textOverflow: "ellipsis",
//         }}
//       >
//         {diagnosis?.length > 0 ? diagnosis?.join("    ") : null}
//       </span>
//     ),
//   },
//   {
//     title: "Medical Tests",
//     dataIndex: "medical_tests",
//     render: (medical_tests) => (
//       <span
//         style={{
//           whiteSpace: "normal",
//           wordBreak: "break-word",
//           maxWidth: "350px",
//           display: "block",
//           overflow: "hidden",
//           textOverflow: "ellipsis",
//         }}
//       >
//         {medical_tests?.length > 0 ? medical_tests?.join(",    ") : null}
//       </span>
//     ),
//   },
//   {
//     title: "Special Instructions",
//     dataIndex: "special_instructions",
//     render: (special_instructions) => (
//       <span
//         style={{
//           whiteSpace: "normal",
//           wordBreak: "break-word",
//           maxWidth: "250px",
//           display: "block",
//           overflow: "hidden",
//           textOverflow: "ellipsis",
//         }}
//       >
//         {special_instructions?.length > 0
//           ? special_instructions?.join(",    ")
//           : null}
//       </span>
//     ),
//   },
//   {
//     title: "Self Notes",
//     dataIndex: "self_notes",
//   },
//   {
//     title: "Created At",
//     dataIndex: "created_at",
//     render: (date) => (
//       <span
//         style={{
//           whiteSpace: "normal",
//           wordBreak: "break-word",
//           maxWidth: "150px",
//           display: "block",
//           overflow: "hidden",
//           textOverflow: "ellipsis",
//         }}
//       >
//         {dayjs(date, "YYYY-MM-DD HH:mm:ss A").format("MMMM DD, YYYY HH:mm")}
//       </span>
//     ),
//   },
//   {
//     title: "Updated At",
//     dataIndex: "updated_at",
//     render: (date) => (
//       <span
//         style={{
//           whiteSpace: "normal",
//           wordBreak: "break-word",
//           maxWidth: "150px",
//           display: "block",
//           overflow: "hidden",
//           textOverflow: "ellipsis",
//         }}
//       >
//         {dayjs(date, "YYYY-MM-DD HH:mm:ss A").format("MMMM DD, YYYY HH:mm")}
//       </span>
//     ),
//   },
// ];
const ShowTreatment = () => {
  const {
    user: { user },
  } = useSelector((store) => store);
  const { id } = useParams();

  const { data: treatmentData, isLoading } = useQuery({
    queryKey: ["getTreatmentDetails", id],
    queryFn: () => getTreatmentDetails(user?.token, id),
    enabled: !!id,
    // staleTime: Infinity, // Keep the data fresh indefinitely

    refetchOnWindowFocus: false, // Don't refetch when window regains focus
    refetchOnReconnect: false, // Don't refetch when reconnecting
  });
  // const treatmentDataList = treatmentData ? [treatmentData] : [];

  return (
    <>
      <Header />
      <Sidebar activeClassName="treatments-dashboard" />
      <>
        <div className="page-wrapper">
          <div className="content">
            {/* Page Header */}
            <div className="page-header">
              <div className="row">
                <div className="col-sm-12">
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item active">
                      <Link to={-1}>Treatment Details</Link>
                    </li>
                    <li className="breadcrumb-item">
                      <i className="feather-chevron-right">
                        <FeatherIcon icon="chevron-right" />
                      </i>
                    </li>
                    <li className="breadcrumb-item active">{id}</li>
                  </ul>
                </div>
              </div>
            </div>
            {/* /Page Header */}
            <div className="row">
              <div className="col-sm-12">
                <div className="card card-table show-entire">
                  <div className="card-body">
                    {/* content Header */}
                    <div className="page-table-header mb-2">
                      <div className="row align-items-center">
                        <div className="col">
                          <div className="doctor-table-blk">
                            <h3>Treatment Details</h3>
                            <div className="doctor-search-blk"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {isLoading ? (
                      <div>
                        <Skeleton
                          variant="rectangular"
                          width="100%"
                          height={430} // Same height as your chart
                        />
                      </div>
                    ) : (
                      <div className="content-details">
                        <div className="col-lg-12">
                          <div className="card-box">
                            <div className="card-block">
                              <ul className="personal-info">
                                <li className="pb-1">
                                  <span className="title">Name:</span>
                                  <span className="text">
                                    {treatmentData?.name || "N/A"}
                                  </span>
                                </li>
                                <li className="pb-1">
                                  <span className="title">Diagnosis:</span>
                                  <span
                                    className="text"
                                    style={{
                                      whiteSpace: "normal",
                                      wordBreak: "break-word",
                                      maxWidth: "150px",
                                      display: "block",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                    }}
                                  >
                                    {treatmentData?.diagnosis?.length > 0
                                      ? treatmentData?.diagnosis?.join("    ")
                                      : "N/A"}
                                  </span>
                                </li>
                                <li className="pb-1">
                                  <span className="title">Medical Tests:</span>
                                  <span
                                    className="text"
                                    style={{
                                      whiteSpace: "normal",
                                      wordBreak: "break-word",
                                      maxWidth: "350px",
                                      display: "block",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                    }}
                                  >
                                    {treatmentData?.medical_tests?.length > 0
                                      ? treatmentData?.medical_tests?.join(
                                          ",    "
                                        )
                                      : "N/A"}
                                  </span>
                                </li>
                                <li className="pb-1">
                                  <span className="title">
                                    Special Instructions:
                                  </span>
                                  <span
                                    className="text"
                                    style={{
                                      whiteSpace: "normal",
                                      wordBreak: "break-word",
                                      maxWidth: "250px",
                                      display: "block",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                    }}
                                  >
                                    {treatmentData?.special_instructions
                                      ?.length > 0
                                      ? treatmentData?.special_instructions?.join(
                                          ",    "
                                        )
                                      : "N/A"}
                                  </span>
                                </li>
                                <li className="pb-1">
                                  <span className="title">Self Notes:</span>
                                  <span className="text">
                                    {treatmentData?.self_notes || "N/A"}
                                  </span>
                                </li>
                                <li className="pb-1">
                                  <span className="title">Created At:</span>
                                  <span
                                    className="text"
                                    style={{
                                      whiteSpace: "normal",
                                      wordBreak: "break-word",
                                      // maxWidth: "150px",
                                      display: "block",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                    }}
                                  >
                                    {treatmentData?.created_at
                                      ? dayjs(
                                          treatmentData?.created_at,
                                          "YYYY-MM-DD HH:mm:ss A"
                                        ).format("MMMM DD, YYYY HH:mm")
                                      : "N/A"}
                                  </span>
                                </li>
                                <li className="pb-1">
                                  <span className="title">Updated At:</span>
                                  <span
                                    className="text"
                                    style={{
                                      whiteSpace: "normal",
                                      wordBreak: "break-word",
                                      // maxWidth: "150px",
                                      display: "block",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                    }}
                                  >
                                    {treatmentData?.updated_at
                                      ? dayjs(
                                          treatmentData?.updated_at,
                                          "YYYY-MM-DD HH:mm:ss A"
                                        ).format("MMMM DD, YYYY HH:mm")
                                      : "N/A"}
                                  </span>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      // <div className="table-responsive doctor-list">
                      //   <Table
                      //     pagination={false}
                      //     columns={columns}
                      //     dataSource={treatmentDataList}
                      //     rowKey={(record) => record.id}
                      //     scroll={{ x: "max-content" }}
                      //   />
                      // </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </>
  );
};

export default ShowTreatment;

// PrivateRoute.js
import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";

const PrivateRoute = () => {
  const { isAuthenticated, user } = useSelector((state) => state.user);

  // Check if user is authenticated
  if (isAuthenticated && user?.token) {
    return <Outlet />; // If authenticated, render the child routes
  }

  // If not authenticated, redirect to the login page
  return <Navigate to="/login" />;
};

export default PrivateRoute;

import React, { useState } from "react";
import Header from "../Header";
import Sidebar from "../Sidebar";
import { Link, useNavigate } from "react-router-dom";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";

import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { postLabs } from "../../connections/postLabs";

const AddLabs = () => {
  const {
    user: { user },
  } = useSelector((store) => store);
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    if (!formData.name) {
      toast.error("Name is required");
      return;
    }
    e.preventDefault();

    const payload = {
      lab: formData,
    };

    try {
      const result = await postLabs(user?.token, payload);
      if (result?.status?.status === "SUCCESS") {
        toast.success("Lab Added Successfully");
        navigate("/admin/labs");
        setFormData({
          title: "",
        });
      }
    } catch (error) {
      // toast.error(error.message);
      console.log(error.message);
    }
  };
  return (
    <>
      <Header />
      <Sidebar activeClassName="labs-list" />
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="row">
              <div className="col-sm-12">
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to={-1}>Labs</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <i className="feather-chevron-right">
                      <FeatherIcon icon="chevron-right" />
                    </i>
                  </li>
                  <li className="breadcrumb-item active">Add Labs</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <div className="card">
                <div className="card-body">
                  <form onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="col-12">
                        <div className="form-heading">
                          <h4>Labs</h4>
                        </div>
                      </div>
                      <div className="col-12 col-md-6 col-xl-6">
                        <div className="form-group local-forms">
                          <label>
                            Name <span className="login-danger">*</span>
                          </label>
                          <input
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                            className="form-control"
                            type="text"
                            required={true}
                          />
                        </div>
                      </div>

                      <div className="col-12">
                        <div className="doctor-submit text-end">
                          <button
                            type="submit"
                            className="btn btn-primary submit-form me-2"
                          >
                            Create Labs
                          </button>
                          <button
                            type="button"
                            onClick={() => navigate("/admin/labs")}
                            className="btn btn-primary cancel-form"
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddLabs;

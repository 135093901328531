import React, { useEffect, useState } from "react";
import Header from "../../Header";
import Sidebar from "../../Sidebar";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { useSelector } from "react-redux";
import Select from "react-select";
import { useQuery } from "@tanstack/react-query";
import { getTreatment } from "../../../connections/getTreatment";
import { putDiagnosis } from "../../../connections/putDiagnosis";

const UpdateDiagnosis = () => {
  const [treatmentOptions, setTreatmentOptions] = useState([]);
  const {
    user: { user },
  } = useSelector((store) => store);
  const [formState, setFormState] = useState({});
  const { data: allTreatment, isLoading } = useQuery({
    queryKey: ["getTreatment", "all"],
    queryFn: () => getTreatment(user?.token, "all", null, null),
    refetchOnWindowFocus: false, // Don't refetch when window regains focus
    refetchOnReconnect: false, // Don't refetch when reconnecting
  });
  const navigate = useNavigate();
  const location = useLocation();
  const diagnosisData = location.state || null;
  const handleChange = (name, value) => {
    setFormState((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // Format options for react-select
  const formattedOptions =
    treatmentOptions?.length > 0
      ? treatmentOptions?.map((test) => ({
          value: test?.id,
          label: test?.name,
        }))
      : [];
  const handleAddData = async (e) => {
    e.preventDefault();
    const payload = {
      diagnosis: formState,
    };
    if (!formState?.treatment_id) {
      toast.error("Please select a  treatment");
      return;
    }
    if (!formState?.value) {
      toast.error("Value must be provided");
      return;
    }
    try {
      const data = await putDiagnosis(user?.token, payload, diagnosisData?.id);
      if (data.status.status === "SUCCESS") {
        toast.success("Diagnosis Updated Successfully");
        setFormState({});
        navigate("/admin/prescription/diagnosis");
      }
    } catch (error) {
      // toast.error(error.message);
      console.log(error);
    }
  };
  useEffect(() => {
    if (allTreatment) {
      setTreatmentOptions(allTreatment?.payload?.treatments);
    }
    if (diagnosisData) {
      setFormState((prev) => ({
        ...prev,
        treatment_id: diagnosisData?.treatment?.id,
        value: diagnosisData?.value,
      }));
    }
  }, [allTreatment, diagnosisData]);

  return (
    <>
      <Header />
      <Sidebar activeClassName="diagnosis-dashboard" />
      <>
        <div className="page-wrapper">
          <div className="content">
            {/* Page Header */}
            <div className="page-header">
              <div className="row">
                <div className="col-sm-12">
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to={-1}>Diagnosis</Link>
                    </li>
                    <li className="breadcrumb-item">
                      <i className="feather-chevron-right">
                        <FeatherIcon icon="chevron-right" />
                      </i>
                    </li>
                    <li className="breadcrumb-item active">Update Diagnosis</li>
                  </ul>
                </div>
              </div>
            </div>
            {/* /Page Header */}
            <div className="row">
              <div className="col-sm-12">
                <div className="card">
                  <div className="card-body">
                    <form>
                      <div className="row">
                        <div className="col-12">
                          <div className="form-heading">
                            <h4>Update Diagnosis</h4>
                          </div>
                        </div>

                        <div className="col-12 col-md-6 col-xl-6">
                          <div className="form-group local-forms">
                            <label>
                              Treatment
                              <span className="login-danger">*</span>
                            </label>
                            <Select
                              isLoading={isLoading}
                              options={formattedOptions}
                              value={
                                formattedOptions?.find(
                                  (option) =>
                                    option.value === formState?.treatment_id
                                ) || null
                              }
                              onChange={(e) =>
                                handleChange("treatment_id", e?.value)
                              }
                              styles={{
                                menu: (provided) => ({
                                  ...provided,
                                  zIndex: 9999, // Set your desired z-index here
                                }),
                                menuPortal: (base) => ({
                                  ...base,
                                  zIndex: 999999,
                                }),
                                control: (baseStyles, state) => ({
                                  ...baseStyles,
                                  borderColor: state.isFocused
                                    ? "#2e37a4"
                                    : "rgba(46, 55, 164, 0.1)",
                                  boxShadow: state.isFocused
                                    ? "0 0 0 1px #2e37a4"
                                    : "none",
                                  "&:hover": {
                                    borderColor: "rgba(46, 55, 164, 0.1)",
                                  },
                                  borderRadius: "10px",
                                  fontSize: "14px",
                                  minHeight: "45px",
                                }),
                                dropdownIndicator: (base, state) => ({
                                  ...base,
                                  transform: state.selectProps.menuIsOpen
                                    ? "rotate(-180deg)"
                                    : "rotate(0)",
                                  transition: "250ms",
                                  width: "35px",
                                  height: "35px",
                                }),
                              }}
                              isSearchable
                              isClearable
                              placeholder="Choose treatment"
                              className="react-select"
                              classNamePrefix="select"
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-6">
                          <div className="form-group local-forms">
                            <label>
                              Value <span className="login-danger">*</span>
                            </label>
                            <input
                              value={formState?.value}
                              name="value"
                              onChange={(e) =>
                                handleChange("value", e.target.value)
                              }
                              className="form-control"
                              type="text"
                              required={true}
                            />
                          </div>
                        </div>

                        <div className="col-12">
                          <div className="doctor-submit text-end">
                            <button
                              onClick={handleAddData}
                              type="submit"
                              className="btn btn-primary submit-form me-2"
                            >
                              Submit
                            </button>
                            <button
                              onClick={(e) => {
                                e.preventDefault();
                                navigate("/admin/prescription/diagnosis");
                              }}
                              type="submit"
                              className="btn btn-primary cancel-form"
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </>
  );
};

export default UpdateDiagnosis;

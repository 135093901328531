import React, { useState } from "react";
import Header from "../../Header";
import Sidebar from "../../Sidebar";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { postFollowUpList } from "../../../connections/postFollowUpList";

const AddFollowUpList = () => {
  const {
    user: { user },
  } = useSelector((store) => store);
  const [formState, setFormState] = useState({});

  const navigate = useNavigate();
  const handleChange = (name, value) => {
    setFormState((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleAddFollowUpList = async (e) => {
    if (!formState.title) {
      toast.error("Title is required");
      return;
    }
    e.preventDefault();
    const payload = {
      followup_list: formState,
    };

    try {
      const data = await postFollowUpList(user?.token, payload);
      if (data.status.status === "SUCCESS") {
        toast.success("Follow-up Added Successfully");
        setFormState({});
        navigate("/admin/prescription/followup_lists");
      }
    } catch (error) {
      // toast.error(error.message);
      console.log(error);
    }
  };
  return (
    <>
      <Header />
      <Sidebar activeClassName="followup_lists-dashboard" />
      <>
        <div className="page-wrapper">
          <div className="content">
            {/* Page Header */}
            <div className="page-header">
              <div className="row">
                <div className="col-sm-12">
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to={-1}>Follow-Up</Link>
                    </li>
                    <li className="breadcrumb-item">
                      <i className="feather-chevron-right">
                        <FeatherIcon icon="chevron-right" />
                      </i>
                    </li>
                    <li className="breadcrumb-item active">Add Follow-Up</li>
                  </ul>
                </div>
              </div>
            </div>
            {/* /Page Header */}
            <div className="row">
              <div className="col-sm-12">
                <div className="card">
                  <div className="card-body">
                    <form>
                      <div className="row">
                        <div className="col-12">
                          <div className="form-heading">
                            <h4>Add Follow-Up</h4>
                          </div>
                        </div>

                        <div className="col-12 col-md-12 col-xl-12">
                          <div className="form-group local-forms">
                            <label>
                              Title <span className="login-danger">*</span>
                            </label>
                            <input
                              value={formState?.title}
                              name="title"
                              onChange={(e) =>
                                handleChange("title", e.target.value)
                              }
                              className="form-control"
                              type="text"
                              required={true}
                            />
                          </div>
                        </div>

                        <div className="col-12">
                          <div className="doctor-submit text-end">
                            <button
                              onClick={handleAddFollowUpList}
                              type="submit"
                              className="btn btn-primary submit-form me-2"
                            >
                              Submit
                            </button>
                            <button
                              onClick={(e) => {
                                e.preventDefault();
                                navigate("/admin/prescription/followup_lists");
                              }}
                              type="submit"
                              className="btn btn-primary cancel-form"
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </>
  );
};
export default AddFollowUpList;

import { useEffect, useState } from "react";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
// import Select from "react-select";

import Header from "../../Header";
import Sidebar from "../../Sidebar";
import { putInvoiceItem } from "../../../connections/putInvoiceItem";
const EditInvoiceItem = () => {
  const {
    user: { user },
  } = useSelector((store) => store);
  const [formState, setFormState] = useState({});
  const navigate = useNavigate();
  const location = useLocation();
  const InvoiceItemData = location.state || null;
  const handleChange = (name, value) => {
    setFormState((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleUpdateInvoiceItem = async (e) => {
    e.preventDefault();
    if (!formState.amount) {
      toast.error("Amount is required");
      return;
    }

    const payload = {
      invoice_item: formState,
    };

    try {
      const data = await putInvoiceItem(
        user?.token,
        payload,
        InvoiceItemData?.id
      );
      if (data.status.status === "SUCCESS") {
        toast.success("Invoice Item Updated Successfully");
        setFormState({});
        navigate(-1);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };
  // const typeOption = [
  //   {
  //     value: "MedicalTest",
  //     label: "MedicalTest",
  //   },
  //   {
  //     value: "Daycare",
  //     label: "Daycare",
  //   },
  //   {
  //     value: "Consultancy",
  //     label: "Consultancy",
  //   },
  //   {
  //     value: "Tests",
  //     label: "Tests",
  //   },
  // ];
  useEffect(() => {
    if (InvoiceItemData) {
      setFormState((prev) => ({
        ...prev,
        name: InvoiceItemData?.name,
        description: InvoiceItemData?.description,
        invoice_itemable_type: InvoiceItemData?.invoice_itemable_type,
        invoice_itemable_id: InvoiceItemData?.invoice_itemable_id,
        amount: InvoiceItemData?.amount,
        quantity: InvoiceItemData?.quantity,
        discount: InvoiceItemData?.discount,
        refund: InvoiceItemData?.refund,
      }));
    }
  }, [InvoiceItemData]);
  return (
    <>
      <Header />
      <Sidebar activeClassName="invoice-list" />
      <>
        <div className="page-wrapper">
          <div className="content">
            {/* Page Header */}
            <div className="page-header">
              <div className="row">
                <div className="col-sm-12">
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to={-1}>Invoice item </Link>
                    </li>
                    <li className="breadcrumb-item">
                      <i className="feather-chevron-right">
                        <FeatherIcon icon="chevron-right" />
                      </i>
                    </li>
                    <li className="breadcrumb-item active">
                      Update Invoice item
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            {/* /Page Header */}
            <div className="row">
              <div className="col-sm-12">
                <div className="card">
                  <div className="card-body">
                    <form>
                      <div className="row">
                        <div className="col-12">
                          <div className="form-heading">
                            <h4>Update Invoice item</h4>
                          </div>
                        </div>

                        <div className="col-12 col-md-6 col-xl-3">
                          <div className="form-group local-forms">
                            <label>
                              Name
                              {/* <span className="login-danger">*</span> */}
                            </label>
                            <input
                              value={formState.name}
                              onChange={(e) =>
                                handleChange("name", e.target.value)
                              }
                              className="form-control"
                              type="text"
                            />
                          </div>
                        </div>

                        <div className="col-12 col-md-6 col-xl-3">
                          <div className="form-group local-forms">
                            <label>
                              Invoice itemable type
                              {/* <span className="login-danger">*</span> */}
                            </label>
                            {/* <Select
                              value={
                                typeOption.find(
                                  (option) =>
                                    option.value ===
                                    formState?.invoice_itemable_type
                                ) || null
                              }
                              onChange={(e) =>
                                handleChange(
                                  "invoice_itemable_type",
                                  e.target.value
                                )
                              }
                              placeholder="type"
                              options={typeOption}
                              menuPortalTarget={document.body}
                              styles={{
                                menuPortal: (base) => ({
                                  ...base,
                                  zIndex: 9999,
                                }),
                                control: (baseStyles, state) => ({
                                  ...baseStyles,
                                  borderColor: state.isFocused
                                    ? "#2e37a4"
                                    : "rgba(46, 55, 164, 0.1)",
                                  boxShadow: state.isFocused
                                    ? "0 0 0 1px #2e37a4"
                                    : "none",
                                  "&:hover": {
                                    borderColor: "rgba(46, 55, 164, 0.1)",
                                  },
                                  borderRadius: "10px",
                                  fontSize: "14px",
                                  minHeight: "45px",
                                  minWidth: "150px",
                                }),
                                dropdownIndicator: (base, state) => ({
                                  ...base,
                                  transform: state.selectProps.menuIsOpen
                                    ? "rotate(-180deg)"
                                    : "rotate(0)",
                                  transition: "250ms",
                                  width: "35px",
                                  height: "35px",
                                }),
                              }}
                              id="search-commodity"
                              components={{
                                IndicatorSeparator: () => null,
                              }}
                            /> */}
                            <input
                              value={formState.invoice_itemable_type}
                              readOnly
                              disabled={true}
                              onChange={(e) =>
                                handleChange(
                                  "invoice_itemable_type",
                                  e.target.value
                                )
                              }
                              className="form-control"
                              type="text"
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-3">
                          <div className="form-group local-forms">
                            <label>
                              Invoice itemable
                              {/* <span className="login-danger">*</span> */}
                            </label>
                            <input
                              value={formState.invoice_itemable_id}
                              onChange={(e) =>
                                handleChange(
                                  "invoice_itemable_id",
                                  e.target.value
                                )
                              }
                              readOnly
                              disabled={true}
                              className="form-control"
                              type="text"
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-3">
                          <div className="form-group local-forms">
                            <label>
                              Amount
                              <span className="login-danger">*</span>
                            </label>
                            <input
                              value={formState.amount}
                              onChange={(e) =>
                                handleChange("amount", e.target.value)
                              }
                              className="form-control"
                              type="text"
                              required={true}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-3">
                          <div className="form-group local-forms">
                            <label>
                              Quantity
                              {/* <span className="login-danger">*</span> */}
                            </label>
                            <input
                              value={formState.quantity}
                              onChange={(e) =>
                                handleChange("quantity", e.target.value)
                              }
                              className="form-control"
                              type="text"
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-3">
                          <div className="form-group local-forms">
                            <label>
                              Discount
                              {/* <span className="login-danger">*</span> */}
                            </label>
                            <input
                              value={formState.discount}
                              onChange={(e) =>
                                handleChange("discount", e.target.value)
                              }
                              className="form-control"
                              type="text"
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-3">
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: 15,
                            }}
                            // className="form-group local-forms"
                          >
                            <label>
                              Refund
                              {/* <span className="login-danger">*</span> */}
                            </label>
                            <input
                              value={formState.refund}
                              checked={formState.refund}
                              onChange={(e) =>
                                handleChange("refund", e.target.checked)
                              }
                              //   className="form-control"
                              type="checkbox"
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-3">
                          <div className="form-group local-forms">
                            <label>
                              Description
                              {/* <span className="login-danger">*</span> */}
                            </label>
                            <textarea
                              rows={3}
                              value={formState.description}
                              onChange={(e) =>
                                handleChange("description", e.target.value)
                              }
                              className="form-control"
                              type="text"
                            />
                          </div>
                        </div>

                        <div className="col-12">
                          <div className="doctor-submit text-end">
                            <button
                              onClick={handleUpdateInvoiceItem}
                              type="submit"
                              className="btn btn-primary submit-form me-2"
                            >
                              Submit
                            </button>
                            <button
                              onClick={(e) => {
                                e.preventDefault();
                                navigate(-1);
                              }}
                              type="submit"
                              className="btn btn-primary cancel-form"
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </>
  );
};
export default EditInvoiceItem;

import React, { useEffect, useState } from "react";
import Header from "../Header";
import Sidebar from "../Sidebar";
import { DatePicker } from "antd";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import Select from "react-select";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useQuery } from "@tanstack/react-query";
// import { getAllMedicine } from "../../connections/getAllMedicine";
import { getSuppliersList } from "../../connections/getSuppliersList";
import { getPack } from "../../connections/getPack";
import { toast } from "react-toastify";

import dayjs from "dayjs";
import { putPurchaseOrder } from "../../connections/putPurchaseOrder";
import { getAllMedicine } from "../../connections/getAllMedicine";
import useDebounce from "../../utils/hooks";
const EditPurchaseOrder = () => {
  const [isClicked] = useState(false);
  const {
    user: { user },
  } = useSelector((store) => store);
  const location = useLocation();
  const supplierData = location.state || null;
  const navigate = useNavigate();
  const [purchaseItem, setPurchaseItem] = useState([]);
  const [supplier, setSupplier] = useState({});
  const [medicineInput, setMedicineInput] = useState("");
  const [filteredSuppliers, setFilteredSuppliers] = useState([]);
  const [pack, setPack] = useState([]);
  const [billType] = useState([
    {
      id: 1,
      value: "cash",
    },
    {
      id: 2,
      value: "credit",
    },
  ]);

  const { data: getSuppliersListData } = useQuery({
    queryKey: ["getSuppliersList", "all"],
    queryFn: () => getSuppliersList(user?.token, "all", null, null),
    cacheTime: 1000 * 60 * 60,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });

  const { data: getPackData } = useQuery({
    queryKey: ["getPack"],
    queryFn: () => getPack(user?.token),

    refetchOnWindowFocus: false, // Don't refetch when window regains focus
    refetchOnReconnect: false, // Don't refetch when reconnecting
  });

  const handleAddRow = (e) => {
    e.preventDefault();
    setPurchaseItem((prev) => [
      ...prev,
      {
        medicine_id: "",
        pack: "",
        hsn_code: "",
        batch: "",
        expiry_date: "",
        quantity: "",
        cgst: "",
        sgst: "",
        rate: "",
        mrp: "",
        mfr: "",
        discount: "",
        amount: "",
        department_id: "9",
      },
    ]);
  };
  const handleRemoveRow = (e, idx) => {
    e.preventDefault();

    const newRow = [...purchaseItem];
    const updatedRow = newRow?.filter((_, index) => index !== idx);
    setPurchaseItem(updatedRow);
  };
  useEffect(() => {
    if (supplierData) {
      setSupplier({
        supplier_id: supplierData.supplier.id,
        purchase_date: supplierData.purchase_date,
        bill_type: supplierData.bill_type,
        bill_number: supplierData.bill_number,
        grand_total: supplierData.grand_total,
      });
      setPurchaseItem(supplierData.purchase_items);
      // const newPurchase = supplierData.purchase_items?.map((el) => ({
      //   value: el?.medicine_id,
      //   label: el.medicine_name || el?.medicine_id,
      // }));
      // console.log(newPurchase);
    }
  }, []);
  useEffect(() => {
    if (getPackData?.packing?.length > 0) {
      const newPack = getPackData?.packing
        ?.filter((el) => el !== null)
        ?.map((el) => ({
          id: el,
          value: el,
        }));
      setPack((prev) => [...prev, ...newPack]);
    }
    if (getSuppliersListData?.payload?.suppliers?.length > 0) {
      localStorage.setItem(
        "allSuppliers",
        JSON.stringify(getSuppliersListData?.payload?.suppliers)
      );
      setFilteredSuppliers(
        getSuppliersListData?.payload?.suppliers?.slice(0, 10).map((el) => ({
          value: el?.id,
          label: el?.name,
        }))
      );
    }
  }, [getPackData, getSuppliersListData]);

  // medicine Options
  const debouncedInputMedicine = useDebounce(medicineInput, 1000);
  const { data: getAllMedicineData } = useQuery({
    queryKey: ["getAllMedicine", debouncedInputMedicine],
    queryFn: () =>
      getAllMedicine(user?.token, "q", 1, 10, debouncedInputMedicine),
    enabled: !!debouncedInputMedicine,
    cacheTime: 1000 * 60 * 60,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });
  const allMedicine = getAllMedicineData?.payload?.medicines || [];
  const formattedMedicineOptions = allMedicine?.map((el) => ({
    value: el?.id,
    label: el.name,
  }));
  const handleSearch = (inputValue) => {
    setMedicineInput(inputValue);
  };
  const handleSearchSuppliers = (inputValue) => {
    const query = inputValue?.toLowerCase();
    const storeSuppliers =
      JSON.parse(localStorage.getItem("allSuppliers")) || [];

    const filteredList = storeSuppliers.filter((el) =>
      el?.name?.toLowerCase().startsWith(query || "")
    );

    const limitedResults = (query ? filteredList : storeSuppliers)
      .slice(0, 10)
      .map((el) => ({
        value: el?.id,
        label: el?.name,
      }));

    setFilteredSuppliers(limitedResults);
  };

  const handleChange = (index, field, value, label) => {
    const newMedicines = [...purchaseItem];
    newMedicines[index] = {
      ...newMedicines[index],
      [field]: value,
      medicine_name: label,
    };
    setPurchaseItem(newMedicines);
  };

  const handleSuppliersChange = (field, value) => {
    setSupplier((prev) => ({
      ...prev,
      [field]: value,
    }));
  };
  const billOptions = billType.map((item) => ({
    value: item.value,
    label: item.value,
  }));
  //----------------- logic for pack---------------

  const options = pack?.map((item) => ({
    value: item.value,
    label: item.value,
  }));

  const handleInputChange = (inputValue) => {
    const optionExists = pack.some((item) => item.value === inputValue);

    if (!optionExists && inputValue) {
      const newOption = {
        id: Math.floor(Math.random() * 1000),
        value: inputValue,
      };
      setPack((prevPack) => [...prevPack, newOption]);
    }
  };

  const handleSubmitPurchaseOrder = async (e) => {
    e.preventDefault();
    const payload = {
      purchase_order: {
        ...supplier,
        department_id: "9",
        ...(purchaseItem.length > 0 && {
          purchase_items_attributes: purchaseItem,
        }),
      },
    };
    if (!supplier.bill_number) {
      toast.error(" Name is required");
      return;
    }
    if (!supplier.purchase_date) {
      toast.error(" Purchase Date is required");
      return;
    }
    if (!supplier.supplier_id) {
      toast.error(" Name is required");
      return;
    }
    // Check if any purchase item has missing or empty fields
    const hasInvalidPurchaseItem = purchaseItem.some(
      (item) => !item.cgst || !item.sgst || !item.amount || !item.quantity
    );
    if (hasInvalidPurchaseItem) {
      toast.error(
        "Each purchase item must have CGST, SGST, Amount, and Quantity filled"
      );
      return;
    }
    try {
      const result = await putPurchaseOrder(
        user?.token,
        payload,
        supplierData?.id
      );
      if (result?.status?.status === "SUCCESS") {
        toast.success("Purchase Order Updated Successfully");
        navigate("/admin/purchase_orders");
      }
    } catch (error) {
      // toast.error(error.message);
      console.log(error.message);
    }
  };
  // console.log(purchaseItem, "Purchase Order");
  return (
    <div>
      <Header />
      <Sidebar
        id="menu-item4"
        id1="menu-items4"
        activeClassName="purchase-order-list"
      />
      <>
        <div className="page-wrapper">
          <div className="content">
            {/* Page Header */}
            <div className="page-header">
              <div className="row">
                <div className="col-sm-12">
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="#">Purchase Order </Link>
                    </li>
                    <li className="breadcrumb-item">
                      <i className="feather-chevron-right">
                        <FeatherIcon icon="chevron-right" />
                      </i>
                    </li>
                    <li className="breadcrumb-item active">
                      Update Purchase Order
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            {/* /Page Header */}
            <div className="row">
              <div className="col-sm-12">
                <div className="card">
                  <div className="card-body">
                    <form>
                      <div className="row">
                        <div className="col-12">
                          <div className="form-heading">
                            <h4>Purchase Details</h4>
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-4">
                          <div className="form-group local-forms">
                            <label>
                              Supplier <span className="login-danger">*</span>
                            </label>
                            <Select
                              onInputChange={handleSearchSuppliers}
                              onChange={(e) =>
                                handleSuppliersChange("supplier_id", e?.value)
                              }
                              placeholder="Search for a Supplier..."
                              options={filteredSuppliers}
                              value={
                                filteredSuppliers.find(
                                  (option) =>
                                    option.value === supplier.supplier_id
                                ) || null
                              }
                              menuPortalTarget={document.body}
                              styles={{
                                menuPortal: (base) => ({
                                  ...base,
                                  zIndex: 9999,
                                }),
                                control: (baseStyles, state) => ({
                                  ...baseStyles,
                                  borderColor: state.isFocused
                                    ? "#2e37a4"
                                    : "rgba(46, 55, 164, 0.1)",
                                  boxShadow: state.isFocused
                                    ? "0 0 0 1px #2e37a4"
                                    : "none",
                                  "&:hover": {
                                    borderColor: "rgba(46, 55, 164, 0.1)",
                                  },
                                  borderRadius: "10px",
                                  fontSize: "14px",
                                  minHeight: "45px",
                                }),
                                dropdownIndicator: (base, state) => ({
                                  ...base,
                                  transform: state.selectProps.menuIsOpen
                                    ? "rotate(-180deg)"
                                    : "rotate(0)",
                                  transition: "250ms",
                                  width: "35px",
                                  height: "35px",
                                }),
                              }}
                              id="search-commodity"
                              components={{
                                IndicatorSeparator: () => null,
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-4">
                          <div className="form-group local-forms cal-icon">
                            <label>
                              Purchase Date
                              <span className="login-danger">*</span>
                            </label>
                            <DatePicker
                              className="form-control datetimepicker"
                              value={
                                supplier.purchase_date
                                  ? dayjs(supplier.purchase_date)
                                  : null
                              }
                              onChange={(_, dateString) =>
                                handleSuppliersChange(
                                  "purchase_date",
                                  dateString
                                )
                              }
                              suffixIcon={null}
                              style={{
                                control: (baseStyles, state) => ({
                                  ...baseStyles,
                                  borderColor: isClicked
                                    ? "#2E37A4"
                                    : "2px solid rgba(46, 55, 164, 0.1)",
                                  "&:hover": {
                                    borderColor: state.isFocused
                                      ? "none"
                                      : "none",
                                  },
                                }),
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-4">
                          <div className="form-group local-forms">
                            <label>Bill Type</label>
                            <Select
                              defaultValue={""}
                              onChange={(e) =>
                                handleSuppliersChange(
                                  "bill_type",
                                  e?.value // Access the value directly from the selected option
                                )
                              }
                              value={
                                billOptions.find(
                                  (option) =>
                                    option.value === supplier.bill_type
                                ) || ""
                              }
                              options={billOptions}
                              menuPortalTarget={document.body}
                              styles={{
                                menuPortal: (base) => ({
                                  ...base,
                                  zIndex: 9999,
                                }),
                              }}
                              id="search-commodity"
                              components={{
                                IndicatorSeparator: () => null,
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-6">
                          <div className="form-group local-forms">
                            <label>
                              Bill Number{" "}
                              <span className="login-danger">*</span>
                            </label>
                            <input
                              value={supplier.bill_number || ""}
                              onChange={(e) =>
                                handleSuppliersChange(
                                  "bill_number",
                                  e?.target?.value // Access the value directly from the selected option
                                )
                              }
                              required={true}
                              className="form-control"
                              type="text"
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-6">
                          <div className="form-group local-forms">
                            <label>
                              Grand Total{" "}
                              {/* <span className="login-danger">*</span> */}
                            </label>
                            <input
                              value={supplier.grand_total || ""}
                              onChange={(e) =>
                                handleSuppliersChange(
                                  "grand_total",
                                  e?.target?.value // Access the value directly from the selected option
                                )
                              }
                              className="form-control"
                              type="number"
                            />
                          </div>
                        </div>
                        {/* {part two} */}
                        <div className="col-12">
                          <div className="form-heading">
                            <h4>Items Details</h4>
                          </div>
                        </div>
                        {purchaseItem?.length > 0 &&
                          purchaseItem?.map((_, index) => (
                            <React.Fragment key={index}>
                              <div className="col-12 col-md-4 col-xl-3">
                                <div className="form-group local-forms">
                                  <label>Medicine</label>
                                  <Select
                                    value={
                                      formattedMedicineOptions.find(
                                        (option) =>
                                          option.value ===
                                          purchaseItem[index]?.medicine_id
                                      ) ||
                                      // Fallback: create an option from medicinesAttributes[index]?.name
                                      (purchaseItem[index]?.medicine_name
                                        ? {
                                            value:
                                              purchaseItem[index]
                                                ?.medicine_id || null,
                                            label:
                                              purchaseItem[index]
                                                ?.medicine_name,
                                          }
                                        : null)
                                    }
                                    options={formattedMedicineOptions}
                                    onInputChange={handleSearch}
                                    menuPortalTarget={document.body}
                                    onChange={(e) =>
                                      handleChange(
                                        index,
                                        "medicine_id",
                                        e?.value,
                                        e?.label
                                      )
                                    }
                                    placeholder="Search for a medicine..."
                                    styles={{
                                      menuPortal: (base) => ({
                                        ...base,
                                        zIndex: 9999,
                                      }),
                                      control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        borderColor: state.isFocused
                                          ? "#2e37a4"
                                          : "rgba(46, 55, 164, 0.1)",
                                        boxShadow: state.isFocused
                                          ? "0 0 0 1px #2e37a4"
                                          : "none",
                                        "&:hover": {
                                          borderColor: "rgba(46, 55, 164, 0.1)",
                                        },
                                        borderRadius: "10px",
                                        fontSize: "14px",
                                        minHeight: "45px",
                                      }),
                                      dropdownIndicator: (base, state) => ({
                                        ...base,
                                        transform: state.selectProps.menuIsOpen
                                          ? "rotate(-180deg)"
                                          : "rotate(0)",
                                        transition: "250ms",
                                        width: "35px",
                                        height: "35px",
                                      }),
                                    }}
                                    components={{
                                      IndicatorSeparator: () => null,
                                    }}
                                    id="search-commodity"
                                  />
                                </div>
                              </div>
                              <div className="col-12 col-md-4 col-xl-3">
                                <div className="form-group local-forms">
                                  <label>Pack Type</label>
                                  <Select
                                    value={
                                      options.find(
                                        (option) =>
                                          option.value ===
                                          purchaseItem[index]?.pack
                                      ) || null
                                    }
                                    onChange={(e) => {
                                      handleChange(
                                        index,
                                        "pack",
                                        e?.value // Access the value directly from the selected option
                                      );
                                    }}
                                    onInputChange={handleInputChange}
                                    options={options}
                                    placeholder="select pack type"
                                    menuPortalTarget={document.body}
                                    styles={{
                                      menuPortal: (base) => ({
                                        ...base,
                                        zIndex: 9999,
                                      }),
                                      control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        borderColor: state.isFocused
                                          ? "#2e37a4"
                                          : "rgba(46, 55, 164, 0.1)",
                                        boxShadow: state.isFocused
                                          ? "0 0 0 1px #2e37a4"
                                          : "none",
                                        "&:hover": {
                                          borderColor: "rgba(46, 55, 164, 0.1)",
                                        },
                                        borderRadius: "10px",
                                        fontSize: "14px",
                                        minHeight: "45px",
                                      }),
                                      dropdownIndicator: (base, state) => ({
                                        ...base,
                                        transform: state.selectProps.menuIsOpen
                                          ? "rotate(-180deg)"
                                          : "rotate(0)",
                                        transition: "250ms",
                                        width: "35px",
                                        height: "35px",
                                      }),
                                    }}
                                    components={{
                                      IndicatorSeparator: () => null,
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="col-12 col-md-4 col-xl-3">
                                <div className="form-group local-forms">
                                  <label>HSN Code </label>
                                  <input
                                    value={purchaseItem[index]?.hsn_code || ""}
                                    onChange={(e) =>
                                      handleChange(
                                        index,
                                        "hsn_code",
                                        e?.target?.value
                                      )
                                    }
                                    className="form-control"
                                    type="text"
                                  />
                                </div>
                              </div>
                              <div className="col-12 col-md-4 col-xl-3">
                                <div className="form-group local-forms">
                                  <label>Batch </label>
                                  <input
                                    value={purchaseItem[index]?.batch || ""}
                                    onChange={(e) =>
                                      handleChange(
                                        index,
                                        "batch",
                                        e?.target?.value
                                      )
                                    }
                                    className="form-control"
                                    type="text"
                                  />
                                </div>
                              </div>
                              <div className="col-12 col-md-4 col-xl-3">
                                <div className="form-group local-forms cal-icon">
                                  <label>
                                    Expiry Date
                                    {/* <span className="login-danger">*</span> */}
                                  </label>
                                  <DatePicker
                                    value={
                                      purchaseItem[index]?.expiry_date
                                        ? dayjs(
                                            purchaseItem[index]?.expiry_date
                                          )
                                        : null
                                    }
                                    className="form-control datetimepicker"
                                    onChange={(_, dateString) =>
                                      handleChange(
                                        index,
                                        "expiry_date",
                                        dateString
                                      )
                                    }
                                    suffixIcon={null}
                                    style={{
                                      control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        borderColor: isClicked
                                          ? "#2E37A4"
                                          : "2px solid rgba(46, 55, 164, 0.1)",
                                        "&:hover": {
                                          borderColor: state.isFocused
                                            ? "none"
                                            : "none",
                                        },
                                      }),
                                    }}
                                  />
                                  {/* <input
                          className="form-control datetimepicker"
                          type="text"
                        /> */}
                                </div>
                              </div>
                              <div className="col-12 col-md-4 col-xl-3">
                                <div className="form-group local-forms">
                                  <label>
                                    Quantity{" "}
                                    <span className="login-danger">*</span>
                                  </label>
                                  <input
                                    value={purchaseItem[index]?.quantity || ""}
                                    onChange={(e) =>
                                      handleChange(
                                        index,
                                        "quantity",
                                        e?.target?.value
                                      )
                                    }
                                    className="form-control"
                                    type="number"
                                  />
                                </div>
                              </div>
                              <div className="col-12 col-md-4 col-xl-3">
                                <div className="form-group local-forms">
                                  <label>
                                    CGST <span className="login-danger">*</span>{" "}
                                  </label>
                                  <input
                                    value={purchaseItem[index]?.cgst || ""}
                                    onChange={(e) =>
                                      handleChange(
                                        index,
                                        "cgst",
                                        e?.target?.value
                                      )
                                    }
                                    className="form-control"
                                    type="number"
                                  />
                                </div>
                              </div>
                              <div className="col-12 col-md-4 col-xl-3">
                                <div className="form-group local-forms">
                                  <label>
                                    SGST <span className="login-danger">*</span>{" "}
                                  </label>
                                  <input
                                    value={purchaseItem[index]?.sgst || ""}
                                    onChange={(e) =>
                                      handleChange(
                                        index,
                                        "sgst",
                                        e?.target?.value
                                      )
                                    }
                                    className="form-control"
                                    type="number"
                                  />
                                </div>
                              </div>
                              <div className="col-12 col-md-4 col-xl-3">
                                <div className="form-group local-forms">
                                  <label>Rate </label>
                                  <input
                                    value={purchaseItem[index]?.rate || ""}
                                    onChange={(e) =>
                                      handleChange(
                                        index,
                                        "rate",
                                        e?.target?.value
                                      )
                                    }
                                    className="form-control"
                                    type="number"
                                  />
                                </div>
                              </div>
                              <div className="col-12 col-md-4 col-xl-3">
                                <div className="form-group local-forms">
                                  <label>MRP </label>
                                  <input
                                    value={purchaseItem[index]?.mrp || ""}
                                    onChange={(e) =>
                                      handleChange(
                                        index,
                                        "mrp",
                                        e?.target?.value
                                      )
                                    }
                                    className="form-control"
                                    type="text"
                                  />
                                </div>
                              </div>
                              <div className="col-12 col-md-4 col-xl-3">
                                <div className="form-group local-forms">
                                  <label>MFR </label>
                                  <input
                                    value={purchaseItem[index]?.mfr || ""}
                                    onChange={(e) =>
                                      handleChange(
                                        index,
                                        "mfr",
                                        e?.target?.value
                                      )
                                    }
                                    className="form-control"
                                    type="text"
                                  />
                                </div>
                              </div>
                              <div className="col-12 col-md-4 col-xl-3">
                                <div className="form-group local-forms">
                                  <label>Discount </label>
                                  <input
                                    value={purchaseItem[index]?.discount || ""}
                                    onChange={(e) =>
                                      handleChange(
                                        index,
                                        "discount",
                                        e?.target?.value
                                      )
                                    }
                                    className="form-control"
                                    type="text"
                                  />
                                </div>
                              </div>
                              <div className="col-12 col-md-6 col-xl-5">
                                <div className="form-group local-forms">
                                  <label>
                                    Amount{" "}
                                    <span className="login-danger">*</span>{" "}
                                  </label>
                                  <input
                                    value={purchaseItem[index]?.amount || ""}
                                    onChange={(e) =>
                                      handleChange(
                                        index,
                                        "amount",
                                        e?.target?.value
                                      )
                                    }
                                    className="form-control"
                                    type="number"
                                  />
                                </div>
                              </div>
                              <div className="col-12 col-md-6 col-xl-5">
                                <div className="form-group local-forms">
                                  <label>Department </label>
                                  <input
                                    value={9}
                                    readOnly={true}
                                    onChange={() =>
                                      handleChange(index, "department_id", 9)
                                    }
                                    className="form-control"
                                    type="number"
                                  />
                                </div>
                              </div>
                              <div
                                style={{ marginBottom: "20px" }}
                                className="col-12 col-md-12 col-xl-2"
                              >
                                <button
                                  // style={{ width: "10%" }}
                                  onClick={(e) => handleRemoveRow(e, index)}
                                  className="btn btn-primary submit-form me-2 form-control"
                                >
                                  Remove
                                </button>
                              </div>
                            </React.Fragment>
                          ))}

                        <div className="col-12 col-md-6 col-xl-2">
                          <button
                            // style={{ width: "50%" }}
                            className="btn btn-primary submit-form me-2 form-control mb-4"
                            onClick={handleAddRow}
                          >
                            Add New Item
                          </button>
                        </div>
                        <div className="col-12">
                          <div className="doctor-submit text-end">
                            <button
                              onClick={handleSubmitPurchaseOrder}
                              type="submit"
                              className="btn btn-primary submit-form me-2"
                            >
                              Submit
                            </button>
                            <button
                              onClick={(e) => {
                                e.preventDefault();
                                navigate("/admin/purchase_orders");
                              }}
                              type="submit"
                              className="btn btn-primary cancel-form"
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="notification-box">
            <div className="msg-sidebar notifications msg-noti">
              <div className="topnav-dropdown-header">
                <span>Messages</span>
              </div>
              <div className="drop-scroll msg-list-scroll" id="msg_list">
                <ul className="list-box">
                  <li>
                    <Link to="#">
                      <div className="list-item">
                        <div className="list-left">
                          <span className="avatar">R</span>
                        </div>
                        <div className="list-body">
                          <span className="message-author">Richard Miles </span>
                          <span className="message-time">12:28 AM</span>
                          <div className="clearfix" />
                          <span className="message-content">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                          </span>
                        </div>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <div className="list-item new-message">
                        <div className="list-left">
                          <span className="avatar">J</span>
                        </div>
                        <div className="list-body">
                          <span className="message-author">John Doe</span>
                          <span className="message-time">1 Aug</span>
                          <div className="clearfix" />
                          <span className="message-content">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                          </span>
                        </div>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <div className="list-item">
                        <div className="list-left">
                          <span className="avatar">T</span>
                        </div>
                        <div className="list-body">
                          <span className="message-author">
                            {" "}
                            Tarah Shropshire{" "}
                          </span>
                          <span className="message-time">12:28 AM</span>
                          <div className="clearfix" />
                          <span className="message-content">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                          </span>
                        </div>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <div className="list-item">
                        <div className="list-left">
                          <span className="avatar">M</span>
                        </div>
                        <div className="list-body">
                          <span className="message-author">Mike Litorus</span>
                          <span className="message-time">12:28 AM</span>
                          <div className="clearfix" />
                          <span className="message-content">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                          </span>
                        </div>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <div className="list-item">
                        <div className="list-left">
                          <span className="avatar">C</span>
                        </div>
                        <div className="list-body">
                          <span className="message-author">
                            {" "}
                            Catherine Manseau{" "}
                          </span>
                          <span className="message-time">12:28 AM</span>
                          <div className="clearfix" />
                          <span className="message-content">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                          </span>
                        </div>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <div className="list-item">
                        <div className="list-left">
                          <span className="avatar">D</span>
                        </div>
                        <div className="list-body">
                          <span className="message-author">
                            {" "}
                            Domenic Houston{" "}
                          </span>
                          <span className="message-time">12:28 AM</span>
                          <div className="clearfix" />
                          <span className="message-content">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                          </span>
                        </div>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <div className="list-item">
                        <div className="list-left">
                          <span className="avatar">B</span>
                        </div>
                        <div className="list-body">
                          <span className="message-author">
                            {" "}
                            Buster Wigton{" "}
                          </span>
                          <span className="message-time">12:28 AM</span>
                          <div className="clearfix" />
                          <span className="message-content">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                          </span>
                        </div>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <div className="list-item">
                        <div className="list-left">
                          <span className="avatar">R</span>
                        </div>
                        <div className="list-body">
                          <span className="message-author">
                            {" "}
                            Rolland Webber{" "}
                          </span>
                          <span className="message-time">12:28 AM</span>
                          <div className="clearfix" />
                          <span className="message-content">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                          </span>
                        </div>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <div className="list-item">
                        <div className="list-left">
                          <span className="avatar">C</span>
                        </div>
                        <div className="list-body">
                          <span className="message-author"> Claire Mapes </span>
                          <span className="message-time">12:28 AM</span>
                          <div className="clearfix" />
                          <span className="message-content">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                          </span>
                        </div>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <div className="list-item">
                        <div className="list-left">
                          <span className="avatar">M</span>
                        </div>
                        <div className="list-body">
                          <span className="message-author">Melita Faucher</span>
                          <span className="message-time">12:28 AM</span>
                          <div className="clearfix" />
                          <span className="message-content">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                          </span>
                        </div>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <div className="list-item">
                        <div className="list-left">
                          <span className="avatar">J</span>
                        </div>
                        <div className="list-body">
                          <span className="message-author">Jeffery Lalor</span>
                          <span className="message-time">12:28 AM</span>
                          <div className="clearfix" />
                          <span className="message-content">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                          </span>
                        </div>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <div className="list-item">
                        <div className="list-left">
                          <span className="avatar">L</span>
                        </div>
                        <div className="list-body">
                          <span className="message-author">Loren Gatlin</span>
                          <span className="message-time">12:28 AM</span>
                          <div className="clearfix" />
                          <span className="message-content">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                          </span>
                        </div>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <div className="list-item">
                        <div className="list-left">
                          <span className="avatar">T</span>
                        </div>
                        <div className="list-body">
                          <span className="message-author">
                            Tarah Shropshire
                          </span>
                          <span className="message-time">12:28 AM</span>
                          <div className="clearfix" />
                          <span className="message-content">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                          </span>
                        </div>
                      </div>
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="topnav-dropdown-footer">
                <Link to="#">See all messages</Link>
              </div>
            </div>
          </div>
        </div>
      </>
    </div>
  );
};
export default EditPurchaseOrder;

import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  login02,
  // loginicon01,
  // loginicon02,
  // loginicon03,
} from "../../imagepath";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Eye, EyeOff } from "feather-icons-react/build/IconComponents";
import { postLoginUser } from "../../../connections/postLoginUser";
import { useDispatch } from "react-redux";
import {
  setSubDomainRoot,
  setUserLogin,
} from "../../../redux/Slices/userSlice";
import { toast } from "react-toastify";

const Login = () => {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [errors, setErrors] = useState({});
  const [subDomainExist, setSubDomainExist] = useState(false);
  const [subDomain, setSubDomain] = useState(
    localStorage.getItem("subDomain") || ""
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const validateForm = () => {
    let formErrors = {};
    let isValid = true;

    if (!email) {
      formErrors.email = "Email is required";
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      formErrors.email = "Email address is invalid";
      isValid = false;
    }

    if (!password) {
      formErrors.password = "Password is required";
      isValid = false;
    } else if (password.length < 6) {
      formErrors.password = "Password must be at least 6 characters";
      isValid = false;
    }

    setErrors(formErrors);
    return isValid;
  };

  const handleLogin = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    const payload = {
      admin_user: {
        email,
        password,
      },
    };

    try {
      const result = await postLoginUser(payload);

      console.log("Login Response:", result); // Debugging to ensure response structure

      // Check if a token is returned to verify successful login
      if (result && result.token) {
        localStorage.setItem("user", JSON.stringify(result)); // Store full user details
        dispatch(setUserLogin(result)); // Dispatch user data to Redux store
        const redirectPath = result.redirect
          ? `/${result.redirect}`
          : "/admin/dashboard";
        navigate(redirectPath);
      } else {
        setErrors({ general: "Incorrect email or password" }); // Display error if no token is present
      }
    } catch (error) {
      console.error("Login failed:", error);
      setErrors({ general: "An error occurred. Please try again." });
    }
  };
  const handleStoreSubDomain = (e) => {
    e.preventDefault();
    setSubDomain(subDomain.toLowerCase().trim()); // Convert to lowercase
    const isValid = /^[a-z]{0,8}$/.test(subDomain); // Regex: Only letters, max 8 characters
    if (isValid) {
      // console.log(subDomain)
      setSubDomain(subDomain); // Update state only if valid
      if (subDomain?.length <= 8 && subDomain?.length > 0) {
        localStorage.setItem("subDomain", subDomain); // Store in local storage
        // showToast("Subdomain saved successfully!", "SUCCESS");
        dispatch(setSubDomainRoot(subDomain));
        // setSubDomainExist(true);
        // window.location.reload()

        setTimeout(() => setSubDomainExist(true), 600);
      }
    } else {
      toast.error(
        "Invalid subdomain! Only letters are allowed, max length is 8."
      );
    }
  };
  const handleEditSubDomain = () => {
    localStorage.setItem("subDomain", "");
    setTimeout(() => setSubDomainExist(false), 600);
  };
  useEffect(() => {
    const storedSubDomain = localStorage.getItem("subDomain");
    if (storedSubDomain) {
      setSubDomainExist(true);
    }
  }, [subDomainExist]);
  if (!subDomainExist) {
    return (
      <>
        <div className="main-wrapper login-body">
          <div className="container-fluid px-0">
            <div className="row">
              <div className="col-lg-6 login-wrap">
                <div className="login-sec">
                  <div className="log-img">
                    <img className="img-fluid" src={login02} alt="#" />
                  </div>
                </div>
              </div>
              <div className="col-lg-6 login-wrap-bg">
                <div className="login-wrapper">
                  <div className="loginbox">
                    <div className="login-right">
                      <div className="login-right-wrap">
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "5px",
                          }}
                          className="account-logo"
                        >
                          {/* <img src={logo} width={35} height={35} alt="" />
                        <h3 style={{ fontStyle: "bold" }}>Cypher MD </h3> */}
                          <img
                            src="/logoPart1.png"
                            width={100}
                            height={43}
                            alt="Logo Part 1"
                          />
                          <img
                            src="/logoPart2.png"
                            width={35}
                            height={45}
                            alt="Logo Part 2"
                          />
                        </div>
                        <h2>Organization Code</h2>
                        {/* Form */}
                        {/* <form> */}
                        <div className="form-group">
                          <label>
                            Enter your Organization Code{" "}
                            <span className="login-danger">*</span>
                          </label>
                          <input
                            value={subDomain}
                            onChange={(e) => {
                              const value = e.target.value.toLowerCase(); // Convert input to lowercase
                              if (/^[a-z]*$/.test(value) && value.length <= 8) {
                                // Only allow lowercase letters and restrict length to 8
                                setSubDomain(value);
                              } else {
                                toast.error(
                                  "Invalid subdomain! Only letters are allowed, and  max length is 8."
                                );
                              }
                            }}
                            className="form-control"
                            type="text"
                          />
                        </div>

                        <div className="form-group login-btn">
                          <button
                            onClick={handleStoreSubDomain}
                            className="btn btn-primary btn-block"
                          >
                            Proceed
                          </button>
                        </div>
                        {/* </form> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
  return (
    <>
      {/* Main Wrapper */}
      <div className="main-wrapper login-body">
        <div className="container-fluid px-0">
          <div className="row">
            <div className="col-lg-6 login-wrap">
              <div className="login-sec">
                <div className="log-img">
                  <img className="img-fluid" src={login02} alt="#" />
                </div>
              </div>
            </div>
            <div className="col-lg-6 login-wrap-bg">
              <div className="login-wrapper">
                <div className="loginbox">
                  <div className="login-right">
                    <div className="login-right-wrap">
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "5px",
                        }}
                        className="account-logo"
                      >
                        {/* <img src={logo} width={35} height={35} alt="" />
                        <h3 style={{ fontStyle: "bold" }}>Cypher MD </h3> */}
                        <img
                          src="/logoPart1.png"
                          width={100}
                          height={43}
                          alt="Logo Part 1"
                        />
                        <img
                          src="/logoPart2.png"
                          width={35}
                          height={45}
                          alt="Logo Part 2"
                        />
                      </div>
                      <h2>Login</h2>
                      {/* Form */}
                      <form>
                        <div className="form-group">
                          <label>
                            Email <span className="login-danger">*</span>
                          </label>
                          <input
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            className="form-control"
                            type="text"
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                handleLogin(e);
                              }
                            }}
                          />
                          {errors.email && (
                            <small className="text-danger">
                              {errors.email}
                            </small>
                          )}
                        </div>
                        <div className="form-group">
                          <label>
                            Password <span className="login-danger">*</span>
                          </label>
                          <input
                            type={passwordVisible ? "text" : "password"}
                            className="form-control pass-input"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                handleLogin(e);
                              }
                            }}
                          />
                          {errors.password && (
                            <small className="text-danger">
                              {errors.password}
                            </small>
                          )}
                          <span
                            className="toggle-password"
                            onClick={togglePasswordVisibility}
                          >
                            {passwordVisible ? (
                              <EyeOff className="react-feather-custom" />
                            ) : (
                              <Eye className="react-feather-custom" />
                            )}
                          </span>
                        </div>
                        {errors.general && (
                          <small className="text-danger">
                            {errors.general}
                          </small>
                        )}
                        <div className="forgotpass">
                          <Link type="submit" onClick={handleEditSubDomain}>
                            Change Code ?
                          </Link>
                        </div>
                        <div className="form-group login-btn">
                          <button
                            onClick={handleLogin}
                            className="btn btn-primary btn-block"
                          >
                            Login
                          </button>
                        </div>
                      </form>
                      {/* /Form */}
                      {/* <div className="next-sign">
                        <p className="account-subtitle">
                          Need an account? <Link to="/signup">Sign Up</Link>
                        </p>
                        <div className="social-login">
                          <Link to="#">
                            <img src={loginicon01} alt="#" />
                          </Link>
                          <Link to="#">
                            <img src={loginicon02} alt="#" />
                          </Link>
                          <Link to="#">
                            <img src={loginicon03} alt="#" />
                          </Link>
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;

import React, { useState } from "react";
import { Table } from "antd";
import Header from "../Header";
import Sidebar from "../Sidebar";
import {
  imagesend,
  pdficon,
  pdficon3,
  plusicon,
  refreshicon,
  pdficon5,
} from "../imagepath";
// import { onShowSizeChange, itemRender } from "../Pagination";
import { Link, useNavigate } from "react-router-dom";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { useSelector } from "react-redux";
import { useQuery } from "@tanstack/react-query";

import dayjs from "dayjs";

import {
  generateCsv,
  handleDownloadPdfInventory,
  handleShowJson,
} from "../../utils/constant/constant";
import { toast } from "react-toastify";

import { getAllInventories } from "../../connections/getAllInventories";
import { deleteLabInventory } from "../../connections/deleteLabInventory";

const LabInventory = () => {
  const [deleteId, setDeleteId] = useState("");
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(50);

  const {
    user: { user },
  } = useSelector((store) => store);
  const navigate = useNavigate();
  const {
    data: getLabInventoryData,
    isLoading: getLabInventoryLoader,
    refetch: refetchLabInventory,
  } = useQuery({
    queryKey: ["getAllInventories", page, pageSize],
    queryFn: () => getAllInventories(user?.token, null, page, pageSize),
    refetchOnWindowFocus: false, // Don't refetch when window regains focus
    refetchOnReconnect: false, // Don't refetch when reconnecting
  });

  const onSelectChange = (newSelectedRowKeys) => {
    console.log("selectedRowKeys changed: ", selectedRowKeys);

    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const columns = [
    {
      title: "Id",
      dataIndex: "id",
      // sorter: (a, b) => a.ConsultingDoctor.length - b.ConsultingDoctor.length,/
    },
    {
      title: "Name",
      dataIndex: "name",
      // sorter: (a, b) => a.name - b.name,
    },
    {
      title: "Medical Tests",
      dataIndex: "medical_tests",
      //   sorter: (a, b) => a.ConsultingDoctor.length - b.ConsultingDoctor.length,
      render: (_, record) => (
        <>
          {record?.medical_tests?.map((el) => (
            <ul
              key={el.id}
              //   style={{
              //     display: "inline-flex",
              //     marginRight: "7px",
              //     flexDirection: "row",
              //   }}
            >
              <li>
                <Link
                  style={{
                    textDecoration: "underline",
                  }}
                  to={`/admin/medical_tests/${el?.id}`}
                >
                  {el.name}
                </Link>
              </li>
            </ul>
          ))}
        </>
      ),
    },
    {
      title: "Stock Left",
      dataIndex: "stock_left",
      //   sorter: (a, b) => a.Treatment.length - b.Treatment.length,
      // render: (date) => <>{dayjs(date).format("MMMM DD, YYYY HH:mm")}</>,
    },
    {
      title: "Critical Stock Limit",
      dataIndex: "critical_stock_limit",
      //   sorter: (a, b) => a.Mobile.length - b.Mobile.length,
      // render: (date) => <>{dayjs(date).format("MMMM DD, YYYY")}</>,
    },
    {
      title: "Price",
      dataIndex: "price",
    },
    {
      title: "Brand",
      dataIndex: "brand",
    },
    {
      title: "Purchased At",
      dataIndex: "purchased_at",
      render: (date) => (
        <>{dayjs(date, "YYYY-MM-DD").format("MMMM DD, YYYY")}</>
      ),
    },
    {
      title: "Units Purchased",
      dataIndex: "units_purchased",
    },
    {
      title: "Auto Purchase Threshold",
      dataIndex: "auto_purchase_threshold",
    },
    {
      title: "",
      dataIndex: "FIELD8",
      render: (_, record) => (
        <>
          <div className="text-end">
            <div className="dropdown dropdown-action">
              <Link
                to="#"
                className="action-icon dropdown-toggle"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i className="fas fa-ellipsis-v" />
              </Link>
              <div className="dropdown-menu dropdown-menu-end">
                <Link
                  className="dropdown-item"
                  to={`/admin/lab_inventory/${record?.id}`}
                >
                  <i className="fa-regular fa-eye m-r-5" />
                  View
                </Link>
                <button
                  className="dropdown-item"
                  onClick={() =>
                    navigate("/admin/update_lab_inventory", { state: record })
                  }
                >
                  <i className="far fa-edit me-2" />
                  Edit
                </button>
                <Link
                  className="dropdown-item"
                  to="#"
                  data-bs-toggle="modal"
                  data-bs-target="#delete_patient"
                  onClick={() => {
                    setDeleteId(record?.id);
                  }}
                >
                  <i className="fa fa-trash-alt m-r-5"></i> Delete
                </Link>
              </div>
            </div>
          </div>
        </>
      ),
    },
  ];
  const handleDeleteLabInventory = async (e) => {
    e.preventDefault();

    try {
      const data = await deleteLabInventory(user?.token, deleteId);
      if (data.status.status === "SUCCESS") {
        toast.success("Lab Inventory Deleted Successfully");
        refetchLabInventory();
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handlePageChange = (newPage, newPageSize) => {
    setPage(newPage);
    setPageSize(newPageSize);
  };

  return (
    <>
      <Header />
      <Sidebar activeClassName="lab-inventory-list" />
      <>
        <div className="page-wrapper">
          <div className="content">
            {/* Page Header */}
            <div className="page-header">
              <div className="row">
                <div className="col-sm-12">
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="#">Lab Inventory</Link>
                    </li>
                    <li className="breadcrumb-item">
                      <i className="feather-chevron-right">
                        <FeatherIcon icon="chevron-right" />
                      </i>
                    </li>
                    <li className="breadcrumb-item active">
                      Lab Inventory List
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            {/* /Page Header */}
            <div className="row">
              <div className="col-sm-12">
                <div className="card card-table show-entire">
                  <div className="card-body">
                    {/* Table Header */}
                    <div className="page-table-header mb-2">
                      <div className="row align-items-center">
                        <div className="col">
                          <div className="doctor-table-blk">
                            <h3>Lab Inventory List</h3>
                            <div className="doctor-search-blk">
                              <div className="add-group">
                                <Link
                                  to="/admin/add_lab_inventory"
                                  className="btn btn-primary add-pluss ms-2"
                                >
                                  <img src={plusicon} alt="#" />
                                </Link>
                                <Link
                                  onClick={refetchLabInventory}
                                  className="btn btn-primary add-pluss ms-2"
                                >
                                  <img src={refreshicon} alt="#" />
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-auto text-end float-end ms-auto download-grp">
                          {/* <Link id="download-pdf" to="#" className=" me-2"> */}
                          <img
                            className=" me-2"
                            onClick={() =>
                              handleDownloadPdfInventory(
                                getLabInventoryData?.payload?.lab_inventories
                              )
                            }
                            src={pdficon}
                            alt="#"
                            style={{ cursor: "pointer" }}
                          />
                          {/* </Link> */}
                          {/* <Link
                           
                            id="download-csv"
                            className=" me-2"
                          > */}
                          <img
                            onClick={() =>
                              generateCsv(
                                getLabInventoryData?.payload?.lab_inventories
                              )
                            }
                            className=" me-2"
                            src={pdficon3}
                            alt="#"
                            style={{ cursor: "pointer" }}
                          />
                          {/* </Link> */}
                          {/* <Link id="show-json" to="#"> */}
                          <img
                            onClick={() =>
                              handleShowJson(
                                getLabInventoryData?.payload?.lab_inventories
                              )
                            }
                            width={"25px"}
                            height={"26px"}
                            src={pdficon5}
                            alt="#"
                            style={{ cursor: "pointer" }}
                          />
                          {/* </Link> */}
                        </div>
                      </div>
                    </div>
                    {/* /Table Header */}
                    <div className="table-responsive doctor-list">
                      <Table
                        loading={getLabInventoryLoader}
                        pagination={{
                          current: page,
                          pageSize: pageSize,
                          total: getLabInventoryData?.meta?.total_count,
                          showTotal: (total, range) =>
                            `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                          onChange: handlePageChange,
                        }}
                        columns={columns}
                        dataSource={
                          getLabInventoryData?.payload?.lab_inventories
                        }
                        rowSelection={rowSelection}
                        rowKey={(record) => record.id}
                        scroll={{ x: "max-content" }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="notification-box">
            <div className="msg-sidebar notifications msg-noti">
              <div className="topnav-dropdown-header">
                <span>Messages</span>
              </div>
              <div className="drop-scroll msg-list-scroll" id="msg_list">
                <ul className="list-box">
                  <li>
                    <Link to="#">
                      <div className="list-item">
                        <div className="list-left">
                          <span className="avatar">R</span>
                        </div>
                        <div className="list-body">
                          <span className="message-author">Richard Miles </span>
                          <span className="message-time">12:28 AM</span>
                          <div className="clearfix" />
                          <span className="message-content">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                          </span>
                        </div>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <div className="list-item new-message">
                        <div className="list-left">
                          <span className="avatar">J</span>
                        </div>
                        <div className="list-body">
                          <span className="message-author">John Doe</span>
                          <span className="message-time">1 Aug</span>
                          <div className="clearfix" />
                          <span className="message-content">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                          </span>
                        </div>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <div className="list-item">
                        <div className="list-left">
                          <span className="avatar">T</span>
                        </div>
                        <div className="list-body">
                          <span className="message-author">
                            {" "}
                            Tarah Shropshire{" "}
                          </span>
                          <span className="message-time">12:28 AM</span>
                          <div className="clearfix" />
                          <span className="message-content">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                          </span>
                        </div>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <div className="list-item">
                        <div className="list-left">
                          <span className="avatar">M</span>
                        </div>
                        <div className="list-body">
                          <span className="message-author">Mike Litorus</span>
                          <span className="message-time">12:28 AM</span>
                          <div className="clearfix" />
                          <span className="message-content">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                          </span>
                        </div>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <div className="list-item">
                        <div className="list-left">
                          <span className="avatar">C</span>
                        </div>
                        <div className="list-body">
                          <span className="message-author">
                            {" "}
                            Catherine Manseau{" "}
                          </span>
                          <span className="message-time">12:28 AM</span>
                          <div className="clearfix" />
                          <span className="message-content">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                          </span>
                        </div>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <div className="list-item">
                        <div className="list-left">
                          <span className="avatar">D</span>
                        </div>
                        <div className="list-body">
                          <span className="message-author">
                            {" "}
                            Domenic Houston{" "}
                          </span>
                          <span className="message-time">12:28 AM</span>
                          <div className="clearfix" />
                          <span className="message-content">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                          </span>
                        </div>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <div className="list-item">
                        <div className="list-left">
                          <span className="avatar">B</span>
                        </div>
                        <div className="list-body">
                          <span className="message-author">
                            {" "}
                            Buster Wigton{" "}
                          </span>
                          <span className="message-time">12:28 AM</span>
                          <div className="clearfix" />
                          <span className="message-content">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                          </span>
                        </div>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <div className="list-item">
                        <div className="list-left">
                          <span className="avatar">R</span>
                        </div>
                        <div className="list-body">
                          <span className="message-author">
                            {" "}
                            Rolland Webber{" "}
                          </span>
                          <span className="message-time">12:28 AM</span>
                          <div className="clearfix" />
                          <span className="message-content">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                          </span>
                        </div>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <div className="list-item">
                        <div className="list-left">
                          <span className="avatar">C</span>
                        </div>
                        <div className="list-body">
                          <span className="message-author"> Claire Mapes </span>
                          <span className="message-time">12:28 AM</span>
                          <div className="clearfix" />
                          <span className="message-content">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                          </span>
                        </div>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <div className="list-item">
                        <div className="list-left">
                          <span className="avatar">M</span>
                        </div>
                        <div className="list-body">
                          <span className="message-author">Melita Faucher</span>
                          <span className="message-time">12:28 AM</span>
                          <div className="clearfix" />
                          <span className="message-content">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                          </span>
                        </div>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <div className="list-item">
                        <div className="list-left">
                          <span className="avatar">J</span>
                        </div>
                        <div className="list-body">
                          <span className="message-author">Jeffery Lalor</span>
                          <span className="message-time">12:28 AM</span>
                          <div className="clearfix" />
                          <span className="message-content">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                          </span>
                        </div>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <div className="list-item">
                        <div className="list-left">
                          <span className="avatar">L</span>
                        </div>
                        <div className="list-body">
                          <span className="message-author">Loren Gatlin</span>
                          <span className="message-time">12:28 AM</span>
                          <div className="clearfix" />
                          <span className="message-content">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                          </span>
                        </div>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <div className="list-item">
                        <div className="list-left">
                          <span className="avatar">T</span>
                        </div>
                        <div className="list-body">
                          <span className="message-author">
                            Tarah Shropshire
                          </span>
                          <span className="message-time">12:28 AM</span>
                          <div className="clearfix" />
                          <span className="message-content">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                          </span>
                        </div>
                      </div>
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="topnav-dropdown-footer">
                <Link to="#">See all messages</Link>
              </div>
            </div>
          </div>
        </div>
        <div
          id="delete_patient"
          className="modal fade delete-modal"
          role="dialog"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body text-center">
                <img src={imagesend} alt="#" width={50} height={46} />
                <h3>Are you sure want to delete this ?</h3>
                <div className="m-t-20">
                  {" "}
                  <Link
                    to="#"
                    className="btn btn-white me-2"
                    data-bs-dismiss="modal"
                  >
                    Close
                  </Link>
                  <button
                    onClick={handleDeleteLabInventory}
                    type="submit"
                    className="btn btn-danger"
                    data-bs-dismiss="modal"
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div
            id="delete_patient"
            className="modal fade delete-modal"
            role="dialog"
          >
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-body text-center">
                  <img src={imagesend} alt="#" width={50} height={46} />
                  <h3>Are you sure want to delete this ?</h3>
                  <div className="m-t-20">
                    {" "}
                    <Link
                      to="#"
                      className="btn btn-white me-2"
                      data-bs-dismiss="modal"
                    >
                      Close
                    </Link>
                    <button
                      data-bs-dismiss="modal"
                      type="submit"
                      className="btn btn-danger"
                    >
                      Delete
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
      ;
    </>
  );
};
export default LabInventory;

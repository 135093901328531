import React from "react";
import Header from "../Header";
import Sidebar from "../Sidebar";
import { Link, useParams } from "react-router-dom";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { useQuery } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { Skeleton } from "@mui/material";
import dayjs from "dayjs";
// import { Table } from "antd";
import { getReferrersDetails } from "../../connections/getReferrersDetails";

const ShowReferrers = () => {
  const {
    user: { user },
  } = useSelector((store) => store);
  const { id } = useParams();
  const { data: referrersDetailsData, isLoading } = useQuery({
    queryKey: ["getReferrersDetails", id],
    queryFn: () => getReferrersDetails(user?.token, id),
    enabled: !!id,
    cacheTime: 1000 * 60 * 60,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchOnMount: false,
  });
  // const columns = [
  //   {
  //     title: "Id",
  //     dataIndex: "id",
  //   },
  //   {
  //     title: "Full Name",
  //     dataIndex: "full_name",
  //   },
  //   {
  //     title: "Email",
  //     dataIndex: "email",
  //   },

  //   {
  //     title: "Phone",
  //     dataIndex: "phone",
  //   },
  //   {
  //     title: "Profession",
  //     dataIndex: "profession",
  //   },
  //   {
  //     title: "Qualification",
  //     dataIndex: "qualification",
  //   },
  //   {
  //     title: "Address",
  //     dataIndex: "address",
  //   },
  //   {
  //     title: "City",
  //     dataIndex: "city",
  //   },
  //   {
  //     title: "Letter Text",
  //     dataIndex: "letter_text",
  //   },
  //   {
  //     title: "Created At",
  //     dataIndex: "created_at",
  //     //   sorter: (a, b) => a.ConsultingDoctor.length - b.ConsultingDoctor.length,
  //     render: (date) => (
  //       <>
  //         {" "}
  //         {dayjs(date, "YYYY-MM-DD HH:mm:ss A").format("MMMM DD, YYYY HH:mm")}
  //       </>
  //     ),
  //   },
  //   {
  //     title: "Updated At",
  //     dataIndex: "updated_at",
  //     //   sorter: (a, b) => a.Treatment.length - b.Treatment.length,
  //     render: (date) => (
  //       <>
  //         {" "}
  //         {dayjs(date, "YYYY-MM-DD HH:mm:ss A").format("MMMM DD, YYYY HH:mm")}
  //       </>
  //     ),
  //   },
  // ];
  return (
    <>
      <Header />
      <Sidebar activeClassName="referrers-list" />
      <>
        <div className="page-wrapper">
          <div className="content">
            {/* Page Header */}
            <div className="page-header">
              <div className="row">
                <div className="col-sm-12">
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item active">
                      <Link to={-1}>Referrers Details</Link>
                    </li>
                    <li className="breadcrumb-item">
                      <i className="feather-chevron-right">
                        <FeatherIcon icon="chevron-right" />
                      </i>
                    </li>
                    <li className="breadcrumb-item active">{id}</li>
                  </ul>
                </div>
              </div>
            </div>
            {/* /Page Header */}
            <div className="row">
              <div className="col-sm-12">
                <div className="card card-table show-entire">
                  <div className="card-body">
                    {/* content Header */}
                    <div className="page-table-header mb-2">
                      <div className="row align-items-center">
                        <div className="col">
                          <div className="doctor-table-blk">
                            <h3>Referrers Details</h3>
                            <div className="doctor-search-blk"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {isLoading ? (
                      <div>
                        <Skeleton
                          variant="rectangular"
                          width="100%"
                          height={430}
                        />
                      </div>
                    ) : (
                      <div className="content-details">
                        <div className="col-lg-12">
                          <div className="card-box">
                            <div className="card-block">
                              <ul className="personal-info">
                                <li className="pb-1">
                                  <span className="title">Id:</span>
                                  <span className="text">
                                    {referrersDetailsData?.id || "N/A"}
                                  </span>
                                </li>
                                <li className="pb-1">
                                  <span className="title">Full Name:</span>
                                  <span className="text">
                                    {referrersDetailsData?.full_name || "N/A"}
                                  </span>
                                </li>
                                <li className="pb-1">
                                  <span className="title">Email:</span>
                                  <span className="text">
                                    {referrersDetailsData?.email || "N/A"}
                                  </span>
                                </li>
                                <li className="pb-1">
                                  <span className="title">Phone:</span>
                                  <span className="text">
                                    {referrersDetailsData?.phone || "N/A"}
                                  </span>
                                </li>
                                <li className="pb-1">
                                  <span className="title">Profession:</span>
                                  <span className="text">
                                    {referrersDetailsData?.profession || "N/A"}
                                  </span>
                                </li>
                                <li className="pb-1">
                                  <span className="title">Qualification:</span>
                                  <span className="text">
                                    {referrersDetailsData?.qualification ||
                                      "N/A"}
                                  </span>
                                </li>
                                <li className="pb-1">
                                  <span className="title">Address:</span>
                                  <span className="text">
                                    {referrersDetailsData?.address || "N/A"}
                                  </span>
                                </li>
                                <li className="pb-1">
                                  <span className="title">City:</span>
                                  <span className="text">
                                    {referrersDetailsData?.city || "N/A"}
                                  </span>
                                </li>
                                <li className="pb-1">
                                  <span className="title">Letter Text:</span>
                                  <span className="text">
                                    {referrersDetailsData?.letter_text || "N/A"}
                                  </span>
                                </li>
                                <li className="pb-1">
                                  <span className="title">Created At:</span>
                                  <span className="text">
                                    {dayjs(
                                      referrersDetailsData?.created_at,
                                      "YYYY-MM-DD HH:mm:ss A"
                                    ).format("MMMM DD, YYYY HH:mm") || "N/A"}
                                  </span>
                                </li>
                                <li className="pb-1">
                                  <span className="title">Updated At:</span>
                                  <span className="text">
                                    {dayjs(
                                      referrersDetailsData?.updated_at,
                                      "YYYY-MM-DD HH:mm:ss A"
                                    ).format("MMMM DD, YYYY HH:mm") || "N/A"}
                                  </span>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      // <div className="table-responsive">
                      //   <Table
                      //     columns={columns}
                      //     dataSource={[referrersDetailsData]}
                      //     pagination={false}
                      //     scroll={{ x: "max-content" }}
                      //   />
                      // </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </>
  );
};

export default ShowReferrers;

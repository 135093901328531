import React, { useEffect, useState } from "react";
import { Dropdown, Menu, Table } from "antd";
import Header from "../Header";
import Sidebar from "../Sidebar";
import {
  imagesend,
  pdficon,
  pdficon3,
  plusicon,
  refreshicon,
  pdficon5,
  searchnormal,
} from "../imagepath";
import { Link } from "react-router-dom";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { useQuery } from "@tanstack/react-query";
import { getAllAppointmentList } from "../../connections/getAllAppointmentList";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import {
  generateCsv,
  handlePrintAppointment,
  handleShowJson,
} from "../../utils/constant/constant";
import { deleteAppointment } from "../../connections/deleteAppointment";
import { toast } from "react-toastify";
import Select from "react-select";
import useDebounce from "../../utils/hooks";
import { getPatientsList } from "../../connections/getPatientsList";
import FilterAltIcon from "@mui/icons-material/FilterAlt";

const AppoinmentList = () => {
  const [AppId, setAppId] = useState(null);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [sortColumns, setSortColumns] = useState("");
  const [sortOrder, setSortOrder] = useState("");
  const [filter, setFilter] = useState({});
  const [showFilter, setShowFilter] = useState(false);
  const [inputValue, setInputValue] = useState("");

  const {
    user: { user },
  } = useSelector((store) => store);
  // const navigate = useNavigate();
  const debouncedInputValue = useDebounce(inputValue, 1000);
  const { data: getSearchedPatient } = useQuery({
    queryKey: ["getAdmissionsList", debouncedInputValue, 1, 10],
    queryFn: () => getPatientsList(user?.token, debouncedInputValue, 1, 10),
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    enabled: !!debouncedInputValue,
  });
  const {
    data: getAllAppointmentListData,
    isLoading: getAppointmentLoader,
    refetch: refetchAppointmentList,
    isRefetching,
  } = useQuery({
    queryKey: ["getAllAppointmentList", page, pageSize, sortColumns, sortOrder],
    queryFn: () =>
      getAllAppointmentList(
        user?.token,
        null,
        page,
        pageSize,
        sortColumns,
        sortOrder,
        filter
      ),
    refetchOnWindowFocus: false, // Don't refetch when window regains focus
    refetchOnReconnect: false, // Don't refetch when reconnecting
  });
  const onSelectChange = (newSelectedRowKeys) => {
    console.log("selectedRowKeys changed: ", selectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const handleTableChange = async (filters, sorter) => {
    // Extract sorting details
    const sortColumn = sorter.field;
    const sortOrder =
      sorter.order === "ascend"
        ? "asc"
        : sorter.order === "descend"
        ? "desc"
        : null;
    // console.log("sorter", sorter);
    setSortColumns(sortColumn);
    setSortOrder(sortOrder);
  };
  const handleFilterChange = (key, value) => {
    setFilter((prev) => {
      const updatedFilter = { ...prev };
      if (value === null || value === "" || !value) {
        delete updatedFilter[key];
      } else {
        updatedFilter[key] = value;
      }

      return updatedFilter;
    });
  };
  const handleSubmitFilter = () => {
    refetchAppointmentList(
      user?.token,
      null,
      page,
      pageSize,
      sortColumns,
      sortOrder,
      filter
    );
  };
  const handleInputChange = (newInputValue) => {
    setInputValue(newInputValue);
  };
  const columns = [
    {
      title: "Id",
      dataIndex: "id",
      sorter: true,
    },
    {
      title: "Patient UID",
      dataIndex: "uid",
      sorter: true,
      render: (_, record) => <span>{record?.patient?.uid}</span>,
    },
    {
      title: "Patient",
      dataIndex: "patient",
      render: (_, record) => (
        <Link
          to={`/admin/patient/${record?.patient?.id}`}
          style={{ textDecoration: "underline" }}
        >
          {record?.patient?.full_name}
        </Link>
      ),
    },
    {
      title: "Visit Type",
      dataIndex: "visit_type",
      sorter: true,
    },
    {
      title: "Visit Date",
      dataIndex: "visit_date",
      sorter: true,
    },
    {
      title: "Status",
      dataIndex: "status",
    },
    {
      title: "Appointment Slot",
      dataIndex: "appointment_slot",
      render: (_, record) => <>{record?.appointment_slot?.code || "waiting"}</>,
    },
    {
      title: "Consultant",
      dataIndex: "consultant",
      render: (_, record) => (
        <Link
          to={`/admin/doctor/${record?.consultant?.id}`}
          style={{ textDecoration: "underline" }}
        >
          {record?.consultant?.first_name} {record?.consultant?.last_name}
        </Link>
      ),
    },
    {
      title: "Doctor",
      dataIndex: "doctor",
      render: (_, record) => (
        <Link
          to={`/admin/doctor/${record?.doctor?.id}`}
          style={{ textDecoration: "underline" }}
        >
          {record?.doctor?.first_name} {record?.doctor?.last_name}
        </Link>
      ),
    },
    {
      title: "Created At",
      dataIndex: "created_at",
      sorter: true,
      render: (date) => (
        <>
          {dayjs(date, "YYYY-MM-DD HH:mm:ss A").format("MMMM DD, YYYY HH:mm")}
        </>
      ),
    },
    // {
    //   title: "",
    //   dataIndex: "FIELD8",
    //   render: (_, record) => (
    //     <>
    //       <div className="text-end">
    //         <div className="dropdown dropdown-action">
    //           <Link
    //             to="#"
    //             className="action-icon dropdown-toggle"
    //             data-bs-toggle="dropdown"
    //             aria-expanded="false"
    //           >
    //             <i className="fas fa-ellipsis-v" />
    //           </Link>
    //           <div className="dropdown-menu dropdown-menu-end">
    //             <Link
    //               className="dropdown-item"
    //               to={`/admin/appointment/${record?.id}`}
    //             >
    //               <i className="fa-regular fa-eye m-r-5" />
    //               View
    //             </Link>
    //             <Link
    //               className="dropdown-item"
    //               // to="/editappoinments"
    //               style={{ cursor: "not-allowed" }}
    //             >
    //               <i className="far fa-edit me-2" />
    //               Edit
    //             </Link>
    //             <Link
    //               className="dropdown-item"
    //               to="#"
    //               data-bs-toggle="modal"
    //               data-bs-target="#delete_patient"
    //               onClick={() => {
    //                 setAppId(record?.id);
    //               }}
    //             >
    //               <i className="fa fa-trash-alt m-r-5"></i> Delete
    //             </Link>
    //           </div>
    //         </div>
    //       </div>
    //     </>
    //   ),
    // },
    {
      title: "",
      dataIndex: "FIELD8",
      render: (_, record) => (
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item key="view">
                <Link to={`/admin/appointment/${record?.id}`}>
                  <i className="fa-regular fa-eye m-r-5" /> View
                </Link>
              </Menu.Item>
              <Menu.Item key="edit" style={{ cursor: "not-allowed" }}>
                <i className="far fa-edit me-2" /> Edit
              </Menu.Item>
              <Menu.Item
                className="dropdown-item"
                to="#"
                data-bs-toggle="modal"
                data-bs-target="#delete_patient"
                key="delete"
                onClick={() => {
                  setAppId(record?.id);
                }}
              >
                <i className="fa fa-trash-alt m-r-5" /> Delete
              </Menu.Item>
            </Menu>
          }
          trigger={["click"]}
          placement="bottomRight"
        >
          <Link
            to="#"
            style={{ marginRight: 5 }}
            className="action-icon"
            // data-bs-toggle="dropdown"
            // aria-expanded="false"
          >
            <i className="fas fa-ellipsis-v" />
          </Link>
        </Dropdown>
      ),
    },
  ];
  const visitTypeOption = [
    { value: "1", label: "New" },
    { value: "2", label: "Old" },
    { value: "3", label: "Review" },
    { value: "4", label: "Complimentary" },
  ];
  const statusOptions = [
    { value: "pending", label: "Pending" },
    { value: "confirmed", label: "Confirmed" },
    { value: "completed", label: "Completed" },
    { value: "cancelled", label: "Cancelled" },
    { value: "paid", label: "Paid" },
    { value: "missed", label: "Missed" },
    { value: "finished", label: "Finished" },
    { value: "rescheduled", label: "Rescheduled" },
    { value: "refunded", label: "Refunded" },
  ];
  const handlePageChange = (newPage, newPageSize) => {
    setPage(newPage);
    setPageSize(newPageSize);
  };
  const handleDeleteAppointment = async (e) => {
    e.preventDefault();
    try {
      const data = await deleteAppointment(user?.token, AppId);
      if (data.status.status === "SUCCESS") {
        toast.success("Appointment Deleted Successfully");
        refetchAppointmentList();
      }
    } catch (error) {
      toast.error(error.message);
    }
  };
  useEffect(() => {
    if (!showFilter) {
      setFilter({});
    }
  }, [showFilter]);
  return (
    <>
      <Header />
      <Sidebar
        id="menu-item4"
        id1="menu-items4"
        activeClassName="appoinment-list"
      />
      <>
        <div className="page-wrapper">
          <div className="content">
            {/* Page Header */}
            <div className="page-header">
              <div className="row">
                <div className="col-sm-12">
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="#">Appointment</Link>
                    </li>
                    <li className="breadcrumb-item">
                      <i className="feather-chevron-right">
                        <FeatherIcon icon="chevron-right" />
                      </i>
                    </li>
                    <li className="breadcrumb-item active">Appointment List</li>
                  </ul>
                </div>
              </div>
            </div>
            {/* /Page Header */}
            <div className="row">
              <div className="col-sm-12">
                <div className="card card-table show-entire">
                  <div className="card-body">
                    {/* {filter } */}
                    <div className="page-table-header mb-2">
                      <div className="row align-items-center">
                        {showFilter && (
                          <div className="col filter-container">
                            <div className="doctor-table-blk">
                              <h3 style={{ marginBottom: 2 }}>Filter</h3>
                            </div>
                            <div className="row mt-2">
                              <div className="col-12 col-md-4 col-lg-2">
                                <div className="form-group local-forms">
                                  <label>
                                    <img
                                      width="15px"
                                      height="15px"
                                      style={{ marginRight: 2 }}
                                      src={searchnormal}
                                      alt="#"
                                    />
                                    Search
                                  </label>
                                  <input
                                    value={filter.q}
                                    onChange={(e) =>
                                      handleFilterChange("q", e.target.value)
                                    }
                                    className="form-control"
                                    type="text"
                                    placeholder="Search here"
                                  />
                                </div>
                              </div>

                              <div className="col-12 col-md-4 col-lg-2">
                                <div className="form-group local-forms">
                                  <label>
                                    Patient
                                    {/* <span className="login-danger">*</span> */}
                                  </label>
                                  <Select
                                    inputValue={inputValue}
                                    onInputChange={handleInputChange}
                                    onChange={(e) =>
                                      handleFilterChange(
                                        "patient_uid",
                                        e ? e.value : null
                                      )
                                    }
                                    isClearable
                                    placeholder="Search"
                                    options={
                                      getSearchedPatient?.payload?.patients?.map(
                                        (patient) => ({
                                          label: patient.full_name,
                                          value: patient.uid,
                                        })
                                      ) || []
                                    }
                                    menuPortalTarget={document.body}
                                    styles={{
                                      menuPortal: (base) => ({
                                        ...base,
                                        zIndex: 9999,
                                      }),
                                      control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        borderColor: state.isFocused
                                          ? "#2e37a4"
                                          : "rgba(46, 55, 164, 0.1)",
                                        boxShadow: state.isFocused
                                          ? "0 0 0 1px #2e37a4"
                                          : "none",
                                        "&:hover": {
                                          borderColor: "rgba(46, 55, 164, 0.1)",
                                        },
                                        borderRadius: "10px",
                                        fontSize: "14px",
                                        // minHeight: "45px",
                                      }),
                                      dropdownIndicator: (base, state) => ({
                                        ...base,
                                        transform: state.selectProps.menuIsOpen
                                          ? "rotate(-180deg)"
                                          : "rotate(0)",
                                        transition: "250ms",
                                        width: "35px",
                                        height: "38px",
                                      }),
                                    }}
                                    id="search-commodity"
                                    components={{
                                      IndicatorSeparator: () => null,
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="col-12 col-md-4 col-lg-2">
                                <div className="form-group local-forms">
                                  <label>
                                    Visit date start
                                    {/* <span className="login-danger">*</span> */}
                                  </label>
                                  <input
                                    type="date"
                                    value={filter?.visit_date_start}
                                    onChange={(e) =>
                                      handleFilterChange(
                                        "visit_date_start",
                                        e.target.value
                                      )
                                    }
                                    className="form-control"
                                  />
                                </div>
                              </div>
                              <div className="col-12 col-md-4 col-lg-2">
                                <div className="form-group local-forms">
                                  <label>
                                    Visit date end
                                    {/* <span className="login-danger">*</span> */}
                                  </label>
                                  <input
                                    type="date"
                                    value={filter?.visit_date_end}
                                    onChange={(e) =>
                                      handleFilterChange(
                                        "visit_date_end",
                                        e.target.value
                                      )
                                    }
                                    className="form-control"
                                  />
                                </div>
                              </div>
                              <div className="col-12 col-md-4 col-lg-2">
                                <div className="form-group local-forms">
                                  <label>
                                    Visit type
                                    {/* <span className="login-danger">*</span> */}
                                  </label>
                                  <Select
                                    value={
                                      visitTypeOption.find(
                                        (option) =>
                                          option.value === filter.visit_type
                                      ) || null
                                    }
                                    onChange={(e) =>
                                      handleFilterChange("visit_type", e?.value)
                                    }
                                    name="visit_type"
                                    options={visitTypeOption}
                                    placeholder="Select visit type"
                                    components={{
                                      IndicatorSeparator: () => null, // Hide separator line between the dropdown indicator and the control
                                    }}
                                    styles={{
                                      control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        // zIndex: 99999,
                                        borderColor: state.isFocused
                                          ? "none"
                                          : "2px solid rgba(46, 55, 164, 0.1)",
                                        boxShadow: state.isFocused
                                          ? "0 0 0 1px #2e37a4"
                                          : "none",
                                        "&:hover": {
                                          borderColor: state.isFocused
                                            ? "none"
                                            : "2px solid rgba(46, 55, 164, 0.1)",
                                        },
                                        borderRadius: "10px",
                                        fontSize: "14px",
                                        minHeight: "45px",
                                      }),
                                      dropdownIndicator: (base, state) => ({
                                        ...base,
                                        transform: state.selectProps.menuIsOpen
                                          ? "rotate(-180deg)"
                                          : "rotate(0)",
                                        transition: "250ms",
                                        width: "35px",
                                        height: "35px",
                                        zIndex: 9999,
                                      }),
                                      menu: (base) => ({
                                        ...base,
                                        zIndex: 9999, // Set a high z-index value here
                                      }),
                                    }}
                                    isClearable
                                  />
                                </div>
                              </div>
                              <div className="col-12 col-md-4 col-lg-2">
                                <div className="form-group local-forms">
                                  <label>
                                    Status
                                    {/* <span className="login-danger">*</span> */}
                                  </label>
                                  <Select
                                    value={
                                      statusOptions.find(
                                        (option) =>
                                          option.value === filter.status
                                      ) || null
                                    }
                                    onChange={(e) =>
                                      handleFilterChange("status", e?.value)
                                    }
                                    name="status"
                                    options={statusOptions}
                                    placeholder="Select status"
                                    components={{
                                      IndicatorSeparator: () => null, // Hide separator line between the dropdown indicator and the control
                                    }}
                                    styles={{
                                      control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        // zIndex: 99999,
                                        borderColor: state.isFocused
                                          ? "none"
                                          : "2px solid rgba(46, 55, 164, 0.1)",
                                        boxShadow: state.isFocused
                                          ? "0 0 0 1px #2e37a4"
                                          : "none",
                                        "&:hover": {
                                          borderColor: state.isFocused
                                            ? "none"
                                            : "2px solid rgba(46, 55, 164, 0.1)",
                                        },
                                        borderRadius: "10px",
                                        fontSize: "14px",
                                        minHeight: "45px",
                                      }),
                                      dropdownIndicator: (base, state) => ({
                                        ...base,
                                        transform: state.selectProps.menuIsOpen
                                          ? "rotate(-180deg)"
                                          : "rotate(0)",
                                        transition: "250ms",
                                        width: "35px",
                                        height: "35px",
                                        zIndex: 9999,
                                      }),
                                      menu: (base) => ({
                                        ...base,
                                        zIndex: 9999, // Set a high z-index value here
                                      }),
                                    }}
                                    isClearable
                                  />
                                </div>
                              </div>
                              <div className="col-12 col-md-3 col-lg-2 mb-2">
                                <button
                                  onClick={handleSubmitFilter}
                                  className="btn btn-primary p-2"
                                >
                                  Apply Filter
                                </button>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    {/* Table Header */}
                    <div className="page-table-header mb-2">
                      <div className="row align-items-center">
                        <div className="col">
                          <div className="doctor-table-blk">
                            <h3>Appointment List</h3>
                            <div className="doctor-search-blk">
                              <div className="add-group">
                                <Link
                                  style={{ cursor: "not-allowed" }}
                                  // to="/addappoinments"
                                  className="btn btn-primary add-pluss ms-2"
                                >
                                  <img src={plusicon} alt="#" />
                                </Link>
                                <Link
                                  onClick={refetchAppointmentList}
                                  className="btn btn-primary doctor-refresh ms-2"
                                >
                                  <img src={refreshicon} alt="#" />
                                </Link>
                                <Link
                                  onClick={() => setShowFilter(!showFilter)}
                                  className="btn btn-primary add-pluss ms-2"
                                >
                                  <FilterAltIcon
                                    sx={{
                                      ":hover": {
                                        color: "white",
                                      },
                                    }}
                                  />
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-auto text-end float-end ms-auto download-grp">
                          <img
                            className=" me-2"
                            onClick={() =>
                              handlePrintAppointment(
                                getAllAppointmentListData?.payload?.appointments
                              )
                            }
                            src={pdficon}
                            alt="#"
                            style={{ cursor: "pointer" }}
                          />

                          <img
                            onClick={() =>
                              generateCsv(
                                getAllAppointmentListData?.payload?.appointments
                              )
                            }
                            className=" me-2"
                            src={pdficon3}
                            alt="#"
                            style={{ cursor: "pointer" }}
                          />

                          <img
                            onClick={() =>
                              handleShowJson(
                                getAllAppointmentListData?.payload?.appointments
                              )
                            }
                            width={"25px"}
                            height={"26px"}
                            src={pdficon5}
                            alt="#"
                            style={{ cursor: "pointer" }}
                          />
                        </div>
                      </div>
                    </div>
                    {/* /Table Header */}
                    <div className="table-responsive doctor-list">
                      <Table
                        loading={getAppointmentLoader || isRefetching}
                        pagination={{
                          current: page,
                          pageSize: pageSize,
                          total: getAllAppointmentListData?.meta?.total_count,
                          showTotal: (total, range) =>
                            `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                          onChange: handlePageChange,
                        }}
                        columns={columns}
                        dataSource={
                          getAllAppointmentListData?.payload?.appointments
                        }
                        rowSelection={rowSelection}
                        rowKey={(record) => record.id}
                        scroll={{ x: "max-content" }}
                        onChange={(val, filter, sorter) => {
                          // console.log(val, filter, sorter, extra);
                          handleTableChange(filter, sorter);
                        }}
                        locale={{
                          triggerDesc: "Sort descending",
                          triggerAsc: "Sort ascending",
                          cancelSort: "Cancel sort",
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="notification-box">
            <div className="msg-sidebar notifications msg-noti">
              <div className="topnav-dropdown-header">
                <span>Messages</span>
              </div>
              <div className="drop-scroll msg-list-scroll" id="msg_list">
                <ul className="list-box">
                  <li>
                    <Link to="#">
                      <div className="list-item">
                        <div className="list-left">
                          <span className="avatar">R</span>
                        </div>
                        <div className="list-body">
                          <span className="message-author">Richard Miles </span>
                          <span className="message-time">12:28 AM</span>
                          <div className="clearfix" />
                          <span className="message-content">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                          </span>
                        </div>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <div className="list-item new-message">
                        <div className="list-left">
                          <span className="avatar">J</span>
                        </div>
                        <div className="list-body">
                          <span className="message-author">John Doe</span>
                          <span className="message-time">1 Aug</span>
                          <div className="clearfix" />
                          <span className="message-content">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                          </span>
                        </div>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <div className="list-item">
                        <div className="list-left">
                          <span className="avatar">T</span>
                        </div>
                        <div className="list-body">
                          <span className="message-author">
                            {" "}
                            Tarah Shropshire{" "}
                          </span>
                          <span className="message-time">12:28 AM</span>
                          <div className="clearfix" />
                          <span className="message-content">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                          </span>
                        </div>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <div className="list-item">
                        <div className="list-left">
                          <span className="avatar">M</span>
                        </div>
                        <div className="list-body">
                          <span className="message-author">Mike Litorus</span>
                          <span className="message-time">12:28 AM</span>
                          <div className="clearfix" />
                          <span className="message-content">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                          </span>
                        </div>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <div className="list-item">
                        <div className="list-left">
                          <span className="avatar">C</span>
                        </div>
                        <div className="list-body">
                          <span className="message-author">
                            {" "}
                            Catherine Manseau{" "}
                          </span>
                          <span className="message-time">12:28 AM</span>
                          <div className="clearfix" />
                          <span className="message-content">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                          </span>
                        </div>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <div className="list-item">
                        <div className="list-left">
                          <span className="avatar">D</span>
                        </div>
                        <div className="list-body">
                          <span className="message-author">
                            {" "}
                            Domenic Houston{" "}
                          </span>
                          <span className="message-time">12:28 AM</span>
                          <div className="clearfix" />
                          <span className="message-content">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                          </span>
                        </div>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <div className="list-item">
                        <div className="list-left">
                          <span className="avatar">B</span>
                        </div>
                        <div className="list-body">
                          <span className="message-author">
                            {" "}
                            Buster Wigton{" "}
                          </span>
                          <span className="message-time">12:28 AM</span>
                          <div className="clearfix" />
                          <span className="message-content">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                          </span>
                        </div>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <div className="list-item">
                        <div className="list-left">
                          <span className="avatar">R</span>
                        </div>
                        <div className="list-body">
                          <span className="message-author">
                            {" "}
                            Rolland Webber{" "}
                          </span>
                          <span className="message-time">12:28 AM</span>
                          <div className="clearfix" />
                          <span className="message-content">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                          </span>
                        </div>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <div className="list-item">
                        <div className="list-left">
                          <span className="avatar">C</span>
                        </div>
                        <div className="list-body">
                          <span className="message-author"> Claire Mapes </span>
                          <span className="message-time">12:28 AM</span>
                          <div className="clearfix" />
                          <span className="message-content">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                          </span>
                        </div>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <div className="list-item">
                        <div className="list-left">
                          <span className="avatar">M</span>
                        </div>
                        <div className="list-body">
                          <span className="message-author">Melita Faucher</span>
                          <span className="message-time">12:28 AM</span>
                          <div className="clearfix" />
                          <span className="message-content">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                          </span>
                        </div>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <div className="list-item">
                        <div className="list-left">
                          <span className="avatar">J</span>
                        </div>
                        <div className="list-body">
                          <span className="message-author">Jeffery Lalor</span>
                          <span className="message-time">12:28 AM</span>
                          <div className="clearfix" />
                          <span className="message-content">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                          </span>
                        </div>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <div className="list-item">
                        <div className="list-left">
                          <span className="avatar">L</span>
                        </div>
                        <div className="list-body">
                          <span className="message-author">Loren Gatlin</span>
                          <span className="message-time">12:28 AM</span>
                          <div className="clearfix" />
                          <span className="message-content">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                          </span>
                        </div>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <div className="list-item">
                        <div className="list-left">
                          <span className="avatar">T</span>
                        </div>
                        <div className="list-body">
                          <span className="message-author">
                            Tarah Shropshire
                          </span>
                          <span className="message-time">12:28 AM</span>
                          <div className="clearfix" />
                          <span className="message-content">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                          </span>
                        </div>
                      </div>
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="topnav-dropdown-footer">
                <Link to="#">See all messages</Link>
              </div>
            </div>
          </div>
        </div>
        <div
          id="delete_patient"
          className="modal fade delete-modal"
          role="dialog"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body text-center">
                <img src={imagesend} alt="#" width={50} height={46} />
                <h3>Are you sure want to delete this ?</h3>
                <div className="m-t-20">
                  {" "}
                  <Link
                    to="#"
                    className="btn btn-white me-2"
                    data-bs-dismiss="modal"
                  >
                    Close
                  </Link>
                  <button
                    onClick={handleDeleteAppointment}
                    type="submit"
                    data-bs-dismiss="modal"
                    className="btn btn-danger"
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div
            id="delete_patient"
            className="modal fade delete-modal"
            role="dialog"
          >
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-body text-center">
                  <img src={imagesend} alt="#" width={50} height={46} />
                  <h3>Are you sure want to delete this ?</h3>
                  <div className="m-t-20">
                    {" "}
                    <Link
                      to="#"
                      className="btn btn-white me-2"
                      data-bs-dismiss="modal"
                    >
                      Close
                    </Link>
                    <button type="submit" className="btn btn-danger">
                      Delete
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>

      <></>
    </>
  );
};

export default AppoinmentList;

import React from "react";
import Header from "../Header";
import Sidebar from "../Sidebar";
import { Link, useParams } from "react-router-dom";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { useQuery } from "@tanstack/react-query";

import { useSelector } from "react-redux";

import { Skeleton } from "@mui/material";
import dayjs from "dayjs";
// import { Table } from "antd";
import { getRoleDetail } from "../../connections/getRoleDetails";

const RolesDetails = () => {
  const {
    user: { user },
  } = useSelector((store) => store);
  const { id } = useParams();
  const { data: getRolesData, isLoading } = useQuery({
    queryKey: ["getRoleDetail", id],
    queryFn: () => getRoleDetail(user?.token, id),
    enabled: !!id,
    // staleTime: Infinity, // Keep the data fresh indefinitely

    refetchOnWindowFocus: false, // Don't refetch when window regains focus
    refetchOnReconnect: false, // Don't refetch when reconnecting
  });
  // const columns = [
  //   {
  //     title: "Name",
  //     dataIndex: "name",
  //     key: "name",
  //   },
  //   {
  //     title: "Created At",
  //     dataIndex: "created_at",
  //     key: "created_at",
  //     render: (created_at) =>
  //       dayjs(created_at, "YYYY-MM-DD HH:mm:ss A").format(
  //         "MMMM DD, YYYY HH:mm A"
  //       ),
  //   },
  //   {
  //     title: "Updated At",
  //     dataIndex: "updated_at",
  //     key: "updated_at",
  //     render: (updated_at) =>
  //       dayjs(updated_at, "YYYY-MM-DD HH:mm:ss A").format(
  //         "MMMM DD, YYYY HH:mm A"
  //       ),
  //   },
  // ];
  return (
    <>
      <Header />
      <Sidebar activeClassName="roles-list" />
      <>
        <div className="page-wrapper">
          <div className="content">
            {/* Page Header */}
            <div className="page-header">
              <div className="row">
                <div className="col-sm-12">
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item active">
                      <Link to={-1}>Roles Details</Link>
                    </li>
                    <li className="breadcrumb-item">
                      <i className="feather-chevron-right">
                        <FeatherIcon icon="chevron-right" />
                      </i>
                    </li>
                    <li className="breadcrumb-item active">{id}</li>
                  </ul>
                </div>
              </div>
            </div>
            {/* /Page Header */}
            <div className="row">
              <div className="col-sm-12">
                <div className="card card-table show-entire">
                  <div className="card-body">
                    {/* content Header */}
                    <div className="page-table-header mb-2">
                      <div className="row align-items-center">
                        <div className="col">
                          <div className="doctor-table-blk">
                            <h3>Roles Details</h3>
                            <div className="doctor-search-blk"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {isLoading ? (
                      <div>
                        <Skeleton
                          variant="rectangular"
                          width="100%"
                          height={430} // Same height as your chart
                        />
                      </div>
                    ) : (
                      <div className="content-details">
                        <div className="col-lg-12">
                          <div className="card-box">
                            <div className="card-block">
                              <ul className="personal-info">
                                <li className="pb-1">
                                  <span className="title">Name:</span>
                                  <span className="text">
                                    {getRolesData?.name || "N/A"}
                                  </span>
                                </li>
                                <li className="pb-1">
                                  <span className="title">Created At:</span>
                                  <span className="text">
                                    {getRolesData?.created_at
                                      ? dayjs(
                                          getRolesData?.created_at,
                                          "YYYY-MM-DD HH:mm:ss A"
                                        ).format("MMMM DD, YYYY HH:mm A")
                                      : "N/A"}
                                  </span>
                                </li>
                                <li className="pb-1">
                                  <span className="title">Updated At:</span>
                                  <span className="text">
                                    {getRolesData?.updated_at
                                      ? dayjs(
                                          getRolesData?.updated_at,
                                          "YYYY-MM-DD HH:mm:ss A"
                                        ).format("MMMM DD, YYYY HH:mm A")
                                      : "N/A"}
                                  </span>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      // <div className="table-responsive">
                      //   <Table
                      //     columns={columns}
                      //     dataSource={[getRolesData]}
                      //     pagination={false}
                      //     scroll={{ x: "max-content" }}
                      //   />
                      // </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </>
  );
};

export default RolesDetails;

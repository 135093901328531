import React, { useEffect, useState } from "react";
import { Dropdown, Menu, Table } from "antd";
import Header from "../../Header";
import Sidebar from "../../Sidebar";
import {
  imagesend,
  pdficon,
  pdficon3,
  plusicon,
  refreshicon,
  pdficon5,
  searchnormal,
} from "../../imagepath";
// import { onShowSizeChange, itemRender } from "../Pagination";
import { Link, useNavigate } from "react-router-dom";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { useSelector } from "react-redux";
import { useQuery } from "@tanstack/react-query";

import dayjs from "dayjs";

import { generateCsv, handleShowJson } from "../../../utils/constant/constant";
import { toast } from "react-toastify";
import { getMedicalTestShortCode } from "../../../connections/getMedicalTestShortCode";
import { deleteShortCode } from "../../../connections/deleteShortCode";
import jsPDF from "jspdf";
import FilterAltIcon from "@mui/icons-material/FilterAlt";

const MedicalShortCode = () => {
  const [deleteId, setDeleteId] = useState("");
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const [sortColumns, setSortColumns] = useState("");
  const [sortOrder, setSortOrder] = useState("");
  const [filter, setFilter] = useState({});
  const [showFilter, setShowFilter] = useState(false);
  const {
    user: { user },
  } = useSelector((store) => store);
  const navigate = useNavigate();
  const {
    data: getMedicalTestCodeData,
    isLoading: medicalTestCodeLoader,
    refetch: medicalTestCodeRefetch,
    isRefetching,
  } = useQuery({
    queryKey: [
      "getMedicalTestShortCode",
      page,
      pageSize,
      sortColumns,
      sortOrder,
    ],
    queryFn: () =>
      getMedicalTestShortCode(
        user?.token,
        null,
        page,
        pageSize,
        sortColumns,
        sortOrder,
        filter
      ),
    refetchOnWindowFocus: false, // Don't refetch when window regains focus
    refetchOnReconnect: false, // Don't refetch when reconnecting
  });

  const onSelectChange = (newSelectedRowKeys) => {
    console.log("selectedRowKeys changed: ", selectedRowKeys);

    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const handleTableChange = async (filters, sorter) => {
    // Extract sorting details
    const sortColumn = sorter.field;
    const sortOrder =
      sorter.order === "ascend"
        ? "asc"
        : sorter.order === "descend"
        ? "desc"
        : null;
    // console.log("sorter", sorter);
    setSortColumns(sortColumn);
    setSortOrder(sortOrder);
  };
  const handleFilterChange = (key, value) => {
    setFilter((prev) => {
      const updatedFilter = { ...prev };
      if (value === null || value === "" || !value) {
        delete updatedFilter[key];
      } else {
        updatedFilter[key] = value;
      }

      return updatedFilter;
    });
  };
  const handleSubmitFilter = () => {
    medicalTestCodeRefetch(
      user?.token,
      null,
      page,
      pageSize,
      sortColumns,
      sortOrder,
      filter
    );
  };
  const columns = [
    {
      title: "Id",
      dataIndex: "id",
      sorter: true,
    },
    {
      title: "Title",
      dataIndex: "name",
    },
    {
      title: "Medical Tests",
      dataIndex: "medical_tests",
      render: (medical_tests) => (
        <span
          style={{
            whiteSpace: "normal",
            wordBreak: "break-word",
            maxWidth: "350px",
            display: "block",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {medical_tests?.map((el, index) => (
            <span key={el.id}>
              <Link
                style={{ textDecoration: "underline" }}
                to={`/admin/medical_tests/${el.id}`}
              >
                {el.name}
              </Link>
              {index < medical_tests.length - 1 && " , "}
            </span>
          ))}
        </span>
      ),
    },
    {
      title: "Created At",
      dataIndex: "created_at",
      sorter: true,
      render: (date) => (
        <>
          {dayjs(date, "YYYY-MM-DD HH:mm:ss A").format("MMMM DD, YYYY HH:mm")}
        </>
      ),
    },
    {
      title: "Updated At",
      dataIndex: "updated_at",
      sorter: true,
      render: (date) => (
        <>
          {dayjs(date, "YYYY-MM-DD HH:mm:ss A").format("MMMM DD, YYYY HH:mm")}
        </>
      ),
    },
    // {
    //   title: "",
    //   dataIndex: "FIELD8",
    //   render: (_, record) => (
    //     <>
    //       <div className="text-end">
    //         <div className="dropdown dropdown-action">
    //           <Link
    //             to="#"
    //             className="action-icon dropdown-toggle"
    //             data-bs-toggle="dropdown"
    //             aria-expanded="false"
    //           >
    //             <i className="fas fa-ellipsis-v" />
    //           </Link>
    //           <div className="dropdown-menu dropdown-menu-end">
    //             <Link
    //               className="dropdown-item"
    //               to={`/admin/prescription/medical_test_shortcodes/${record?.id}`}
    //             >
    //               <i className="fa-regular fa-eye m-r-5" />
    //               View
    //             </Link>
    //             <button
    //               className="dropdown-item"
    //               onClick={() =>
    //                 navigate("/admin/update-medical_test_shortcodes", {
    //                   state: record,
    //                 })
    //               }
    //               // to={{
    //               //   pathname: "/admin/update-suppliers",
    //               //   state: { supplier: record },
    //               //   data: "ddddddddddddd",
    //               // }}
    //             >
    //               <i className="far fa-edit me-2" />
    //               Edit
    //             </button>
    //             <Link
    //               className="dropdown-item"
    //               to="#"
    //               data-bs-toggle="modal"
    //               data-bs-target="#delete_patient"
    //               onClick={() => {
    //                 setDeleteId(record?.id);
    //               }}
    //             >
    //               <i className="fa fa-trash-alt m-r-5"></i> Delete
    //             </Link>
    //           </div>
    //         </div>
    //       </div>
    //     </>
    //   ),
    // },
    {
      title: "",
      dataIndex: "FIELD8",
      render: (_, record) => (
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item key="view">
                <Link
                  to={`/admin/prescription/medical_test_shortcodes/${record?.id}`}
                >
                  <i className="fa-regular fa-eye m-r-5" /> View
                </Link>
              </Menu.Item>
              <Menu.Item
                key="edit"
                onClick={() =>
                  navigate("/admin/update-medical_test_shortcodes", {
                    state: record,
                  })
                }
              >
                <i className="far fa-edit me-2" /> Edit
              </Menu.Item>
              <Menu.Item
                className="dropdown-item"
                to="#"
                data-bs-toggle="modal"
                data-bs-target="#delete_patient"
                key="delete"
                onClick={() => {
                  setDeleteId(record?.id);
                }}
              >
                <i className="fa fa-trash-alt m-r-5" /> Delete
              </Menu.Item>
            </Menu>
          }
          trigger={["click"]}
          placement="bottomRight"
        >
          <Link
            to="#"
            style={{ marginRight: 5 }}
            className="action-icon"
            // data-bs-toggle="dropdown"
            // aria-expanded="false"
          >
            <i className="fas fa-ellipsis-v" />
          </Link>
        </Dropdown>
      ),
    },
  ];
  const deleteShortCodeFun = async () => {
    try {
      const data = await deleteShortCode(user?.token, deleteId);
      if (data.status.status === "SUCCESS") {
        toast.success("Medical Test  ShortCode Deleted Successfully", deleteId);
        medicalTestCodeRefetch();
        setDeleteId(null);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };
  const handlePageChange = (newPage, newPageSize) => {
    setPage(newPage);
    setPageSize(newPageSize);
    // medicalTestCodeRefetch();
  };
  const downloadPdf = (patients) => {
    // Return if no data
    if (!patients || patients.length === 0) return;

    // Initialize PDF document
    const doc = new jsPDF({
      unit: "mm",
      format: "a4",
    });

    // Define table headers with custom widths
    const headers = [
      { header: "Id", width: 15 },
      { header: "Title", width: 30 },
      { header: "Medical Tests", width: 80 },
      { header: "Created At", width: 30 },
      { header: "Updated At", width: 30 },
    ];

    // Map data with formatting
    const data = patients?.map((item) => [
      item.id,
      item.name,
      item.medical_tests?.map((el) => el?.name).join(","),
      item.created_at,
      item.updated_at,
    ]);

    // Add title
    doc.setFontSize(16);
    doc.setFont(undefined, "bold");
    doc.text(
      "Medical test Short Code List",
      doc.internal.pageSize.getWidth() / 2,
      15,
      {
        align: "center",
      }
    );

    // Add timestamp
    doc.setFontSize(10);
    doc.setFont(undefined, "normal");
    doc.text(`Generated on: ${dayjs().format("YYYY-MM-DD HH:mm:ss")}`, 14, 22);

    // Configure table
    doc.autoTable({
      startY: 30,
      head: [headers.map((h) => h.header)],
      body: data,
      theme: "grid",
      headStyles: {
        fillColor: [240, 240, 240],
        textColor: [0, 0, 0],
        fontStyle: "bold",
        halign: "center",
        lineWidth: 0.5,
        lineColor: [200, 200, 200],
        fontSize: 8,
      },
      bodyStyles: {
        fontSize: 6,
        lineColor: [200, 200, 200],
        lineWidth: 0.5,
      },
      alternateRowStyles: {
        fillColor: [248, 248, 248],
      },
      columnStyles: headers.reduce((acc, h, i) => {
        acc[i] = { cellWidth: h.width };
        return acc;
      }, {}),
      margin: { top: 30, right: 14, bottom: 20, left: 14 },
      didDrawPage: (data) => {
        // Add page number at the bottom
        doc.setFontSize(10);
        doc.text(
          `Page ${data.pageNumber} of ${data.pageCount}`,
          doc.internal.pageSize.getWidth() / 2,
          doc.internal.pageSize.getHeight() - 10,
          { align: "center" }
        );
      },
    });

    // Save with formatted filename including timestamp
    const filename = `shortcode${dayjs().format("YYYYMMDD_HHmmss")}.pdf`;
    doc.save(filename);
  };
  useEffect(() => {
    if (!showFilter) {
      setFilter({});
    }
  }, [showFilter]);
  return (
    <>
      <Header />
      <Sidebar activeClassName="medical_test_shortcodes-dashboard" />
      <>
        <div className="page-wrapper">
          <div className="content">
            {/* Page Header */}
            <div className="page-header">
              <div className="row">
                <div className="col-sm-12">
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="#">Medical Shortcode Test</Link>
                    </li>
                    <li className="breadcrumb-item">
                      <i className="feather-chevron-right">
                        <FeatherIcon icon="chevron-right" />
                      </i>
                    </li>
                    <li className="breadcrumb-item active">
                      Medical Shortcode Test List
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            {/* /Page Header */}
            <div className="row">
              <div className="col-sm-12">
                <div className="card card-table show-entire">
                  <div className="card-body">
                    {/* {filter } */}
                    <div className="page-table-header mb-2">
                      <div className="row align-items-center">
                        {showFilter && (
                          <div className="col filter-container">
                            <div className="doctor-table-blk">
                              <h3 style={{ marginBottom: 2 }}>Filter</h3>
                            </div>
                            <div className="row mt-2">
                              <div className="col-12 col-md-4 col-lg-2">
                                <div className="form-group local-forms">
                                  <label>
                                    <img
                                      width="15px"
                                      height="15px"
                                      style={{ marginRight: 2 }}
                                      src={searchnormal}
                                      alt="#"
                                    />
                                    Search
                                  </label>
                                  <input
                                    value={filter.q}
                                    onChange={(e) =>
                                      handleFilterChange("q", e.target.value)
                                    }
                                    className="form-control"
                                    type="text"
                                    placeholder="Search here"
                                  />
                                </div>
                              </div>
                              <div className="col-12 col-md-4 col-lg-2">
                                <div className="form-group local-forms">
                                  <label>Medical tests id</label>
                                  <input
                                    value={filter.medical_tests_id}
                                    onChange={(e) =>
                                      handleFilterChange(
                                        "medical_tests_id",
                                        e.target.value
                                      )
                                    }
                                    className="form-control"
                                    type="text"
                                    placeholder="medical tests id"
                                  />
                                </div>
                              </div>
                              <div className="col-12 col-md-4 col-lg-2">
                                <div className="form-group local-forms">
                                  <label>
                                    Created at start
                                    {/* <span className="login-danger">*</span> */}
                                  </label>
                                  <input
                                    type="date"
                                    value={filter?.created_at_start}
                                    onChange={(e) =>
                                      handleFilterChange(
                                        "created_at_start",
                                        e.target.value
                                      )
                                    }
                                    className="form-control"
                                  />
                                </div>
                              </div>
                              <div className="col-12 col-md-4 col-lg-2">
                                <div className="form-group local-forms">
                                  <label>
                                    Created at end
                                    {/* <span className="login-danger">*</span> */}
                                  </label>
                                  <input
                                    type="date"
                                    value={filter?.created_at_end}
                                    onChange={(e) =>
                                      handleFilterChange(
                                        "created_at_end",
                                        e.target.value
                                      )
                                    }
                                    className="form-control"
                                  />
                                </div>
                              </div>
                              <div className="col-12 col-md-4 col-lg-2">
                                <div className="form-group local-forms">
                                  <label>
                                    Updated at start
                                    {/* <span className="login-danger">*</span> */}
                                  </label>
                                  <input
                                    type="date"
                                    value={filter?.updated_at_start}
                                    onChange={(e) =>
                                      handleFilterChange(
                                        "updated_at_start",
                                        e.target.value
                                      )
                                    }
                                    className="form-control"
                                  />
                                </div>
                              </div>
                              <div className="col-12 col-md-4 col-lg-2">
                                <div className="form-group local-forms">
                                  <label>
                                    Updated at end
                                    {/* <span className="login-danger">*</span> */}
                                  </label>
                                  <input
                                    type="date"
                                    value={filter?.updated_at_end}
                                    onChange={(e) =>
                                      handleFilterChange(
                                        "updated_at_end",
                                        e.target.value
                                      )
                                    }
                                    className="form-control"
                                  />
                                </div>
                              </div>
                              <div className="col-12 col-md-3 col-lg-2 mb-2">
                                <button
                                  onClick={handleSubmitFilter}
                                  className="btn btn-primary p-2"
                                >
                                  Apply Filter
                                </button>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    {/* Table Header */}
                    <div className="page-table-header mb-2">
                      <div className="row align-items-center">
                        <div className="col">
                          <div className="doctor-table-blk">
                            <h3>Medical Shortcode Test List</h3>
                            <div className="doctor-search-blk">
                              <div className="add-group">
                                <Link
                                  to="/admin/add-medical_test_shortcodes"
                                  className="btn btn-primary add-pluss ms-2"
                                >
                                  <img src={plusicon} alt="#" />
                                </Link>
                                <Link
                                  onClick={medicalTestCodeRefetch}
                                  className="btn btn-primary add-pluss ms-2"
                                >
                                  <img
                                    // onClick={medicalTestCodeRefetch}
                                    src={refreshicon}
                                    alt="#"
                                  />
                                </Link>
                                <Link
                                  onClick={() => setShowFilter(!showFilter)}
                                  className="btn btn-primary add-pluss ms-2"
                                >
                                  <FilterAltIcon
                                    sx={{
                                      ":hover": {
                                        color: "white",
                                      },
                                    }}
                                  />
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-auto text-end float-end ms-auto download-grp">
                          {/* <Link id="download-pdf" to="#" className=" me-2"> */}
                          <img
                            className=" me-2"
                            onClick={() =>
                              downloadPdf(
                                getMedicalTestCodeData?.payload
                                  ?.medical_test_shortcodes
                              )
                            }
                            src={pdficon}
                            alt="#"
                            style={{ cursor: "pointer" }}
                          />
                          {/* </Link> */}
                          {/* <Link
                           
                            id="download-csv"
                            className=" me-2"
                          > */}
                          <img
                            onClick={() =>
                              generateCsv(
                                getMedicalTestCodeData?.payload
                                  ?.medical_test_shortcodes
                              )
                            }
                            className=" me-2"
                            src={pdficon3}
                            alt="#"
                            style={{ cursor: "pointer" }}
                          />
                          {/* </Link> */}
                          {/* <Link id="show-json" to="#"> */}
                          <img
                            onClick={() =>
                              handleShowJson(
                                getMedicalTestCodeData?.payload
                                  ?.medical_test_shortcodes
                              )
                            }
                            width={"25px"}
                            height={"26px"}
                            src={pdficon5}
                            alt="#"
                            style={{ cursor: "pointer" }}
                          />
                          {/* </Link> */}
                        </div>
                      </div>
                    </div>
                    {/* /Table Header */}
                    <div className="table-responsive doctor-list">
                      <Table
                        loading={medicalTestCodeLoader || isRefetching}
                        pagination={{
                          current: page,
                          pageSize: pageSize,
                          total: getMedicalTestCodeData?.meta?.total_count,
                          showTotal: (total, range) =>
                            `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                          onChange: handlePageChange,
                        }}
                        columns={columns}
                        dataSource={
                          getMedicalTestCodeData?.payload
                            ?.medical_test_shortcodes
                        }
                        rowSelection={rowSelection}
                        rowKey={(record) => record.id}
                        scroll={{ x: "max-content" }}
                        onChange={(val, filter, sorter) => {
                          // console.log(val, filter, sorter, extra);
                          handleTableChange(filter, sorter);
                        }}
                        locale={{
                          triggerDesc: "Sort descending",
                          triggerAsc: "Sort ascending",
                          cancelSort: "Cancel sort",
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          id="delete_patient"
          className="modal fade delete-modal"
          role="dialog"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body text-center">
                <img src={imagesend} alt="#" width={50} height={46} />
                <h3>Are you sure want to delete this ?</h3>
                <div className="m-t-20">
                  {" "}
                  <Link
                    to="#"
                    className="btn btn-white me-2"
                    data-bs-dismiss="modal"
                  >
                    Close
                  </Link>
                  <button
                    onClick={deleteShortCodeFun}
                    type="submit"
                    className="btn btn-danger"
                    data-bs-dismiss="modal"
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div
            id="delete_patient"
            className="modal fade delete-modal"
            role="dialog"
          >
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-body text-center">
                  <img src={imagesend} alt="#" width={50} height={46} />
                  <h3>Are you sure want to delete this ?</h3>
                  <div className="m-t-20">
                    {" "}
                    <Link
                      to="#"
                      className="btn btn-white me-2"
                      data-bs-dismiss="modal"
                    >
                      Close
                    </Link>
                    <button
                      data-bs-dismiss="modal"
                      type="submit"
                      className="btn btn-danger"
                    >
                      Delete
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </>
  );
};

export default MedicalShortCode;

import { toast } from "react-toastify";
import { BASE_URL } from "./connectionUrl";

export const getTopTenMedicineByImpact = async (token, startDate, endDate) => {
  try {
    const response = await fetch(
      // eslint-disable-next-line no-undef
      `${BASE_URL}/api/v1/analytics/dashboard/top_medicines_by_impact?start_date=${startDate}&end_date=${endDate}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      }
    );

    const data = await response.json();
    // if (data.status.status === "SUCCESS") {
    return data;
  } catch (error) {
    toast.error(error.message || "something went wrong");
  }
};

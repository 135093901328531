import { toast } from "react-toastify";
import { BASE_URL } from "./connectionUrl";

export const getTreatment = async (
  token,
  type = null,
  page = null,
  per_page = null
) => {
  const endpoint =
    type === "all"
      ? `/api/v1/admin/treatments?type=${type}`
      : `/api/v1/admin/treatments?page=${page}&per_page=${per_page}`;
  try {
    const response = await fetch(
      // eslint-disable-next-line no-undef
      `${BASE_URL}${endpoint}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      }
    );

    const data = await response.json();
    // if (data.status.status === "SUCCESS") {
    return data;
  } catch (error) {
    toast.error(error.message || "something went wrong");
  }
};

import React, { useState } from "react";
import Header from "../Header";
import Sidebar from "../Sidebar";

import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import Select from "react-select";
import { postRevenueShare } from "../../connections/postRevenueShare";
import { useQuery } from "@tanstack/react-query";
import { getDoctor } from "../../connections/getDoctor";
import { getAllDepartments } from "../../connections/getAllDepartments";

const AddRevenueShare = () => {
  const [formState, setFormState] = useState({});
  const {
    user: { user },
  } = useSelector((store) => store);
  const navigate = useNavigate();
  const handleFormChange = (name, val) => {
    setFormState((prev) => ({
      ...prev,
      [name]: val,
    }));
  };

  const { data: getDoctorData, isLoading } = useQuery({
    queryKey: ["getDoctor", "all"],
    queryFn: () => getDoctor(user?.token, "all", null, null),
    refetchOnWindowFocus: false, // Don't refetch when window regains focus
    refetchOnReconnect: false, // Don't refetch when reconnecting
  });
  let allDoctor = getDoctorData?.payload?.specialists || [];

  const doctorOption = allDoctor?.map((el) => ({
    label:
      (el?.first_name ? el.first_name : "") +
      (el.last_name ? el.last_name : ""),
    value: el?.id,
  }));

  const { data: getDepartmentData, isLoading: deptLoader } = useQuery({
    queryKey: ["getAllDepartments", "all"],
    queryFn: () => getAllDepartments(user?.token, "all", null, null),
    refetchOnWindowFocus: false, // Don't refetch when window regains focus
    refetchOnReconnect: false, // Don't refetch when reconnecting
  });
  const allDepartments = getDepartmentData?.payload?.departments || [];
  const departmentOption = allDepartments?.map((el) => ({
    value: el.id,
    label: el?.name,
  }));
  const handleSubmitForm = async (e) => {
    e.preventDefault();
    if (!formState?.specialist_id) {
      toast.error("Please select a specialist");
      return;
    }
    if (!formState?.department_id) {
      toast.error("Please select a department");
      return;
    }
    if (!formState?.value) {
      toast.error("Value is required");
      return;
    }
    const payload = {
      revenue_share: formState,
    };
    try {
      const data = await postRevenueShare(user?.token, payload);
      if (data.status.status === "SUCCESS") {
        toast.success("Revenue Share Added Successfully");
        navigate("/admin/revenue_shares");
      }
    } catch (error) {
      //   toast.error(error.message);
      console.log(error.message);
    }
  };
  return (
    <div>
      <Header />
      <Sidebar activeClassName="revenue-shares-list" />
      <>
        <div className="page-wrapper">
          <div className="content">
            {/* Page Header */}
            <div className="page-header">
              <div className="row">
                <div className="col-sm-12">
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to={-1}>Revenue Share </Link>
                    </li>
                    <li className="breadcrumb-item">
                      <i className="feather-chevron-right">
                        <FeatherIcon icon="chevron-right" />
                      </i>
                    </li>
                    <li className="breadcrumb-item active">
                      Add Revenue Share{" "}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            {/* /Page Header */}
            <div className="row">
              <div className="col-sm-12">
                <div className="card">
                  <div className="card-body">
                    <form>
                      <div className="row">
                        <div className="col-12">
                          <div className="form-heading">
                            <h4>Add Revenue Share </h4>
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-4">
                          <div className="form-group local-forms">
                            <label>
                              Specialist
                              <span className="login-danger">*</span>
                            </label>
                            <Select
                              isLoading={isLoading}
                              value={
                                (doctorOption?.length > 0 &&
                                  doctorOption.find(
                                    (option) =>
                                      option.value === formState?.specialist_id
                                  )) ||
                                null
                              }
                              onChange={(e) =>
                                handleFormChange("specialist_id", e?.value)
                              }
                              options={doctorOption}
                              menuPortalTarget={document.body}
                              styles={{
                                menu: (base) => ({
                                  ...base,
                                  zIndex: 9999,
                                }),
                                menuPortal: (base) => ({
                                  ...base,
                                  zIndex: 9999,
                                }),
                              }}
                              id="search-commodity"
                              components={{
                                IndicatorSeparator: () => null,
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-4">
                          <div className="form-group local-forms">
                            <label>
                              Department
                              <span className="login-danger">*</span>
                            </label>
                            <Select
                              isLoading={deptLoader}
                              value={
                                (departmentOption?.length > 0 &&
                                  departmentOption.find(
                                    (option) =>
                                      option.value === formState?.department_id
                                  )) ||
                                null
                              }
                              onChange={(e) =>
                                handleFormChange("department_id", e?.value)
                              }
                              options={departmentOption}
                              menuPortalTarget={document.body}
                              styles={{
                                menu: (base) => ({
                                  ...base,
                                  zIndex: 9999,
                                }),
                                menuPortal: (base) => ({
                                  ...base,
                                  zIndex: 9999,
                                }),
                              }}
                              id="search-commodity"
                              components={{
                                IndicatorSeparator: () => null,
                              }}
                            />
                          </div>
                        </div>

                        <div className="col-12 col-md-6 col-xl-4">
                          <div className="form-group local-forms">
                            <label>
                              Value
                              <span className="login-danger">*</span>
                            </label>
                            <input
                              value={formState?.value}
                              name="value"
                              onChange={(e) =>
                                handleFormChange("value", e.target.value)
                              }
                              className="form-control"
                              type="number"
                            />
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="doctor-submit text-end">
                            <button
                              onClick={handleSubmitForm}
                              type="submit"
                              className="btn btn-primary submit-form me-2"
                            >
                              Submit
                            </button>
                            <button
                              onClick={(e) => {
                                e.preventDefault();
                                navigate("/admin/revenue_shares");
                              }}
                              type="submit"
                              className="btn btn-primary cancel-form"
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </div>
  );
};
export default AddRevenueShare;

import React, { useState } from "react";
import { Table } from "antd";
import Header from "../Header";
import Sidebar from "../Sidebar";

import {
  imagesend,
  pdficon,
  pdficon3,
  refreshicon,
  pdficon5,
} from "../imagepath";
import { Link, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
// import dayjs from "dayjs";

import { useQuery } from "@tanstack/react-query";
import {
  generateCsv,
  getCurrentMonthStartEndIST,
  handleDownloadPdfCollection,
  handleShowJson,
} from "../../utils/constant/constant";
// import { deletePatient } from "../../connections/deletePatient";
// import { toast } from "react-toastify";
import { getCollectionsInvoiceList } from "../../connections/getCollectionsInvoiceList";
import { TextField } from "@mui/material";
import Select from "react-select";

const PatientsList = () => {
  const { startDateOfMonth, endDateOfMonth } = getCurrentMonthStartEndIST();

  const [startDate, setStartDate] = useState(startDateOfMonth);
  const [endDate, setEndDate] = useState(endDateOfMonth);
  const typeOption = [
    {
      value: "pharmacy",
      label: "Pharmacy",
    },
    {
      value: "daycare",
      label: "Daycare",
    },
    {
      value: "consultancy",
      label: "Consultancy",
    },
    {
      value: "tests",
      label: "Tests",
    },
  ];
  const staff_id = useParams().id || null;
  const [type, setType] = useState(staff_id ? "" : "pharmacy");
  const [dataFilter] = useState(false);
  // console.log(startDateOfMonth,"7777777",endDateOfMonth)
  // const navigate = useNavigate();

  const {
    user: { user },
  } = useSelector((store) => store);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const {
    data: getCollectionsInvoiceData,
    isLoading: getAllPatientsListLoading,
    refetch: getAllPatientsListRefetch,
  } = useQuery({
    queryKey: ["getCollectionsInvoiceList", dataFilter],
    queryFn: () =>
      getCollectionsInvoiceList(
        user?.token,
        startDate,
        endDate,
        staff_id ? staff_id : null,
        type
      ),
    enabled: !!user?.token,

    refetchOnWindowFocus: false, // Don't refetch when window regains focus
    refetchOnReconnect: false, // Don't refetch when reconnecting
  });

  const handleStartDateChange = (event) => {
    setStartDate(event.target.value);
  };

  const handleEndDateChange = (event) => {
    setEndDate(event.target.value);
  };

  const handleDataFilter = () => {
    console.log(type, startDate, endDate);
    getAllPatientsListRefetch(
      user?.token,
      startDate,
      endDate,
      staff_id ? staff_id : null,
      type
    );
  };

  // const onSelectChange = (newSelectedRowKeys) => {
  //   console.log("selectedRowKeys changed: ", selectedRowKeys);
  //   setSelectedRowKeys(newSelectedRowKeys);
  // };

  // const rowSelection = {
  //   selectedRowKeys,
  //   onChange: onSelectChange,
  // };

  const columns = [
    {
      title: "Id",
      dataIndex: "id",
    },
    {
      title: "Patient Uid",
      dataIndex: "patient_uid",
    },
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "Status",
      dataIndex: "status",
    }, //
    {
      title: "Department",
      dataIndex: "department_name",
    },
    // {
    //   title: "Mobile",
    //   dataIndex: "mobile",
    // },

    {
      title: "Type",
      dataIndex: "invoice_type",
    },
    {
      title: "Total",
      dataIndex: "total",
    },

    {
      title: "Grand Total",
      dataIndex: "grand_total",
    },
  ];

  const handlePageChange = (newPage, newPageSize) => {
    setPage(newPage);
    setPageSize(newPageSize);
    // refetchSuppliersList();
  };

  return (
    <>
      <Header />
      <Sidebar
        id="menu-item2"
        id1="menu-items2"
        activeClassName="collections-list"
      />
      <div className="page-wrapper">
        <div className="content">
          {/* Page Header */}
          <div className="page-header">
            <div className="row">
              <div className="col-sm-12">
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to={-1}>Collections </Link>
                  </li>
                  <li className="breadcrumb-item">
                    <i className="feather-chevron-right" />
                  </li>
                  <li className="breadcrumb-item active">Collections List</li>
                </ul>
              </div>
            </div>
          </div>
          {/* /Page Header */}
          <div className="row">
            <div className="col-sm-12">
              <div className="card card-table show-entire">
                <div className="card-body">
                  {/* Table Header */}
                  <div
                    style={{ padding: "0px 12px", paddingTop: "12px" }}
                    className="col"
                  >
                    <div className="doctor-table-blk">
                      <h3>Filter</h3>
                    </div>
                    <div className="row">
                      <div className="col-12 col-md-3 col-lg-2 mb-3">
                        <Select
                          value={
                            typeOption.find(
                              (option) => option.value === type
                            ) || null
                          }
                          onChange={(e) => setType(e.value)}
                          placeholder="Type"
                          options={typeOption}
                          menuPortalTarget={document.body}
                          styles={{
                            menuPortal: (base) => ({
                              ...base,
                              zIndex: 9999,
                            }),
                            control: (baseStyles, state) => ({
                              ...baseStyles,
                              borderColor: state.isFocused
                                ? "#2e37a4"
                                : "rgba(46, 55, 164, 0.1)",
                              boxShadow: state.isFocused
                                ? "0 0 0 1px #2e37a4"
                                : "none",
                              "&:hover": {
                                borderColor: "rgba(46, 55, 164, 0.1)",
                              },
                              borderRadius: "10px",
                              fontSize: "14px",
                              minHeight: "45px",
                              minWidth: "150px",
                            }),
                            dropdownIndicator: (base, state) => ({
                              ...base,
                              transform: state.selectProps.menuIsOpen
                                ? "rotate(-180deg)"
                                : "rotate(0)",
                              transition: "250ms",
                              width: "35px",
                              height: "35px",
                            }),
                          }}
                          id="search-commodity"
                          components={{
                            IndicatorSeparator: () => null,
                          }}
                        />
                      </div>
                      <div className="col-12 col-md-3 col-lg-2 mb-3">
                        <TextField
                          label="Start Date"
                          type="date"
                          value={startDate}
                          onChange={handleStartDateChange}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          variant="outlined"
                          fullWidth
                          size="small"
                        />
                      </div>

                      <div className="col-12 col-md-3 col-lg-2 mb-3">
                        <TextField
                          label="End Date"
                          type="date"
                          value={endDate}
                          onChange={handleEndDateChange}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          variant="outlined"
                          fullWidth
                          size="small"
                        />
                      </div>
                      <div className="col-12 col-md-3 col-lg-2 mb-3 d-flex justify-content-center">
                        <button
                          onClick={handleDataFilter}
                          className="btn btn-primary w-100"
                        >
                          Apply
                        </button>
                      </div>
                    </div>

                    {/* </div>
                    </div> */}
                  </div>
                  <div className="page-table-header mb-2">
                    <div className="row align-items-center">
                      <div className="col">
                        <div className="doctor-table-blk">
                          <h3>Collections List</h3>
                          <div className="doctor-search-blk">
                            <div className="add-group">
                              {/* <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: 2,
                                  padding: "0.6rem",
                                  justifyContent: "center",
                                  // border:"3px solid red"
                                }}
                                // className="form-group mb-0"
                              >
                                <Select
                                  value={
                                    typeOption.find(
                                      (option) => option.value === type
                                    ) || null
                                  }
                                  onChange={(e) => setType(e.value)}
                                  placeholder="type"
                                  options={typeOption}
                                  menuPortalTarget={document.body}
                                  styles={{
                                    menuPortal: (base) => ({
                                      ...base,
                                      zIndex: 9999,
                                    }),
                                    control: (baseStyles, state) => ({
                                      ...baseStyles,
                                      borderColor: state.isFocused
                                        ? "#2e37a4"
                                        : "rgba(46, 55, 164, 0.1)",
                                      boxShadow: state.isFocused
                                        ? "0 0 0 1px #2e37a4"
                                        : "none",
                                      "&:hover": {
                                        borderColor: "rgba(46, 55, 164, 0.1)",
                                      },
                                      borderRadius: "10px",
                                      fontSize: "14px",
                                      minHeight: "45px",
                                      minWidth: "150px",
                                    }),
                                    dropdownIndicator: (base, state) => ({
                                      ...base,
                                      transform: state.selectProps.menuIsOpen
                                        ? "rotate(-180deg)"
                                        : "rotate(0)",
                                      transition: "250ms",
                                      width: "35px",
                                      height: "35px",
                                    }),
                                  }}
                                  id="search-commodity"
                                  components={{
                                    IndicatorSeparator: () => null,
                                  }}
                                />

                                <TextField
                                  label="Start Date"
                                  type="date"
                                  value={startDate}
                                  onChange={handleStartDateChange}
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  variant="outlined"
                                  fullWidth
                                  size="small"
                                />

                                <TextField
                                  label="End Date"
                                  type="date"
                                  value={endDate}
                                  onChange={handleEndDateChange}
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  variant="outlined"
                                  fullWidth
                                  size="small"
                                />
                              </div>
                              <button
                                onClick={handleDataFilter}
                                style={{
                                  backgroundColor: "blue",
                                  color: "white",
                                }}
                                className="btn "
                              >
                                Apply
                              </button> */}

                              <Link
                                onClick={getAllPatientsListRefetch}
                                className="btn btn-primary doctor-refresh ms-2"
                              >
                                <img src={refreshicon} alt="#" />
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-auto text-end float-end ms-auto download-grp">
                        {/* <Link id="download-pdf" to="#" className=" me-2"> */}
                        <img
                          className=" me-2"
                          onClick={() =>
                            handleDownloadPdfCollection(
                              getCollectionsInvoiceData?.payload?.invoices
                            )
                          }
                          src={pdficon}
                          alt="#"
                          style={{ cursor: "pointer" }}
                        />
                        {/* </Link> */}
                        {/* <Link
                           
                            id="download-csv"
                            className=" me-2"
                          > */}
                        <img
                          onClick={() =>
                            generateCsv(
                              getCollectionsInvoiceData?.payload?.invoices
                            )
                          }
                          className=" me-2"
                          src={pdficon3}
                          alt="#"
                          style={{ cursor: "pointer" }}
                        />
                        {/* </Link> */}
                        {/* <Link id="show-json" to="#"> */}
                        <img
                          onClick={() =>
                            handleShowJson(
                              getCollectionsInvoiceData?.payload?.invoices
                            )
                          }
                          width={"25px"}
                          height={"26px"}
                          src={pdficon5}
                          alt="#"
                          style={{ cursor: "pointer" }}
                        />
                        {/* </Link> */}
                      </div>
                    </div>
                  </div>
                  {/* /Table Header */}
                  <div className="table-responsive doctor-list">
                    <Table
                      loading={getAllPatientsListLoading}
                      pagination={{
                        current: page,
                        pageSize: pageSize,
                        total: getCollectionsInvoiceData?.meta?.total_count,
                        showTotal: (total, range) =>
                          `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                        onChange: handlePageChange,
                      }}
                      columns={columns}
                      dataSource={getCollectionsInvoiceData?.payload?.invoices}
                      // rowKey={(record) => record.id+dATE}
                      scroll={{ x: "max-content" }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="notification-box">
          <div className="msg-sidebar notifications msg-noti">
            <div className="topnav-dropdown-header">
              <span>Messages</span>
            </div>
            <div className="drop-scroll msg-list-scroll" id="msg_list">
              <ul className="list-box">
                <li>
                  <Link to="#">
                    <div className="list-item">
                      <div className="list-left">
                        <span className="avatar">R</span>
                      </div>
                      <div className="list-body">
                        <span className="message-author">Richard Miles </span>
                        <span className="message-time">12:28 AM</span>
                        <div className="clearfix" />
                        <span className="message-content">
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                        </span>
                      </div>
                    </div>
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <div className="list-item new-message">
                      <div className="list-left">
                        <span className="avatar">J</span>
                      </div>
                      <div className="list-body">
                        <span className="message-author">John Doe</span>
                        <span className="message-time">1 Aug</span>
                        <div className="clearfix" />
                        <span className="message-content">
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                        </span>
                      </div>
                    </div>
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <div className="list-item">
                      <div className="list-left">
                        <span className="avatar">T</span>
                      </div>
                      <div className="list-body">
                        <span className="message-author">
                          {" "}
                          Tarah Shropshire{" "}
                        </span>
                        <span className="message-time">12:28 AM</span>
                        <div className="clearfix" />
                        <span className="message-content">
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                        </span>
                      </div>
                    </div>
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <div className="list-item">
                      <div className="list-left">
                        <span className="avatar">M</span>
                      </div>
                      <div className="list-body">
                        <span className="message-author">Mike Litorus</span>
                        <span className="message-time">12:28 AM</span>
                        <div className="clearfix" />
                        <span className="message-content">
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                        </span>
                      </div>
                    </div>
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <div className="list-item">
                      <div className="list-left">
                        <span className="avatar">C</span>
                      </div>
                      <div className="list-body">
                        <span className="message-author">
                          {" "}
                          Catherine Manseau{" "}
                        </span>
                        <span className="message-time">12:28 AM</span>
                        <div className="clearfix" />
                        <span className="message-content">
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                        </span>
                      </div>
                    </div>
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <div className="list-item">
                      <div className="list-left">
                        <span className="avatar">D</span>
                      </div>
                      <div className="list-body">
                        <span className="message-author">
                          {" "}
                          Domenic Houston{" "}
                        </span>
                        <span className="message-time">12:28 AM</span>
                        <div className="clearfix" />
                        <span className="message-content">
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                        </span>
                      </div>
                    </div>
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <div className="list-item">
                      <div className="list-left">
                        <span className="avatar">B</span>
                      </div>
                      <div className="list-body">
                        <span className="message-author"> Buster Wigton </span>
                        <span className="message-time">12:28 AM</span>
                        <div className="clearfix" />
                        <span className="message-content">
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                        </span>
                      </div>
                    </div>
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <div className="list-item">
                      <div className="list-left">
                        <span className="avatar">R</span>
                      </div>
                      <div className="list-body">
                        <span className="message-author"> Rolland Webber </span>
                        <span className="message-time">12:28 AM</span>
                        <div className="clearfix" />
                        <span className="message-content">
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                        </span>
                      </div>
                    </div>
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <div className="list-item">
                      <div className="list-left">
                        <span className="avatar">C</span>
                      </div>
                      <div className="list-body">
                        <span className="message-author"> Claire Mapes </span>
                        <span className="message-time">12:28 AM</span>
                        <div className="clearfix" />
                        <span className="message-content">
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                        </span>
                      </div>
                    </div>
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <div className="list-item">
                      <div className="list-left">
                        <span className="avatar">M</span>
                      </div>
                      <div className="list-body">
                        <span className="message-author">Melita Faucher</span>
                        <span className="message-time">12:28 AM</span>
                        <div className="clearfix" />
                        <span className="message-content">
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                        </span>
                      </div>
                    </div>
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <div className="list-item">
                      <div className="list-left">
                        <span className="avatar">J</span>
                      </div>
                      <div className="list-body">
                        <span className="message-author">Jeffery Lalor</span>
                        <span className="message-time">12:28 AM</span>
                        <div className="clearfix" />
                        <span className="message-content">
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                        </span>
                      </div>
                    </div>
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <div className="list-item">
                      <div className="list-left">
                        <span className="avatar">L</span>
                      </div>
                      <div className="list-body">
                        <span className="message-author">Loren Gatlin</span>
                        <span className="message-time">12:28 AM</span>
                        <div className="clearfix" />
                        <span className="message-content">
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                        </span>
                      </div>
                    </div>
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <div className="list-item">
                      <div className="list-left">
                        <span className="avatar">T</span>
                      </div>
                      <div className="list-body">
                        <span className="message-author">Tarah Shropshire</span>
                        <span className="message-time">12:28 AM</span>
                        <div className="clearfix" />
                        <span className="message-content">
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                        </span>
                      </div>
                    </div>
                  </Link>
                </li>
              </ul>
            </div>
            <div className="topnav-dropdown-footer">
              <Link to="#">See all messages</Link>
            </div>
          </div>
        </div>
      </div>
      <div
        id="delete_patient"
        className="modal fade delete-modal"
        role="dialog"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body text-center">
              <img src={imagesend} alt="#" width={50} height={46} />
              <h3>Are you sure want to delete this ?</h3>
              <div className="m-t-20">
                {" "}
                <Link
                  to="#"
                  className="btn btn-white me-2"
                  data-bs-dismiss="modal"
                >
                  Close
                </Link>
                <button
                  // onClick={handleDeletePatient}
                  type="submit"
                  className="btn btn-danger"
                  data-bs-dismiss="modal"
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PatientsList;
